import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useAuthContext from "../../context/AuthContext";
import AdminLayout from "../../layouts/AdminLayout";

//
import TriangleBlueLoadingLottie from "../../assets/lotties/nice-hop/triangle-blue-loading-lottie.json";
import Lottie from "lottie-react";

const PrivateAdmin = ({ children }) => {
    const { getUserQuery } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!getUserQuery?.isLoading && !getUserQuery?.data?.data) {
            navigate("/login");
        }
    }, [getUserQuery?.isLoading]);

    return getUserQuery?.isLoading ? (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // Full viewport height
            }}
        >
            {/* <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i> */}
            <Lottie animationData={TriangleBlueLoadingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
        </div>
    ) : getUserQuery?.data?.data ? (
        <AdminLayout />
    ) : (
        <Navigate to="/login" replace />
    );
};

export default PrivateAdmin;
