import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

//
import {
    getRegionSalesVsSply,
    getActualPerformanceDeficitSurplus,
    getRoutePerformanceDeficitSurplus,
    getProductSalesDeficitSurplus,
    getAgentSalesDeficitSurplus,
    getSelloutAgentProductOnlyDrilldown,
    getSelloutRegionProductOnlyDrilldown,
    getCustomerRankingSales,
} from "../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

//lotties
import BrownFilesProcessingLottie from "../../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialogForLineGraphs from "../../widgets/FiltersFormDialogForLineGraphs";
import { Button } from "primereact/button";

import moment from "moment";
import { Tooltip } from "@mui/material";

import { Accordion, AccordionTab } from "primereact/accordion";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

// Sample data for region performance vs. same period last year
// const sampleData = {
//     regions: [
//         {
//             name: "Central",
//             salesThisYear: [12000, 15000, 18000, 13000, 20000],
//             salesLastYear: [10000, 14000, 16000, 11000, 18000],
//         },
//         {
//             name: "West",
//             salesThisYear: [8000, 10000, 12000, 9000, 15000],
//             salesLastYear: [6000, 9000, 11000, 7000, 13000],
//         },
//     ],
// };

// Function to generate random colors
const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const generateSeriesData = (data) => {
    if (!Array.isArray(data)) {
        return [];
    }
    return data
        .map((region) => {
            return [
                {
                    name: `${region.name} (This Year)`,
                    data: region.salesThisYear,
                    color: getRandomColor(),
                },
                {
                    name: `${region.name} (Last Year)`,
                    data: region.salesLastYear,
                    dashStyle: "ShortDash",
                    color: getRandomColor(),
                },
            ];
        })
        .flat();
};

// const seriesData = generateSeriesData(sampleData);

const RegionPerformanceVsSPLYLineChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const RegionSalesVsSplyQuery = useQuery({
        disable: false,
        queryKey: ["region-sales-vs-sply", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getRegionSalesVsSply({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (RegionSalesVsSplyQuery?.isError) {
            console.log("🚀 ~ useEffect ~ RegionSalesVsSplyQuery?.isError:", RegionSalesVsSplyQuery?.error);
            RegionSalesVsSplyQuery?.error?.response?.data?.message ? toast.error(RegionSalesVsSplyQuery?.error?.response?.data?.message) : !RegionSalesVsSplyQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [RegionSalesVsSplyQuery?.isError]);

    console.log("data for NiceTwo RegionSalesVsSplyQuery?.data?.data is:", RegionSalesVsSplyQuery?.data?.data);

    const seriesData = generateSeriesData(RegionSalesVsSplyQuery?.data?.data?.data?.data);

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    const startDate = RegionSalesVsSplyQuery?.data?.data?.requestParams?.startDate ? moment(RegionSalesVsSplyQuery?.data?.data?.requestParams.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = RegionSalesVsSplyQuery?.data?.data?.requestParams?.endDate ? moment(RegionSalesVsSplyQuery?.data?.data?.requestParams.endDate).format("MMMM Do, YYYY") : "N/S";

    const options = {
        chart: {
            type: "line",
        },
        title: {
            text: `Region Performance (Current Vs SPLY) from ${startDate} to ${endDate}`,
        },
        xAxis: {
            categories: RegionSalesVsSplyQuery?.data?.data?.data?.xAxisCategories,
            // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
            // categories: ["Q1", "Q2", "Q3", "Q4", "Q5"],
        },
        yAxis: {
            title: {
                text: "Sales (UGX)",
            },
        },
        tooltip: {
            shared: true,
        },
        // tooltip: {
        //     shared: true,
        //     formatter: function () {
        //         let s = `<b>${this.x}</b>`;

        //         this.points.forEach(function (point) {
        //             s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y.toFixed(2)}</b>`;
        //         });

        //         return s;
        //     },
        // },
        credits: {
            enabled: false,
        },
        series: seriesData,
    };

    //=============== handle displaying filters on Chart ======================
    const dataFilters = RegionSalesVsSplyQuery?.data?.data?.requestParams;

    // Function to ensure array and join names
    const formatArray = (array, key) => {
        return Array.isArray(array) && array.length > 0 ? array.map((item) => item[key]).join(", ") : "N/S";
    };

    // Extracting and formatting the filters
    const agents = formatArray(dataFilters?.agents, "name");
    const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
    const regions = formatArray(dataFilters?.regions, "name");
    const routes = formatArray(dataFilters?.routes, "name");
    const statuses = formatArray(dataFilters?.statuses, "label");
    const orderBy = dataFilters?.orderBy?.label || "N/S";
    const dataLimit = dataFilters?.dataLimit?.label || "N/S";
    const productCategories = formatArray(dataFilters?.productCategories, "name");
    const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
    const products = formatArray(dataFilters?.products, "name");

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            {RegionSalesVsSplyQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={BrownFilesProcessingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : RegionSalesVsSplyQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialogForLineGraphs
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>

                    <Accordion>
                        <AccordionTab header="Selected Filters" closed>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "0.5rem" }}>
                                <p>
                                    <strong>Start Date:</strong> {startDate}
                                </p>
                                <p>
                                    <strong>End Date:</strong> {endDate}
                                </p>
                                <p>
                                    <strong>Agents:</strong> {agents}
                                </p>
                                <p>
                                    <strong>Sales Associates:</strong> {salesAssociates}
                                </p>
                                <p>
                                    <strong>Regions:</strong> {regions}
                                </p>
                                <p>
                                    <strong>Routes:</strong> {routes}
                                </p>
                                <p>
                                    <strong>Statuses:</strong> {statuses}
                                </p>
                                <p>
                                    <strong>Order By:</strong> {orderBy}
                                </p>
                                <p>
                                    <strong>Data Limit:</strong> {dataLimit}
                                </p>
                                <p>
                                    <strong>Product Categories:</strong> {productCategories}
                                </p>
                                <p>
                                    <strong>Product Sub Categories:</strong> {productSubCategories}
                                </p>
                                <p>
                                    <strong>Products:</strong> {products}
                                </p>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    <HighchartsReact highcharts={Highcharts} options={options} />
                </>
            )}
        </>
    );
};

export default RegionPerformanceVsSPLYLineChart;
