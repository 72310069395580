import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import ProductCategorySalesComparisonChart from "./ProductCategorySalesComparisonChart";
import RegionWithProductDrillPerformanceChart from "./RegionWithProductDrillPerformanceChart";
import AgentWithProductDrillPerformanceChart from "./AgentWithProductDrillPerformanceChart";
import ActualPerfomanceBarChart from "./ActualPerfomanceBarChart";
import RouteWithProductDrillPerformanceChart from "./RouteWithProductDrillPerformanceChart";

function ToggleComparisonActualPerfomanceBarCharts({ measure = "sales_value" }) {
    const [selectedChart, setSelectedChart] = useState("period-perfomance");

    const chartOptions = [
        { label: "Period Perfomance", value: "period-perfomance" },
        { label: "Regional Perfomance", value: "regions-perfomance" },
        { label: "Distributors Perfomance", value: "distributors-perfomance" },
        { label: "Routes Perfomance", value: "routes-perfomance" },
        { label: "Product Category Perfomance", value: "products-perfomance" },
    ];
    return (
        <div className="col-12">
            <Dropdown value={selectedChart} options={chartOptions} onChange={(e) => setSelectedChart(e.value)} placeholder="Select a chart" className="p-mb-4" />
            {selectedChart === "period-perfomance" && <ActualPerfomanceBarChart measure={measure} />}
            {selectedChart === "products-perfomance" && <ProductCategorySalesComparisonChart measure={measure} />}
            {selectedChart === "regions-perfomance" && <RegionWithProductDrillPerformanceChart measure={measure} />}
            {selectedChart === "distributors-perfomance" && <AgentWithProductDrillPerformanceChart measure={measure} />}
            {selectedChart === "routes-perfomance" && <RouteWithProductDrillPerformanceChart measure={measure} />}
        </div>
    );
}

export default ToggleComparisonActualPerfomanceBarCharts;
