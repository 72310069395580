import React, { useState, useContext, useEffect, useRef } from "react";
import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import numeral from "numeral";

//
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OverlayPanel } from "primereact/overlaypanel";

import { getMonthlyRevenueData } from "../../../../services/nice-two-endpoints/nice-two-revenue-cards-service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";

import { Grid, Stack } from "@mui/material";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import RobotProcessorLottie from "../../../../assets/lotties/nice-hop/robot-processor-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

//
import CardsFiltersFormDialog from "./widgets/CardsFiltersFormDialog";
import { Button } from "primereact/button";
import { Tooltip } from "@mui/material";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const formatNumber = (number) => {
    return numeral(number).format("0.[00]a"); // e.g., 3.5k, 3.45m, 3.4b
};

const getChangeColor = (change) => {
    if (change > 100) {
        return "green";
    } else if (change < 90) {
        return "red";
    } else {
        return "orange";
    }
};

const getChangeColor2 = (change) => {
    if (change > 100) {
        return "red";
    } else if (change < 90) {
        return "green";
    } else {
        return "orange";
    }
};

const RevenueMonthToDate = ({ measure = "sales_value" }) => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        // startDate: filters?.startDate,
        // endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            // { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const MonthlyRevenueDataQuery = useQuery({
        disable: false,
        queryKey: ["getMonthlyRevenueData", measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getMonthlyRevenueData({ measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (MonthlyRevenueDataQuery?.isError) {
            console.log("🚀 ~ useEffect ~ MonthlyRevenueDataQuery?.isError:", MonthlyRevenueDataQuery?.error);
            MonthlyRevenueDataQuery?.error?.response?.data?.message ? toast.error(MonthlyRevenueDataQuery?.error?.response?.data?.message) : !MonthlyRevenueDataQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [MonthlyRevenueDataQuery?.isError]);

    if (MonthlyRevenueDataQuery?.isLoading) {
        return (
            <Grid item xs={12} md={6} lg={3}>
                <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
                <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
            </Grid>
        );
    }

    if (MonthlyRevenueDataQuery?.isError) {
        return (
            <Grid item xs={12} md={6} lg={3}>
                <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
            </Grid>
        );
    }

    console.log("data for NiceTwo MonthlyRevenueDataQuery?.data?.data is:", MonthlyRevenueDataQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, MonthlyRevenueDataQuery?.data?.data);

    const revenueTD = MonthlyRevenueDataQuery?.data?.data?.data;

    //====================card ========================
    const SalesCard = ({ title, categories, tooltip, chartData }) => {
        const [visible, setVisible] = useState(false);

        // const calculatePercentageChange = (current, previous) => {
        //     return (((current - previous) / previous) * 100).toFixed(2);
        // };

        const calculatePercentageChange = (current, previous) => {
            if (previous === 0) {
                return (0).toFixed(2);
            }
            return ((current / previous) * 100).toFixed(2);
        };

        const sply = chartData[0]["data"][2];
        const actual = chartData[1]["data"][2];
        const target = chartData[2]["data"][2];

        // console.log("sdafwgkjiuq", chartData[1]["data"][2]);
        const actualVsTargetChange = calculatePercentageChange(actual, target);
        const actualVsSplyChange = calculatePercentageChange(actual, sply);

        const options = {
            chart: {
                type: "line",
                height: "190",
            },
            title: {
                text: `${title} Vs SPLY Vs Target`,
            },
            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: {
                    text: "",
                },
            },
            series: chartData,
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
        };

        return (
            <div className="card mb-0">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{title}</span>
                        <Tooltip title={tooltip?.definition} arrow>
                            <div className={`text-900 font-medium text-xl`}>
                                {measure === "quantity" ? "" : "UGX"} {formatNumber(actual)}
                            </div>
                        </Tooltip>
                    </div>
                    <div
                        className="flex align-items-center justify-content-center bg-blue-100 border-round"
                        style={{
                            width: "2.5rem",
                            height: "2.5rem",
                        }}
                        // onClick={() => setVisible(true)}
                    >
                        {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>

                        <CardsFiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>
                </div>
                <div className="mb-1">
                    {actualVsSplyChange && (
                        <div className={`font-medium mb-1 flex justify-content-between`}>
                            <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange) }}>{actualVsSplyChange}% of SPLY</span>{" "}
                            <span style={{ fontSize: "10px" }}>
                                SPLY: {measure === "quantity" ? "" : "UGX"} {formatNumber(sply)}
                            </span>
                        </div>
                    )}
                    {actualVsTargetChange && (
                        <div className={`font-medium flex justify-content-between`}>
                            <span style={{ fontSize: "10px", color: getChangeColor(actualVsTargetChange) }}>{actualVsTargetChange}% of the Target</span>{" "}
                            <span style={{ fontSize: "10px" }}>
                                Target: {measure === "quantity" ? "" : "UGX"} {formatNumber(target)}
                            </span>
                        </div>
                    )}
                </div>

                <div>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
            </div>
        );
    };

    return (
        <>
            <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1}>
                    <SalesCard {...revenueTD} />
                </Stack>
            </Grid>

            {/* <Grid item xs={12} md={6} lg={3} style={{ pointerEvents: "none", opacity: 0.7 }}>
                <SalesCard {...data.revenueYTD} />
            </Grid> */}
        </>
    );
};

export default RevenueMonthToDate;
