import axiosAPI from "../axiosApi";

export async function getAllNiceTwoSellOutCardStarts(params = {}) {
    const response = await axiosAPI.get("nice-two-sellout-card-stats", { params: params?.params, signal: params?.signal });
    return response;
}

//revenue gauge
export async function getRevenueGauge(params = {}) {
    // const response = await axiosAPI.get("product-sales-vs-sply", { params: params });
    const response = await axiosAPI.get("revenue-gauge-card", { params: params });
    return response;
}

//run rate
export async function getRunRate(params = {}) {
    // const response = await axiosAPI.get("product-sales-vs-sply", { params: params });
    const response = await axiosAPI.get("bop-card", { params: params });
    return response;
}
//Spotlanding graph
export async function getSpotlandingData(params = {}) {
    // const response = await axiosAPI.get("product-sales-vs-sply", { params: params });
    const response = await axiosAPI.get("spot-landing", { params: params });
    return response;
}
//Spotlanding graph
export async function getRevenueToDate(params = {}) {
    // const response = await axiosAPI.get("product-sales-vs-sply", { params: params });
    const response = await axiosAPI.get("sellout-statistics", { params: params });
    return response;
}
// export async function getAllNiceTwoRegions(params = {}) {
//     const response = await axiosAPI.get("nice-two-regions", { params: params });
//     return response;
// }
// export async function getAllNiceTwoRoutes(params = {}) {
//     const response = await axiosAPI.get("nice-two-routes", { params: params });
//     return response;
// }

//================== dashbord graphs ==========================
export async function getProductSalesVsSplyVsTarget(params = {}) {
    // const response = await axiosAPI.get("product-sales-vs-sply", { params: params });
    const response = await axiosAPI.get("product-line", { params: params });
    return response;
}

export async function getProductSalesVsSply(params = {}) {
    // const response = await axiosAPI.get("product-sales-vs-sply", { params: params });
    const response = await axiosAPI.get("product-sales-vs-sply", { params: params });
    return response;
}

export async function getRegionSalesVsSply(params = {}) {
    const response = await axiosAPI.get("region-sales-vs-sply", { params: params });
    return response;
}

export async function getActualPerformanceDeficitSurplus(params = {}) {
    const response = await axiosAPI.get("actual-performance-deficit-surplus", { params: params });
    return response;
}

export async function getRoutePerformanceDeficitSurplus(params = {}) {
    const response = await axiosAPI.get("route-performance-deficit-surplus", { params: params });
    return response;
}

export async function getProductSalesDeficitSurplus(params = {}) {
    const response = await axiosAPI.get("product-sales-deficit-surplus", { params: params });
    return response;
}

export async function getAgentSalesDeficitSurplus(params = {}) {
    const response = await axiosAPI.get("agent-sales-deficit-surplus", { params: params });
    return response;
}

export async function getSelloutAgentProductOnlyDrilldown(params = {}) {
    const response = await axiosAPI.get("sellout-agent-product-only-drilldown", { params: params });
    return response;
}

export async function getSelloutSalesAssociatesOnlyProductDrilldown(params = {}) {
    const response = await axiosAPI.get("sellout-salesassoc-product-drilldown", { params: params });
    return response;
}

export async function getCustomerSalesProductOnlyDrilldown(params = {}) {
    const response = await axiosAPI.get("customer-sales-product-only-drilldown", { params: params });
    return response;
}

export async function getSelloutRegionProductOnlyDrilldown(params = {}) {
    const response = await axiosAPI.get("sellout-region-product-only-drilldown", { params: params });
    return response;
}

export async function getCustomerRankingSales(params = {}) {
    const response = await axiosAPI.get("customer-ranking-sales", { params: params });
    return response;
}

export async function getMostSellingProducts(params = {}) {
    const response = await axiosAPI.get("top-selling-products", { params: params });
    return response;
}

export async function getMostProducedProducts(params = {}) {
    const response = await axiosAPI.get("top-produced-products", { params: params });
    return response;
}

//======================= filters end points ================================

// Fetch data for two agents
export async function getAllNiceTwoAgents(params = {}) {
    const response = await axiosAPI.get("nice-two-agents", { params: params });
    return response;
}

// Fetch data for two sales associates
export async function getAllNiceTwoSalesAssociates(params = {}) {
    const response = await axiosAPI.get("nice-two-sales-associates", { params: params });
    return response;
}

// Fetch data for two regions
export async function getAllNiceTwoRegions(params = {}) {
    const response = await axiosAPI.get("nice-two-regions", { params: params });
    return response;
}

// Fetch data for two routes
export async function getAllNiceTwoRoutes(params = {}) {
    const response = await axiosAPI.get("nice-two-routes", { params: params });
    return response;
}

// Fetch data for two product categories
export async function getAllNiceTwoProductCategories(params = {}) {
    const response = await axiosAPI.get("nice-two-product-categories", { params: params });
    return response;
}

// Fetch data for two product subcategories
export async function getAllNiceTwoProductSubCategories(params = {}) {
    const response = await axiosAPI.get("nice-two-product-subcategories", { params: params });
    return response;
}

// Fetch data for two products
export async function getAllNiceTwoProducts(params = {}) {
    const response = await axiosAPI.get("nice-two-products", { params: params });
    return response;
}

//======================= filters end points ================================

//=================================== transction drills =============================
export async function getAllNiceTxCompleteDrill(params = {}) {
    const response = await axiosAPI.get("nice-two-tx-complete-drill", { params: params });
    return response;
}

export async function getAllNiceTxProductCompleteDrill(params = {}) {
    const response = await axiosAPI.get("nice-two-tx-product-complete-drill", { params: params });
    return response;
}

//================== ranking drills ================
export async function getAllNiceTxSalesAssocWithProductDrill(params = {}) {
    const response = await axiosAPI.get("nice-two-tx-sales-asscoc-with-product-drill", { params: params });
    return response;
}

export async function getAllNiceTxAgentWithProductDrill(params = {}) {
    const response = await axiosAPI.get("nice-two-tx-agent-with-product-drill", { params: params });
    return response;
}
export async function getAllNiceTxRegionWithProductDrill(params = {}) {
    const response = await axiosAPI.get("nice-two-tx-region-with-product-drill", { params: params });
    return response;
}
export async function getAllNiceTxCustomerWithProductDrill(params = {}) {
    const response = await axiosAPI.get("nice-two-tx-customer-with-product-drill", { params: params });
    return response;
}
export async function getAllNiceTxRoutesWithProductDrill(params = {}) {
    const response = await axiosAPI.get("nice-two-tx-routes-with-product-drill", { params: params });
    return response;
}

// =============== drill down end points =================

//================= stat cards ====================
export async function getAllNiceVistsStatCards(params = {}) {
    const response = await axiosAPI.get("nice-two-sellout-vists-statistics-cards", { params: params?.params, signal: params?.signal });
    return response;
}
export async function getAllNiceOrdersStatCards(params = {}) {
    const response = await axiosAPI.get("nice-two-sellout-order-statistics-cards", { params: params?.params, signal: params?.signal });
    return response;
}

// export async function getChannelById(id) {
//     const response = await axiosAPI.get(`channels/` + id);
//     return response;
// }

// export async function postChannel(data) {
//     const response = await axiosAPI.post(`channels`, data);
//     return response;
// }

// export async function updateChannel(id, data) {
//     const response = await axiosAPI.post(`channels/${id}`, data);
//     return response;
// }

// export async function deleteChannelById(id) {
//     const response = await axiosAPI.delete(`channels/${id}`);
//     return response;
// }
