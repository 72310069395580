import axiosAPI from "../axiosApi";

export async function getTotalCustomerStatistics(params = {}) {
    const response = await axiosAPI.get("getTotalCustomerStatistics", { params: params });
    return response;
}

export async function getTotalVisitsStatistics(params = {}) {
    const response = await axiosAPI.get("getTotalVisitsStatistics", { params: params });
    return response;
}

export async function getTotalUniqueVisitsStatistics(params = {}) {
    const response = await axiosAPI.get("getTotalUniqueVisitsStatistics", { params: params });
    return response;
}

export async function getTotalProductiveVisitsStatistics(params = {}) {
    const response = await axiosAPI.get("getTotalProductiveVisitsStatistics", { params: params });
    return response;
}
export async function getTotalUniqueProductiveVisitsStatistics(params = {}) {
    const response = await axiosAPI.get("getTotalUniqueProductiveVisitsStatistics", { params: params });
    return response;
}

export async function getNewCustomerStatistics(params = {}) {
    const response = await axiosAPI.get("getNewCustomerStatistics", { params: params });
    return response;
}

export async function getTotalVisitsTimeStatistics(params = {}) {
    const response = await axiosAPI.get("getTotalVisitsTimeStatistics", { params: params });
    return response;
}

export async function getAverageVisitsTimeStatistics(params = {}) {
    const response = await axiosAPI.get("getAverageVisitsTimeStatistics", { params: params });
    return response;
}

export async function getMedianVisitsTimeStatistics(params = {}) {
    const response = await axiosAPI.get("getMedianVisitsTimeStatistics", { params: params });
    return response;
}

export async function getInactiveCustomerStatistics(params = {}) {
    const response = await axiosAPI.get("getInactiveCustomerStatistics", { params: params });
    return response;
}

export async function getAgentsVisitsPieStatistics(params = {}) {
    const response = await axiosAPI.get("getAgentsVisitsPieStatistics", { params: params });
    return response;
}

export async function getSalesAssociateVisitsPieStatistics(params = {}) {
    const response = await axiosAPI.get("getSalesAssociateVisitsPieStatistics", { params: params });
    return response;
}
