import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);

const sampleData = {
    categories: [
        {
            id: "category1",
            name: "Bottles",
            sply_sales: 100,
            actual_sales: 120,
            target_sales: 130,
            subCategories: [
                {
                    id: "subcategory1-1",
                    name: "Water Bottles",
                    sply_sales: 50,
                    actual_sales: 60,
                    target_sales: 70,
                    products: [
                        { name: "Bottle A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                        { name: "Bottle B", sply_sales: 15, actual_sales: 18, target_sales: 20 },
                        { name: "Bottle C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                    ],
                },
                {
                    id: "subcategory1-2",
                    name: "Juice Bottles",
                    sply_sales: 50,
                    actual_sales: 60,
                    target_sales: 70,
                    products: [
                        { name: "Bottle D", sply_sales: 25, actual_sales: 28, target_sales: 30 },
                        { name: "Bottle E", sply_sales: 20, actual_sales: 22, target_sales: 25 },
                    ],
                },
            ],
        },
        {
            id: "category2",
            name: "Containers",
            sply_sales: 90,
            actual_sales: 110,
            target_sales: 120,
            subCategories: [
                {
                    id: "subcategory2-1",
                    name: "Food Containers",
                    sply_sales: 45,
                    actual_sales: 55,
                    target_sales: 60,
                    products: [
                        { name: "Container A", sply_sales: 15, actual_sales: 20, target_sales: 25 },
                        { name: "Container B", sply_sales: 12, actual_sales: 15, target_sales: 18 },
                        { name: "Container C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                    ],
                },
                {
                    id: "subcategory2-2",
                    name: "Storage Containers",
                    sply_sales: 45,
                    actual_sales: 55,
                    target_sales: 60,
                    products: [
                        { name: "Container D", sply_sales: 25, actual_sales: 30, target_sales: 35 },
                        { name: "Container E", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                    ],
                },
            ],
        },
    ],
};

const GroupedProductSalesDrilldownChart = ({ data = sampleData }) => {
    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const categoriesData = [];

        // Handle product categories
        data.categories.forEach((category) => {
            categoriesData.push([
                {
                    name: category.name,
                    y: category.sply_sales,
                    drilldown: `${category.id}_sply`,
                },
                {
                    name: category.name,
                    y: category.actual_sales,
                    drilldown: `${category.id}_actual`,
                },
                {
                    name: category.name,
                    y: category.target_sales,
                    drilldown: `${category.id}_target`,
                },
            ]);

            // Handle product subcategories
            ["sply", "actual", "target"].forEach((saleType) => {
                const categoryDrilldown = {
                    name: `${category.name} ${saleType.toUpperCase()} Sales`,
                    id: `${category.id}_${saleType}`,
                    data: category.subCategories.map((subCategory) => ({
                        name: subCategory.name,
                        y: subCategory[`${saleType}_sales`],
                        drilldown: `${subCategory.id}_${saleType}`,
                    })),
                };
                drilldownSeries.push(categoryDrilldown);

                // Handle products
                category.subCategories.forEach((subCategory) => {
                    const subCategoryDrilldown = {
                        name: `${subCategory.name} ${saleType.toUpperCase()} Sales`,
                        id: `${subCategory.id}_${saleType}`,
                        data: subCategory.products.map((product) => ({
                            name: product.name,
                            y: product[`${saleType}_sales`],
                        })),
                    };
                    drilldownSeries.push(subCategoryDrilldown);
                });
            });
        });

        return { categoriesData, drilldownSeries };
    };

    const { categoriesData, drilldownSeries } = generateDrilldownData(data);
    console.log("🚀 ~ GroupedProductSalesDrilldownChart ~ categoriesData:", categoriesData);
    console.log("🚀 ~ GroupedProductSalesDrilldownChart ~ drilldownSeries:", drilldownSeries);

    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: "Product Sales Drilldown",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Sales",
            },
        },
        legend: {
            enabled: true,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                },
            },
        },
        series: [
            {
                name: "SPLY Sales",
                data: categoriesData.map((category) => category[0]),
                color: Highcharts.getOptions().colors[0],
            },
            {
                name: "Actual Sales",
                data: categoriesData.map((category) => category[1]),
                color: Highcharts.getOptions().colors[1],
            },
            {
                name: "Target Sales",
                data: categoriesData.map((category) => category[2]),
                color: Highcharts.getOptions().colors[2],
            },
        ],
        drilldown: {
            allowPointDrilldown: true,
            series: drilldownSeries,
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default GroupedProductSalesDrilldownChart;
