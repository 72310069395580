import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

//
import { getAllNiceTxProductCompleteDrill } from "../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import BrownFilesProcessingLottie from "../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import ServerProcessingLottie from "../../../../assets/lotties/nice-hop/server-processing-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialog from "../../widgets/FiltersFormDialog";
import { Button } from "primereact/button";

import moment from "moment";
import { Tooltip } from "@mui/material";
import { Accordion, AccordionTab } from "primereact/accordion";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

// Sample hierarchical data for agents and products

const getChartOptions = (sampleData, dataFilters) => {
    if (!sampleData?.product_categories || !Array.isArray(sampleData?.product_categories)) {
        return null;
    }

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const productCategoriesData = data.product_categories.map((category) => {
            const categoryId = category.categoryName;

            // Add product categories drilldown
            drilldownSeries.push({
                id: categoryId,
                name: `${category.categoryName} Sub-categories`,
                data: category.product_sub_categories.map((subCategory) => ({
                    name: subCategory.subCategoryName,
                    y: subCategory.sales,
                    drilldown: `${categoryId}-${subCategory.subCategoryName}`, // Ensure unique drilldown ID
                })),
            });

            category.product_sub_categories.forEach((subCategory) => {
                const subCategoryId = `${categoryId}-${subCategory.subCategoryName}`;

                // Add products drilldown
                drilldownSeries.push({
                    id: subCategoryId,
                    name: `${subCategory.subCategoryName} Products`,
                    data: subCategory.products.map((product) => [product.name, product.sales]),
                });
            });

            return {
                name: category.categoryName,
                y: category.sales,
                drilldown: categoryId,
            };
        });

        return { productCategoriesData, drilldownSeries };
    };

    const { productCategoriesData, drilldownSeries } = generateDrilldownData(sampleData);

    return {
        chart: {
            type: "bar", // Change this to "bar"
        },
        title: {
            text: `Sales Drilldown from Product Category from ${startDate} to ${endDate}`,
        },
        subtitle: {
            text: "Click the columns to view deeper levels.",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Total Sales (UGX)",
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.1f}",
                },
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.2f} UGX</b><br/>',
        },
        series: [
            {
                name: "Product Categories ",
                colorByPoint: true,
                data: productCategoriesData,
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            series: drilldownSeries,
        },
        credits: {
            enabled: false,
        },
    };
};

const SelloutTxProductDrilldownChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });
    console.log("🚀 ~ SelloutTxProductDrilldownChart ~ filtersFormInitialDataValues:", filtersFormInitialDataValues);

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const SellOutNiceTxProductCompleteDrillQuery = useQuery({
        disable: false,
        queryKey: ["nice-two-tx-product-complete-drill", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getAllNiceTxProductCompleteDrill({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SellOutNiceTxProductCompleteDrillQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SellOutNiceTxProductCompleteDrillQuery?.isError:", SellOutNiceTxProductCompleteDrillQuery?.error);
            SellOutNiceTxProductCompleteDrillQuery?.error?.response?.data?.message
                ? toast.error(SellOutNiceTxProductCompleteDrillQuery?.error?.response?.data?.message)
                : !SellOutNiceTxProductCompleteDrillQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SellOutNiceTxProductCompleteDrillQuery?.isError]);

    console.log("data for NiceTwo SellOutNiceTxProductCompleteDrillQuery?.data?.data is:", SellOutNiceTxProductCompleteDrillQuery?.data?.data);

    const chartOptionsData = getChartOptions(SellOutNiceTxProductCompleteDrillQuery?.data?.data?.data, SellOutNiceTxProductCompleteDrillQuery?.data?.data?.requestParams);

    //=============== handle displaying filters on Chart ======================
    const dataFilters = SellOutNiceTxProductCompleteDrillQuery?.data?.data?.requestParams;

    // Format the dates
    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    // Function to ensure array and join names
    const formatArray = (array, key) => {
        return Array.isArray(array) && array.length > 0 ? array.map((item) => item[key]).join(", ") : "N/S";
    };

    // Extracting and formatting the filters
    const agents = formatArray(dataFilters?.agents, "name");
    const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
    const regions = formatArray(dataFilters?.regions, "name");
    const routes = formatArray(dataFilters?.routes, "name");
    const statuses = formatArray(dataFilters?.statuses, "label");
    const orderBy = dataFilters?.orderBy?.label || "N/S";
    const dataLimit = dataFilters?.dataLimit?.label || "N/S";
    const productCategories = formatArray(dataFilters?.productCategories, "name");
    const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
    const products = formatArray(dataFilters?.products, "name");

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            {SellOutNiceTxProductCompleteDrillQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={ServerProcessingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : SellOutNiceTxProductCompleteDrillQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>

                    <Accordion>
                        <AccordionTab header="Selected Filters" closed>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "0.5rem" }}>
                                <p>
                                    <strong>Start Date:</strong> {startDate}
                                </p>
                                <p>
                                    <strong>End Date:</strong> {endDate}
                                </p>
                                <p>
                                    <strong>Agents:</strong> {agents}
                                </p>
                                <p>
                                    <strong>Sales Associates:</strong> {salesAssociates}
                                </p>
                                <p>
                                    <strong>Regions:</strong> {regions}
                                </p>
                                <p>
                                    <strong>Routes:</strong> {routes}
                                </p>
                                <p>
                                    <strong>Statuses:</strong> {statuses}
                                </p>
                                <p>
                                    <strong>Order By:</strong> {orderBy}
                                </p>
                                <p>
                                    <strong>Data Limit:</strong> {dataLimit}
                                </p>
                                <p>
                                    <strong>Product Categories:</strong> {productCategories}
                                </p>
                                <p>
                                    <strong>Product Sub Categories:</strong> {productSubCategories}
                                </p>
                                <p>
                                    <strong>Products:</strong> {products}
                                </p>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    <HighchartsReact highcharts={Highcharts} options={chartOptionsData} />
                </>
            )}
        </>
    );
};

export default SelloutTxProductDrilldownChart;
