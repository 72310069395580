import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
// import ProductSalesPerfomanceSpLineGraph from "./ProductSalesPerfomanceSpLineGraph";
import RegionSalesPerformanceSplineChart from "./RegionSalesPerformanceSplineChart";
import AgentSalesPerformanceSplineChart from "./AgentSalesPerformanceSplineChart";
import RouteSalesPerformanceSplineChart from "./RouteSalesPerformanceSplineChart";
import ProductCategorySalesPerfomanceSpLineGraph from "./ProductCategorySalesPerfomanceSpLineGraph";

function ToggleComparisonActualPerfomanceSplineCharts({ measure = "sales_value" }) {
    const [selectedChart, setSelectedChart] = useState("region-perfomance");

    const chartOptions = [
        { label: "Regional Perfomance", value: "region-perfomance" },
        { label: "Distributors Perfomance", value: "agent-perfomance" },
        { label: "Routes Perfomance", value: "route-perfomance" },
        { label: "Product Category Perfomance", value: "product-catz-perfomance" },
        // { label: "Products Perfomance", value: "product-perfomance" },
    ];
    return (
        <div className="col-12">
            <Dropdown value={selectedChart} options={chartOptions} onChange={(e) => setSelectedChart(e.value)} placeholder="Select a chart" className="p-mb-4" />
            {/* {selectedChart === "product-perfomance" && <ProductSalesPerfomanceSpLineGraph />} */}
            {selectedChart === "region-perfomance" && <RegionSalesPerformanceSplineChart measure={measure} />}
            {selectedChart === "agent-perfomance" && <AgentSalesPerformanceSplineChart measure={measure} />}
            {selectedChart === "route-perfomance" && <RouteSalesPerformanceSplineChart measure={measure} />}
            {selectedChart === "product-catz-perfomance" && <ProductCategorySalesPerfomanceSpLineGraph measure={measure} />}
        </div>
    );
}

export default ToggleComparisonActualPerfomanceSplineCharts;
