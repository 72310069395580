import React from "react";
import { Panel } from "primereact/panel";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

// Sample data
const productQuantityData = {
    products: ["Cups", "Basins", "Toothbrushes", "Buckets", "Plates", "Containers", "Spoons", "Bowls", "Trays", "Bottles"],
    sellIn: [1600, 2100, 1700, 2300, 1800, 2200, 1500, 2000, 2400, 1900],
    sellOut: [1400, 1900, 1900, 2100, 1600, 2000, 1700, 1800, 2200, 1700],
};

// Chart options
// Chart options
const getQuantityChartOptions = () => ({
    chart: {
        type: "bar",
    },
    title: {
        text: `Product Quantity Sell In vs Sell Out - ${new Date().getFullYear()}`,
        align: "left",
    },
    // subtitle: {
    //     text: "Source: Internal Data",
    //     align: "left",
    // },
    xAxis: {
        categories: productQuantityData.products,
        title: {
            text: "Products",
        },
        gridLineWidth: 1,
        lineWidth: 0,
    },
    yAxis: {
        min: 0,
        title: {
            text: "Quantity (No)",
            align: "high",
        },
        labels: {
            overflow: "justify",
        },
        gridLineWidth: 0,
    },
    tooltip: {
        valueSuffix: " No",
    },
    plotOptions: {
        bar: {
            borderRadius: "50%",
            dataLabels: {
                enabled: true,
            },
            groupPadding: 0.1,
        },
    },
    legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true,
    },
    credits: {
        enabled: false,
    },
    series: [
        {
            name: "Sell In",
            data: productQuantityData.sellIn,
            color: "#E3170A", // Set color for Sell In
        },
        {
            name: "Sell Out",
            data: productQuantityData.sellOut,
            color: "#53DD6C", // Set color for Sell Out
        },
    ],
});

//pie chart
// Pie chart options
const getPieChartOptions = () => {
    const colors = Highcharts.getOptions().colors;
    const totalSellIn = productQuantityData.sellIn.reduce((a, b) => a + b, 0);
    const totalSellOut = productQuantityData.sellOut.reduce((a, b) => a + b, 0);

    const sellInData = productQuantityData.products.map((product, index) => ({
        name: `${product} (Sell In)`,
        y: productQuantityData.sellIn[index],
        color: colors[index % colors.length],
    }));

    const sellOutData = productQuantityData.products.map((product, index) => ({
        name: `${product} (Sell Out)`,
        y: productQuantityData.sellOut[index],
        color: colors[(index + 1) % colors.length],
    }));

    return {
        chart: {
            type: "pie",
        },
        title: {
            text: "Product Sell In vs Sell Out",
        },
        tooltip: {
            valueSuffix: " units",
        },
        plotOptions: {
            pie: {
                shadow: false,
                center: ["50%", "50%"],
            },
        },
        series: [
            {
                name: "Total",
                data: [
                    {
                        name: "Sell In",
                        y: totalSellIn,
                        color: colors[0],
                    },
                    {
                        name: "Sell Out",
                        y: totalSellOut,
                        color: colors[1],
                    },
                ],
                size: "60%",
                dataLabels: {
                    formatter: function () {
                        return `<b>${this.point.name}:</b> ${this.y} No`;
                    },
                    color: "#ffffff",
                    distance: -30,
                },
            },
            {
                name: "Total",
                data: [...sellInData, ...sellOutData],
                size: "80%",
                innerSize: "60%",
                dataLabels: {
                    formatter: function () {
                        return `<b>${this.point.name}:</b> ${this.y} No`;
                    },
                },
                id: "versions",
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        series: [
                            {},
                            {
                                id: "versions",
                                dataLabels: {
                                    distance: 15,
                                    format: "{point.name}",
                                    filter: {
                                        property: "percentage",
                                        operator: ">",
                                        value: 2,
                                    },
                                },
                            },
                        ],
                    },
                },
            ],
        },
    };
};

// Components
const ProductQuantitySellInVsSellOutTrendChart = () => {
    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={getQuantityChartOptions()} />
            <HighchartsReact highcharts={Highcharts} options={getPieChartOptions()} />
        </>
    );
};

export default ProductQuantitySellInVsSellOutTrendChart;
