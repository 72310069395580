import React, { useState, useContext, useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

import { getSellInAgentPfmceAVsSPLYVsTSplineChart } from "../../../../services/sell-in/sell-In-Spline-Charts";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DashboardFilterContext } from "../../../../context/DashboardFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialogForLineGraphs from "../../widgets/FiltersFormDialogForLineGraphs";
import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";

import { Accordion, AccordionTab } from "primereact/accordion";

//
import { getAllChannels } from "../../../../services/dashboard/filters";

//
import useHandleQueryError from "../../../../hooks/useHandleQueryError";
import useAuthContext from "../../../../context/AuthContext";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

// Sample data
const sampleData = {
    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    products: [
        {
            name: "Product A",
            sply_sales: [100, 120, 110, 130, 140, 150, 160, 170, 180, 190, 200, 210],
            actual_sales: [110, 130, 120, 140, 150, 160, 170, 180, 190, 200, 210, 220],
            target_sales: [120, 140, 130, 150, 160, 170, 180, 190, 200, 210, 220, 230],
        },
        // {
        //     name: "Product B",
        //     sply_sales: [90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200],
        //     actual_sales: [95, 105, 115, 125, 135, 145, 155, 165, 175, 185, 195, 205],
        //     target_sales: [100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210],
        // },
        // Add more products as needed
    ],
};

// const generateSeriesData = (data) => {
//     if (!Array.isArray(data)) {
//         return [];
//     }
//     return data
//         .map((product) => {
//             return [
//                 {
//                     name: `${product.name} (Target)`,
//                     data: product.salesTarget,
//                     // color: getRandomColor(),
//                 },
//                 {
//                     name: `${product.name} (This Year)`,
//                     data: product.salesThisYear,
//                     // color: getRandomColor(),
//                 },
//                 {
//                     name: `${product.name} (Last Year)`,
//                     data: product.salesLastYear,
//                     dashStyle: "ShortDash",
//                     // color: getRandomColor(),
//                 },
//             ];
//         })
//         .flat();
// };

const getChartOptions = (data, dataFilters, visibleSeries) => {
    console.log("🚀 ~ gggggg getChartOptions ~ data:", data?.data);
    if (!data?.data || !Array.isArray(data?.data)) {
        return null;
    }
    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

        // Extracting and formatting the filters
        const agents = formatArray(dataFilters?.agents, "name");
        const salesAssociates = formatArray(dataFilters?.sales_associates, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const routes = formatArray(dataFilters?.routes, "name");
        const statuses = formatArray(dataFilters?.statuses, "label");
        const orderBy = dataFilters?.orderBy?.label;
        const dataLimit = dataFilters?.dataLimit?.label;
        const dataLimitNumber = dataFilters?.dataLimitNumber;
        const viewInterval = dataFilters?.viewInterval?.label;
        const productCategories = formatArray(dataFilters?.productCategories, "name");
        const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
        const products = formatArray(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "Filters: ";
        if (agents) sentence += `Distributors: ${agents}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        if (orderBy) sentence += `Order By: ${orderBy}. `;
        if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
        if (dataLimitNumber) sentence += `Data Limit No: ${dataLimitNumber}. `;
        if (viewInterval) sentence += `View Interval: ${viewInterval}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        if (startDate) sentence += `Start Date: ${startDate}. `;
        if (endDate) sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    let chartTitle = formatFilters(dataFilters);

    const defaultColors = Highcharts.getOptions().colors;
    const options = {
        chart: {
            type: "spline",
        },
        title: { text: `Business Partners Sales Performance <br/> ${chartTitle}` },
        xAxis: {
            categories: data.xAxisCategories,
            title: {
                text: "Categories",
            },
        },
        yAxis: {
            title: {
                text: "Sales",
            },
            labels: {
                formatter: function () {
                    // Automatically format the label to show 'B' instead of 'G'
                    if (this.value >= 1000000000) {
                        return this.value / 1000000000 + "b"; // Format to billions
                    }
                    if (this.value >= 1000000) {
                        return this.value / 1000000 + "m"; // Format to billions
                    }
                    if (this.value >= 1000) {
                        return this.value / 1000 + "k"; // Format to billions
                    }
                    return this.value; // Return the original value for lower numbers
                },
            },
        },
        // \
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: data.data.flatMap((product, index) => [
            {
                name: `${product.name} - SPLY Sales`,
                data: product.sply_sales,
                visible: visibleSeries.SPLY,
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
                dashStyle: "Dot", // Use dotted line
                lineWidth: 2,
                // visible: data.data.length <= 1 ? true : false,
                color: defaultColors[index % defaultColors.length],
            },
            {
                name: `${product.name} - Actual Sales`,
                data: product.actual_sales,
                visible: visibleSeries.Actual,
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
                dashStyle: "Solid", // Use dotted line
                lineWidth: 2,
                color: defaultColors[index % defaultColors.length],
            },
            {
                name: `${product.name} - Target Sales`,
                data: product.target_sales,
                visible: visibleSeries.Target,
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
                dashStyle: "Dash", // Use dotted line
                lineWidth: 2,
                // visible: data.data.length <= 1 ? true : false,
                color: defaultColors[index % defaultColors.length],
            },
        ]),
        legend: {
            align: "right", // Aligns the legend to the right
            verticalAlign: "middle", // Vertically centers the legend
            layout: "vertical", // Displays the legend items in a vertical layout
            maxHeight: 250, // Set a maximum height for the legend
            scrollable: true, // Make the legend scrollable
            navigation: {
                enabled: true, // Enables navigation arrows for the legend
            },
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom",
                        },
                    },
                },
            ],
        },
    };
    return options;
};

function AgentSalesPerformanceSplineChart({ measure = "sales_value" }) {
    const [visibleSeries, setVisibleSeries] = useState({
        SPLY: true,
        Actual: true,
        Target: true,
    });

    //
    const toggleSeriesVisibility = (seriesType) => {
        setVisibleSeries((prev) => ({
            ...prev,
            [seriesType]: !prev[seriesType],
        }));
    };

    //
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        viewInterval: { id: 1, label: "Default", value: "default" },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    //
    //===== set channel distribution ============
    const { getUserQuery } = useAuthContext();
    //get distribution channel
    // Channels Query
    const channelsQuery = useQuery({
        queryKey: ["sellin-channels", "by_channel_name", "distribution"],
        queryFn: () => getAllChannels({ channel_name: "distribution" }),
        enabled: getUserQuery?.data?.data ? true : false,
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(channelsQuery?.isError, channelsQuery?.error);

    // Memoize the filters, updating the channels only if it's an empty array
    const memorisedDistributionChannelsData = useMemo(() => {
        return channelsQuery?.data?.data?.data;
    }, [channelsQuery?.data?.data?.data]);

    console.log("🚀 ~ memorisedDistributionChannelsData ~ memorisedDistributionChannelsData:", memorisedDistributionChannelsData);

    useEffect(() => {
        let updatedChannels = [];

        if (filtersFormInitialDataValues.channels.length === 0) {
            // If channels are empty, determine what to set based on loading state
            if (channelsQuery?.isLoading) {
                updatedChannels = []; // Return empty array if loading
            } else if (memorisedDistributionChannelsData) {
                updatedChannels = memorisedDistributionChannelsData; // Return the data as an array if not loading
            }
        } else {
            updatedChannels = filtersFormInitialDataValues.channels;
        }

        // Update the filtersFormInitialDataValues state with the updated channels
        setFiltersFormInitialDataValues((oldData) => ({
            ...oldData,
            channels: updatedChannels,
        }));
    }, [memorisedDistributionChannelsData, channelsQuery?.isLoading]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const SellInAgentPfmceAVsSPLYVsTSplineChartQuery = useQuery({
        disable: false,
        queryKey: ["SellInAgentPfmceAVsSPLYVsTSplineChart", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellInAgentPfmceAVsSPLYVsTSplineChart({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isError:", SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error);
            SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error?.response?.data?.message
                ? toast.error(SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error?.response?.data?.message)
                : !SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isError]);

    if (SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data is:", SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data);

    console.log("zinasd", sampleData, SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data);

    const data = SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data?.data;

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data?.requestParams?.startDate ? moment(SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data?.requestParams.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data?.requestParams?.endDate ? moment(SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data?.requestParams.endDate).format("YYYY-MM-DD") : "N/S";

    //=============== handle displaying filters on Chart ======================
    const dataFilters = SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data?.requestParams;

    const seriesData = SellInAgentPfmceAVsSPLYVsTSplineChartQuery?.data?.data?.data;

    return (
        <div>
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                <div className="series-toggle-buttons" style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                    <Button
                        label="SPLY"
                        onClick={() => toggleSeriesVisibility("SPLY")}
                        style={{
                            backgroundColor: visibleSeries.SPLY ? "#2caffe" : "grey",
                            borderColor: visibleSeries.SPLY ? "#2caffe" : "grey",
                        }}
                    />
                    <Button
                        label="Actual"
                        onClick={() => toggleSeriesVisibility("Actual")}
                        style={{
                            backgroundColor: visibleSeries.Actual ? "#544fc5" : "grey",
                            borderColor: visibleSeries.Actual ? "#544fc5" : "grey",
                        }}
                    />
                    <Button
                        label="Target"
                        onClick={() => toggleSeriesVisibility("Target")}
                        style={{
                            backgroundColor: visibleSeries.Target ? "#00e272" : "grey",
                            borderColor: visibleSeries.Target ? "#00e272" : "grey",
                        }}
                    />
                </div>

                <div className="text-right">
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>
                    <FiltersFormDialogForLineGraphs
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>

            <HighchartsReact highcharts={Highcharts} options={getChartOptions(seriesData, dataFilters, visibleSeries)} immutable={true} />
        </div>
    );
}

export default AgentSalesPerformanceSplineChart;
