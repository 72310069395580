import axiosAPI from "../axiosApi";

export async function getAllFileImports(params = {}) {
    const response = await axiosAPI.get("file-imports", { params: params });
    return response;
}

export async function getFileImportById(id) {
    const response = await axiosAPI.get(`file-imports/` + id);
    return response;
}

export async function postFileImport(data) {
    const response = await axiosAPI.post(`file-imports`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToImportExcel(data) {
    const response = await axiosAPI.post(
        `import-excel`,
        data
        //     {
        //     headers: { "Content-Type": "multipart/form-data" },
        // }
    );
    return response;
}

export async function deleteBcData() {
    const response = await axiosAPI.get(
        `delete-transactions`
        //     {
        //     headers: { "Content-Type": "multipart/form-data" },
        // }
    );
    return response;
}

export async function flushDataCache() {
    const response = await axiosAPI.get(
        `flush-cache`
        //     {
        //     headers: { "Content-Type": "multipart/form-data" },
        // }
    );
    return response;
}

export async function dispatchBcDataJob() {
    const response = await axiosAPI.get(
        `dispatchBcDataJob`
        //     {
        //     headers: { "Content-Type": "multipart/form-data" },
        // }
    );
    return response;
}

export async function jobDone() {
    const response = await axiosAPI.get(
        `jobDone`
        //     {
        //     headers: { "Content-Type": "multipart/form-data" },
        // }
    );
    return response;
}

export async function updateFileImport(id, data) {
    const response = await axiosAPI.post(`file-imports/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteFileImportById(id) {
    const response = await axiosAPI.delete(`file-imports/${id}`);
    return response;
}
