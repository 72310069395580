import React, { useState, useContext, useEffect, useRef } from "react";
import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import numeral from "numeral";

//
import CardsFiltersFormDialog from "./CardsFiltersFormDialog";
import { Button } from "primereact/button";
import { Tooltip } from "@mui/material";
import moment from "moment";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const getChartOptions = (data, dataFilters) => {
    if (!data?.chartData || !Array.isArray(data?.chartData)) {
        return null;
    }
    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

        // Extracting and formatting the filters
        const agents = formatArray(dataFilters?.agents, "name");
        const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const routes = formatArray(dataFilters?.routes, "name");
        const statuses = formatArray(dataFilters?.statuses, "label");
        const orderBy = dataFilters?.orderBy?.label;
        const dataLimit = dataFilters?.dataLimit?.label;
        const viewInterval = dataFilters?.viewInterval?.label;
        const productCategories = formatArray(dataFilters?.productCategories, "name");
        const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
        const products = formatArray(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "Filters: ";
        if (agents) sentence += `Distributors: ${agents}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        if (orderBy) sentence += `Order By: ${orderBy}. `;
        if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
        if (viewInterval) sentence += `View Interval: ${viewInterval}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        if (startDate) sentence += `Start Date: ${startDate}. `;
        if (endDate) sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    let chartTitle = formatFilters(dataFilters);

    const options = {
        chart: {
            type: "line",
            height: "190",
        },

        // title: { text: `${data?.title} <br/> ${chartTitle}` },
        title: { text: `${data?.title}` },
        xAxis: {
            categories: data?.categories,
        },
        yAxis: {
            title: {
                text: "",
            },
        },
        series: [
            {
                name: "SPLY",
                data: data.chartData.map((item) => item.sply_count),
                // color: "#d3d3d3",
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
            },
            {
                name: "Actual",
                data: data.chartData.map((item) => item.current_count),
                // color: "#000",
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
            },
        ],
        // tooltip: {
        //     pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
        // },
        tooltip: {
            formatter: function () {
                return `${this.series.name}: <b>${this.y}</b>`;
            },
        },

        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
    };
    return options;
};

const CoverageStatsCard = ({ cardData, selctedFilters, handleSubmitForFilters, measure = "sales_value", filtersFormInitialDataValues, setFiltersFormInitialDataValues }) => {
    const formatNumber = (number) => {
        return numeral(number).format("0.[00]a"); // e.g., 3.5k, 3.45m, 3.4b
    };

    const getChangeColor = (change) => {
        if (change > 100) {
            return "green";
        } else if (change < 90) {
            return "red";
        } else {
            return "orange";
        }
    };

    const getChangeColor2 = (change) => {
        if (change > 100) {
            return "red";
        } else if (change < 90) {
            return "green";
        } else {
            return "orange";
        }
    };

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const [visible, setVisible] = useState(false);

    // const calculatePercentageChange = (current, previous) => {
    //     return (((current - previous) / previous) * 100).toFixed(2);
    // };

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    const sply = cardData?.totals?.sply;
    const actual = cardData?.totals?.actual;

    // console.log("sdafwgkjiuq", chartData[1]["data"][2]);
    // const actualVsTargetChange = calculatePercentageChange(actual, target);
    const actualVsSplyChange = calculatePercentageChange(actual, sply);

    //
    const chartOptions = getChartOptions(cardData, selctedFilters);

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3">{cardData?.title}</span>
                    <Tooltip title={cardData?.tooltip?.definition} arrow>
                        <div className={`text-900 font-medium text-xl`}>{formatNumber(actual)}</div>
                    </Tooltip>
                </div>
                <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                    }}
                    onClick={() => setVisible(true)}
                >
                    {/* <i className="pi pi-chart-bar text-blue-500 text-xl" /> */}
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFiltersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </div>
            <div className="mb-1">
                {actualVsSplyChange && (
                    <div className={`font-medium mb-1 flex justify-content-between`}>
                        <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange) }}>{actualVsSplyChange}% of SPLY</span> <span style={{ fontSize: "10px" }}>SPLY: {formatNumber(sply)}</span>
                    </div>
                )}
            </div>

            <div>
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </div>
        </div>
    );
};

export default CoverageStatsCard;
