import React, { useState, useContext, useMemo, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import drilldown from "highcharts/modules/drilldown";
import HC_accessibility from "highcharts/modules/accessibility";

//
import { getSelloutTxAgentpdtDrillSVSAVsTChart } from "../../../../../services/nice-two-endpoints/nice-two-db-comparison-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import { Accordion, AccordionTab } from "primereact/accordion";

//lotties
import MaterialUiLoaderLottie from "../../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
import BrownFilesProcessingLottie from "../../../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialog from "../../../widgets/FiltersFormDialog";
import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);
HC_accessibility(Highcharts);

// Initialize the drilldown module
drilldown(Highcharts);

const sampleData = {
    agents: [
        {
            id: "region1",
            name: "North Region",
            sply_sales: 190,
            actual_sales: 230,
            target_sales: 250,
            categories: [
                {
                    id: "category1",
                    name: "Bottles",
                    sply_sales: 100,
                    actual_sales: 120,
                    target_sales: 130,
                    subCategories: [
                        {
                            id: "subcategory1-1",
                            name: "Water Bottles",
                            sply_sales: 50,
                            actual_sales: 60,
                            target_sales: 70,
                            products: [
                                { id: "product1-1-1", name: "Bottle A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                                { id: "product1-1-2", name: "Bottle B", sply_sales: 15, actual_sales: 18, target_sales: 20 },
                                { id: "product1-1-3", name: "Bottle C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                            ],
                        },
                        {
                            id: "subcategory1-2",
                            name: "Juice Bottles",
                            sply_sales: 50,
                            actual_sales: 60,
                            target_sales: 70,
                            products: [
                                { id: "product1-2-1", name: "Bottle D", sply_sales: 25, actual_sales: 28, target_sales: 30 },
                                { id: "product1-2-2", name: "Bottle E", sply_sales: 20, actual_sales: 22, target_sales: 25 },
                            ],
                        },
                    ],
                },
                {
                    id: "category2",
                    name: "Containers",
                    sply_sales: 90,
                    actual_sales: 110,
                    target_sales: 120,
                    subCategories: [
                        {
                            id: "subcategory2-1",
                            name: "Food Containers",
                            sply_sales: 45,
                            actual_sales: 55,
                            target_sales: 60,
                            products: [
                                { id: "product2-1-1", name: "Container A", sply_sales: 15, actual_sales: 20, target_sales: 25 },
                                { id: "product2-1-2", name: "Container B", sply_sales: 12, actual_sales: 15, target_sales: 18 },
                                { id: "product2-1-3", name: "Container C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                            ],
                        },
                        {
                            id: "subcategory2-2",
                            name: "Storage Containers",
                            sply_sales: 45,
                            actual_sales: 55,
                            target_sales: 60,
                            products: [
                                { id: "product2-2-1", name: "Container D", sply_sales: 25, actual_sales: 30, target_sales: 35 },
                                { id: "product2-2-2", name: "Container E", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: "region2",
            name: "South Region",
            sply_sales: 170,
            actual_sales: 200,
            target_sales: 220,
            categories: [
                {
                    id: "category3",
                    name: "Cutlery",
                    sply_sales: 80,
                    actual_sales: 95,
                    target_sales: 105,
                    subCategories: [
                        {
                            id: "subcategory3-1",
                            name: "Forks",
                            sply_sales: 40,
                            actual_sales: 48,
                            target_sales: 52,
                            products: [
                                { id: "product3-1-1", name: "Fork A", sply_sales: 20, actual_sales: 24, target_sales: 26 },
                                { id: "product3-1-2", name: "Fork B", sply_sales: 20, actual_sales: 24, target_sales: 26 },
                            ],
                        },
                        {
                            id: "subcategory3-2",
                            name: "Spoons",
                            sply_sales: 40,
                            actual_sales: 47,
                            target_sales: 53,
                            products: [
                                { id: "product3-2-1", name: "Spoon A", sply_sales: 20, actual_sales: 23, target_sales: 26 },
                                { id: "product3-2-2", name: "Spoon B", sply_sales: 20, actual_sales: 24, target_sales: 27 },
                            ],
                        },
                    ],
                },
                {
                    id: "category4",
                    name: "Plates",
                    sply_sales: 90,
                    actual_sales: 105,
                    target_sales: 115,
                    subCategories: [
                        {
                            id: "subcategory4-1",
                            name: "Dinner Plates",
                            sply_sales: 50,
                            actual_sales: 58,
                            target_sales: 63,
                            products: [
                                { id: "product4-1-1", name: "Dinner Plate A", sply_sales: 25, actual_sales: 29, target_sales: 31 },
                                { id: "product4-1-2", name: "Dinner Plate B", sply_sales: 25, actual_sales: 29, target_sales: 32 },
                            ],
                        },
                        {
                            id: "subcategory4-2",
                            name: "Side Plates",
                            sply_sales: 40,
                            actual_sales: 47,
                            target_sales: 52,
                            products: [
                                { id: "product4-2-1", name: "Side Plate A", sply_sales: 20, actual_sales: 23, target_sales: 26 },
                                { id: "product4-2-2", name: "Side Plate B", sply_sales: 20, actual_sales: 24, target_sales: 26 },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
const getChartOptions = (data, dataFilters) => {
    if (!data || !Array.isArray(data.agents) || !Array.isArray(data.agents)) {
        return null;
    }
    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

        // Extracting and formatting the filters
        const regions = formatArray(dataFilters?.regions, "name");
        const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
        const agents = formatArray(dataFilters?.agents, "name");
        const routes = formatArray(dataFilters?.routes, "name");
        const statuses = formatArray(dataFilters?.statuses, "label");
        const orderBy = dataFilters?.orderBy?.label || "N/S";
        const dataLimit = dataFilters?.dataLimit?.label || "N/S";
        const dataLimitNumber = dataFilters?.dataLimitNumber;
        const productCategories = formatArray(dataFilters?.productCategories, "name");
        const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
        const products = formatArray(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "Filters: ";
        if (regions) sentence += `Regions: ${regions}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (agents) sentence += `Distributors: ${agents}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        sentence += `Order By: ${orderBy}. `;
        sentence += `Data Limit: ${dataLimit}. `;
        if (dataLimitNumber) sentence += `Data Limit No: ${dataLimitNumber}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        sentence += `Start Date: ${startDate}. `;
        sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    let chartTitle = formatFilters(dataFilters);

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";
    // Format data for chart series
    // Example: { name: "Bottles", sply: 100, actual: 120, target: 130, drilldown: "category1" }
    // products represents final level
    // Format data for chart series

    const formatData = (items, level) => {
        return items.map((item) => ({
            name: item.name,
            sply: item.sply_sales,
            actual: item.actual_sales,
            target: item.target_sales,
            drilldown: level !== "products" ? item.id : null,
        }));
    };

    // Calculate percentage change
    const calculatePercentageChange = (actual, comparison) => {
        return ((actual - comparison) / comparison) * 100;
    };

    // Create drilldown series for each level
    const createDrilldownSeries = (items, level) => {
        return items.map((item) => {
            const nextLevel = level === "agents" ? "categories" : level === "categories" ? "subCategories" : "products";
            const hasNextLevel = item[nextLevel] && item[nextLevel].length > 0;
            const drilldownData = hasNextLevel ? formatData(item[nextLevel], nextLevel) : [];

            return {
                id: item.id,
                data: drilldownData,
                drilldown: hasNextLevel,
            };
        });
    };

    // Create percentage change lollipop series
    const createPercentageChangeSeries = (items, comparisonType) => {
        return {
            type: "lollipop",
            name: `Percentage Change Actual vs. ${comparisonType}`,
            data: items.map((item) => {
                const percentageChange = calculatePercentageChange(item.actual_sales, item[`${comparisonType.toLowerCase()}_sales`]);
                return {
                    name: item.name,
                    y: percentageChange,
                    // color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                    color: percentageChange < 90 ? "#FF0000" : percentageChange > 100 ? "#008000" : "#FFBF00",
                };
            }),
            yAxis: 1,
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y:,.0f}%</b>",
            },
            marker: {
                enabled: true,
                lineColor: null,
                symbol: "circle",
            },
            dataLabels: {
                enabled: true,
                format: "{point.y:,.0f}%",
            },
            showInLegend: false,
        };
    };

    // Create drilldown series for all levels
    const drilldownSeries = [
        ...createDrilldownSeries(data.agents, "agents"),
        ...data.agents.flatMap((item) => createDrilldownSeries(item.categories, "categories")),
        ...data.agents.flatMap((item) => item.categories.flatMap((category) => createDrilldownSeries(category.subCategories, "subCategories"))),
    ];

    const options = {
        chart: {
            type: "bar",
            height: 800,
            scrollablePlotArea: {
                minHeight: 500,
                scrollPositionX: 1,
            },
            events: {
                drilldown: function (e) {
                    if (!e.seriesOptions) {
                        const chart = this;
                        const series = chart.userOptions.drilldown.series.find((s) => s.id === e.point.drilldown);

                        if (series && series.data && series.data.length > 0) {
                            const splyData = series.data.map((item) => ({ name: item.name, y: item.sply, drilldown: item.drilldown }));
                            const actualData = series.data.map((item) => ({ name: item.name, y: item.actual, drilldown: item.drilldown }));
                            const targetData = series.data.map((item) => ({ name: item.name, y: item.target, drilldown: item.drilldown }));

                            // Add SPLY, Actual, and Target sales series
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                name: "SPLY Sales",
                                data: splyData,
                                color: Highcharts.getOptions().colors[0],
                            });
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                name: "Actual Sales",
                                data: actualData,
                                color: Highcharts.getOptions().colors[1],
                            });
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                name: "Target Sales",
                                data: targetData,
                                color: Highcharts.getOptions().colors[2],
                            });

                            // Add percentage change lollipop series for SPLY comparison
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                type: "lollipop",
                                name: "Percentage Change Actual vs. SPLY",
                                data: series.data.map((item) => {
                                    const percentageChange = calculatePercentageChange(item.actual, item.sply);
                                    return {
                                        name: item.name,
                                        y: percentageChange,
                                        color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                    };
                                }),
                                yAxis: 1,
                                tooltip: {
                                    pointFormat: "{series.name}: <b>{point.y:,.0f}%</b>",
                                },
                                marker: {
                                    enabled: true,
                                    lineColor: null,
                                    symbol: "circle",
                                },
                                dataLabels: {
                                    enabled: true,
                                    format: "{point.y:,.0f}%",
                                },
                                showInLegend: false,
                            });

                            // Add percentage change lollipop series for Target comparison
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                type: "lollipop",
                                name: "Percentage Change Actual vs. Target",
                                data: series.data.map((item) => {
                                    const percentageChange = calculatePercentageChange(item.actual, item.target);
                                    return {
                                        name: item.name,
                                        y: percentageChange,
                                        color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                    };
                                }),
                                yAxis: 1,
                                tooltip: {
                                    pointFormat: "{series.name}: <b>{point.y:,.0f}%</b>",
                                },
                                marker: {
                                    enabled: true,
                                    lineColor: null,
                                    symbol: "circle",
                                },
                                dataLabels: {
                                    enabled: true,
                                    format: "{point.y:,.0f}%",
                                },
                                showInLegend: false,
                            });

                            chart.applyDrilldown();
                        } else {
                            // If there's no data to drill down to, prevent the drilldown
                            e.preventDefault();
                        }
                    }
                },
            },
        },
        accessibility: {
            enabled: true,
            keyboardNavigation: {
                enabled: false, // or modify other specific accessibility options
            },
        },
        title: { text: `Distributors Sales Performance <br/> ${chartTitle}` },
        xAxis: { type: "category", uniqueNames: false },
        yAxis: [
            {
                title: { text: "Sales" },
                width: "60%",
                lineWidth: 2,
                offset: 0,
                labels: {
                    formatter: function () {
                        // Automatically format the label to show 'B' instead of 'G'
                        if (this.value >= 1000000000) {
                            return this.value / 1000000000 + "b"; // Format to billions
                        }
                        if (this.value >= 1000000) {
                            return this.value / 1000000 + "m"; // Format to billions
                        }
                        if (this.value >= 1000) {
                            return this.value / 1000 + "k"; // Format to billions
                        }
                        return this.value; // Return the original value for lower numbers
                    },
                },
            },
            {
                title: { text: "Percentage Change" },
                left: "65%",
                width: "30%",
                offset: 0,
                lineWidth: 2,
                // max: 100,
                // min: -100,
            },
        ],
        legend: { enabled: true },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.0f}", // This formats the number to 2 decimal place
                    style: {
                        fill: "#000000",
                    },
                },
                point: {
                    // to disable click events on points without drilldown data
                    events: {
                        click: function () {
                            if (!this.drilldown) {
                                return false;
                            }
                        },
                    },
                },
            },
        },
        credits: {
            enabled: false,
        },
        // Main chart series
        series: [
            {
                name: "SPLY Sales",
                data: data.agents.map((item) => ({
                    name: item.name,
                    y: item.sply_sales,
                    drilldown: item.id,
                    color: Highcharts.getOptions().colors[0],
                })),
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
            },
            {
                name: "Actual Sales",
                data: data.agents.map((item) => ({
                    name: item.name,
                    y: item.actual_sales,
                    drilldown: item.id,
                    color: Highcharts.getOptions().colors[1],
                })),
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
            },
            {
                name: "Target Sales",
                data: data.agents.map((item) => ({
                    name: item.name,
                    y: item.target_sales,
                    drilldown: item.id,
                    color: Highcharts.getOptions().colors[2],
                })),
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
            },
            createPercentageChangeSeries(data.agents, "SPLY"),
            createPercentageChangeSeries(data.agents, "Target"),
        ],
        drilldown: {
            allowPointDrilldown: false,
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            activeDataLabelStyle: {
                color: "#000000",
                textDecoration: "none",
                textOutline: "1px contrast",
                // textOutline: "1px #000000",
            },
            series: drilldownSeries,
        },
    };
    return options;
};

const AgentWithProductDrillPerformanceChart = ({ measure = "sales_value" }) => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        regions: [],
        salesAssociates: [],
        agents: [],
        routes: [],
        statuses: [{ id: 2, label: "Closed", value: "CLOSED" }],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    console.log("testing AgentWithProductDrillPerformanceChart such that it doesnt go into infinite due to useEffect dependancy array : ", filtersFormInitialDataValues);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const SelloutTxAgentpdtDrillSVSAVsTChartQuery = useQuery({
        disable: false,
        queryKey: ["SelloutTxAgentpdtDrillSVSAVsTChart", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSelloutTxAgentpdtDrillSVSAVsTChart({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SelloutTxAgentpdtDrillSVSAVsTChartQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SelloutTxAgentpdtDrillSVSAVsTChartQuery?.isError:", SelloutTxAgentpdtDrillSVSAVsTChartQuery?.error);
            SelloutTxAgentpdtDrillSVSAVsTChartQuery?.error?.response?.data?.message
                ? toast.error(SelloutTxAgentpdtDrillSVSAVsTChartQuery?.error?.response?.data?.message)
                : !SelloutTxAgentpdtDrillSVSAVsTChartQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SelloutTxAgentpdtDrillSVSAVsTChartQuery?.isError]);

    console.log("data for NiceTwo SelloutTxAgentpdtDrillSVSAVsTChartQuery?.data?.data is:", SelloutTxAgentpdtDrillSVSAVsTChartQuery?.data?.data);

    const seriesData = SelloutTxAgentpdtDrillSVSAVsTChartQuery?.data?.data?.data;

    //=============== handle displaying filters on Chart ======================
    const dataFilters = SelloutTxAgentpdtDrillSVSAVsTChartQuery?.data?.data?.requestParams;

    // Format the dates
    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            {SelloutTxAgentpdtDrillSVSAVsTChartQuery?.isLoading ? (
                <>
                    <div className="col-12">
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={BrownFilesProcessingLottie} style={{ height: "300px" }} loop={true} autoplay={true} />
                                <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                            </div>
                        </div>
                        {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    </div>
                </>
            ) : SelloutTxAgentpdtDrillSVSAVsTChartQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>

                        <FiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>

                    <div style={{ height: "400px", overflowY: "auto" }}>
                        <HighchartsReact highcharts={Highcharts} options={getChartOptions(seriesData, SelloutTxAgentpdtDrillSVSAVsTChartQuery?.data?.data?.requestParams)} immutable={true} />
                    </div>
                </>
            )}
        </>
    );
};

export default AgentWithProductDrillPerformanceChart;
