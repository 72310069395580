import React, { useState, useCallback, memo, useMemo } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { useSidebar } from "./context/SidebarContext";

const AppSubmenu = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();
    const componentDetailFromNavLink = state?.componentDetailFromNavLink || null;

    console.log("componentDetailFromNavLink  ggg :  ", componentDetailFromNavLink);

    // Memoizing dependencies
    const memoizedActiveIndex = useMemo(() => activeIndex, [activeIndex]);
    const memoizedComponentDetailFromNavLink = useMemo(() => componentDetailFromNavLink, [componentDetailFromNavLink]);

    // Memoized onMenuItemClick function
    const onMenuItemClick = useCallback(
        (event, item, index) => {
            // Avoid processing disabled items
            if (item.disabled) {
                event.preventDefault();
                return true;
            }

            // Execute command
            if (item.command) {
                item.command({ originalEvent: event, item: item });
            }

            // Toggle active index
            setActiveIndex((prevIndex) => (index === prevIndex ? null : index));

            // Call parent handler if provided
            if (props.onMenuItemClick) {
                props.onMenuItemClick({
                    originalEvent: event,
                    item: item,
                });
            }
        },
        [props.onMenuItemClick]
    );

    // Memoized onKeyDown function
    const onKeyDown = useCallback((event) => {
        if (event.code === "Enter" || event.code === "Space") {
            event.preventDefault();
            event.target.click();
        }
    }, []);

    // Memoized renderLinkContent function
    const renderLinkContent = useCallback((item) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <Badge value={item.badge} />;

        return (
            <React.Fragment>
                <i className={item.icon}></i>
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
                <Ripple />
            </React.Fragment>
        );
    }, []);

    // Memoized renderLink function
    const renderLink = useCallback(
        (item, i, onHideSideBar) => {
            let submenuIcon = item.items && (
                <i
                    className={`pi pi-fw ${memoizedActiveIndex === i ? "pi-angle-up" : "pi-angle-down"} menuitem-toggle-icon`}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent NavLink onClick from being triggered
                        onMenuItemClick(e, item, i);
                    }}
                ></i>
            );
            let badge = item.badge && <Badge value={item.badge} />;

            const linkContent = (
                <React.Fragment>
                    <i className={item.icon}></i>
                    <span>{item.label}</span>
                    {badge}
                </React.Fragment>
            );

            if (item.to) {
                return (
                    <div role="menuitem" style={{ display: "flex", width: "100%", alignItems: "center", cursor: "pointer", justifyContent: "space-between" }}>
                        <NavLink
                            key={item?.label}
                            aria-label={item.label}
                            onKeyDown={onKeyDown}
                            role="menuitem"
                            state={{ componentDetailFromNavLink: item }}
                            style={({ isActive }) => ({
                                width: "100%",
                                color: isActive && item.label === memoizedComponentDetailFromNavLink?.label ? "blue" : "inherit", // Adjust active text color here
                            })}
                            className={({ isActive }) => (isActive && item.label === memoizedComponentDetailFromNavLink?.label ? "navbar-item active" : "navbar-item")}
                            to={item.to}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent NavLink onClick from being triggered by the submenu icon
                                onMenuItemClick(e, item, i);
                                onHideSideBar();
                            }}
                            target={item.target}
                        >
                            {linkContent}
                        </NavLink>
                        {submenuIcon}
                    </div>
                );
            } else {
                return (
                    <a
                        tabIndex="0"
                        aria-label={item.label}
                        onKeyDown={onKeyDown}
                        role="menuitem"
                        href="/"
                        className="p-ripple"
                        onClick={(e) => {
                            e.preventDefault();
                            onMenuItemClick(e, item, i);
                        }}
                        target={item.target}
                    >
                        {linkContent}
                        {submenuIcon}
                        <Ripple />
                    </a>
                );
            }
        },
        [memoizedActiveIndex, onMenuItemClick, onKeyDown, memoizedComponentDetailFromNavLink]
    );

    let items =
        props.items &&
        props.items.map((item, i) => {
            let active = memoizedActiveIndex === i;
            let styleClass = classNames(item.badgeStyleClass, { "layout-menuitem-category": props.root, "active-menuitem": active && !item.to });

            if (props.root) {
                return (
                    <li className={styleClass} key={i} role="none">
                        {props.root === true && (
                            <React.Fragment>
                                <div className="layout-menuitem-root-text" aria-label={item.label}>
                                    {item.label}
                                </div>
                                <AppSubmenu items={item.items} onHideSideBar={props.onHideSideBar} onMenuItemClick={props.onMenuItemClick} />
                            </React.Fragment>
                        )}
                    </li>
                );
            } else {
                return (
                    <li className={styleClass} key={i} role="none">
                        {renderLink(item, i, props.onHideSideBar)}
                        <CSSTransition classNames="layout-submenu-wrapper" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
                            <AppSubmenu items={item.items} onHideSideBar={props.onHideSideBar} onMenuItemClick={props.onMenuItemClick} />
                        </CSSTransition>
                    </li>
                );
            }
        });

    return items ? (
        <ul className={props.className} role="menu">
            {items}
        </ul>
    ) : null;
};

export default AppSubmenu;

export const AppMenuWithPrimeSideBar = (props) => {
    const [visible, setVisible] = useState(false);

    //
    const { menuVisible, setMenuVisible } = useSidebar();

    const customHeader = (
        <div className="flex align-items-center gap-2">
            {/* <Avatar image="assets/layout/images/nice-removebg-preview.png" shape="circle" /> */}
            <img src={"assets/layout/images/nice-removebg-preview.png"} alt="logo" style={{ height: "50px" }} />
            {/* <span className="font-bold">NHOP</span> */}
        </div>
    );
    return (
        <>
            <Sidebar visible={menuVisible} onHide={() => setMenuVisible(false)} header={customHeader}>
                <AppSubmenu
                    items={props.model}
                    className="layout-menu"
                    onHideSideBar={() => setMenuVisible(false)}
                    //  onMenuItemClick={props.onMenuItemClick}
                    root={true}
                    role="menu"
                />
            </Sidebar>

            {/* <a href="https://www.primefaces.org/primeblocks-react" className="block mt-3">
                <img alt="primeblocks" className="w-full" src={props.layoutColorMode === "light" ? "assets/layout/images/banner-primeblocks.png" : "assets/layout/images/banner-primeblocks-dark.png"} />
            </a> */}
        </>
    );
};
