import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import more from "highcharts/highcharts-more";

// Initialize modules
drilldown(Highcharts);
more(Highcharts);

const PlasticProductsChartTesting = () => {
    const options = {
        chart: {
            type: "bar",
            events: {
                drilldown: function (e) {
                    const chart = this;
                    const drilldowns = chart.userOptions.drilldown.series;

                    e.preventDefault();

                    Highcharts.each(drilldowns, function (p) {
                        if (p.id === e.point.drilldown) {
                            chart.addSingleSeriesAsDrilldown(e.point, p);
                        }
                    });

                    chart.applyDrilldown();
                },
            },
        },
        title: {
            text: "Product Sales Drilldown",
        },
        xAxis: {
            type: "category",
        },
        yAxis: [
            {
                title: {
                    text: "Sales",
                },
                width: "30%",
                lineWidth: 2,
                offset: 0,
            },
            {
                title: {
                    text: "Percentage Change",
                },
                left: "60%",
                width: "30%",
                offset: 0,
                lineWidth: 2,
            },
        ],
        legend: {
            enabled: true,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                },
            },
        },
        series: [
            {
                name: "Sales",
                type: "bar",
                yAxis: 0,
                data: [
                    { name: "Bottles", y: 120, drilldown: "Bottles" },
                    { name: "Containers", y: 80, drilldown: "Containers" },
                    { name: "Bags", y: 60, drilldown: "Bags" },
                ],
            },
            {
                name: "Growth",
                type: "lollipop",
                yAxis: 1,
                data: [
                    { name: "Bottles", y: 5.2 },
                    { name: "Containers", y: 3.8 },
                    { name: "Bags", y: -2.1 },
                ],
                tooltip: {
                    pointFormat: "{point.y}%",
                },
            },
        ],
        drilldown: {
            series: [
                {
                    name: "Bottles Sales",
                    id: "Bottles",
                    type: "bar",
                    yAxis: 0,
                    data: [
                        { name: "Small", y: 50 },
                        { name: "Medium", y: 40 },
                        { name: "Large", y: 30 },
                    ],
                },
                {
                    name: "Bottles Growth",
                    id: "Bottles",
                    type: "lollipop",
                    yAxis: 1,
                    data: [
                        { name: "Small", y: 6.5 },
                        { name: "Medium", y: 4.8 },
                        { name: "Large", y: 3.2 },
                    ],
                    tooltip: {
                        pointFormat: "{point.y}%",
                    },
                },
                {
                    name: "Containers Sales",
                    id: "Containers",
                    type: "bar",
                    yAxis: 0,
                    data: [
                        { name: "Food", y: 35 },
                        { name: "Storage", y: 25 },
                        { name: "Industrial", y: 20 },
                    ],
                },
                {
                    name: "Containers Growth",
                    id: "Containers",
                    type: "lollipop",
                    yAxis: 1,
                    data: [
                        { name: "Food", y: 4.2 },
                        { name: "Storage", y: 3.5 },
                        { name: "Industrial", y: 3.0 },
                    ],
                    tooltip: {
                        pointFormat: "{point.y}%",
                    },
                },
                {
                    name: "Bags Sales",
                    id: "Bags",
                    type: "bar",
                    yAxis: 0,
                    data: [
                        { name: "Shopping", y: 30 },
                        { name: "Garbage", y: 20 },
                        { name: "Zipper", y: 10 },
                    ],
                },
                {
                    name: "Bags Growth",
                    id: "Bags Growth",
                    type: "lollipop",
                    yAxis: 1,
                    data: [
                        { name: "Shopping", y: -3.5 },
                        { name: "Garbage", y: -1.2 },
                        { name: "Zipper", y: 1.8 },
                    ],
                    tooltip: {
                        pointFormat: "{point.y}%",
                    },
                },
            ],
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default PlasticProductsChartTesting;
