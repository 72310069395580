import axiosAPI from "../axiosApi";

export async function getAllSalesAssociates(params = {}) {
    const response = await axiosAPI.get("sales-associates", { params: params });
    return response;
}

export async function getSalesAssociateById(id) {
    const response = await axiosAPI.get(`sales-associates/` + id);
    return response;
}

export async function postSalesAssociate(data) {
    const response = await axiosAPI.post(`sales-associates`, data);
    return response;
}

export async function updateSalesAssociate(id, data) {
    const response = await axiosAPI.post(`sales-associates/${id}`, data);
    return response;
}

export async function deleteSalesAssociateById(id) {
    const response = await axiosAPI.delete(`sales-associates/${id}`);
    return response;
}
