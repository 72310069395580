import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "./context/NiceTwoDBFilterContext";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

import { Fieldset } from "primereact/fieldset";
import { toast } from "react-toastify";

//
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField, Autocomplete, Grid, Stack, InputLabel, FormHelperText, CircularProgress, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Avatar } from "primereact/avatar";

// Service Imports
import { getAllNiceTwoAgents, getAllNiceTwoSalesAssociates, getAllNiceTwoRegions, getAllNiceTwoRoutes, getAllNiceTwoProductCategories, getAllNiceTwoProductSubCategories, getAllNiceTwoProducts } from "./services/nice-two-endpoints/nice-two-dashboard-endpoints";

const validationSchema = Yup.object().shape({
    dateRange: Yup.date().nullable(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    month: Yup.string().nullable(),
    week: Yup.string().nullable(),
    financialYear: Yup.object().nullable(),
    region: Yup.object().nullable(),
    channel: Yup.object().nullable(),
    productCategory: Yup.object().nullable(),
    itemSubcategory: Yup.object().nullable(),
    product: Yup.object().nullable(),
    salesEmployee: Yup.object().nullable(),
    agent: Yup.object().nullable(),
    customer: Yup.object().nullable(),
    paymentTerms: Yup.object().nullable(),
});

const NiceTwoDbFilterSideBar = ({ visible, onHide }) => {
    // context state

    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ NiceTwoDbFilterSideBar ~ filters:", filters);

    //========================start Handle Date Selectors =======================================

    function findDateInRange(startDate, endDate, day) {
        const start = moment(startDate, "YYYY-MM-DD");
        const end = moment(endDate, "YYYY-MM-DD");

        let current = start.clone();
        // console.log("datess 1", current.date());
        while (current.isSameOrBefore(end)) {
            console.log("datess 2", `${current.format("YYYY-MM-DD")}`, day, current.date());
            if (String(current.date()) === String(day)) {
                return `${current.format("YYYY-MM-DD")}`;
            }
            current.add(1, "days");
        }

        return null; // If no matching date is found
    }

    const [selectedYears, setSelectedYears] = useState(null);
    const [selectedMonths, setSelectedMonths] = useState(null);
    const [selectedWeeks, setSelectedWeeks] = useState(null);
    const [selectedDays, setSelectedDays] = useState(null);
    const [selectWeek, setSelectWeek] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [startDateLast, setStartDateLast] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateLast, setEndDateLast] = useState(null);
    const [days, setDays] = useState([]);

    const financialYears = [];
    for (let year = 2020; year <= 2100; year++) {
        financialYears.push({ id: year, name: `${year}-${year + 1}` });
    } // Replace with your financial years
    const months = [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "Jun" },
        { id: 7, name: "Jul" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sep" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" },
    ];
    const calendarWeeks = [
        { id: 1, name: "Week 1" },
        { id: 2, name: "Week 2" },
        { id: 3, name: "Week 3" },
        { id: 4, name: "Week 4" },
        { id: 5, name: "Week 5" },
        { id: 6, name: "Week 6" },
        { id: 7, name: "Week 7" },
        { id: 8, name: "Week 8" },
        { id: 9, name: "Week 9" },
        { id: 10, name: "Week 10" },
        { id: 11, name: "Week 11" },
        { id: 12, name: "Week 12" },
        { id: 13, name: "Week 13" },
        { id: 14, name: "Week 14" },
        { id: 15, name: "Week 15" },
        { id: 16, name: "Week 16" },
        { id: 17, name: "Week 17" },
        { id: 18, name: "Week 18" },
        { id: 19, name: "Week 19" },
        { id: 20, name: "Week 20" },
        { id: 21, name: "Week 21" },
        { id: 22, name: "Week 22" },
        { id: 23, name: "Week 23" },
        { id: 24, name: "Week 24" },
        { id: 25, name: "Week 25" },
        { id: 26, name: "Week 26" },
        { id: 27, name: "Week 27" },
        { id: 28, name: "Week 28" },
        { id: 29, name: "Week 29" },
        { id: 30, name: "Week 30" },
        { id: 31, name: "Week 31" },
        { id: 32, name: "Week 32" },
        { id: 33, name: "Week 33" },
        { id: 34, name: "Week 34" },
        { id: 35, name: "Week 35" },
        { id: 36, name: "Week 36" },
        { id: 37, name: "Week 37" },
        { id: 38, name: "Week 38" },
        { id: 39, name: "Week 39" },
        { id: 40, name: "Week 40" },
        { id: 41, name: "Week 41" },
        { id: 42, name: "Week 42" },
        { id: 43, name: "Week 43" },
        { id: 44, name: "Week 44" },
        { id: 45, name: "Week 45" },
        { id: 46, name: "Week 46" },
        { id: 47, name: "Week 47" },
        { id: 48, name: "Week 48" },
        { id: 49, name: "Week 49" },
        { id: 50, name: "Week 50" },
        { id: 51, name: "Week 51" },
        { id: 52, name: "Week 52" },
    ]; // Replace with actual months or weeks
    // Replace with actual days

    //========================end Handle Date Selectors =======================================
    //
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showResetConfirmDialog, setShowResetConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    //product
    const [selectedProductCategories, setSelectedProductCategories] = useState(filters?.productCategories);
    const [selectedProductSubCategories, setSelectedProductSubCategories] = useState(filters?.productSubCategories);

    // State for selected regions and routes
    const [selectedRegions, setSelectedRegions] = useState(filters?.regions);
    const [selectedRoutes, setSelectedRoutes] = useState(filters?.routes);

    const [selectedSalesAssociates, setSelectedSalesAssociates] = useState(filters?.salesAssociates);

    //===================initioalise queries =========================

    // toast handler
    const toastError = (error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
        error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
    };

    // Queries and useEffects
    // Agents Query
    const agentsQuery = useQuery({
        queryKey: ["agents", selectedRegions, selectedSalesAssociates],
        queryFn: () => getAllNiceTwoAgents({ regions: selectedRegions, salesAssociates: selectedSalesAssociates }),
    });

    useEffect(() => {
        if (agentsQuery?.isError) {
            toastError(agentsQuery?.error);
        }
    }, [agentsQuery?.isError]);

    // Sales Associates Query
    const salesAssociatesQuery = useQuery({
        queryKey: ["salesAssociates", selectedRegions],
        queryFn: () => getAllNiceTwoSalesAssociates({ regions: selectedRegions }),
    });

    useEffect(() => {
        if (salesAssociatesQuery?.isError) {
            toastError(salesAssociatesQuery?.error);
        }
    }, [salesAssociatesQuery?.isError]);

    // Regions Query
    const regionsQuery = useQuery({
        queryKey: ["regions"],
        queryFn: () => getAllNiceTwoRegions({}),
    });

    useEffect(() => {
        if (regionsQuery?.isError) {
            toastError(regionsQuery?.error);
        }
    }, [regionsQuery?.isError]);

    // Routes Query (depends on selected region)
    const routesQuery = useQuery({
        queryKey: ["routes", selectedRegions],
        queryFn: () => getAllNiceTwoRoutes({ regions: selectedRegions }),
        enabled: selectedRegions.length > 0,
    });

    useEffect(() => {
        if (routesQuery?.isError) {
            toastError(routesQuery?.error);
        }
    }, [routesQuery?.isError]);

    // Product Categories Query
    const productCategoriesQuery = useQuery({
        queryKey: ["productCategories"],
        queryFn: () => getAllNiceTwoProductCategories({}),
    });

    useEffect(() => {
        if (productCategoriesQuery?.isError) {
            toastError(productCategoriesQuery?.error);
        }
    }, [productCategoriesQuery?.isError]);

    // Product Subcategories Query (depends on selected product category)
    const productSubCategoriesQuery = useQuery({
        queryKey: ["productSubCategories", selectedProductCategories],
        queryFn: () => getAllNiceTwoProductSubCategories({ categories: selectedProductCategories }),
        enabled: selectedProductCategories.length > 0,
    });

    console.log("🚀 ~ FiltersFormDialog ~ productSubCategoriesQuery:", productSubCategoriesQuery);

    useEffect(() => {
        if (productSubCategoriesQuery?.isError) {
            toastError(productSubCategoriesQuery?.error);
        }
    }, [productSubCategoriesQuery?.isError]);

    // Products Query (depends on selected product subcategory)
    const productsQuery = useQuery({
        queryKey: ["products", selectedProductSubCategories],
        queryFn: () => getAllNiceTwoProducts({ subCategories: selectedProductSubCategories }),
        enabled: selectedProductSubCategories.length > 0,
    });

    useEffect(() => {
        if (productsQuery?.isError) {
            toastError(productsQuery?.error);
        }
    }, [productsQuery?.isError]);

    //===================== initialise quaeries =====================

    // drop down options

    //
    const orderByOptions = [
        { id: 1, label: "Default", value: "default" },
        { id: 2, label: "Ascending", value: "asc" },
        { id: 3, label: "Descending", value: "desc" },
    ];

    const statusOptions = [
        { id: 1, label: "Pending", value: "PENDING" },
        { id: 2, label: "Closed", value: "CLOSED" },
        { id: 3, label: "Canceled", value: "CANCELED" },
    ];

    const dataLimitOptions = [
        { id: 1, label: "All", value: "all" },
        { id: 2, label: "5", value: 5 },
        { id: 3, label: "10", value: 10 },
        { id: 4, label: "20", value: 20 },
        { id: 5, label: "25", value: 25 },
        { id: 6, label: "30", value: 30 },
    ];

    // ================ handle submit =====================

    //hndle form submit
    const handleFormSubmit = (values) => {
        setFilters(values);
        onHide();

        // setPendingData(values);
        // setShowConfirmDialog(true);
    };

    const handleConfirm = () => {
        if (pendingData) {
            setFilters(pendingData);
            setPendingData(null);
            onHide();
        }
        setShowConfirmDialog(false);
    };

    const handleCancel = () => {
        setShowConfirmDialog(false);
    };

    //-=========== handle reset ===============
    const handleReset = (e, resetForm, initialValues, setValues, values) => {
        // setShowResetConfirmDialog(true);

        e.preventDefault();
        setSelectedProductCategories([]);
        setSelectedProductSubCategories([]);
        setSelectedRegions([]);
        setSelectedRoutes([]);
        setSelectedSalesAssociates([]);
        resetForm();
        setValues({
            startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            agents: [],
            salesAssociates: [],
            regions: [],
            routes: [],
            statuses: [
                // { id: 1, label: "Pending", value: "PENDING" },
                { id: 2, label: "Closed", value: "CLOSED" },
            ],
            // orderBy: { id: 3, label: "Descending", value: "desc" },
            // dataLimit: { id: 2, label: "5", value: 5 },
            // dataLimitNumber: null,
            productCategories: [],
            productSubCategories: [],
            products: [],
        });
        resetFilters();
        // setShowResetConfirmDialog(false);
        // reset form has a problem it automatically triggers initil values
        // so its better we use set values and we reset formik values our seleves
        // resetForm();
        // onHide();
    };

    const handleConfirmReset = (e, resetForm, initialValues, setValues, values) => {
        e.preventDefault();
        setSelectedProductCategories([]);
        setSelectedProductSubCategories([]);
        setSelectedRegions([]);
        setSelectedRoutes([]);
        setSelectedSalesAssociates([]);
        resetForm();
        setValues({
            startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            agents: [],
            salesAssociates: [],
            regions: [],
            routes: [],
            statuses: [
                // { id: 1, label: "Pending", value: "PENDING" },
                { id: 2, label: "Closed", value: "CLOSED" },
            ],
            // orderBy: { id: 3, label: "Descending", value: "desc" },
            // dataLimit: { id: 2, label: "5", value: 5 },
            // dataLimitNumber: null,
            productCategories: [],
            productSubCategories: [],
            products: [],
        });
        resetFilters();
        setShowResetConfirmDialog(false);
        // reset form has a problem it automatically triggers initil values
        // so its better we use set values and we reset formik values our seleves
        // resetForm();
        // onHide();
    };

    const handleCancelReset = () => {
        setShowResetConfirmDialog(false);
    };

    const customHeader = (
        <div className="flex align-items-center gap-2">
            <img src={"assets/layout/images/nice-removebg-preview.png"} alt="logo" style={{ height: "50px" }} />
            {/* <Avatar image="assets/layout/images/nice-removebg-preview.png" shape="circle" /> */}
            {/* <span className="font-bold">NHOP</span> */}
        </div>
    );

    return (
        <Sidebar
            visible={visible}
            onHide={onHide}
            header={customHeader}
            //  className="w-full md:w-30rem lg:w-30rem"
            style={{ zIndex: "1000" }}
        >
            <center>
                <Button
                    label="Filters"
                    icon="pi pi-filter"
                    className="p-button-text"
                    style={{ color: "#0000FF" }} // Blue color
                />
            </center>
            <Formik initialValues={filters} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
                {({ values, setValues, setFieldValue, resetForm, initialValues, errors, touched, isSubmitting }) => (
                    <Form>
                        <Accordion
                        // activeIndex={0}
                        >
                            <AccordionTab header="Date Range">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="startDate">Start Date</InputLabel>
                                            <Field name="startDate">
                                                {({ field }) => (
                                                    <DatePicker
                                                        label="Start Date"
                                                        format="DD/MM/YYYY"
                                                        views={["year", "month", "day"]}
                                                        value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                        onChange={(value) => setFieldValue("startDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                error={Boolean(touched.startDate && errors.startDate)}
                                                                // helperText={touched.startDate && errors.startDate ? errors.startDate : ""}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="startDate" component="div" style={{ color: "red" }} />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="endDate">End Date</InputLabel>
                                            <Field name="endDate">
                                                {({ field }) => (
                                                    <DatePicker
                                                        label="End Date"
                                                        format="DD/MM/YYYY"
                                                        views={["year", "month", "day"]}
                                                        value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                        onChange={(value) => setFieldValue("endDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                error={Boolean(touched.endDate && errors.endDate)}
                                                                // helperText={touched.endDate && errors.endDate ? errors.endDate : ""}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="endDate" component="div" style={{ color: "red" }} />
                                        </Stack>
                                    </Grid>
                                </LocalizationProvider>
                            </AccordionTab>
                            <AccordionTab header="Date Selector">
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="financial_years">Financial Year</InputLabel>
                                        <Field name="financial_years">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    // multiple
                                                    options={financialYears}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedYears}
                                                    onChange={(event, newValue) => {
                                                        setSelectedYears(newValue);
                                                        setSelectedMonths(null);
                                                        setSelectedWeeks(null);
                                                        setSelectedDays(null);
                                                        if (newValue !== null) {
                                                            setStartDate(`${newValue.id}-07-01`);
                                                            setStartDateLast(`${newValue.id}-07-01`);
                                                            setEndDate(`${newValue.id + 1}-06-30`);
                                                            setEndDateLast(`${newValue.id + 1}-06-30`);

                                                            setFieldValue("startDate", moment(`${newValue.id}-07-01`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${newValue.id + 1}-06-30`).format("YYYY-MM-DD"));
                                                        } else {
                                                            setStartDate(null);
                                                            setEndDate(null);
                                                            setFieldValue("startDate", moment().startOf("year").format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment().format("YYYY-MM-DD"));
                                                        }

                                                        // setFieldValue("channels", newValue);
                                                        // setFieldValue("regions", []);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    // disabled={channelsQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Financial Year"
                                                            // error={Boolean(touched.channels && errors.channels)}
                                                            // disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {channelsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="financial_years" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="months">Months</InputLabel>
                                        <Field name="months">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    // multiple
                                                    options={months}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedMonths}
                                                    onChange={(event, newValue) => {
                                                        setSelectedMonths(newValue);
                                                        setSelectedWeeks(null);
                                                        setSelectedDays(null);
                                                        setSelectWeek(false);
                                                        if (newValue !== null) {
                                                            setStartDate(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-01`);
                                                            setStartDateLast(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-01`);
                                                            setEndDate(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-${moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth()}`);
                                                            setEndDateLast(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-${moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth()}`);

                                                            setFieldValue("startDate", moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-01`).format("YYYY-MM-DD"));
                                                            setFieldValue(
                                                                "endDate",
                                                                moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-${moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth()}`).format("YYYY-MM-DD")
                                                            );

                                                            const newDays = [];
                                                            console.log("daasss", moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM"));
                                                            for (let day = 1; day <= moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth(); day++) {
                                                                newDays.push(`${day}`);
                                                            }
                                                            setDays(newDays);
                                                        } else {
                                                            setStartDate(`${selectedYears.id}-07-01`);
                                                            setEndDate(`${selectedYears.id + 1}-06-30`);
                                                            setFieldValue("startDate", moment(`${selectedYears.id}-07-01`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${selectedYears.id + 1}-06-30`).format("YYYY-MM-DD"));
                                                        }

                                                        // setFieldValue("regions", newValue);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    disabled={selectedYears === null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Months"
                                                            // error={Boolean(touched.regions && errors.regions)}
                                                            disabled={selectedYears === null}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="months" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="weeks">Weeks</InputLabel>
                                        <Field name="weeks">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    // multiple
                                                    options={calendarWeeks}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedWeeks}
                                                    onChange={(event, newValue) => {
                                                        setSelectedWeeks(newValue);
                                                        setSelectedMonths(null);
                                                        setSelectedDays(null);
                                                        setSelectWeek(true);
                                                        if (newValue !== null) {
                                                            let weekYear = selectedYears.id;
                                                            // console.log(moment().year(selectedYears.id).week(newValue.id).startOf("week").format("YYYY-MM-DD"));
                                                            if (moment().year(selectedYears.id).week(newValue.id).startOf("week").format("YYYY-MM-DD") > moment(`${selectedYears.id}-06-30`, "YYYY-MM-DD").format("YYYY-MM-DD")) {
                                                                weekYear = selectedYears.id + 1;
                                                            }
                                                            setStartDate(`${moment().year(weekYear).week(newValue.id).startOf("week").format("YYYY-MM-DD")}`);
                                                            setStartDateLast(`${moment().year(weekYear).week(newValue.id).startOf("week").format("YYYY-MM-DD")}`);
                                                            setEndDate(`${moment().year(weekYear).week(newValue.id).endOf("week").format("YYYY-MM-DD")}`);
                                                            setEndDateLast(`${moment().year(weekYear).week(newValue.id).endOf("week").format("YYYY-MM-DD")}`);

                                                            setFieldValue("startDate", moment(`${moment().year(weekYear).week(newValue.id).startOf("week").format("YYYY-MM-DD")}`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${moment().year(weekYear).week(newValue.id).endOf("week").format("YYYY-MM-DD")}`).format("YYYY-MM-DD"));
                                                            const newDays = [];
                                                            for (let i = 0; i < 7; i++) {
                                                                newDays.push(moment().year(weekYear).week(newValue.id).startOf("week").add(i, "days").format("D"));
                                                            }
                                                            setDays(newDays);
                                                        } else {
                                                            setStartDate(`${selectedYears.id}-07-01`);
                                                            setEndDate(`${selectedYears.id + 1}-06-30`);
                                                            setFieldValue("startDate", moment(`${selectedYears.id}-07-01`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${selectedYears.id + 1}-06-30`).format("YYYY-MM-DD"));
                                                        }
                                                        // setFieldValue("regions", newValue);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    disabled={selectedYears === null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Week"
                                                            // error={Boolean(touched.regions && errors.regions)}
                                                            disabled={selectedYears === null}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="weeks" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="days">Days</InputLabel>
                                        <Field name="days">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    // multiple
                                                    options={days}
                                                    getOptionLabel={(option) => option}
                                                    value={selectedDays}
                                                    onChange={(event, newValue) => {
                                                        setSelectedDays(newValue);
                                                        if (newValue !== null) {
                                                            // console.log("datess 3", findDateInRange(startDateLast, endDateLast, newValue));
                                                            if (selectWeek) {
                                                                setStartDate(findDateInRange(startDateLast, endDateLast, newValue));
                                                                setEndDate(findDateInRange(startDateLast, endDateLast, newValue));
                                                                setFieldValue("startDate", moment(findDateInRange(startDateLast, endDateLast, newValue)).format("YYYY-MM-DD"));
                                                                setFieldValue("endDate", moment(findDateInRange(startDateLast, endDateLast, newValue)).format("YYYY-MM-DD"));
                                                            } else {
                                                                setStartDate(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}}`);
                                                                setEndDate(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}}`);

                                                                console.log("sdadfegwgq", moment(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}`).format("YYYY-MM-DD"));
                                                                setFieldValue("startDate", moment(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}`).format("YYYY-MM-DD"));
                                                                setFieldValue("endDate", moment(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}`).format("YYYY-MM-DD"));
                                                            }
                                                        } else {
                                                            setStartDate(startDateLast);
                                                            setEndDate(endDateLast);
                                                            setFieldValue("startDate", moment(startDateLast).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(endDateLast).format("YYYY-MM-DD"));
                                                        }
                                                        // setFieldValue("regions", newValue);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    disabled={selectedYears === null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Day"
                                                            // error={Boolean(touched.regions && errors.regions)}
                                                            disabled={selectedYears === null}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="days" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </AccordionTab>

                            {/* Multi-select fields */}

                            <AccordionTab header="Geo">
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="regions">Regions</InputLabel>
                                        <Field name="regions">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={regionsQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedRegions}
                                                    onChange={(event, newValue) => {
                                                        setSelectedRegions(newValue);
                                                        setSelectedRoutes([]);
                                                        setFieldValue("regions", newValue);
                                                        setFieldValue("routes", []);
                                                        setSelectedSalesAssociates([]);
                                                        setFieldValue("salesAssociates", []);
                                                        setFieldValue("agents", []);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select regions"
                                                            error={Boolean(touched.regions && errors.regions)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="regions" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="routes">Routes</InputLabel>
                                        <Field name="routes">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={routesQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedRoutes}
                                                    onChange={(event, newValue) => {
                                                        setSelectedRoutes(newValue);
                                                        setFieldValue("routes", newValue);
                                                    }}
                                                    disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || routesQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select routes"
                                                            error={Boolean(touched.routes && errors.routes)}
                                                            disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || routesQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {routesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="routes" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </AccordionTab>

                            <AccordionTab header="Stakeholders">
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="salesAssociates">Sales Associates</InputLabel>
                                        <Field name="salesAssociates">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.user_id === value?.user_id}
                                                    multiple
                                                    options={salesAssociatesQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    // value={field.value}
                                                    value={selectedSalesAssociates}
                                                    // onChange={(event, newValue) => setFieldValue("salesAssociates", newValue)}
                                                    onChange={(event, newValue) => {
                                                        setFieldValue("salesAssociates", newValue);
                                                        setSelectedSalesAssociates(newValue);
                                                        setFieldValue("agents", []);
                                                    }}
                                                    disabled={regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select sales associates"
                                                            error={Boolean(touched.salesAssociates && errors.salesAssociates)}
                                                            disabled={regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {salesAssociatesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="salesAssociates" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="agents">Distributors</InputLabel>
                                        <Field name="agents">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={agentsQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={field.value}
                                                    onChange={(event, newValue) => setFieldValue("agents", newValue)}
                                                    disabled={regionsQuery?.isLoading || agentsQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select distributors"
                                                            error={Boolean(touched.agents && errors.agents)}
                                                            // helperText={touched.agents && errors.agents ? errors.agents : ""}
                                                            disabled={regionsQuery?.isLoading || agentsQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {agentsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="agents" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </AccordionTab>

                            <AccordionTab header="Products">
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="productCategories">Product Categories</InputLabel>
                                        <Field name="productCategories">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={productCategoriesQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedProductCategories}
                                                    onChange={(event, newValue) => {
                                                        setSelectedProductCategories(newValue);
                                                        setSelectedProductSubCategories([]);
                                                        setFieldValue("productCategories", newValue);
                                                        setFieldValue("productSubCategories", []);
                                                        setFieldValue("products", []);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select product categories"
                                                            error={Boolean(touched.productCategories && errors.productCategories)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {productCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="productCategories" component="div" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="productSubCategories">Product Sub-Categories</InputLabel>
                                        <Field name="productSubCategories">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={productSubCategoriesQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedProductSubCategories}
                                                    onChange={(event, newValue) => {
                                                        setSelectedProductSubCategories(newValue);
                                                        setFieldValue("productSubCategories", newValue);
                                                        setFieldValue("products", []);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select product sub-categories"
                                                            error={Boolean(touched.productSubCategories && errors.productSubCategories)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {productSubCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="productSubCategories" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="products">Products</InputLabel>
                                        <Field name="products">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={productsQuery?.data?.data?.data || []}
                                                    // getOptionLabel={(option) => option.name}
                                                    getOptionLabel={(option) => `${option.name} - ${option.variableName}`}
                                                    value={field.value}
                                                    onChange={(event, newValue) => setFieldValue("products", newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select products"
                                                            error={Boolean(touched.products && errors.products)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {productsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="products" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </AccordionTab>

                            <AccordionTab header="Others">
                                {/* Status Multiple Select */}
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={0.5}>
                                        <InputLabel htmlFor="statuses">Statuses</InputLabel>
                                        <Field name="statuses">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={statusOptions}
                                                    getOptionLabel={(option) => option.label}
                                                    value={Array.isArray(field.value) ? field.value : []}
                                                    onChange={(event, newValue) => setFieldValue("statuses", newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select statuses"
                                                            error={Boolean(touched.statuses && errors.statuses)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: <>{params.InputProps.endAdornment}</>,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="statuses" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                                {/* Multi-select fields */}
                            </AccordionTab>
                        </Accordion>

                        {/* orderBy Single Select */}
                        {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="orderBy">Order By</InputLabel>
                                    <Field name="orderBy">
                                        {({ field }) => (
                                            <Autocomplete
                                                options={orderByOptions}
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                getOptionLabel={(option) => option.label}
                                                value={field.value}
                                                onChange={(event, newValue) => setFieldValue("orderBy", newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select order by"
                                                        error={Boolean(touched.order && errors.order)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="orderBy" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                        {/* <Grid item xs={12} md={6} lg={3}>
                             <Stack spacing={1}>
                                 <InputLabel htmlFor="dataLimitNumber">Data Limit Number</InputLabel>
                                 <Field name="dataLimitNumber">
                                     {({ field }) => <TextField {...field} id="dataLimitNumber" variant="outlined" placeholder="Enter data limit" type="number" InputProps={{ inputProps: { min: 1 } }} error={Boolean(touched.dataLimitNumber && errors.dataLimitNumber)} />}
                                 </Field>
                                 <ErrorMessage name="dataLimitNumber" component="div" style={{ color: "red" }} />
                             </Stack>
                         </Grid> */}

                        {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="dataLimit">Data Limit</InputLabel>
                                    <Field name="dataLimit">
                                        {({ field }) => (
                                            <Autocomplete
                                                options={dataLimitOptions}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                getOptionLabel={(option) => option.label}
                                                value={field.value}
                                                onChange={(event, newValue) => setFieldValue("dataLimit", newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select data limit"
                                                        error={Boolean(touched.dataLimit && errors.dataLimit)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="dataLimit" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                        <Grid item xs={12} md={12} lg={12}>
                            <Button type="submit" label="Filter" className="p-button-primary m-2" />
                            <Button type="button" onClick={(e) => handleReset(e, resetForm, initialValues, setValues, values)} label="Reset" className="p-button-secondary m-2" />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <center>{errors.form && <div style={{ color: "red" }}>{errors.form}</div>}</center>
                        </Grid>

                        <Dialog
                            visible={showConfirmDialog}
                            header="Confirmation"
                            modal
                            onHide={handleCancel}
                            footer={
                                <>
                                    <Button label="Yes" onClick={handleConfirm} />
                                    <Button label="No" onClick={handleCancel} />
                                </>
                            }
                        >
                            Are you sure you want to submit the form?
                        </Dialog>

                        <Dialog
                            header="Confirm Reset"
                            visible={showResetConfirmDialog}
                            onHide={handleCancelReset}
                            modal
                            footer={
                                <>
                                    <Button label="Yes" onClick={(e) => handleConfirmReset(e, resetForm, initialValues, setValues, values)} color="primary" autoFocus />
                                    <Button label="No" onClick={handleCancelReset} color="primary" />
                                </>
                            }
                        >
                            Are you sure you want to reset the form? All changes will be lost.
                        </Dialog>
                    </Form>
                )}
            </Formik>
        </Sidebar>
    );
};

export default NiceTwoDbFilterSideBar;
