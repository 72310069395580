import React, { useState, useContext, useEffect } from "react";
import { Panel } from "primereact/panel";

import { NiceTwoDBFilterContext } from "../../context/NiceTwoDBFilterContext";
import moment from "moment";
import { Fieldset } from "primereact/fieldset";

import Cards from "./cards/Cards";
import SelloutProductDrilldownChart from "./charts/dummy-charts/SelloutProductDrilldownChart";
import SelloutAgentProductDrilldownChart from "./charts/dummy-charts/SelloutAgentProductDrilldownChart";
import SelloutAgentOnlyProductDrilldownChart from "./charts/SelloutAgentOnlyProductDrilldownChart";
import SelloutRegionOnlyProductDrilldownChart from "./charts/SelloutRegionOnlyProductDrilldownChart";
import SelloutSalesAssociatesOnlyProductDrilldownChart from "./charts/SelloutSalesAssociatesOnlyProductDrilldownChart";

//
import RegionPerformanceVsSPLYLineChart from "./charts/actual-perfomance-c-vs-sply-line-graphs/RegionPerformanceVsSPLYLineChart";
import ProductSalesPerformanceVsSPLYLineChart from "./charts/actual-perfomance-c-vs-sply-line-graphs/ProductSalesPerformanceVsSPLYLineChart";

//
import AgentSalesComparisonDeficitSurplusChart from "./charts/actual-perfomance-c-vs-sply-bar-charts/AgentSalesComparisonDeficitSurplusChart";
import ProductSalesComparisonDeficitSurplusChart from "./charts/actual-perfomance-c-vs-sply-bar-charts/ProductSalesComparisonDeficitSurplusChart";

//
import ActualPerformanceComparisonDeficitSurplusChart from "./charts/actual-perfomance-c-vs-sply-bar-charts/ActualPerformanceComparisonDeficitSurplusChart";
import RoutePerformanceComparisonDeficitSurplusChart from "./charts/actual-perfomance-c-vs-sply-bar-charts/RoutePerformanceComparisonDeficitSurplusChart";

//
import CustomerRankingSalesChart from "./charts/CustomerRankingSalesChart";
import CustomerSalesProductOnlyDrilldownChart from "./charts/CustomerSalesProductOnlyDrilldownChart";

//
import SelloutPeriodSalesAssocProductDrilldownChart from "./charts/dummy-charts/SelloutPeriodSalesAssocProductDrilldownChart";

//
import SellOutTopProducedProductsPage from "./tables/SellOutTopProducedProductsPage";
import SellOutTopSellingProductsPage from "./tables/SellOutTopSellingProductsPage";

//testing
import SelloutTransactionsDrilldownChartTesting from "./charts/dummy-charts/SelloutTransactionsDrilldownChart";

import SelloutTransactionsDrilldownChart from "./charts/perfomance-insights-with-product-drills/SelloutTransactionsDrilldownChart";

import SelloutTxProductDrilldownChart from "./charts/perfomance-insights-with-product-drills/SelloutTxProductDrilldownChart";

import ToggleRankingCharts from "./charts/rankings-with-product-drill/ToggleRankingCharts";
import TogglePerfomanceCharts from "./charts/perfomance-insights-with-product-drills/TogglePerfomanceCharts";
import ToggleActualPerfomanceBarCharts from "./charts/actual-perfomance-c-vs-sply-bar-charts/ToggleActualPerfomanceBarCharts";
import ToggleActualPerfomanceLineCharts from "./charts/actual-perfomance-c-vs-sply-line-graphs/ToggleActualPerfomanceLineCharts";
import ToggleCards from "./cards/ToggleCards";

// dummies
import LayereredVerticalTargetVsSplyChart from "./charts/dummy-charts/LayereredVerticalTargetVsSplyChart";
import LayeredHorizontalTargetVsSplyChart from "./charts/dummy-charts/LayeredHorizontalTargetVsSplyChart";
import SalesCardChart from "./charts/dummy-charts/SalesCardChart";
import LayeredProductSalesChart from "./charts/dummy-charts/LayeredProductSalesChart";
import LayeredProductSalesDrilldownChart from "./charts/dummy-charts/LayeredProductSalesDrilldownChart";
import GroupedProductSalesDrilldownChart from "./charts/dummy-charts/GroupedProductSalesDrilldownChart";
import LayeredGroupedProductSalesDrilldownWithGroupedLollipopChart from "./charts/dummy-charts/LayeredGroupedProductSalesDrilldownWithGroupedLollipopChart";
import LayeredProductSalesWithGroupedlolliposDrilldownChart from "./charts/dummy-charts/LayeredProductSalesWithGroupedlolliposDrilldownChart";
import LayeredGroupedProductSalesDrilldownWithGroupedLollipopSeparateCharts from "./charts/dummy-charts/LayeredGroupedProductSalesDrilldownWithGroupedLollipopSeparateCharts";
import PlasticProductsChartTesting from "./charts/dummy-charts/PlasticProductsChartTesting";
import PlasticProductsChartTestingGrouped from "./charts/dummy-charts/PlasticProductsChartTestingGrouped";

// new comparisan charts
import ToggleComparisonActualPerfomanceBarCharts from "./new-charts/comparion-charts/comparison-actual-perfomance-bar-charts/ToggleComparisonActualPerfomanceBarCharts";
import ToggleComparisonActualPerfomanceSplineCharts from "./new-charts/comparion-charts/comparison-actual-perfomance-spline-charts/ToggleComparisonActualPerfomanceSplineCharts";
function NiceTwoDashbaordPage() {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);

    const calculatePeriod = (filters) => {
        // const now = moment();
        // const startOfYear = moment().startOf("year");

        const now = moment().format("YYYY-MM-DD");
        const startOfYear = moment().subtract(6, "days").format("YYYY-MM-DD");

        const startDate = filters.startDate ? moment(filters.startDate) : startOfYear;
        const endDate = filters.endDate ? moment(filters.endDate) : now;

        return `${startDate.format("MMM DD, YYYY")} - ${endDate.format("MMM DD, YYYY")}`;
    };

    const period = calculatePeriod(filters);
    return (
        <div style={{ display: "flex", flexWrap: "wrap", padding: "0.5rem" }}>
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <SalesCardChart />
            </div> */}

            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <LayeredGroupedProductSalesDrilldownWithGroupedLollipopSeparateCharts />
            </div> */}

            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <h4>testing</h4>
                <PlasticProductsChartTesting />
                <h4>testing Grouped</h4>
                <PlasticProductsChartTestingGrouped />
            </div>
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <LayeredProductSalesWithGroupedlolliposDrilldownChart />
            </div> */}
            {/* Testing Dummy Charts */}
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <LayeredGroupedProductSalesDrilldownWithGroupedLollipopChart />
            </div>

            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <GroupedProductSalesDrilldownChart />
            </div>

            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <LayereredVerticalTargetVsSplyChart />
            </div>
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <LayeredHorizontalTargetVsSplyChart />
            </div>
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <LayeredProductSalesChart />
            </div>
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <LayeredProductSalesDrilldownChart />
            </div> */}

            <ToggleCards />

            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header={`Performance Trend vs Same Period Last Year (SPLY)`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            // <ToggleActualPerfomanceBarCharts />
                            <ToggleComparisonActualPerfomanceBarCharts />
                        </div>
                        <div className="col-12 xl:col-6">
                            // <ToggleActualPerfomanceLineCharts /> 
                            <ToggleComparisonActualPerfomanceSplineCharts />
                        </div>
                    </div>
                </Panel>
            </div> */}

            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header={`Performance Drill-down and Top Rankings`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <TogglePerfomanceCharts />
                        </div>
                        <div className="col-12 xl:col-6">
                            <ToggleRankingCharts />
                        </div>
                    </div>
                </Panel>
            </div> */}

            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header={`Sales Associates Performance Insight`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12">
                            <SelloutSalesAssociatesOnlyProductDrilldownChart />
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header={`Sales Agents Performance Insight`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12">
                            <SelloutAgentOnlyProductDrilldownChart />
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header={`Region Performance Insight`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12">
                            <SelloutRegionOnlyProductDrilldownChart />
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header={`Customers Sales Insight`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12">
                            <CustomerRankingSalesChart />
                            //the bottom one
                            <CustomerSalesProductOnlyDrilldownChart />
                        </div>
                    </div>
                </Panel>
            </div> */}

            {/* //======================== ending here for now ============================= */}

            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Testing Sale Out Transactions Insight" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12">
                            <SelloutTransactionsDrilldownChartTesting />
                        </div>
                    </div>
                </Panel>
            </div> */}

            {/* <Fieldset legend="Pending Api" className="mt-2 mb-2 col-12" toggleable collapsed>
                <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                    <Panel header="Sales Analysis by Period, Associate, and Product Details" toggleable style={{ minWidth: "100%" }}>
                        <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="col-12">
                                <SelloutPeriodSalesAssocProductDrilldownChart />
                            </div>
                        </div>
                    </Panel>
                </div>

                <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                    <Panel header="Agents Raked By Sales with Product Detailed Analysis" toggleable style={{ minWidth: "100%" }}>
                        <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="col-12">
                                <SelloutAgentProductDrilldownChart />
                            </div>
                        </div>
                    </Panel>
                </div>

                <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                    <Panel header="Products Raked By Sales Detailed Analysis" toggleable style={{ minWidth: "100%" }}>
                        <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="col-12">
                                <SelloutProductDrilldownChart />
                            </div>
                        </div>
                    </Panel>
                </div>
            </Fieldset> */}
        </div>
    );
}

export default NiceTwoDashbaordPage;
