import axiosAPI from "../axiosApi";

export async function getAllNiceTwoSellOutCardStarts(params = {}) {
    const response = await axiosAPI.get("nice-two-sellout-card-stats", { params: params?.params, signal: params?.signal });
    return response;
}
// export async function getAllNiceTwoRegions(params = {}) {
//     const response = await axiosAPI.get("nice-two-regions", { params: params });
//     return response;
// }
// export async function getAllNiceTwoRoutes(params = {}) {
//     const response = await axiosAPI.get("nice-two-routes", { params: params });
//     return response;
// }

//=================== spline charts ======================
export async function getSellInPdtPfmceActualVsSPLYVsTargetSplineChart(params = {}) {
    const response = await axiosAPI.get("getSellInPdtPfmceActualVsSPLYVsTargetSplineChart", { params: params });
    return response;
}

export async function getSellInPdtCatzPfmceActVsSPLYVsTargetSplineChart(params = {}) {
    const response = await axiosAPI.get("getSellInPdtCatzPfmceActVsSPLYVsTargetSplineChart", { params: params });
    return response;
}

export async function getSellInRegionPfmceAVsSPLYVsTSplineChart(params = {}) {
    const response = await axiosAPI.get("getSellInRegionPfmceAVsSPLYVsTSplineChart", { params: params });
    return response;
}

export async function getSellInAgentPfmceAVsSPLYVsTSplineChart(params = {}) {
    const response = await axiosAPI.get("getSellInAgentPfmceAVsSPLYVsTSplineChart", { params: params });
    return response;
}

export async function getSellInChannelPfmceAVsSPLYVsTSplineChart(params = {}) {
    const response = await axiosAPI.get("getSellInChannelPfmceAVsSPLYVsTSplineChart", { params: params });
    return response;
}
