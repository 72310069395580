import React, { useState } from "react";
import SellOutHeatMapChart from "./SellOutHeatMapChart";
import SellOutAgentHeatMapChart from "./SellOutAgentHeatMapChart";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import SellOutCategoryHeatMapChart from "./CategorySellOutHeatMapChart";
import SellOutSubcategoryHeatMapChart from "./subcategorySellOutHeatMapChart";
import SellOutRounteHeatMapChart from "./SellOutRouteHeatMapChart";

// //testing
// import HeatmapDrillDownTable from "./dummy/HeatmapDrillDownTable";

function SellOutHeatMapPage() {
    const [selectedbar, setSelectedbar] = useState("region");

    const barOptions = [
        // { label: "Testing", value: "testing" },
        { label: "Regions Heat Map", value: "region" },
        { label: "Distributors Heat Map", value: "agent" },
        { label: "Routes Heat Map", value: "route" },
        { label: "Categories Heat Map", value: "category" },
        { label: "Sub-Categories Heat Map", value: "subcategory" },
    ];

    const handleBarChange = (e) => {
        setSelectedbar(e.value);
    };
    return (
        <div className="col-12 xl:col-12">
            <Panel header="Sell Out Heat Maps" style={{ marginBottom: "20px", padding: "0.5rem" }}>
                <div className="col-12">
                    <Dropdown value={selectedbar} options={barOptions} onChange={handleBarChange} placeholder="Choose..." className="p-mb-4 mx-3 my-2" style={{ marginBottom: "20px" }} />

                    {/* {selectedbar === "testing" && <HeatmapDrillDownTable />} */}
                    {selectedbar === "region" && <SellOutHeatMapChart />}
                    {selectedbar === "agent" && <SellOutAgentHeatMapChart />}
                    {selectedbar === "route" && <SellOutRounteHeatMapChart />}
                    {selectedbar === "category" && <SellOutCategoryHeatMapChart />}
                    {selectedbar === "subcategory" && <SellOutSubcategoryHeatMapChart />}
                </div>
            </Panel>
        </div>
    );
}

export default SellOutHeatMapPage;
