import axiosAPI from "../axiosApi";

export async function getAllNiceTwoSellOutCardStarts(params = {}) {
    const response = await axiosAPI.get("nice-two-sellout-card-stats", { params: params?.params, signal: params?.signal });
    return response;
}
// export async function getAllNiceTwoRegions(params = {}) {
//     const response = await axiosAPI.get("nice-two-regions", { params: params });
//     return response;
// }
// export async function getAllNiceTwoRoutes(params = {}) {
//     const response = await axiosAPI.get("nice-two-routes", { params: params });
//     return response;
// }

//================== dashbord graphs ==========================
export async function getSelloutTxpdtDrillSplyVSActualVsTargetChart(params = {}) {
    const response = await axiosAPI.get("getSelloutTxpdtDrillSplyVSActualVsTargetChart", { params: params });
    return response;
}

//====================== region perfomance with product drill down ===============================
export async function getSelloutTxRegionpdtDrillSplyVSActualVsTargetChart(params = {}) {
    const response = await axiosAPI.get("getSelloutTxRegionpdtDrillSplyVSActualVsTargetChart", { params: params });
    return response;
}

export async function getSelloutTxActualPerfomanceChart(params = {}) {
    const response = await axiosAPI.get("getSelloutTxActualPerfomanceChart", { params: params });
    return response;
}

//
export async function getSelloutHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-region-heat-map", { params: params });
    return response;
}
export async function getSelloutCategoryHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-category-heat-map", { params: params });
    return response;
}
export async function getSelloutCoverageHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-coverage-heat-map", { params: params });
    return response;
}
export async function getSelloutCallProductivityHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-call-productivity-heat-map", { params: params });
    return response;
}
export async function getSelloutAverageCallProductivityRateHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-average-call-productivity-rate-heat-map", { params: params });
    return response;
}
export async function getSelloutSubcategoryHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-subcategory-heat-map", { params: params });
    return response;
}
export async function getSelloutAgentHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-agent-heat-map", { params: params });
    return response;
}
export async function getSelloutRouteHeatMap(params = {}) {
    const response = await axiosAPI.get("sellout-route-heat-map", { params: params });
    return response;
}

//====================== bar drill downs ===================================

export async function getSelloutTxAgentpdtDrillSVSAVsTChart(params = {}) {
    const response = await axiosAPI.get("getSelloutTxAgentpdtDrillSVSAVsTChart", { params: params });
    return response;
}

export async function getSelloutTxRoutepdtDrillSVSAVsTChart(params = {}) {
    const response = await axiosAPI.get("getSelloutTxRoutepdtDrillSVSAVsTChart", { params: params });
    return response;
}

//=================== spline charts ======================

export async function getProductPerformanceVsSPLYVsTargetSpLineChartData(params = {}) {
    const response = await axiosAPI.get("getProductPerformanceVsSPLYVsTargetSpLineChartData", { params: params });
    return response;
}

export async function getPdtCatzPerformanceVsSPLYVsTargetSpLineChartData(params = {}) {
    const response = await axiosAPI.get("getPdtCatzPerformanceVsSPLYVsTargetSpLineChartData", { params: params });
    return response;
}

export async function getRegionPfmceActualVsSPLYVsTargetSplineChart(params = {}) {
    const response = await axiosAPI.get("getRegionPfmceActualVsSPLYVsTargetSplineChart", { params: params });
    return response;
}

export async function getAgentPfmceActualVsSPLYVsTargetSplineChart(params = {}) {
    const response = await axiosAPI.get("getAgentPfmceActualVsSPLYVsTargetSplineChart", { params: params });
    return response;
}

export async function getRoutePfmceActualVsSPLYVsTargetSplineChart(params = {}) {
    const response = await axiosAPI.get("getRoutePfmceActualVsSPLYVsTargetSplineChart", { params: params });
    return response;
}

//===================== order cards and bell curve ================

export async function getNewOrderStatisticsCards(params = {}) {
    const response = await axiosAPI.get("getNewOrderStatisticsCards", { params: params?.params, signal: params?.signal });
    return response;
}

export async function getBellCurveOrderStatistics(params = {}) {
    const response = await axiosAPI.get("getBellCurveOrderStatistics", { params: params?.params, signal: params?.signal });
    return response;
}
