import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import LollipopModule from "highcharts/modules/lollipop";

// Initialize the modules
HighchartsMore(Highcharts);
highchartsDumbbell(Highcharts);
LollipopModule(Highcharts);

const sampleData = {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    actualSales: [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32],
    targetSales: [8, 10, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31],
    salesSPLY: [12, 11, 15, 14, 19, 18, 23, 22, 27, 26, 29, 28],
    percentageChangeActualTarget: [25, 20, 7.7, 6.7, 5.9, 5.3, 4.8, 4.3, 4.0, 3.7, 3.4, 3.2],
    percentageChangeActualSPLY: [-16.7, -8.3, -6.7, 14.3, -5.3, 11.1, -4.3, 9.1, -3.7, 7.7, -1.4, 7.7],
};

const LayereredVerticalTargetVsSplyChart = () => {
    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: "Sales and Percentage Change",
        },
        xAxis: {
            categories: sampleData.categories,
        },
        yAxis: [
            {
                title: {
                    text: "Actual Sales vs Target",
                },
                height: "20%", // Reduce height to prevent overlap
                lineWidth: 2,
            },
            {
                title: {
                    text: "Percentage Change",
                },
                top: "25%", // Adjust top to prevent chart axis intersect
                height: "20%",
                offset: 0,
                lineWidth: 2,
            },
            {
                title: {
                    text: "Actual Sales Vs SPLY",
                },
                top: "50%", // Adjust top to prevent chart axis intersect
                height: "20%",
                offset: 0,
                lineWidth: 2,
            },
            {
                title: {
                    text: "Percentage Change",
                },
                top: "75%", // Adjust top to prevent chart axis intersect
                height: "20%",
                offset: 0,
                lineWidth: 2,
            },
        ],
        series: [
            {
                type: "column",
                name: "Actual Sales",
                data: sampleData.actualSales,
                yAxis: 0,
            },
            {
                type: "column",
                name: "Target Sales",
                data: sampleData.targetSales,
                yAxis: 0,
            },
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. Target",
                data: sampleData.percentageChangeActualTarget.map((value) => ({
                    y: value,
                    color: value > 0 ? "#90ed7d" : "#f45b5b",
                })),
                yAxis: 1,
                marker: {
                    enabled: true,
                    lineColor: null, // Remove black border
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
            },
            {
                type: "column",
                name: "Sales SPLY",
                data: sampleData.salesSPLY,
                yAxis: 2,
            },
            {
                type: "column",
                name: "Actual Sales",
                data: sampleData.actualSales,
                yAxis: 2,
            },
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. SPLY",
                data: sampleData.percentageChangeActualSPLY.map((value) => ({
                    y: value,
                    color: value > 0 ? "#90ed7d" : "#f45b5b",
                })),
                yAxis: 3,
                marker: {
                    enabled: true,
                    lineColor: null, // Remove black border
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
            },
        ],
        plotOptions: {
            lollipop: {
                colorByPoint: false,
                marker: {
                    lineColor: null, // Remove black border
                    lineWidth: 1,
                },
            },
        },
    };

    return (
        <div className="col-12">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default LayereredVerticalTargetVsSplyChart;
