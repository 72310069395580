import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import numeral from "numeral";
import moment from "moment";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const salesAssociatesData = [
    { name: "John Doe", currentSales: 120, splySales: 100, currentTotalOrders: 150, splyTotalOrders: 130 },
    { name: "Jane Smith", currentSales: 100, splySales: 110, currentTotalOrders: 130, splyTotalOrders: 125 },
    { name: "Jim Brown", currentSales: 80, splySales: 70, currentTotalOrders: 90, splyTotalOrders: 85 },
    { name: "Sara White", currentSales: 50, splySales: 60, currentTotalOrders: 70, splyTotalOrders: 65 },
];

const SalesAssociatesOrdersBarChart = ({ data = [], dataFilters, isSalesView = false, setIsSalesView = () => {} }) => {
    console.log("🚀 ~ SalesAssociatesOrdersBarChart ~ dataFilters:", dataFilters);
    // const [isSalesView, setIsSalesView] = useState(false);

    const toggleView = () => {
        setIsSalesView(!isSalesView);
    };

    const getChartOptions = (data, dataFilters) => {
        if (!data || !Array.isArray(data)) {
            return null;
        }

        function formatArray(arr, key) {
            if (!Array.isArray(arr) || arr.length === 0) return "";
            return arr.map((item) => item[key]).join(", ");
        }

        function formatFilters(dataFilters) {
            // Format the dates
            const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";

            const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

            // Extracting and formatting the filters
            const agents = formatArray(dataFilters?.agents, "name");
            const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
            const regions = formatArray(dataFilters?.regions, "name");
            const routes = formatArray(dataFilters?.routes, "name");
            const statuses = formatArray(dataFilters?.statuses, "label");
            // const orderBy = dataFilters?.orderBy?.label || "N/S";
            // const dataLimit = dataFilters?.dataLimit?.label || "N/S";
            const productCategories = formatArray(dataFilters?.productCategories, "name");
            const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
            const products = formatArray(dataFilters?.products, "name");

            // Constructing the sentence
            let sentence = "Filters: ";
            if (agents) sentence += `Distributors: ${agents}. `;
            if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
            if (regions) sentence += `Regions: ${regions}. `;
            if (routes) sentence += `Routes: ${routes}. `;
            if (statuses) sentence += `Statuses: ${statuses}. `;
            // if (orderBy) sentence += `Order By: ${orderBy}. `;
            // if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
            if (productCategories) sentence += `Product Categories: ${productCategories}. `;
            if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
            if (products) sentence += `Products: ${products}. `;
            if (startDate) sentence += `Start Date: ${startDate}. `;
            if (endDate) sentence += `End Date: ${endDate}.`;

            return sentence.trim();
        }

        let chartTitle = formatFilters(dataFilters);
        console.log("🚀 ~ getChartOptions ~ chartTitle:", chartTitle);

        // Prepare the data for the pie chart
        const formattedData = data.map((associate) => ({
            name: associate.name,
            y: associate.orders,
        }));

        const options = {
            chart: {
                type: "bar",
                height: 1000,
                scrollablePlotArea: {
                    minHeight: 500,
                    scrollPositionX: 1,
                },
            },
            title: {
                text: `<br/><br/> Sales Associates ${isSalesView ? "Sales" : "Orders"} <br/> ${chartTitle}`,
            },
            xAxis: {
                categories: data.map((associate) => associate.name),
                title: {
                    text: null,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: isSalesView ? "Sales" : "Orders",
                    align: "high",
                },
                labels: {
                    overflow: "justify",
                },
            },
            tooltip: {
                formatter: function () {
                    const associate = data[this.point.index];
                    const isCurrent = this.series.name.includes("Current");
                    const value = isSalesView ? (isCurrent ? associate.currentSales : associate.splySales) : isCurrent ? associate.currentTotalOrders : associate.splyTotalOrders;
                    const oppositeValue = isSalesView ? (isCurrent ? associate.currentTotalOrders : associate.splyTotalOrders) : isCurrent ? associate.currentSales : associate.splySales;

                    return `<b>${associate.name}</b><br/>
                        ${this.series.name}: ${numeral(value).format("0,0")}<br/>
                        ${isSalesView ? "Orders" : "Sales"}: ${numeral(oppositeValue).format("0,0")}`;
                },
            },
            legend: { enabled: true },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: "{point.y:,.0f}",
                        style: {
                            fill: "#000000",
                        },
                    },
                },
            },
            series: [
                {
                    name: `Current Total ${isSalesView ? "Sales" : "Orders"}`,
                    data: data.map((associate) => (isSalesView ? associate.currentSales : associate.currentTotalOrders)),
                    color: "#235789",
                },
                {
                    name: `SPLY Total ${isSalesView ? "Sales" : "Orders"}`,
                    data: data.map((associate) => (isSalesView ? associate.splySales : associate.splyTotalOrders)),
                    color: "#F1D302",
                },
            ],
            // exporting: {
            //     buttons: {
            //         customButton: {
            //             text: isSalesView ? "Show Orders" : "Show Sales",
            //             onclick: toggleView,
            //             theme: {
            //                 "stroke-width": 0,
            //                 // stroke: "silver",
            //                 r: 0,
            //                 states: {
            //                     hover: {
            //                         fill: "#a4edba",
            //                     },
            //                     select: {
            //                         stroke: "#039",
            //                         fill: "#a4edba",
            //                     },
            //                 },
            //             },
            //         },
            //     },
            // },
            credits: {
                enabled: false,
            },
        };
        return options;
    };

    return (
        <div style={{ height: "400px", width: "100%", overflowY: "auto" }}>
            <HighchartsReact highcharts={Highcharts} options={getChartOptions(data, dataFilters)} />
        </div>
    );
};

export default SalesAssociatesOrdersBarChart;
