import axiosAPI from "../axiosApi";

export async function getAllRoutes(params = {}) {
    const response = await axiosAPI.get("routes", { params: params });
    return response;
}

export async function getRouteById(id) {
    const response = await axiosAPI.get(`routes/` + id);
    return response;
}

export async function postRoute(data) {
    const response = await axiosAPI.post(`routes`, data);
    return response;
}

export async function updateRoute(id, data) {
    const response = await axiosAPI.post(`routes/${id}`, data);
    return response;
}

export async function deleteRouteById(id) {
    const response = await axiosAPI.delete(`routes/${id}`);
    return response;
}
