import React, { useState, useRef } from "react";
import FilesUploadComponent from "./FilesUploadComponent";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, LinearProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { InputNumber } from "primereact/inputnumber";
import { Accordion, AccordionTab } from "primereact/accordion";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";

const FileUploadPage = () => {
    const [fileData, setFileData] = useState([]);
    const [filesDataToUpload, setFilesDataToUpload] = useState();
    const [rowsToShow, setRowsToShow] = useState(20);
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const toastRef = useRef(null);

    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const queryClient = useQueryClient();

    const [uploadMutationIsLoading, setUploadMutationIsLoading] = useState(false);
    const uploadMutation = useMutation({
        mutationFn: (data) => axios.post("/upload", data),
        onSuccess: () => {
            queryClient.invalidateQueries(["files"]);
            setUploadMutationIsLoading(false);
            toastRef.current.show({ severity: "success", summary: "Success", detail: "Files uploaded successfully!" });
        },
        onError: (error) => {
            setUploadMutationIsLoading(false);
            toastRef.current.show({ severity: "error", summary: "Error", detail: error?.response?.data?.message || "An error occurred. Please try again." });
        },
    });

    const handleFilesUpload = async (files) => {
        setIsProcessing(true);

        let files_to_upload = files?.files_to_upload;
        setFilesDataToUpload(files);

        const readFileData = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = new Uint8Array(e.target.result);
                        const workbook = XLSX.read(data, { type: "array" });
                        const fileData = workbook.SheetNames.map((sheetName) => {
                            const sheet = workbook.Sheets[sheetName];
                            if (!sheet["!ref"]) {
                                return {
                                    sheetName,
                                    data: [],
                                    totalRows: 0,
                                };
                            }
                            const range = XLSX.utils.decode_range(sheet["!ref"]);
                            range.e.r = Math.min(range.e.r, rowsToShow - 1);
                            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, range: range });
                            const totalRows = sheet["!ref"] ? XLSX.utils.decode_range(sheet["!ref"]).e.r + 1 : 0;
                            return {
                                sheetName,
                                data: jsonData,
                                totalRows,
                            };
                        });
                        resolve({
                            fileName: file.name,
                            fileSize: (file.size / 1024 / 1024).toFixed(2),
                            fileType: file.type,
                            totalSheets: workbook.SheetNames.length,
                            sheets: fileData,
                        });
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            });
        };

        try {
            //process the excell files
            const results = await Promise.all(files_to_upload.map((file) => readFileData(file)));
            setFileData(results);
        } catch (error) {
            console.log("🚀 ~ readFileData ~ error:", error);
            toastRef.current.show({ severity: "error", summary: "Error", detail: "Error processing file. Please try again." });
        } finally {
            setIsProcessing(false);
        }
    };

    const handleSubmit = () => {
        if (fileData.length === 0) {
            toastRef.current.show({ severity: "warn", summary: "Warning", detail: "No files uploaded. Please upload at least one Excel file." });
            return;
        }
        setShowSubmitDialog(true);
    };

    const confirmSubmit = () => {
        setShowSubmitDialog(false);

        console.log("🚀 ~ confirmSubmit ~ filesDataToUpload:", filesDataToUpload);
        // Convert fileData to FormData
        let formData = new FormData();
        formData.append("data_type", filesDataToUpload?.data_type);

        // fileData.forEach((file, index) => {
        //     formData.append(`files_to_upload[${index}]`, new Blob([JSON.stringify(file)], { type: "application/json" }), file.fileName);
        // });

        filesDataToUpload?.files_to_upload?.forEach((file, index) => {
            console.log("Appending file to FormData:", file);
            formData.append(`files_to_upload[]`, file); // Assuming file is of type File or Blob
        });
        uploadMutation.mutate(formData);
    };

    const handleCancel = () => {
        setShowCancelDialog(true);
    };

    const confirmCancel = () => {
        setFileData([]);
        setFilesDataToUpload(null);
        setShowCancelDialog(false);
        toastRef.current.show({ severity: "info", summary: "Cancelled", detail: "File upload has been cancelled." });
    };

    return (
        <div>
            <Toast ref={toastRef} />
            <center>
                <h3>File Upload and Preview</h3>
                <small>First, choose a data type.</small>
                <br />
                <small>Please upload your Excel files (.xlsx). You can upload up to 5 files at a time.</small>
                <br />
                <small>To upload a file, simply click on "Choose" or drag and drop the file, then select the data type.</small>
            </center>

            <FilesUploadComponent onFilesUpload={handleFilesUpload} />

            <div className="p-field m-4">
                <label htmlFor="rowsToShow">Rows to display:</label>
                <InputNumber id="rowsToShow" value={rowsToShow} onValueChange={(e) => setRowsToShow(e.value)} min={1} />
            </div>

            {isProcessing && <LinearProgress />}

            {fileData.length > 0 &&
                fileData.map((file, fileIndex) => (
                    <Accordion key={fileIndex}>
                        <AccordionTab header={`${file.fileName} - ${file.fileSize} MB`}>
                            <Typography variant="subtitle1">File Specifications:</Typography>
                            <ul>
                                <li>Name: {file.fileName}</li>
                                <li>Size: {parseFloat(file.fileSize).toLocaleString()} MB</li>
                                <li>Type: {file.fileType}</li>
                                <li>Total Sheets: {file.totalSheets.toLocaleString()}</li>
                                {file.sheets.map((sheet, sheetIndex) => (
                                    <li key={sheetIndex}>
                                        Sheet: {sheet.sheetName} - Total Rows: {sheet.totalRows.toLocaleString()}
                                    </li>
                                ))}
                            </ul>
                            <TabView>
                                {file.sheets.map((sheet, sheetIndex) => (
                                    <TabPanel key={sheetIndex} header={sheet.sheetName}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>{sheet.data[0] && sheet.data[0].map((header, index) => <TableCell key={index}>{header}</TableCell>)}</TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sheet.data.slice(1).map((row, rowIndex) => (
                                                        <TableRow key={rowIndex}>
                                                            {row.map((cell, cellIndex) => (
                                                                <TableCell key={cellIndex}>{cell}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                ))}
                            </TabView>
                        </AccordionTab>
                    </Accordion>
                ))}

            <Button variant="contained" disabled={uploadMutationIsLoading} color="primary" onClick={handleSubmit}>
                Submit
            </Button>

            <Button variant="contained" disabled={uploadMutationIsLoading} color="secondary" onClick={handleCancel} style={{ marginLeft: "10px" }}>
                Cancel
            </Button>

            {uploadMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px",
                            borderColor: "blue",
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}

            <Dialog
                header="Confirmation"
                visible={showSubmitDialog}
                style={{ minWidth: "30vw" }}
                onHide={() => setShowSubmitDialog(false)}
                footer={
                    <div>
                        <Button label="No" icon="pi pi-times" onClick={() => setShowSubmitDialog(false)} className="p-button-text" />
                        <Button label="Yes" icon="pi pi-check" onClick={confirmSubmit} autoFocus />
                    </div>
                }
            >
                Are you sure you want to submit the uploaded files?
            </Dialog>

            <Dialog
                header="Confirmation"
                visible={showCancelDialog}
                style={{ minWidth: "30vw" }}
                onHide={() => setShowCancelDialog(false)}
                footer={
                    <div>
                        <Button label="No" icon="pi pi-times" onClick={() => setShowCancelDialog(false)} className="p-button-secondary" />
                        <Button label="Yes" icon="pi pi-check" onClick={confirmCancel} autoFocus />
                    </div>
                }
            >
                Are you sure you want to cancel the file upload?
            </Dialog>
        </div>
    );
};

export default FileUploadPage;
