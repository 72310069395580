import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import more from "highcharts/highcharts-more";

// Initialize modules
drilldown(Highcharts);
more(Highcharts);

const PlasticProductsChartTestingGrouped = () => {
    const options = {
        chart: {
            type: "column", // Using column type for better grouping
            events: {
                drilldown: function (e) {
                    const chart = this;
                    const drilldowns = chart.userOptions.drilldown.series;

                    e.preventDefault();

                    Highcharts.each(drilldowns, function (p) {
                        if (p.id === e.point.drilldown) {
                            chart.addSingleSeriesAsDrilldown(e.point, p);
                        }
                    });

                    chart.applyDrilldown();
                },
            },
        },
        title: {
            text: "Product Sales Drilldown",
        },
        xAxis: {
            type: "category",
        },
        yAxis: [
            {
                title: {
                    text: "Sales",
                },
                opposite: false,
                offset: 0,
                lineWidth: 2,
            },
            {
                title: {
                    text: "Percentage Change",
                },
                opposite: true,
                offset: 0,
                lineWidth: 2,
            },
        ],
        legend: {
            enabled: true,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                },
                grouping: true,
                groupPadding: 0.1,
            },
        },
        series: [
            {
                name: "SPLY Sales",
                data: [
                    { name: "Bottles", y: 100, drilldown: "Bottles-SPLY" },
                    { name: "Containers", y: 80, drilldown: "Containers-SPLY" },
                    { name: "Bags", y: 60, drilldown: "Bags-SPLY" },
                ],
                stack: "Sales",
            },
            {
                name: "Actual Sales",
                data: [
                    { name: "Bottles", y: 120, drilldown: "Bottles-Actual" },
                    { name: "Containers", y: 90, drilldown: "Containers-Actual" },
                    { name: "Bags", y: 70, drilldown: "Bags-Actual" },
                ],
                stack: "Sales",
            },
            {
                name: "Target Sales",
                data: [
                    { name: "Bottles", y: 130, drilldown: "Bottles-Target" },
                    { name: "Containers", y: 100, drilldown: "Containers-Target" },
                    { name: "Bags", y: 80, drilldown: "Bags-Target" },
                ],
                stack: "Sales",
            },
            {
                name: "Growth Actual vs SPLY",
                type: "lollipop",
                yAxis: 1,
                data: [
                    { name: "Bottles", y: 20.0, drilldown: "Bottles-Growth-SPLY" },
                    { name: "Containers", y: 12.5, drilldown: "Containers-Growth-SPLY" },
                    { name: "Bags", y: 16.7, drilldown: "Bags-Growth-SPLY" },
                ],
                tooltip: {
                    pointFormat: "{point.y}%",
                },
            },
            {
                name: "Growth Actual vs Target",
                type: "lollipop",
                yAxis: 1,
                data: [
                    { name: "Bottles", y: -7.7, drilldown: "Bottles-Growth-Target" },
                    { name: "Containers", y: -10.0, drilldown: "Containers-Growth-Target" },
                    { name: "Bags", y: -12.5, drilldown: "Bags-Growth-Target" },
                ],
                tooltip: {
                    pointFormat: "{point.y}%",
                },
            },
        ],
        drilldown: {
            series: [
                // Processing categories, subcategories, and products
                {
                    name: "Bottles Sales",
                    id: "Bottles-SPLY",
                    data: [
                        { name: "Small", y: 50, drilldown: "Bottles-Small-SPLY" },
                        { name: "Medium", y: 40, drilldown: "Bottles-Medium-SPLY" },
                        { name: "Large", y: 30, drilldown: "Bottles-Large-SPLY" },
                    ],
                },
                {
                    name: "Bottles Actual Sales",
                    id: "Bottles-Actual",
                    data: [
                        { name: "Small", y: 60, drilldown: "Bottles-Small-Actual" },
                        { name: "Medium", y: 50, drilldown: "Bottles-Medium-Actual" },
                        { name: "Large", y: 40, drilldown: "Bottles-Large-Actual" },
                    ],
                },
                {
                    name: "Bottles Target Sales",
                    id: "Bottles-Target",
                    data: [
                        { name: "Small", y: 70, drilldown: "Bottles-Small-Target" },
                        { name: "Medium", y: 60, drilldown: "Bottles-Medium-Target" },
                        { name: "Large", y: 50, drilldown: "Bottles-Large-Target" },
                    ],
                },
                {
                    name: "Bottles Growth Actual vs SPLY",
                    id: "Bottles-Growth-SPLY",
                    type: "lollipop",
                    yAxis: 1,
                    data: [
                        { name: "Small", y: 20.0 },
                        { name: "Medium", y: 25.0 },
                        { name: "Large", y: 33.3 },
                    ],
                    tooltip: {
                        pointFormat: "{point.y}%",
                    },
                },
                {
                    name: "Bottles Growth Actual vs Target",
                    id: "Bottles-Growth-Target",
                    type: "lollipop",
                    yAxis: 1,
                    data: [
                        { name: "Small", y: -14.3 },
                        { name: "Medium", y: -16.7 },
                        { name: "Large", y: -20.0 },
                    ],
                    tooltip: {
                        pointFormat: "{point.y}%",
                    },
                },
                // Repeat for other main categories (Containers and Bags)
            ],
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default PlasticProductsChartTestingGrouped;
