import React, { useEffect, useState, useContext, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField, Autocomplete, Grid, Stack, InputLabel, CircularProgress, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { getAllChannels, getRegions, getSalesAssociates, getAgents, getProductCategories, getProductSubcategories, getProducts } from "../../services/dashboard/filters";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { DashboardFilterContext } from "../../context/DashboardFilterContext";

const FiltersFormDialog = ({ onSubmit, filtersFormInitialDataValues, setFiltersFormInitialDataValues, showFiltersFormDialog, setShowFiltersFormDialog }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showResetConfirmDialog, setShowResetConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const { filters } = useContext(DashboardFilterContext);

    //product
    const [selectedProductCategories, setSelectedProductCategories] = useState(filtersFormInitialDataValues?.productCategories);
    const [selectedProductSubCategories, setSelectedProductSubCategories] = useState(filtersFormInitialDataValues?.productSubCategories);

    // State for selected regions and channels
    const [selectedSalesAssociates, setSelectedSalesAssociates] = useState(filtersFormInitialDataValues?.sales_associates);
    const [selectedRegions, setSelectedRegions] = useState(filtersFormInitialDataValues?.regions);
    const [selectedChannels, setSelectedChannels] = useState(filtersFormInitialDataValues?.channels);

    const memoizedFiltersFormInitialDataValues = useMemo(() => filtersFormInitialDataValues, [filtersFormInitialDataValues]);
    useEffect(() => {
        setSelectedProductCategories(filtersFormInitialDataValues?.productCategories);
        setSelectedProductSubCategories(filtersFormInitialDataValues?.productSubCategories);
        setSelectedSalesAssociates(filtersFormInitialDataValues?.sales_associates);
        setSelectedRegions(filtersFormInitialDataValues?.regions);
        setSelectedChannels(filtersFormInitialDataValues?.channels);
    }, [memoizedFiltersFormInitialDataValues]);

    // toast handler
    const toastError = (error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
        error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
    };

    // Queries and useEffects
    // Agents Query
    const agentsQuery = useQuery({
        queryKey: ["sellin-agents", selectedSalesAssociates],
        queryFn: () => getAgents({ sales_associates: selectedSalesAssociates }),
        enabled: selectedSalesAssociates.length > 0,
    });

    useEffect(() => {
        if (agentsQuery?.isError) {
            toastError(agentsQuery?.error);
        }
    }, [agentsQuery?.isError]);

    // Sales Associates Query
    const salesAssociatesQuery = useQuery({
        queryKey: ["sellin-sales_associates", selectedRegions],
        queryFn: () => getSalesAssociates({ regions: selectedRegions }),
        enabled: selectedRegions.length > 0,
    });

    useEffect(() => {
        if (salesAssociatesQuery?.isError) {
            toastError(salesAssociatesQuery?.error);
        }
    }, [salesAssociatesQuery?.isError]);

    // Regions Query (depends on selected channel)
    const regionsQuery = useQuery({
        queryKey: ["sellin-regions", selectedChannels],
        queryFn: () => getRegions({ channels: selectedChannels }),
        enabled: selectedChannels.length > 0,
    });

    useEffect(() => {
        if (regionsQuery?.isError) {
            toastError(regionsQuery?.error);
        }
    }, [regionsQuery?.isError]);

    // Channels Query
    const channelsQuery = useQuery({
        queryKey: ["sellin-channels"],
        queryFn: () => getAllChannels({}),
    });

    useEffect(() => {
        if (channelsQuery?.isError) {
            toastError(channelsQuery?.error);
        }
    }, [channelsQuery?.isError]);

    // Product Categories Query
    const productCategoriesQuery = useQuery({
        queryKey: ["sellin-productCategories"],
        queryFn: () => getProductCategories({}),
    });

    useEffect(() => {
        if (productCategoriesQuery?.isError) {
            toastError(productCategoriesQuery?.error);
        }
    }, [productCategoriesQuery?.isError]);

    // Product Subcategories Query (depends on selected product category)
    const productSubCategoriesQuery = useQuery({
        queryKey: ["sellin-productSubCategories", selectedProductCategories],
        queryFn: () => getProductSubcategories({ productCategories: selectedProductCategories }),
        enabled: selectedProductCategories.length > 0,
    });

    console.log("🚀 ~ FiltersFormDialog ~ productSubCategoriesQuery:", productSubCategoriesQuery);

    useEffect(() => {
        if (productSubCategoriesQuery?.isError) {
            toastError(productSubCategoriesQuery?.error);
        }
    }, [productSubCategoriesQuery?.isError]);

    // Products Query (depends on selected product subcategory)
    const productsQuery = useQuery({
        queryKey: ["sellin-products", selectedProductSubCategories],
        queryFn: () => getProducts({ productSubcategories: selectedProductSubCategories }),
        enabled: selectedProductSubCategories.length > 0,
    });

    useEffect(() => {
        if (productsQuery?.isError) {
            toastError(productsQuery?.error);
        }
    }, [productsQuery?.isError]);

    //hndle form submit
    // const handleFormSubmit = (values) => {
    //     setPendingData(values);
    //     setShowConfirmDialog(true);
    // };

    const handleFormSubmit = (values) => {
        // if (pendingData) {
        onSubmit(values);
        // setPendingData(null);
        setShowFiltersFormDialog(false);
        // }
        setShowConfirmDialog(false);
    };

    // const handleCancel = () => {
    //     setShowConfirmDialog(false);
    // };

    //
    const orderOptions = [
        // { id: 1, label: "Default", value: "default" },
        { id: 1, label: "Ascending", value: "asc" },
        { id: 2, label: "Descending", value: "desc" },
    ];

    // const statusOptions = [
    //     { id: 1, label: "Pending", value: "pending" },
    //     { id: 2, label: "Closed", value: "closed" },
    //     { id: 3, label: "Ongoing", value: "ongoing" },
    // ];

    const measures = [
        { measure: "sales_value", name: "Revenue" },
        { measure: "quantity", name: "Volume" },
        { measure: "gross_profit", name: "Gross Profit" },
    ];

    // const dataLimitOptions = [
    //     { id: 1, label: "All", value: "all" },
    //     { id: 2, label: "5", value: 5 },
    //     { id: 3, label: "10", value: 10 },
    //     { id: 4, label: "20", value: 20 },
    //     { id: 5, label: "25", value: 25 },
    //     { id: 6, label: "30", value: 30 },
    // ];

    //-=========== handle reset ===============
    // const handleReset = (resetForm) => {
    //     setShowResetConfirmDialog(true);
    // };

    const handleReset = (resetForm) => {
        resetForm();
        setFiltersFormInitialDataValues({
            startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            agents: [],
            sales_associates: [],
            regions: [],
            channels: [],
            // statuses: [],
            measure: "sales_value",
            order: null,
            // dataLimit: { id: 2, label: "5", value: 5 },
            dataLimitNumber: null,
            productCategories: [],
            productSubCategories: [],
            products: [],
        });
        setSelectedProductSubCategories([]);
        setSelectedProductCategories([]);
        setSelectedSalesAssociates([]);
        setSelectedRegions([]);
        setSelectedChannels([]);
        setShowResetConfirmDialog(false);
        setShowFiltersFormDialog(false);
    };

    // const handleCancelReset = () => {
    //     setShowResetConfirmDialog(false);
    // };

    // Custom validation function
    const validate = (values) => {
        const errors = {};

        // Date validation
        if (values.startDate && !values.endDate) {
            errors.endDate = "End date is required when start date is chosen";
        }
        if (values.startDate && values.endDate && new Date(values.startDate) > new Date(values.endDate)) {
            errors.endDate = "End date cannot be before start date";
        }

        // if (values.startDate) errors.agents = "start date  is required";

        // if (!Array.isArray(values.agents) || values.agents.length === 0) errors.agents = "Agent is required";
        // Ensure at least one field is filled and check if fields are arrays
        if (
            (!Array.isArray(values.agents) || values.agents.length === 0) &&
            (!Array.isArray(values.sales_associates) || values.sales_associates.length === 0) &&
            (!Array.isArray(values.regions) || values.regions.length === 0) &&
            (!Array.isArray(values.channels) || values.channels.length === 0) &&
            // (!Array.isArray(values.statuses) || values.statuses.length === 0) &&
            !values.order &&
            !values.measure &&
            (!Array.isArray(values.productCategories) || values.productCategories.length === 0) &&
            (!Array.isArray(values.productSubCategories) || values.productSubCategories.length === 0) &&
            (!Array.isArray(values.products) || values.products.length === 0) &&
            !values.startDate &&
            !values.endDate
        ) {
            errors.form = "At least one field must be filled";
        }

        return errors;
    };

    return (
        <Dialog
            header="Filters"
            visible={showFiltersFormDialog}
            maximizable
            style={{ width: "60vw" }}
            footer={() => (
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={() => setShowFiltersFormDialog(false)} className="p-button-text" />
                    {/* <Button label="Submit" icon="pi pi-check" onClick={handleSubmit} /> */}
                </div>
            )}
            onHide={() => setShowFiltersFormDialog(false)}
        >
            <Formik
                initialValues={filtersFormInitialDataValues}
                onSubmit={handleFormSubmit}
                // validationSchema={validationSchema}
                validate={validate}
            >
                {({ values, setFieldValue, resetForm, errors, touched, isSubmitting }) => (
                    <Form>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="startDate">Start Date</InputLabel>
                                        <Field name="startDate">
                                            {({ field }) => (
                                                <DatePicker
                                                    label="Start Date"
                                                    views={["year", "month", "day"]}
                                                    format="DD/MM/YYYY"
                                                    value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                    onChange={(value) => setFieldValue("startDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            error={Boolean(touched.startDate && errors.startDate)}
                                                            // helperText={touched.startDate && errors.startDate ? errors.startDate : ""}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="startDate" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="endDate">End Date</InputLabel>
                                        <Field name="endDate">
                                            {({ field }) => (
                                                <DatePicker
                                                    label="End Date"
                                                    views={["year", "month", "day"]}
                                                    format="DD/MM/YYYY"
                                                    value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                    onChange={(value) => setFieldValue("endDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            error={Boolean(touched.endDate && errors.endDate)}
                                                            // helperText={touched.endDate && errors.endDate ? errors.endDate : ""}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="endDate" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </LocalizationProvider>

                            {/* Status Multiple Select */}
                            {/* <Grid item xs={12} md={6} lg={4}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="measure">Measure</InputLabel>
                                    <Field name="measure">
                                        {({ field }) => (
                                            <Autocomplete
                                                options={measures}
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.measure === value?.measure}
                                                getOptionLabel={(option) => option.name}
                                                value={measures.find((option) => option.measure === field.value) || null}
                                                onChange={(event, newValue) => setFieldValue("measure", newValue ? newValue.measure : null)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select Measure"
                                                        error={Boolean(touched.statuses && errors.statuses)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="measure" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            {/* Order Single Select */}
                            {/* <Grid item xs={12} md={6} lg={3}> */}
                            {/* <Stack spacing={1}>
                                    <InputLabel htmlFor="order">Order</InputLabel>
                                    <Field name="order">
                                        {({ field }) => (
                                            <Autocomplete
                                                options={orderOptions}
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                getOptionLabel={(option) => option.label}
                                                value={field.value}
                                                onChange={(event, newValue) => setFieldValue("order", newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select order"
                                                        error={Boolean(touched.order && errors.order)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="order" component="div" style={{ color: "red" }} />
                                </Stack> */}
                            {/* </Grid> */}

                            {/* Multi-select fields */}

                            {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="channels">Channel</InputLabel>
                                    <Field name="channels">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={channelsQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedChannels}
                                                onChange={(event, newValue) => {
                                                    setSelectedChannels(newValue);
                                                    setSelectedRegions([]);
                                                    setSelectedSalesAssociates([]);
                                                    setFieldValue("channels", newValue);
                                                    setFieldValue("regions", []);
                                                    setFieldValue("sales_associates", []);
                                                    setFieldValue("agents", []);
                                                }}
                                                // disabled={channelsQuery?.isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select channels"
                                                        error={Boolean(touched.channels && errors.channels)}
                                                        // disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {channelsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="channels" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="regions">Region</InputLabel>
                                    <Field name="regions">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={regionsQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedRegions}
                                                onChange={(event, newValue) => {
                                                    setSelectedRegions(newValue);
                                                    setSelectedSalesAssociates([]);
                                                    setFieldValue("regions", newValue);
                                                    setFieldValue("sales_associates", []);
                                                    setFieldValue("agents", []);
                                                }}
                                                disabled={!Array.isArray(selectedChannels) || (Array.isArray(selectedChannels) && selectedChannels.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select regions"
                                                        error={Boolean(touched.regions && errors.regions)}
                                                        disabled={!Array.isArray(selectedChannels) || (Array.isArray(selectedChannels) && selectedChannels.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="regions" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="sales_associates">Sales Associates</InputLabel>
                                    <Field name="sales_associates">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={salesAssociatesQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={field.value}
                                                onChange={(event, newValue) => {
                                                    setSelectedSalesAssociates(newValue);
                                                    setFieldValue("sales_associates", newValue);
                                                    setFieldValue("agents", []);
                                                }}
                                                disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select sales associates"
                                                        error={Boolean(touched.sales_associates && errors.sales_associates)}
                                                        disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {salesAssociatesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="sales_associates" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="agents">Distributors</InputLabel>
                                    <Field name="agents">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={agentsQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={field.value}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("agents", newValue);
                                                }}
                                                disabled={!Array.isArray(selectedSalesAssociates) || (Array.isArray(selectedSalesAssociates) && selectedSalesAssociates.length === 0) || agentsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select Distributors"
                                                        error={Boolean(touched.agents && errors.agents)}
                                                        // helperText={touched.agents && errors.agents ? errors.agents : ""}
                                                        disabled={!Array.isArray(selectedSalesAssociates) || (Array.isArray(selectedSalesAssociates) && selectedSalesAssociates.length === 0) || agentsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {agentsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="agents" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            {/* Multi-select fields */}

                            {/* <Grid item xs={12} md={6} lg={4}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="productCategories">Product Category</InputLabel>
                                    <Field name="productCategories">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={productCategoriesQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedProductCategories}
                                                onChange={(event, newValue) => {
                                                    setSelectedProductCategories(newValue);
                                                    setSelectedProductSubCategories([]);
                                                    setFieldValue("productCategories", newValue);
                                                    setFieldValue("productSubCategories", []);
                                                    setFieldValue("products", []);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select product categories"
                                                        error={Boolean(touched.productCategories && errors.productCategories)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {productCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="productCategories" component="div" />
                                </Stack>
                            </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={4}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="productSubCategories">Product Sub-Category</InputLabel>
                                    <Field name="productSubCategories">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={productSubCategoriesQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedProductSubCategories}
                                                onChange={(event, newValue) => {
                                                    setSelectedProductSubCategories(newValue);
                                                    setFieldValue("productSubCategories", newValue);
                                                    setFieldValue("products", []);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select product sub-categories"
                                                        error={Boolean(touched.productSubCategories && errors.productSubCategories)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {productSubCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="productSubCategories" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={4}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="products">Product</InputLabel>
                                    <Field name="products">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={productsQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={field.value}
                                                onChange={(event, newValue) => setFieldValue("products", newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select products"
                                                        error={Boolean(touched.products && errors.products)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {productsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="products" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            {/* <Grid item xs={12} md={6} lg={3}> */}
                            {/* <Stack spacing={1}>
                                    <InputLabel htmlFor="dataLimitNumber">Data Limit Number</InputLabel>
                                    <Field name="dataLimitNumber">
                                        {({ field }) => <TextField {...field} id="dataLimitNumber" variant="outlined" placeholder="Enter data limit" type="number" InputProps={{ inputProps: { min: 1 } }} error={Boolean(touched.dataLimitNumber && errors.dataLimitNumber)} />}
                                    </Field>
                                    <ErrorMessage name="dataLimitNumber" component="div" style={{ color: "red" }} />
                                </Stack> */}
                            {/* </Grid> */}
                            {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="dataLimit">Data Limit</InputLabel>
                                    <Field name="dataLimit">
                                        {({ field }) => (
                                            <Autocomplete
                                                options={dataLimitOptions}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                getOptionLabel={(option) => option.label}
                                                value={field.value}
                                                onChange={(event, newValue) => setFieldValue("dataLimit", newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select data limit"
                                                        error={Boolean(touched.dataLimit && errors.dataLimit)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="dataLimit" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            <Grid item xs={12} md={12} lg={12}>
                                <Button type="submit" label="Filter" className="p-button-primary m-2" />
                                <Button type="button" onClick={() => handleReset(resetForm)} label="Reset" className="p-button-secondary m-2" />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <center>{errors.form && <div style={{ color: "red" }}>{errors.form}</div>}</center>
                            </Grid>
                        </Grid>
                        {/* <Dialog
                            visible={showConfirmDialog}
                            header="Confirmation"
                            modal
                            onHide={handleCancel}
                            footer={
                                <>
                                    <Button label="Yes" onClick={handleConfirm} />
                                    <Button label="No" onClick={handleCancel} />
                                </>
                            }
                        >
                            Are you sure you want to submit the form?
                        </Dialog> */}

                        {/* <Dialog
                            header="Confirm Reset"
                            visible={showResetConfirmDialog}
                            onHide={handleCancelReset}
                            modal
                            footer={
                                <>
                                    <Button label="Yes" onClick={() => handleConfirmReset(resetForm)} color="primary" autoFocus />
                                    <Button label="No" onClick={handleCancelReset} color="primary" />
                                </>
                            }
                        >
                            Are you sure you want to reset the form? All changes will be lost.
                        </Dialog> */}
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FiltersFormDialog;
