import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import RevenueCardsPage from "./RevenueCardsPage";
import OrderCardsAndBellCurvePage from "./OrderCardsAndBellCurvePage";
import CoverageCardsPage from "./coverage-cards/CoverageCardsPage";
import { Panel } from "primereact/panel";

//
// import RevenueDumyCardsPage from "./dummy-cards/RevenueDumyCardsPage";
// import SalesCardChart from "./dummy-cards/updated/SalesCardChart";
import SalesCardChart from "./revenue-cards/SalesCardChart";

//
import ToggleComparisonActualPerfomanceBarCharts from "../new-charts/comparion-charts/comparison-actual-perfomance-bar-charts/ToggleComparisonActualPerfomanceBarCharts";
import ToggleComparisonActualPerfomanceSplineCharts from "../new-charts/comparion-charts/comparison-actual-perfomance-spline-charts/ToggleComparisonActualPerfomanceSplineCharts";

//
import CoverageCardSupportComponents from "./coverage-cards/CoverageCardSupportComponents";

function ToggleCards() {
    const [selectedChart, setSelectedChart] = useState("revenue");

    const chartOptions = [
        { label: "Revenue", value: "revenue" },
        { label: "Volume", value: "volume" },
        { label: "Orders", value: "orders" },
        { label: "Coverage", value: "coverage" },
        { label: "Coverage Heat Maps", value: "coverage-heatmaps" },
    ];
    return (
        <div className="col-12">
            <Panel header={`Statistics`} toggleable style={{ minWidth: "100%" }}>
                <div className="col-12">
                    <Dropdown value={selectedChart} options={chartOptions} onChange={(e) => setSelectedChart(e.value)} placeholder="Select a chart" className="p-mb-4 mx-3 my-2" />
                    {selectedChart === "revenue" && <SalesCardChart />}
                    {selectedChart === "volume" && <SalesCardChart measure="quantity" />}
                    {selectedChart === "orders" && <OrderCardsAndBellCurvePage />}
                    {selectedChart === "coverage" && <CoverageCardsPage />}
                    {selectedChart === "coverage-heatmaps" && <CoverageCardSupportComponents />}
                </div>
            </Panel>

            {selectedChart === "revenue" && (
                <Panel header={`Performance Trend vs Same Period Last Year (SPLY)`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <ToggleComparisonActualPerfomanceBarCharts />
                        </div>
                        <div className="col-12 xl:col-6">
                            <ToggleComparisonActualPerfomanceSplineCharts />
                        </div>
                    </div>
                </Panel>
            )}

            {selectedChart === "volume" && (
                <Panel header={`Performance Trend vs Same Period Last Year (SPLY)`} toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <ToggleComparisonActualPerfomanceBarCharts measure="quantity" />
                        </div>
                        <div className="col-12 xl:col-6">
                            <ToggleComparisonActualPerfomanceSplineCharts measure="quantity" />
                        </div>
                    </div>
                </Panel>
            )}
            {/* {selectedChart === "coverage" && <CoverageCardSupportComponents />} */}
        </div>
    );
}

export default ToggleCards;
