import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

import React, { useContext, useEffect, useState } from "react";
import { DashboardFilterContext } from "../../../../context/DashboardFilterContext";
import { getProductCategoryPerformanceChart } from "../../../../services/dashboard/charts";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

//
import FiltersFormDialog from "../../widgets/FiltersFormDialog";
import { Button } from "primereact/button";

import noData from "highcharts/modules/no-data-to-display";
// Initialize the required Highcharts modules
noData(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

// Function to generate a list of colors
const generateColors = (numColors) => {
    const colors = [];
    const alpha = 1; // Fixed alpha for RGBA
    var rs;
    var gs;
    var bs;
    for (let i = 0; i < numColors; i++) {
        var r;
        var g;
        var b;
        do {
            r = Math.round(getRndInteger(0, 255));
            g = Math.round(getRndInteger(0, 255));
            b = Math.round(getRndInteger(0, 255));
        } while ((r > 200 && g > 200 && b > 200) || (Math.abs(r - rs) < 10 && Math.abs(g - gs) < 10 && Math.abs(b - bs) < 10));
        colors.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
        rs = r;
        gs = g;
        bs = b;
    }

    return colors;
};
// const generateColors = (numColors) => {
//     const colors = [];
//     for (let i = 1; i <= numColors; i++) {
//         const hue = ((i * 360) / numColors) % 360;
//         colors.push(`hsl(${hue}, 100%, 50%)`);
//     }
//     return colors;
// };

const ProductCategoryPerformanceChart = () => {
    const { filters } = useContext(DashboardFilterContext);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        start_date: filters?.startDate,
        end_date: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [],
        measure: null,
        order: null,
        dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            start_date: filters?.startDate,
            end_date: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            measure: filters?.measure,
        });
    }, [filters]);

    // FiltersForm diaglog
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const ProductCategoryPerformanceChartQuery = useQuery({
        queryKey: ["product-category-performance", filters?.startDate, filters?.endDate, filters?.measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getProductCategoryPerformanceChart({ start_date: filters?.startDate, end_date: filters?.endDate, measure: filters?.measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (ProductCategoryPerformanceChartQuery?.isError) {
            console.log("🚀 ~ useEffect ~ ProductCategoryPerformanceChartQuery?.isError:", ProductCategoryPerformanceChartQuery?.error);
            ProductCategoryPerformanceChartQuery?.error?.response?.data?.message
                ? toast.error(ProductCategoryPerformanceChartQuery?.error?.response?.data?.message)
                : !ProductCategoryPerformanceChartQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [ProductCategoryPerformanceChartQuery?.isError]);

    if (ProductCategoryPerformanceChartQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (ProductCategoryPerformanceChartQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    const productCategoryData = ProductCategoryPerformanceChartQuery?.data?.data?.data;

    if (!productCategoryData) {
        return <div>No data available</div>;
    }

    const { months, cy, sply } = productCategoryData;

    const productCategories = Object.keys(cy);
    const colors = generateColors(productCategories.length * 2); // Generating colors for both CY and SPLY series

    const series = productCategories.flatMap((productCategory, index) => [
        {
            name: `${productCategory}`,
            data: cy[productCategory],
            color: colors[index * 2],
        },
        {
            name: `${productCategory} SPLY`,
            data: sply[productCategory],
            color: colors[index * 2 + 1],
        },
    ]);

    const getChartOptions = () => ({
        chart: {
            type: "line",
            // height: 700,
        },
        title: {
            text: `Product Category Performance from ${ProductCategoryPerformanceChartQuery?.data?.data?.heading} vs SPLY`,
        },
        xAxis: {
            categories: months,
        },
        yAxis: {
            title: {
                text: ProductCategoryPerformanceChartQuery?.data?.data?.measureName,
            },
        },
        tooltip: {
            shared: true,
            valuePrefix: ProductCategoryPerformanceChartQuery?.data?.data?.measureName === "Volume" ? "" : "UGX ",
            valueSuffix: "",
            headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
        },
        series: series.map((s, index) => ({
            name: s.name,
            data: s.data,
            color: s.color,
            // visible: index < 4 && index >= 2, // Show only the first 2 series initially
            // visible: index < 3, // Show only the first 3 series initially
        })),
        exporting: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
    });

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    return (
        <>
            <div className="col-12 text-right">
                {/* <Button icon="pi pi-filter" onClick={(e) => op.current.toggle(e)} />
                        <OverlayPanel ref={op}>
                            <FiltersForm />
                        </OverlayPanel> */}

                <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                <FiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} />
            </div>
            <HighchartsReact highcharts={Highcharts} options={getChartOptions()} />
        </>
    );
};

export default ProductCategoryPerformanceChart;
