import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import BellCurve from "highcharts/modules/histogram-bellcurve";
import Annotations from "highcharts/modules/annotations";

//
import { Tooltip, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OverlayPanel } from "primereact/overlaypanel";
import moment from "moment";

// Initialize the bell curve and annotations modules
BellCurve(Highcharts);
Annotations(Highcharts);

const OrdersBellChart = ({ chartData, title, dataFilters }) => {
    // const data = [
    //     3.5, 3, 3.2, 3.1, 3.6, 3.9, 3.4, 3.4, 2.9, 3.1, 3.7, 3.4, 3, 3, 4, 4.4, 3.9, 3.5, 3.8, 3.8, 3.4, 3.7, 3.6, 3.3, 3.4, 3, 3.4, 3.5, 3.4, 3.2, 3.1, 3.4, 4.1, 4.2, 3.1, 3.2, 3.5, 3.6, 3, 3.4, 3.5, 2.3, 3.2, 3.5, 3.8, 3, 3.8, 3.2, 3.7, 3.3, 3.2, 3.2, 3.1, 2.3, 2.8, 2.8, 3.3, 2.4, 2.9, 2.7, 2, 3,
    //     2.2, 2.9, 2.9, 3.1, 3, 2.7, 2.2, 2.5, 3.2, 2.8, 2.5, 2.8, 2.9, 3, 2.8, 3, 2.9, 2.6, 2.4, 2.4, 2.7, 2.7, 3, 3.4, 3.1, 2.3, 3, 2.5, 2.6, 3, 2.6, 2.3, 2.7, 3, 2.9, 2.9, 2.5, 2.8, 3.3, 2.7, 3, 2.9, 3, 3, 2.5, 2.9, 2.5, 3.6, 3.2, 2.7, 3, 2.5, 2.8, 3.2, 3, 3.8, 2.6, 2.2, 3.2, 2.8, 2.8, 2.7, 3.3,
    //     3.2, 2.8, 3, 2.8, 3, 2.8, 3.8, 2.8, 2.8, 2.6, 3, 3.4, 3.1, 3, 3.1, 3.1, 3.1, 2.7, 3.2, 3.3, 3, 2.5, 3, 3.4, 3,
    // ];
    const op = useRef(null);

    let data = chartData?.transactionValues ?? [];

    // Calculate statistics
    const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
    const sortedData = [...data].sort((a, b) => a - b);
    const median = sortedData.length % 2 === 0 ? (sortedData[sortedData.length / 2 - 1] + sortedData[sortedData.length / 2]) / 2 : sortedData[Math.floor(sortedData.length / 2)];
    const maxTransaction = Math.max(...data);

    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

        // Extracting and formatting the filters
        const agents = formatArray(dataFilters?.agents, "name");
        const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const routes = formatArray(dataFilters?.routes, "name");
        const statuses = formatArray(dataFilters?.statuses, "label");
        // const orderBy = dataFilters?.orderBy?.label || "N/S";
        // const dataLimit = dataFilters?.dataLimit?.label || "N/S";
        const productCategories = formatArray(dataFilters?.productCategories, "name");
        const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
        const products = formatArray(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "Filters: ";
        if (agents) sentence += `Distributors: ${agents}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        // if (orderBy) sentence += `Order By: ${orderBy}. `;
        // if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        if (startDate) sentence += `Start Date: ${startDate}. `;
        if (endDate) sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    let chartTitle = formatFilters(dataFilters);

    const options = {
        title: {
            text: `${title} <br/> ${chartTitle}`,
        },
        xAxis: [
            {
                title: {
                    text: "Transaction Index",
                },
                alignTicks: false,
            },
            {
                title: {
                    text: "Density",
                },
                alignTicks: false,
                opposite: true,
            },
        ],
        yAxis: [
            {
                title: {
                    text: "Transaction Amount",
                },
            },
            {
                title: {
                    text: "Density",
                },
                opposite: true,
            },
        ],
        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Bell Curve",
                type: "bellcurve",
                xAxis: 1,
                yAxis: 1,
                baseSeries: 1,
                zIndex: -1,
            },
            {
                name: "Data",
                type: "scatter",
                data: data,
                accessibility: {
                    exposeAsGroupOnly: true,
                },
                marker: {
                    radius: 1.5,
                },
            },
        ],
    };

    return (
        <div>
            <div className="card">
                <center>
                    <h3>
                        {title} Statistics
                        <Tooltip title="How to interpret this chart">
                            <IconButton onClick={(e) => op.current.toggle(e)}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </h3>
                    <ul style={{ listStyle: "none" }}>
                        <li>Average Transaction Value: {mean.toLocaleString()}</li>
                        <li>Median Transaction Value: {median.toLocaleString()}</li>
                        <li>Highest Transaction Value: {maxTransaction.toLocaleString()}</li>
                    </ul>
                </center>
            </div>
            <OverlayPanel ref={op} style={{ maxWidth: "80vw", padding: "20px" }}>
                <div>
                    <h4>Interpreting the Bell Curve of Current Transactions</h4>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Overview</h5>
                    <p>This bell curve, also known as a normal distribution, visualizes the distribution of transaction values in our dataset. It provides a powerful tool for understanding the overall pattern and characteristics of our transaction data.</p>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Key Components</h5>
                    <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>X-axis (Transaction Index):</strong> This axis represents the index of each transaction in the dataset. It is a simple sequential numbering of each data point from 0 to the total number of transactions.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Y-axis (Left):</strong> This axis represents the actual transaction amounts from your dataset.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Y-axis (Right):</strong> Displays the density, indicating the relative frequency or probability of transactions at each point.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Top X-axis:</strong> Provides an additional scale for transaction values, including both negative and positive amounts for a comprehensive view.
                            <ul>
                                <li>The top x-axis, labeled "Density," corresponds to the bell curve (normal distribution) plotted over the transaction index.</li>
                                <li>The values on this axis are standardized, typically indicating standard deviations from the mean.</li>
                                <li>This axis helps interpret how densely the data points are distributed around the mean. For example, values closer to the center indicate higher density (more data points around the mean), while values towards the edges indicate lower density.</li>
                                <li>z = (X - μ) / σ where X is the transaction amount, μ is the mean, and σ is the standard deviation.</li>
                            </ul>
                        </li>
                    </ul>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Understanding the Curve</h5>
                    <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Shape:</strong> The symmetric, bell-shaped curve indicates a normal distribution of transactions. This suggests that most transactions cluster around a central value, with fewer transactions at the extremes.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Peak:</strong> The highest point of the curve represents the most common transaction value or frequency. Transactions near this peak are the most typical in your dataset.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Width:</strong> The spread of the curve indicates the variability of transactions. A wider curve suggests more diverse transaction values, while a narrower curve indicates more consistency.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Tails:</strong> The tapering ends of the curve represent less common, extreme transactions. The left tail shows lower values, while the right tail shows higher values. Long tails might indicate outliers or unusual transactions.
                        </li>
                    </ul>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Data Line</h5>
                    <p>The blue line at the bottom represents actual transaction data. Its shape roughly follows the bell curve but shows some deviations, particularly at higher transaction indices. These deviations highlight where real-world data differs from the idealized normal distribution.</p>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Practical Interpretation</h5>
                    <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ marginBottom: "5px" }}>Use the peak to identify the most common transaction range.</li>
                        <li style={{ marginBottom: "5px" }}>Assess the width to understand transaction variability.</li>
                        <li style={{ marginBottom: "5px" }}>Examine the tails for insights into unusual or extreme transactions.</li>
                        <li style={{ marginBottom: "5px" }}>Compare the actual data line to the curve to spot patterns or anomalies.</li>
                    </ul>

                    <p>This visualization allows you to quickly grasp the overall distribution of transactions, identify typical ranges, and spot any unusual patterns or outliers in your transaction data.</p>
                </div>
            </OverlayPanel>

            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default OrdersBellChart;
