import axiosAPI from "../axiosApi";

export async function getAllCustomers(params = {}) {
    const response = await axiosAPI.get("customers", { params: params });
    return response;
}

export async function getCustomerById(id) {
    const response = await axiosAPI.get(`customers/` + id);
    return response;
}

export async function postCustomer(data) {
    const response = await axiosAPI.post(`customers`, data);
    return response;
}

export async function updateCustomer(id, data) {
    const response = await axiosAPI.post(`customers/${id}`, data);
    return response;
}

export async function deleteCustomerById(id) {
    const response = await axiosAPI.delete(`customers/${id}`);
    return response;
}
