import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import highchartsDrilldown from "highcharts/modules/drilldown";
import LollipopModule from "highcharts/modules/lollipop";

// Initialize the modules
HighchartsMore(Highcharts);
highchartsDrilldown(Highcharts);
LollipopModule(Highcharts);

const sampleData = {
    categories: [
        {
            name: "Furniture",
            sply: 200,
            actual: 205,
            target: 225,
            percentageChangeActualTarget: -8.89,
            percentageChangeActualSPLY: 2.5,
            subCategories: [
                {
                    name: "Chairs",
                    sply: 180,
                    actual: 195,
                    target: 215,
                    percentageChangeActualTarget: -9.3,
                    percentageChangeActualSPLY: 8.33,
                    products: [
                        {
                            name: "Plastic Chair",
                            sply: 100,
                            actual: 120,
                            target: 130,
                            percentageChangeActualTarget: -7.7,
                            percentageChangeActualSPLY: 20,
                        },
                        {
                            name: "Folding Chair",
                            sply: 90,
                            actual: 85,
                            target: 95,
                            percentageChangeActualTarget: -10.5,
                            percentageChangeActualSPLY: -5.6,
                        },
                    ],
                },
            ],
        },
        {
            name: "Kitchenware",
            sply: 200,
            actual: 215,
            target: 230,
            percentageChangeActualTarget: -6.52,
            percentageChangeActualSPLY: 7.5,
            subCategories: [
                {
                    name: "Basins",
                    sply: 200,
                    actual: 215,
                    target: 230,
                    percentageChangeActualTarget: -6.52,
                    percentageChangeActualSPLY: 7.5,
                    products: [
                        {
                            name: "Plastic Basin",
                            sply: 110,
                            actual: 115,
                            target: 120,
                            percentageChangeActualTarget: -4.2,
                            percentageChangeActualSPLY: 4.5,
                        },
                        {
                            name: "Large Basin",
                            sply: 90,
                            actual: 100,
                            target: 110,
                            percentageChangeActualTarget: -9.1,
                            percentageChangeActualSPLY: 11.1,
                        },
                    ],
                },
            ],
        },
    ],
};

const LayeredProductSalesDrilldownChart = () => {
    const options = {
        chart: {
            type: "bar",
        },
        title: {
            text: "Plastic Product Sales",
        },
        xAxis: {
            type: "category",
        },
        yAxis: [
            {
                title: {
                    text: "Sales",
                },
                width: "30%",
                lineWidth: 2,
                offset: 0,
            },
            {
                title: {
                    text: "Percentage Change Actual vs. Target",
                },
                left: "35%",
                width: "30%",
                offset: 0,
                lineWidth: 2,
            },
            {
                title: {
                    text: "Percentage Change Actual vs. SPLY",
                },
                left: "70%",
                width: "30%",
                offset: 0,
                lineWidth: 2,
            },
        ],
        series: [
            {
                name: "SPLY",
                data: sampleData.categories.map((category) => ({
                    name: category.name,
                    y: category.sply,
                    drilldown: category.name,
                })),
            },
            {
                name: "Actual",
                data: sampleData.categories.map((category) => ({
                    name: category.name,
                    y: category.actual,
                    drilldown: category.name,
                })),
            },
            {
                name: "Target",
                data: sampleData.categories.map((category) => ({
                    name: category.name,
                    y: category.target,
                    drilldown: category.name,
                })),
            },
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. Target",
                data: sampleData.categories.map((category) => ({
                    name: category.name,
                    y: category.percentageChangeActualTarget,
                })),
                yAxis: 1,
                marker: {
                    enabled: true,
                    lineColor: null,
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
            },
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. SPLY",
                data: sampleData.categories.map((category) => ({
                    name: category.name,
                    y: category.percentageChangeActualSPLY,
                })),
                yAxis: 2,
                marker: {
                    enabled: true,
                    lineColor: null,
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
            },
        ],
        drilldown: {
            allowPointDrilldown: false,
            series: sampleData.categories.flatMap((category) => [
                ...category.subCategories.map((subCategory) => ({
                    name: subCategory.name,
                    id: category.name,
                    data: subCategory.products.map((product) => ({
                        name: product.name,
                        y: product.actual,
                        drilldown: `${category.name}-${subCategory.name}-${product.name}`,
                    })),
                })),
                ...category.subCategories.flatMap((subCategory) => [
                    {
                        type: "lollipop",
                        name: `Percentage Change Actual vs. Target (${subCategory.name})`,
                        id: `${category.name}-${subCategory.name}`,
                        yAxis: 1,
                        data: subCategory.products.map((product) => ({
                            name: product.name,
                            y: product.percentageChangeActualTarget,
                        })),
                    },
                    {
                        type: "lollipop",
                        name: `Percentage Change Actual vs. SPLY (${subCategory.name})`,
                        id: `${category.name}-${subCategory.name}`,
                        yAxis: 2,
                        data: subCategory.products.map((product) => ({
                            name: product.name,
                            y: product.percentageChangeActualSPLY,
                        })),
                    },
                ]),
            ]),
        },
        plotOptions: {
            series: {
                grouping: true,
                groupPadding: 0.1,
                pointPadding: 0.1,
            },
            lollipop: {
                colorByPoint: false,
                marker: {
                    lineColor: null,
                    lineWidth: 1,
                },
            },
        },
    };

    return (
        <div className="col-12">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default LayeredProductSalesDrilldownChart;
