import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

import { getCustomerSalesProductOnlyDrilldown } from "../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import MaterialUiLoaderLottie from "../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialog from "../widgets/FiltersFormDialog";
import { Button } from "primereact/button";

import moment from "moment";
import { Tooltip } from "@mui/material";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const getChartOptions = (sampleData, dataFilters) => {
    if (!sampleData?.customers || !Array.isArray(sampleData?.customers)) {
        return null;
    }

    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const customersData = data.customers.map((customer) => {
            drilldownSeries.push({
                id: customer.id,
                name: `${customer.name} Products`,
                data: customer.products.map((product) => [product.name, product.sales]),
            });
            return {
                name: customer.name,
                y: customer.sales,
                drilldown: customer.id,
            };
        });
        return { customersData, drilldownSeries };
    };

    const { customersData, drilldownSeries } = generateDrilldownData(sampleData);

    return {
        chart: {
            type: "bar",
        },
        title: {
            text: `Customers Sales Insight from ${startDate} to ${endDate}`,
        },
        subtitle: {
            text: "Click the columns to view products.",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Total Sales (UGX)",
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.0f}",
                },
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f} UGX</b><br/>',
        },
        series: [
            {
                name: "Customers",
                colorByPoint: true,
                data: customersData,
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            series: drilldownSeries,
        },
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
    };
};

const CustomerSalesProductOnlyDrilldownChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);

    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "5", value: 5 },
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        setFiltersFormInitialDataValues(data);
    };

    const CustomerSalesProductOnlyDrilldownQuery = useQuery({
        queryKey: ["customer-sales-product-only-drilldown", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getCustomerSalesProductOnlyDrilldown({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (CustomerSalesProductOnlyDrilldownQuery?.isError) {
            const errorMessage = CustomerSalesProductOnlyDrilldownQuery?.error?.response?.data?.message
                ? CustomerSalesProductOnlyDrilldownQuery?.error?.response?.data?.message
                : !CustomerSalesProductOnlyDrilldownQuery?.error?.response
                ? "Check Your Internet Connection Please"
                : "An Error Occurred Please Contact Admin";
            toast.error(errorMessage);
        }
    }, [CustomerSalesProductOnlyDrilldownQuery?.isError]);

    const chartOptionsData = getChartOptions(CustomerSalesProductOnlyDrilldownQuery?.data?.data?.data, CustomerSalesProductOnlyDrilldownQuery?.data?.data?.requestParams);

    return (
        <>
            {CustomerSalesProductOnlyDrilldownQuery?.isLoading ? (
                <div className="col-12">
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={ServerProcessingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : CustomerSalesProductOnlyDrilldownQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsData} />
                </>
            )}
        </>
    );
};

export default CustomerSalesProductOnlyDrilldownChart;
