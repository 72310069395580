import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

import React, { useContext, useEffect, useState } from "react";
import { DashboardFilterContext } from "../../../../context/DashboardFilterContext";
import { getSuplusDeficitRegionPerformanceChart } from "../../../../services/dashboard/charts";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";
//
import FiltersFormDialog from "../../widgets/FiltersFormDialog";
import { Button } from "primereact/button";
import noData from "highcharts/modules/no-data-to-display";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

// Sample data (replace with your actual data)
// const salesData = {
//     months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//     cy: [10000, 12000, 13000, 12000, 15000, 14000, 17000, 16000, 19000, 18000, 21000, 20000],
//     sply: [9000, 11000, 14000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 21000],
//     profit: [1000, 1000, 0, -1000, 1000, -1000, 1000, -1000, 1000, -1000, 1000, -1000],
//     loss: [0, 0, -1000, 1000, 0, 1000, 0, 1000, 0, 1000, 0, 1000],
// };

const MomRegionChart = () => {
    const { filters } = useContext(DashboardFilterContext);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        start_date: filters?.startDate,
        end_date: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [],
        measure: null,
        order: null,
        dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            start_date: filters?.startDate,
            end_date: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            measure: filters?.measure,
        });
    }, [filters]);

    // FiltersForm diaglog
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const SuplusDeficitPerformanceRegionChartQuery = useQuery({
        queryKey: ["region-suplus-deficit", filters?.startDate, filters?.endDate, filters?.measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSuplusDeficitRegionPerformanceChart({ start_date: filters?.startDate, end_date: filters?.endDate, measure: filters?.measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SuplusDeficitPerformanceRegionChartQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SuplusDeficitPerformanceRegionChartQuery?.isError:", SuplusDeficitPerformanceRegionChartQuery?.error);
            SuplusDeficitPerformanceRegionChartQuery?.error?.response?.data?.message
                ? toast.error(SuplusDeficitPerformanceRegionChartQuery?.error?.response?.data?.message)
                : !SuplusDeficitPerformanceRegionChartQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SuplusDeficitPerformanceRegionChartQuery?.isError]);

    if (SuplusDeficitPerformanceRegionChartQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (SuplusDeficitPerformanceRegionChartQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    const salesData = SuplusDeficitPerformanceRegionChartQuery?.data?.data?.data;

    if (!salesData) {
        return <div>No data available</div>;
    }

    const getChartOptions = () => ({
        chart: {
            type: "bar",
            // height: 700,
        },
        title: {
            text: `Region Performance from ${SuplusDeficitPerformanceRegionChartQuery?.data?.data?.heading} vs SPLY`,
        },
        xAxis: {
            categories: salesData.months,
            crosshair: true,
        },
        yAxis: {
            title: {
                text: SuplusDeficitPerformanceRegionChartQuery?.data?.data?.measureName,
            },
        },
        legend: {
            shadow: false,
        },
        tooltip: {
            shared: true,
            valuePrefix: SuplusDeficitPerformanceRegionChartQuery?.data?.data?.measureName === "Volume" ? "" : "UGX ",
            valueDecimals: 0,
        },
        plotOptions: {
            series: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
            },
        },
        series: [
            {
                name: "Selected Period Sales",
                color: "#0000FF",
                data: salesData.cy,
                pointPadding: 0.3,
                pointPlacement: -0.2,
            },
            {
                name: "Same Period Last Year Sales",
                color: "rgba(248,161,63,1)",
                data: salesData.sply,
                pointPadding: 0.4,
                pointPlacement: -0.2,
            },
            {
                name: "Surplus",
                color: "#32CD32",
                data: salesData.profit.map((value) => Math.max(0, value)), // Positive values for profit
                pointPadding: 0.3,
                pointPlacement: 0.2,
            },
            {
                name: "Deficit",
                color: "#CD5C5C",
                data: salesData.loss.map((value) => Math.min(0, value)), // Negative values for loss
                pointPadding: 0.4,
                pointPlacement: 0.2,
            },
        ],
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
    });

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    return (
        <>
            <div className="col-12 text-right">
                {/* <Button icon="pi pi-filter" onClick={(e) => op.current.toggle(e)} />
                        <OverlayPanel ref={op}>
                            <FiltersForm />
                        </OverlayPanel> */}

                <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                <FiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} />
            </div>
            <HighchartsReact highcharts={Highcharts} options={getChartOptions()} />
        </>
    );
};

export default MomRegionChart;
