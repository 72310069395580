import axiosAPI from "../axiosApi";

export async function getAllAgents(params = {}) {
    const response = await axiosAPI.get("agents", { params: params });
    return response;
}

export async function getAgentById(id) {
    const response = await axiosAPI.get(`agents/` + id);
    return response;
}

export async function postAgent(data) {
    const response = await axiosAPI.post(`agents`, data);
    return response;
}

export async function updateAgent(id, data) {
    const response = await axiosAPI.post(`agents/${id}`, data);
    return response;
}

export async function deleteAgentById(id) {
    const response = await axiosAPI.delete(`agents/${id}`);
    return response;
}
