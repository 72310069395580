import React, { createContext, useMemo, useState } from "react";
import moment from "moment";

const NiceTwoDBFilterContext = createContext();

const NiceTwoDBFilterProvider = ({ children }) => {
    const [unMemorisedfilters, setFilters] = useState({
        startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            // { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        // orderBy: { id: 3, label: "Descending", value: "desc" },
        // dataLimit: { id: 2, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    const resetFilters = () => {
        setFilters({
            startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            agents: [],
            salesAssociates: [],
            regions: [],
            routes: [],
            statuses: [
                // { id: 1, label: "Pending", value: "PENDING" },
                { id: 2, label: "Closed", value: "CLOSED" },
            ],
            // orderBy: { id: 3, label: "Descending", value: "desc" },
            // dataLimit: { id: 2, label: "5", value: 5 },
            // dataLimitNumber: null,
            productCategories: [],
            productSubCategories: [],
            products: [],
        });
    };

    // Memoize the filters
    const filters = useMemo(() => unMemorisedfilters, [unMemorisedfilters]);

    return <NiceTwoDBFilterContext.Provider value={{ filters, setFilters, resetFilters }}>{children}</NiceTwoDBFilterContext.Provider>;
};

export { NiceTwoDBFilterContext, NiceTwoDBFilterProvider };
