import React from "react";
import { Card, CardContent, Typography, Box, Tooltip } from "@mui/material";
//icon imports
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TimelineIcon from "@mui/icons-material/Timeline";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import PublicIcon from "@mui/icons-material/Public";
import CategoryIcon from "@mui/icons-material/Category";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EuroIcon from "@mui/icons-material/Euro";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import StoreIcon from "@mui/icons-material/Store";

const iconMap = {
    ShoppingCartIcon: ShoppingCartIcon,
    MonetizationOnIcon: MonetizationOnIcon,
    TrendingUpIcon: TrendingUpIcon,
    TimelineIcon: TimelineIcon,
    MultilineChartIcon: MultilineChartIcon,
    PublicIcon: PublicIcon,
    CategoryIcon: CategoryIcon,
    ShowChartIcon: ShowChartIcon,
    PieChartIcon: PieChartIcon,
    ListAltIcon: ListAltIcon,
    BarChartIcon: BarChartIcon,
    StarIcon: StarIcon,
    PersonIcon: PersonIcon,
    EmojiEventsIcon: EmojiEventsIcon,
    AssessmentIcon: AssessmentIcon,
    TrendingDownIcon: TrendingDownIcon,
    EqualizerIcon: EqualizerIcon,
    EuroIcon: EuroIcon,
    AttachMoneyIcon: AttachMoneyIcon,
    DonutSmallIcon: DonutSmallIcon,
    BusinessCenterIcon: BusinessCenterIcon,
    StoreIcon: StoreIcon,
};

const StatsCard = ({ data }) => {
    let IconComponent = iconMap[data?.icon] ?? TrendingUpIcon;

    const getChangeColor = (change) => {
        if (change.startsWith("0")) {
            return "text-blue-500";
        } else if (change.startsWith("-")) {
            return "text-red-500";
        } else {
            return "text-green-500";
        }
    };

    const formatNumber = (num) => {
        if (isNaN(num)) {
            return num;
        }
        // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <span className="block text-500 font-medium mb-3"> {data?.title}</span>
                    <Tooltip title={data?.tooltip} arrow>
                        <div className="text-900 font-medium text-xl">
                            <small style={{ color: "grey" }}>{data?.unit}</small> {formatNumber(data?.value)}
                        </div>
                    </Tooltip>
                </div>
                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                    {/* <i className="pi pi-shopping-cart text-blue-500 text-xl" /> */}
                    <IconComponent style={{ color: data?.color }} />
                </div>
            </div>
            {data?.change && <span className={`${getChangeColor(data?.change)} font-medium`}>{data?.change}</span>}
            {data?.description && <span className="text-500"> {data?.description}</span>}
        </div>
    );
};

export default StatsCard;
