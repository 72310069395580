import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";

// Initialize the drilldown module
drilldown(Highcharts);

const sampleData = {
    categories: [
        {
            id: "category1",
            name: "Bottles",
            sply_sales: 100,
            actual_sales: 120,
            target_sales: 130,
            subCategories: [
                {
                    id: "subcategory1-1",
                    name: "Water Bottles",
                    sply_sales: 50,
                    actual_sales: 60,
                    target_sales: 70,
                    products: [
                        { name: "Bottle A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                        { name: "Bottle B", sply_sales: 15, actual_sales: 18, target_sales: 20 },
                        { name: "Bottle C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                    ],
                },
                {
                    id: "subcategory1-2",
                    name: "Juice Bottles",
                    sply_sales: 50,
                    actual_sales: 60,
                    target_sales: 70,
                    products: [
                        { name: "Bottle D", sply_sales: 25, actual_sales: 28, target_sales: 30 },
                        { name: "Bottle E", sply_sales: 20, actual_sales: 22, target_sales: 25 },
                    ],
                },
            ],
        },
        {
            id: "category2",
            name: "Containers",
            sply_sales: 90,
            actual_sales: 110,
            target_sales: 120,
            subCategories: [
                {
                    id: "subcategory2-1",
                    name: "Food Containers",
                    sply_sales: 45,
                    actual_sales: 55,
                    target_sales: 60,
                    products: [
                        { name: "Container A", sply_sales: 15, actual_sales: 20, target_sales: 25 },
                        { name: "Container B", sply_sales: 12, actual_sales: 15, target_sales: 18 },
                        { name: "Container C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                    ],
                },
                {
                    id: "subcategory2-2",
                    name: "Storage Containers",
                    sply_sales: 45,
                    actual_sales: 55,
                    target_sales: 60,
                    products: [
                        { name: "Container D", sply_sales: 25, actual_sales: 30, target_sales: 35 },
                        { name: "Container E", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                    ],
                },
            ],
        },
    ],
};

const ProductSalesDrilldownLollipopChart = ({ data = sampleData }) => {
    const chartRef = useRef(null);

    // Format data for chart series
    // Example: { name: "Bottles", sply: 100, actual: 120, target: 130, drilldown: "category1" }
    const formatData = (items) => {
        return items.map((item) => ({
            name: item.name,
            sply: item.sply_sales,
            actual: item.actual_sales,
            target: item.target_sales,
            drilldown: item.id,
        }));
    };

    // Calculate percentage change
    // Example: calculatePercentageChange(120, 100) returns 16.67 (%)
    const calculatePercentageChange = (actual, comparison) => {
        return ((actual - comparison) / actual) * 100;
    };

    // Create drilldown series for each level
    const createDrilldownSeries = (items, level) => {
        return items.map((item) => {
            const nextLevel = level === "categories" ? "subCategories" : "products";
            const hasNextLevel = item[nextLevel] && item[nextLevel].length > 0;
            const drilldownData = hasNextLevel ? formatData(item[nextLevel]) : [];

            return {
                id: item.id,
                data: drilldownData,
                drilldown: hasNextLevel,
            };
        });
    };

    // Create percentage change lollipop series
    // Example output: { type: 'lollipop', name: 'Percentage Change Actual vs. SPLY', data: [{name: 'Bottles', y: 16.67}] }
    const createPercentageChangeSeries = (items, comparisonType) => {
        return {
            type: "lollipop",
            name: `Percentage Change Actual vs. ${comparisonType}`,
            data: items.map((item) => {
                const percentageChange = calculatePercentageChange(item.actual_sales, item[`${comparisonType.toLowerCase()}_sales`]);
                return {
                    name: item.name,
                    y: percentageChange,
                    color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                };
            }),
            yAxis: 1,
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
            },
            marker: {
                enabled: true,
                lineColor: null,
            },
            dataLabels: {
                enabled: true,
                format: "{point.y:.2f}%",
            },
        };
    };

    // Create drilldown series for all levels
    const drilldownSeries = [...createDrilldownSeries(data.categories, "categories"), ...data.categories.flatMap((category) => createDrilldownSeries(category.subCategories, "subCategories"))];

    const options = {
        chart: {
            type: "bar",
            height: 530,
            events: {
                drilldown: function (e) {
                    if (!e.seriesOptions) {
                        const chart = this;
                        const series = chart.userOptions.drilldown.series.find((s) => s.id === e.point.drilldown);

                        const splyData = series.data.map((item) => ({ name: item.name, y: item.sply, drilldown: item.drilldown }));
                        const actualData = series.data.map((item) => ({ name: item.name, y: item.actual, drilldown: item.drilldown }));
                        const targetData = series.data.map((item) => ({ name: item.name, y: item.target, drilldown: item.drilldown }));

                        // Add SPLY, Actual, and Target sales series
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            name: "SPLY Sales",
                            data: splyData,
                            color: Highcharts.getOptions().colors[0],
                        });
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            name: "Actual Sales",
                            data: actualData,
                            color: Highcharts.getOptions().colors[1],
                        });
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            name: "Target Sales",
                            data: targetData,
                            color: Highcharts.getOptions().colors[2],
                        });

                        // Add percentage change lollipop series for SPLY comparison
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            type: "lollipop",
                            name: "Percentage Change Actual vs. SPLY",
                            data: series.data.map((item) => {
                                const percentageChange = calculatePercentageChange(item.actual, item.sply);
                                return {
                                    name: item.name,
                                    y: percentageChange,
                                    color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                };
                            }),
                            yAxis: 1,
                            tooltip: {
                                pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
                            },
                            marker: {
                                enabled: true,
                                lineColor: null,
                            },
                            dataLabels: {
                                enabled: true,
                                format: "{point.y:.2f}%",
                            },
                        });

                        // Add percentage change lollipop series for Target comparison
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            type: "lollipop",
                            name: "Percentage Change Actual vs. Target",
                            data: series.data.map((item) => {
                                const percentageChange = calculatePercentageChange(item.actual, item.target);
                                return {
                                    name: item.name,
                                    y: percentageChange,
                                    color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                };
                            }),
                            yAxis: 1,
                            tooltip: {
                                pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
                            },
                            marker: {
                                enabled: true,
                                lineColor: null,
                            },
                            dataLabels: {
                                enabled: true,
                                format: "{point.y:.2f}%",
                            },
                        });

                        chart.applyDrilldown();
                    }
                },
            },
        },
        title: { text: "Sales Performance" },
        xAxis: { type: "category" },
        yAxis: [
            {
                title: { text: "Sales" },
                width: "60%",
                lineWidth: 2,
                offset: 0,
            },
            {
                title: { text: "Percentage Change" },
                left: "65%",
                width: "30%",
                offset: 0,
                lineWidth: 2,
                max: 100,
                min: -100,
            },
        ],
        legend: { enabled: true },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y}",
                },
            },
        },
        credits: {
            enabled: false,
        },
        // Main chart series
        series: [
            {
                name: "SPLY Sales",
                data: data.categories.map((category) => ({
                    name: category.name,
                    y: category.sply_sales,
                    drilldown: category.id,
                    color: Highcharts.getOptions().colors[0],
                })),
            },
            {
                name: "Actual Sales",
                data: data.categories.map((category) => ({
                    name: category.name,
                    y: category.actual_sales,
                    drilldown: category.id,
                    color: Highcharts.getOptions().colors[1],
                })),
            },
            {
                name: "Target Sales",
                data: data.categories.map((category) => ({
                    name: category.name,
                    y: category.target_sales,
                    drilldown: category.id,
                    color: Highcharts.getOptions().colors[2],
                })),
            },
            createPercentageChangeSeries(data.categories, "SPLY"),
            createPercentageChangeSeries(data.categories, "Target"),
        ],
        drilldown: {
            allowPointDrilldown: false,
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            activeDataLabelStyle: {
                color: "#FFFFFF",
                textDecoration: "none",
                textOutline: "1px #000000",
            },
            series: drilldownSeries,
        },
    };

    return (
        <div>
            <div style={{ height: "60px" }}></div>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} immutable={true} />
        </div>
    );
};
export default ProductSalesDrilldownLollipopChart;
