import React, { createContext, useState, useMemo } from "react";
import moment from "moment";

const DashboardFilterContext = createContext();

const DashboardFilterProvider = ({ children }) => {
    //get distribution channel

    const [unMemorisedfilters, setFilters] = useState({
        // startDate: moment().startOf("year").add(6, "months").startOf("month").format("YYYY-MM-DD"),
        startDate: moment().subtract(6, "months").startOf("month").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // measure: "sales_value",
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    const resetFilters = () => {
        setFilters({
            // startDate: moment().startOf("year").add(6, "months").startOf("month").format("YYYY-MM-DD"),
            startDate: moment().subtract(6, "months").startOf("month").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            agents: [],
            sales_associates: [],
            regions: [],
            channels: [],
            // measure: "sales_value",
            productCategories: [],
            productSubCategories: [],
            products: [],
        });
    };

    // Memoize the filters
    const filters = useMemo(() => unMemorisedfilters, [unMemorisedfilters]);

    console.log("🚀global sell dashboard ~ filters ~ filters: ", filters);

    return <DashboardFilterContext.Provider value={{ filters, setFilters, resetFilters }}>{children}</DashboardFilterContext.Provider>;
};

export { DashboardFilterContext, DashboardFilterProvider };
