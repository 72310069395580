import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Tooltip } from "@mui/material";
//icon imports
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TimelineIcon from "@mui/icons-material/Timeline";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import PublicIcon from "@mui/icons-material/Public";
import CategoryIcon from "@mui/icons-material/Category";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EuroIcon from "@mui/icons-material/Euro";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import StoreIcon from "@mui/icons-material/Store";
import OrdersIcon from "@mui/icons-material/ShoppingCart";
import SalesIcon from "@mui/icons-material/AttachMoney";
import numeral from "numeral";

//
// import SalesAssociatesOrdersPieChart from "./SalesAssociatesOrdersPieChart";
import SalesAssociatesOrdersBarChart from "./SalesAssociatesOrdersBarChart";

const iconMap = {
    ShoppingCartIcon: ShoppingCartIcon,
    MonetizationOnIcon: MonetizationOnIcon,
    TrendingUpIcon: TrendingUpIcon,
    TimelineIcon: TimelineIcon,
    MultilineChartIcon: MultilineChartIcon,
    PublicIcon: PublicIcon,
    CategoryIcon: CategoryIcon,
    ShowChartIcon: ShowChartIcon,
    PieChartIcon: PieChartIcon,
    ListAltIcon: ListAltIcon,
    BarChartIcon: BarChartIcon,
    StarIcon: StarIcon,
    PersonIcon: PersonIcon,
    EmojiEventsIcon: EmojiEventsIcon,
    AssessmentIcon: AssessmentIcon,
    TrendingDownIcon: TrendingDownIcon,
    EqualizerIcon: EqualizerIcon,
    EuroIcon: EuroIcon,
    AttachMoneyIcon: AttachMoneyIcon,
    DonutSmallIcon: DonutSmallIcon,
    BusinessCenterIcon: BusinessCenterIcon,
    StoreIcon: StoreIcon,
};

const StatsCard = ({ data, dataFilters }) => {
    const [isSalesView, setIsSalesView] = useState(false);
    let IconComponent = iconMap[data?.icon] ?? TrendingUpIcon;

    const toggleView = () => {
        setIsSalesView(!isSalesView);
    };

    // const getChangeColor = (change) => {
    //     const changeNum = Number(change);
    //     if (changeNum === 0) {
    //         return "text-blue-500";
    //     } else if (changeNum < 0) {
    //         return "text-red-500";
    //     } else {
    //         return "text-green-500";
    //     }
    // };

    const getChangeColor = (change) => {
        const changeNum = Number(change);

        if (changeNum === 0) {
            return "#0000FF"; // Blue
        } else if (changeNum < 90) {
            return "#FF0000"; // Red
        } else if (changeNum > 100) {
            return "#008000"; // Green
        } else {
            return "#FFBF00"; // Amber
        }
    };

    // const formatNumber = (num) => {
    //     if (isNaN(num)) {
    //         return num;
    //     }
    //     // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //     return num;
    // };

    const formatNumber = (number) => {
        if (isNaN(number)) {
            return number;
        }
        return numeral(number).format("0.[00]a"); // e.g., 3.5k, 3.45m, 3.4b
    };

    const calculatePercentageChange = (current, sply) => {
        if (sply === 0) return 0; // Handle division by zero
        return (current / sply) * 100;
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div style={{ width: "100%", marginTop: "1rem" }}>
                    <span className="block text-500 font-medium mb-3">{data?.title}</span>
                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {isSalesView ? (
                            <>
                                <Tooltip title={`Current Period: ${formatNumber(data?.details_current.revenueGenerated)}`} arrow>
                                    <span className="text-900 font-medium text-xl">
                                        <small style={{ color: "grey" }}>Current Period</small> {formatNumber(data?.details_current.revenueGenerated)}
                                    </span>
                                </Tooltip>

                                <Tooltip title={`SPLY Revenue: ${formatNumber(data?.details_sply.revenueGenerated)}`} arrow>
                                    <span className="text-900 font-medium text-xl">
                                        <small style={{ color: "grey" }}>SPLY</small> {formatNumber(data?.details_sply.revenueGenerated)}
                                    </span>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title={data?.tooltip} arrow>
                                    <span className="text-900 font-medium text-xl">
                                        <small style={{ color: "grey" }}>Current Period</small> {formatNumber(data?.current_value)}
                                    </span>
                                </Tooltip>
                                <Tooltip title={data?.tooltip} arrow>
                                    <span className="text-900 font-medium text-xl">
                                        <small style={{ color: "grey" }}>SPLY</small> {formatNumber(data?.sply_value)}
                                    </span>
                                </Tooltip>
                            </>
                        )}
                    </div>
                </div>

                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                    {/* <i className="pi pi-shopping-cart text-blue-500 text-xl" /> */}
                    {/* <IconComponent style={{ color: data?.color }} /> */}
                    <Tooltip title={isSalesView ? "Switch to Orders View" : "Switch to Sales View"} arrow>
                        <div onClick={toggleView} style={{ cursor: "pointer", display: "inline-flex", alignItems: "center" }}>
                            {isSalesView ? <SalesIcon style={{ color: data?.color }} /> : <OrdersIcon style={{ color: data?.color }} />}
                        </div>
                    </Tooltip>
                </div>
            </div>
            {/* <span className={`${getChangeColor(data?.percentage_change)} font-medium`}></span> */}
            {/* {data?.percentage_change && <span style={{ color: getChangeColor(data?.percentage_change) }}>(Δ%) Percentage Change {Math.round(Number(data?.percentage_change))}%</span>} */}
            {isSalesView
                ? data?.details_current &&
                  data?.details_sply && (
                      <span style={{ color: getChangeColor(calculatePercentageChange(data?.details_current.revenueGenerated, data?.details_sply.revenueGenerated)) }}>
                          (Δ%) Percentage Change {Math.round(calculatePercentageChange(data?.details_current.revenueGenerated, data?.details_sply.revenueGenerated))}%
                      </span>
                  )
                : data?.percentage_change && <span style={{ color: getChangeColor(data?.percentage_change) }}>(Δ%) Percentage Change {Math.round(Number(data?.percentage_change))}%</span>}

            {data?.description && <span className="text-500"> {data?.description}</span>}
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <SalesAssociatesOrdersBarChart isSalesView={isSalesView} setIsSalesView={setIsSalesView} data={data?.sales_associate_contribution} dataFilters={dataFilters} />
            </div>
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-12 xl:col-6">
                    <SalesAssociatesOrdersPieChart data={data?.sales_associate_contribution_current} title="Current" />
                </div>
                <div className="col-12 xl:col-6">
                    <SalesAssociatesOrdersPieChart data={data?.sales_associate_contribution_sply} title="SPLY" />
                </div>
            </div> */}
        </div>
    );
};

export default StatsCard;
