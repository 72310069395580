// FileImportsPage.jsx
import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import FileUploadPage from "./file-upload/FileUploadPage";
import UploadedFilesPage from "./uploaded-files/UploadedFilesPage";

const FileImportsPage = ({ loggedInUserData }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Import">
                    <FileUploadPage loggedInUserData={loggedInUserData} />
                </TabPanel>
                <TabPanel header="Files">
                    <UploadedFilesPage loggedInUserData={loggedInUserData} />
                </TabPanel>
            </TabView>
        </div>
    );
};

export default FileImportsPage;
