import axiosAPI from "../axiosApi";

export async function getAllProductSubCategories(params = {}) {
    const response = await axiosAPI.get("product-subcategories", { params: params });
    return response;
}

export async function getProductSubCategorieById(id) {
    const response = await axiosAPI.get(`product-subcategories/` + id);
    return response;
}

export async function postProductSubCategorie(data) {
    const response = await axiosAPI.post(`product-subcategories`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateProductSubCategorie(id, data) {
    const response = await axiosAPI.post(`product-subcategories/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteProductSubCategorieById(id) {
    const response = await axiosAPI.delete(`product-subcategories/${id}`);
    return response;
}
