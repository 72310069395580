import React, { useState, useContext, useEffect, useRef } from "react";
import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import numeral from "numeral";

//
import CardsFilterForInactiveCustomersFormDialog from "./CardsFilterForInactiveCustomersFormDialog";
import { Button } from "primereact/button";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { Grid, Stack } from "@mui/material";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const getChartOptions = (data, dataFilters) => {
    console.log("🚀 ~ inactive customers  getChartOptions ~ data:", data);
    if (!data?.customers) {
        return null;
    }
    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

        // Extracting and formatting the filters
        const agents = formatArray(dataFilters?.agents, "name");
        const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const routes = formatArray(dataFilters?.routes, "name");
        const statuses = formatArray(dataFilters?.statuses, "label");
        const orderBy = dataFilters?.orderBy?.label;
        const dataLimit = dataFilters?.dataLimit?.label;
        const viewInterval = dataFilters?.viewInterval?.label;
        const productCategories = formatArray(dataFilters?.productCategories, "name");
        const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
        const products = formatArray(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "Filters: ";
        if (agents) sentence += `Distributors: ${agents}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        if (orderBy) sentence += `Order By: ${orderBy}. `;
        if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
        if (viewInterval) sentence += `View Interval: ${viewInterval}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        if (startDate) sentence += `Start Date: ${startDate}. `;
        if (endDate) sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    let chartTitle = formatFilters(dataFilters);

    const options = {
        chart: {
            type: "bar",
            height: 300, // Adjust height for a single chart
        },
        // title: { text: `<br/><br/> ${categoryName} Customers`, },
        // title: { text: `${data?.title} <br/> ${chartTitle}` },
        title: { text: `${data?.title}` },

        xAxis: {
            categories: ["Inactive Customers"], // Single data point (customers for this category)
            title: {
                text: null,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Number of Inactive Customers",
            },
        },
        series: [
            {
                name: "Current Inactive Total Customers",
                data: [data?.customers?.current],
                color: "#235789",
            },
            {
                name: "SPLY Inactive Total Customers",
                data: [data?.customers?.sply],
                color: "#F1D302",
            },
        ],
        credits: {
            enabled: false,
        },
    };
    return options;
};

const InactiveCustomerStatsCard = ({ cardData, handleSubmitForFilters, filtersFormInitialDataValues, setFiltersFormInitialDataValues }) => {
    const formatNumber = (number) => {
        return numeral(number).format("0.[00]a"); // e.g., 3.5k, 3.45m, 3.4b
    };

    const getChangeColor = (change) => {
        if (change > 100) {
            return "green";
        } else if (change < 90) {
            return "red";
        } else {
            return "orange";
        }
    };

    const getChangeColor2 = (change) => {
        if (change > 100) {
            return "red";
        } else if (change < 90) {
            return "green";
        } else {
            return "orange";
        }
    };

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const [visible, setVisible] = useState(false);

    // const calculatePercentageChange = (current, previous) => {
    //     return (((current - previous) / previous) * 100).toFixed(2);
    // };

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return (0).toFixed(2);
        }
        return ((current / previous) * 100).toFixed(2);
    };

    // Inactive 30 Days
    const sply30Days = cardData?.data?.inactive_30_days?.customers?.sply;
    const actual30Days = cardData?.data?.inactive_30_days?.customers?.current;
    const actualVsSplyChange30Days = calculatePercentageChange(actual30Days, sply30Days);

    // Inactive 30-60 Days
    const sply30to60Days = cardData?.data?.inactive_30_60_days?.customers?.sply;
    const actual30to60Days = cardData?.data?.inactive_30_60_days?.customers?.current;
    const actualVsSplyChange30to60Days = calculatePercentageChange(actual30to60Days, sply30to60Days);

    // Inactive 60+ Days
    const sply60PlusDays = cardData?.data?.inactive_60_plus_days?.customers?.sply;
    const actual60PlusDays = cardData?.data?.inactive_60_plus_days?.customers?.current;
    const actualVsSplyChange60PlusDays = calculatePercentageChange(actual60PlusDays, sply60PlusDays);

    //
    // Inactive 30 Days
    const chartOptions30Days = getChartOptions(cardData?.data?.inactive_30_days, cardData?.requestParams);

    // Inactive 30-60 Days
    const chartOptions30to60Days = getChartOptions(cardData?.data?.inactive_30_60_days, cardData?.requestParams);

    // Inactive 60+ Days
    const chartOptions60PlusDays = getChartOptions(cardData?.data?.inactive_60_plus_days, cardData?.requestParams);

    return (
        <Grid lg={9} container spacing={2} justifyContent="center" alignItems="center" style={{ padding: "10px" }}>
            {/* Filter Button */}
            <Grid item xs={12} md={12} lg={12} className="flex justify-content-between mb-3">
                <div></div>
                <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                    }}
                    onClick={() => setVisible(true)}
                >
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>

                    <CardsFilterForInactiveCustomersFormDialog
                        onSubmit={handleSubmitForFilters}
                        filtersFormInitialDataValues={filtersFormInitialDataValues}
                        setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                        showFiltersFormDialog={showFiltersFormDialog}
                        setShowFiltersFormDialog={setShowFiltersFormDialog}
                    />
                </div>
            </Grid>

            {/* below 30 days */}
            <Grid item xs={12} md={6} lg={3.5}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">{cardData?.data?.inactive_30_days?.title}</span>
                            <Tooltip title={cardData?.data?.inactive_30_days?.definition} arrow>
                                <div className={`text-900 font-medium text-xl`}>{formatNumber(actual30Days)}</div>
                            </Tooltip>
                        </div>
                        <div></div>
                    </div>
                    <div className="mb-1">
                        {actualVsSplyChange30Days && (
                            <div className={`font-medium mb-1 flex justify-content-between`}>
                                <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange30Days) }}>{actualVsSplyChange30Days}% of SPLY</span> <span style={{ fontSize: "10px" }}>SPLY: {formatNumber(sply30Days)}</span>
                            </div>
                        )}
                    </div>

                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions30Days} />
                    </div>
                </div>
            </Grid>

            {/* 30-60 days */}
            <Grid item xs={12} md={6} lg={3.5}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">{cardData?.data?.inactive_30_60_days?.title}</span>
                            <Tooltip title={cardData?.data?.inactive_30_60_days?.definition} arrow>
                                <div className={`text-900 font-medium text-xl`}>{formatNumber(actual30to60Days)}</div>
                            </Tooltip>
                        </div>
                        <div></div>
                    </div>
                    <div className="mb-1">
                        {actualVsSplyChange30to60Days && (
                            <div className={`font-medium mb-1 flex justify-content-between`}>
                                <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange30to60Days) }}>{actualVsSplyChange30to60Days}% of SPLY</span> <span style={{ fontSize: "10px" }}>SPLY: {formatNumber(sply30to60Days)}</span>
                            </div>
                        )}
                    </div>

                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions30to60Days} />
                    </div>
                </div>
            </Grid>

            {/* 60 and above days */}
            <Grid item xs={12} md={6} lg={3.5}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">{cardData?.data?.inactive_60_plus_days?.title}</span>
                            <Tooltip title={cardData?.data?.inactive_60_plus_days?.definition} arrow>
                                <div className={`text-900 font-medium text-xl`}>{formatNumber(actual60PlusDays)}</div>
                            </Tooltip>
                        </div>
                        <div></div>
                    </div>
                    <div className="mb-1">
                        {actualVsSplyChange60PlusDays && (
                            <div className={`font-medium mb-1 flex justify-content-between`}>
                                <span style={{ fontSize: "10px", color: getChangeColor(actualVsSplyChange60PlusDays) }}>{actualVsSplyChange60PlusDays}% of SPLY</span> <span style={{ fontSize: "10px" }}>SPLY: {formatNumber(sply60PlusDays)}</span>
                            </div>
                        )}
                    </div>

                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions60PlusDays} />
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default InactiveCustomerStatsCard;
