import React from "react";
import OrderCardsNewPage from "./OrderCardsNewPage";
import OrdersBellCurvesPage from "./OrdersBellCurvesPage";
function OrderCardsAndBellCurvePage() {
    return (
        <>
            <OrderCardsNewPage />
            <OrdersBellCurvesPage />
        </>
    );
}

export default OrderCardsAndBellCurvePage;
