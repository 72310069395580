import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

//
import { getSelloutAgentProductOnlyDrilldown, getSelloutRegionProductOnlyDrilldown, getCustomerRankingSales } from "../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import MaterialUiLoaderLottie from "../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import BrownFilesProcessingLottie from "../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialog from "../widgets/FiltersFormDialog";
import { Button } from "primereact/button";

import moment from "moment";
import { Tooltip } from "@mui/material";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

// Sample hierarchical data for agents and products
const sampleData = {
    agents: [
        {
            id: "agent1",
            name: "Agent A",
            sales: 90000,
            products: [
                { name: "Bottle A", sales: 30000 },
                { name: "Bottle B", sales: 25000 },
                { name: "Bottle C", sales: 15000 },
                { name: "Container A", sales: 20000 },
            ],
        },
        {
            id: "agent2",
            name: "Agent B",
            sales: 60000,
            products: [
                { name: "Bag A", sales: 20000 },
                { name: "Bag B", sales: 15000 },
                { name: "Cup A", sales: 10000 },
                { name: "Cup B", sales: 15000 },
            ],
        },
    ],
};

const getChartOptions = (sampleData, dataFilters) => {
    if (!sampleData?.agents || !Array.isArray(sampleData?.agents)) {
        return null;
    }

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const agentsData = data.agents.map((agent) => {
            drilldownSeries.push({
                id: agent.id,
                name: `${agent.name} Products`,
                data: agent.products.map((product) => [product.name, product.sales]),
            });
            return {
                name: agent.name,
                y: agent.sales,
                drilldown: agent.id,
            };
        });
        return { agentsData, drilldownSeries };
    };

    const { agentsData, drilldownSeries } = generateDrilldownData(sampleData);

    return {
        chart: {
            type: "bar",
        },
        title: {
            text: `Agents Performance by Sales from ${startDate} to ${endDate}`,
        },
        subtitle: {
            text: "Click the columns to view products.",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Total Sales (UGX)",
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.0f}",
                },
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f} UGX</b><br/>',
        },
        series: [
            {
                name: "Agents",
                colorByPoint: true,
                data: agentsData,
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            series: drilldownSeries,
        },
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
    };
};

const SelloutAgentOnlyProductDrilldownChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const SelloutAgentProductOnlyDrilldownQuery = useQuery({
        disable: false,
        queryKey: ["sellout-agent-product-only-drilldown", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSelloutAgentProductOnlyDrilldown({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SelloutAgentProductOnlyDrilldownQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SelloutAgentProductOnlyDrilldownQuery?.isError:", SelloutAgentProductOnlyDrilldownQuery?.error);
            SelloutAgentProductOnlyDrilldownQuery?.error?.response?.data?.message
                ? toast.error(SelloutAgentProductOnlyDrilldownQuery?.error?.response?.data?.message)
                : !SelloutAgentProductOnlyDrilldownQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SelloutAgentProductOnlyDrilldownQuery?.isError]);

    console.log("data for NiceTwo SelloutAgentProductOnlyDrilldownQuery?.data?.data is:", SelloutAgentProductOnlyDrilldownQuery?.data?.data);

    const chartOptionsData = getChartOptions(SelloutAgentProductOnlyDrilldownQuery?.data?.data?.data, SelloutAgentProductOnlyDrilldownQuery?.data?.data?.requestParams);

    return (
        <>
            {SelloutAgentProductOnlyDrilldownQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={ServerProcessingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : SelloutAgentProductOnlyDrilldownQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>

                    <HighchartsReact highcharts={Highcharts} options={chartOptionsData} />
                </>
            )}
        </>
    );
};

export default SelloutAgentOnlyProductDrilldownChart;
