import React, { useState, useContext, useEffect, useRef } from "react";
import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

import StatsCardTarget from "./StatsCardTarget";
import { Grid, Stack } from "@mui/material";

//
import SpotLandingCard from "./SpotLandingCard";
import RunRateCard from "./RunRateCard";

//
import RevenueTodayCard from "./RevenueTodayCard";
import RevenueWeekToDate from "./RevenueWeekToDate";
import RevenueMonthToDate from "./RevenueMonthToDate";
import RevenueYearToDate from "./RevenueYearToDate";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const SalesCardChart = ({ measure = "sales_value" }) => {
    const data = {
        revenue: {
            title: "Revenue Today",
            categories: ["21st", "22nd", "23rd"],
            chartData: [
                {
                    name: "SPLY",
                    data: [91300, 92300, 93300],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [92100, 93100, 94100],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [88500, 89500, 95000],
                    color: "#1e90ff",
                },
            ],
            tooltip: {
                definition: "Total income generated from sales.",
                formula: "Revenue = Actual Sales Amount",
            },
        },

        revenueWTD: {
            title: "Revenue WTD",
            categories: ["Week 28", "Week 29", "Week 30"],
            actual: 4640,
            sply: 4500,
            unit: "K/day",
            chartData: [
                {
                    name: "SPLY",
                    data: [88000, 89000, 90000],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [91000, 92000, 93000],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [92000, 93000, 94000],
                    color: "#1e90ff",
                },
            ],
            color: "blue",
            tooltip: {
                definition: "The average revenue earned per day.",
            },
        },
        revenueMTD: {
            title: "Revenue MTD",
            categories: ["May", "Jun", "Jul"],
            actual: 1690000,
            sply: 1600000,
            unit: "K",
            chartData: [
                {
                    name: "SPLY",
                    data: [970000, 950000, 940000],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [920000, 930000, 940000],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [910000, 920000, 930000],
                    color: "#1e90ff",
                },
            ],
            color: "blue",
            tooltip: {
                definition: "The total revenue projected based on current run rate.",
            },
        },
        revenueYTD: {
            title: "Revenue YTD",
            categories: ["2022", "2023", "2024"],
            actual: 0,
            sply: 0,
            unit: "K",
            chartData: [
                {
                    name: "SPLY",
                    data: [0, 0, 0],
                    color: "#d3d3d3",
                },
                {
                    name: "Actual",
                    data: [0, 0, 0],
                    color: "#000",
                },
                {
                    name: "Target",
                    data: [0, 0, 0],
                    color: "#1e90ff",
                },
            ],
            color: "blue",
            tooltip: {
                definition: "The total revenue projected based on current run rate.",
            },
        },
        dailyRunRate: {
            title: "BOP Run Rate Vs Daily Run Rate",
            categories: ["BOP Run Rate", "Daily Run Rate"],
            actual: 4440,
            target: 4500,
            unit: "Revenue",
            chartData: [
                // Daily Run Rate
                { y: 4440, color: 4440 > 4500 ? "#FF7F7F" : "#90EE90" }, // BOP Run Rate
                { y: 4500 },
            ], // Updated to an array of numbers
            color: "blue",
            tooltip: {
                definition: "The average revenue earned per day.",
            },
        },
        spotLanding: {
            title: "Spot Landing Vs Target",
            categories: ["Spot Landing", "Target"],
            actual: 4440,
            target: 4500,
            unit: "Revenue",
            chartData: [
                // Daily Run Rate
                { y: 4440, color: 4440 > 4500 ? "#FF7F7F" : "#90EE90" }, // BOP Run Rate
                { y: 4500 },
            ], // Updated to an array of numbers
            tooltip: {
                definition: "The average revenue earned per day.",
            },
        },
    };

    return (
        // <div style={{ padding: "10px" }}>
        <Grid style={{ padding: "10px" }} container spacing={2} justifyContent="center" alignItems="center">
            <RevenueTodayCard measure={measure} />

            <RevenueWeekToDate measure={measure} />

            <RevenueMonthToDate measure={measure} />

            <RevenueYearToDate measure={measure} />

            <Grid item xs={12} md={6} lg={4}>
                <StatsCardTarget
                    measure={measure}
                    // revenueData={{
                    //     actual: 290000000000, // in millions
                    //     target: 240000000000, // percentage of annual target
                    //     title: "Revenue from 1st Jan 2024 to 23rd May 2024", // percentage of annual target
                    // }}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <SpotLandingCard {...data.spotLanding} measure={measure} />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
                <RunRateCard {...data.dailyRunRate} measure={measure} />
            </Grid>
        </Grid>
        // </div>
    );
};

export default SalesCardChart;
