import axiosAPI from "../axiosApi";

// Fetch data for two routes
export async function getAllChannels(params = {}) {
    const response = await axiosAPI.get("channels", { params: params });
    return response;
}

// Fetch data for two routes
export async function getRegions(params = {}) {
    const response = await axiosAPI.get("regions", { params: params });
    return response;
}

// Fetch data for two routes
export async function getSalesAssociates(params = {}) {
    const response = await axiosAPI.get("sales-associates", { params: params });
    return response;
}

// Fetch data for two routes
export async function getAgents(params = {}) {
    const response = await axiosAPI.get("agents", { params: params });
    return response;
}

// Fetch data for two routes
export async function getProductCategories(params = {}) {
    const response = await axiosAPI.get("product-categories", { params: params });
    return response;
}

// Fetch data for two routes
export async function getProductSubcategories(params = {}) {
    const response = await axiosAPI.get("product-subcategories", { params: params });
    return response;
}

// Fetch data for two routes
export async function getProducts(params = {}) {
    const response = await axiosAPI.get("products", { params: params });
    return response;
}
