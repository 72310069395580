import axiosAPI from "../axiosApi";

export async function getAllCardStats(params = {}) {
    const response = await axiosAPI.get("cards", { params: params });
    return response;
}
//sellin endpoints for runrate, spotlanding, Revenuegauge
export async function getSellinSpotLanding(params = {}) {
    const response = await axiosAPI.get("sell-in-spot-landing", { params: params });
    return response;
}
export async function getSellinRunRate(params = {}) {
    const response = await axiosAPI.get("sell-in-bop-card", { params: params });
    return response;
}
export async function getSellinRevenueGauge(params = {}) {
    const response = await axiosAPI.get("sell-in-revenue-gauge", { params: params });
    return response;
}

export async function getSellinPeriodicCharts(params = {}) {
    const response = await axiosAPI.get("sellin-statistics", { params: params });
    return response;
}

export async function getSellinDailyCharts(params = {}) {
    const response = await axiosAPI.get("sellin-daily-statistics", { params: params });
    return response;
}

export async function getSellinWeeklyCharts(params = {}) {
    const response = await axiosAPI.get("sellin-weekly-statistics", { params: params });
    return response;
}

export async function getSellinMonthlyCharts(params = {}) {
    const response = await axiosAPI.get("sellin-monthly-statistics", { params: params });
    return response;
}

export async function getSellinYearlyCharts(params = {}) {
    const response = await axiosAPI.get("sellin-yearly-statistics", { params: params });
    return response;
}
