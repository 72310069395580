import React, { useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

const FilesUploadComponent = ({ onFilesUpload }) => {
    const toastRef = useRef(null);
    const fileUploadRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [selectedFileDataOption, setSelectedFileDataTypeOption] = useState(null);
    const [isOptionInvalid, setIsOptionInvalid] = useState(false);

    const options = [
        { label: "Choose Data Type", value: "" },
        { label: "Sell In", value: "sell_in" },
        { label: "Sell Out", value: "sell_out" },
    ];

    // Function to handle file upload
    const onUpload = (e) => {
        if (!selectedFileDataOption) {
            setIsOptionInvalid(true);
            toastRef.current.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please select an option (Sell In/Sell Out) before uploading.",
                life: 3000,
            });
            return;
        }

        const selectedFiles = e.files;
        const allFiles = [...uploadedFiles, ...selectedFiles];

        // Check if the total number of files exceeds the limit
        if (allFiles.length > 5) {
            toastRef.current.show({
                severity: "warn",
                summary: "Warning",
                detail: "You can only upload up to 5 files.",
                life: 3000,
            });

            e.options.clear();
            return;
        }

        // Check if all selected files are Excel files
        const isExcelFile = selectedFiles.every((file) => file.name.toLowerCase().endsWith(".xlsx"));

        if (!isExcelFile) {
            toastRef.current.show({
                severity: "warn",
                summary: "Warning",
                detail: "Only Excel files (.xlsx) are allowed.",
                life: 3000,
            });

            e.options.clear();
            return;
        }

        setIsOptionInvalid(false);
        setFilesToUpload(selectedFiles);
        setShowConfirmDialog(true); // Show confirmation dialog
    };

    const onConfirm = () => {
        let finalData = {
            data_type: selectedFileDataOption,
            files_to_upload: filesToUpload,
        };
        onFilesUpload(finalData); // Pass uploaded files to the parent component
        setUploadedFiles([...uploadedFiles, ...filesToUpload]);
        setFilesToUpload([]);
        setShowConfirmDialog(false);
        setSelectedFileDataTypeOption(null); // Clear the dropdown selection
        // use the clear method to reset it
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <Toast ref={toastRef} />

            <div className="p-field m-4" style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <label htmlFor="data_type">Data Type</label>
                <Dropdown
                    id="data_type"
                    value={selectedFileDataOption}
                    options={options}
                    onChange={(e) => {
                        setSelectedFileDataTypeOption(e.value);
                        setIsOptionInvalid(false);
                    }}
                    placeholder="Select an option"
                    className={classNames({ "p-invalid": isOptionInvalid })}
                    required
                />
                {isOptionInvalid && <small className="p-error">Data Type is required.</small>}
            </div>

            <div className="p-field m-4">
                <label htmlFor="support_document">Support Document</label>
                {/* //200mb max file size */}
                <FileUpload ref={fileUploadRef} name="support_document" customUpload multiple maxFileSize={200000000} accept=".xlsx" uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>} />
            </div>

            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                style={{ minWidth: "30vw" }}
                maximizable
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirm} />
                        <Button label="No" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to upload the selected files?
            </Dialog>
        </div>
    );
};

export default FilesUploadComponent;
