import axiosAPI from "../axiosApi";

// export async function getAllNiceTwoSellOutCardStarts(params = {}) {
//     const response = await axiosAPI.get("nice-two-sellout-card-stats", { params: params?.params, signal: params?.signal });
//     return response;
// }
// export async function getAllNiceTwoRegions(params = {}) {
//     const response = await axiosAPI.get("nice-two-regions", { params: params });
//     return response;
// }
// export async function getAllNiceTwoRoutes(params = {}) {
//     const response = await axiosAPI.get("nice-two-routes", { params: params });
//     return response;
// }

//=================== order cards ======================

export async function getSellInOrderCardsStats(params = {}) {
    const response = await axiosAPI.get("getSellInOrderCardsStats", { params: params?.params, signal: params?.signal });
    return response;
}

export async function getSellInOrderCardsOnlyStats(params = {}) {
    const response = await axiosAPI.get("getSellInOrderCardsOnlyStats", { params: params?.params, signal: params?.signal });
    return response;
}

export async function getSellInOrdersBellCurveStats(params = {}) {
    const response = await axiosAPI.get("getSellInOrdersBellCurveStats", { params: params?.params, signal: params?.signal });
    return response;
}
