import React, { useState } from "react";

import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import CoverageSellOutHeatMapChart from "./CoverageSellOutHeatMapChart";
import CallProductivitySellOutHeatMapChart from "./CallProductivitySellOutHeatMapChart";
import AverageCallProductivityRateSellOutHeatMapChart from "./AverageCallProductivityRateSellOutHeatMapChart";
import { Accordion, AccordionTab } from "primereact/accordion";

// //testing
// import HeatmapDrillDownTable from "./dummy/HeatmapDrillDownTable";

function CoverageHeatMapsPage() {
    const [selectedbar, setSelectedbar] = useState("coverage");

    const barOptions = [
        { label: "Coverage Heat Map", value: "coverage" },
        { label: "Call Productivity Heat Map", value: "callProductivity" },
        { label: "Average Call Productivity Rate Heat Map", value: "avearegeCallProductivityRate" },
    ];

    const handleBarChange = (e) => {
        setSelectedbar(e.value);
    };

    return (
        <div className="col-12 xl:col-12">
            {/* <Panel header="Coverage Heat Maps" style={{ marginBottom: "20px", padding: "0.5rem" }}> */}
            {/* <Accordion activeIndex={0} style={{ marginBottom: "20px" }}>
                <AccordionTab header="Coverage Heat Maps"> */}
            <div className="col-12">
                <Dropdown value={selectedbar} options={barOptions} onChange={handleBarChange} placeholder="Choose..." className="p-mb-4 mx-3 my-2" style={{ marginBottom: "20px" }} />

                {selectedbar === "coverage" && <CoverageSellOutHeatMapChart />}
                {selectedbar === "callProductivity" && <CallProductivitySellOutHeatMapChart />}
                {selectedbar === "avearegeCallProductivityRate" && <AverageCallProductivityRateSellOutHeatMapChart />}
            </div>
            {/* </AccordionTab>
            </Accordion> */}
            {/* </Panel> */}
        </div>
    );
}

export default CoverageHeatMapsPage;
