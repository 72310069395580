import React, { useState, useContext, useEffect, useRef } from "react";
import StatsCard from "./widgets/StatsCard";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
// Service Imports
import { getNewOrderStatisticsCards } from "../../../services/nice-two-endpoints/nice-two-db-comparison-endpoints";
import { ProgressBar } from "primereact/progressbar";

import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
import MaterialUiLoaderLottie from "../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import BrownFilesProcessingLottie from "../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

//
import FiltersForm from "../widgets/FiltersForm";
import CardsFiltersFormDialog from "../widgets/CardsFiltersFormDialog";

import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import moment from "moment";

//
import { Tooltip, IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

import { Accordion, AccordionTab } from "primereact/accordion";

function OrderCardsNewPage() {
    const op = useRef(null);
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ global filters:", filters);

    const componentRef = useRef();

    // // 4 cards per row
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: "Stats Cards",
    //     pageStyle: `
    //         @page { size: A4; margin: 1cm; }
    //         @media print {
    //             body { -webkit-print-color-adjust: exact; }
    //             div#print-content { height: 100%; }
    //             .header, .footer { background-color: transparent !important; }
    //             img { display: block; margin: 0 auto; }
    //             .col-12, .lg\\:col-6, .xl\\:col-3 { flex: 0 0 25% !important; max-width: 25% !important; }
    //         }
    //     `,
    // });

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            // { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
            // { id: 3, label: "Canceled", value: "CANCELED" },
        ],
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    console.log("🚀 ~ Cards ~ filtersFormInitialDataValues:", filtersFormInitialDataValues);

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);
    // FiltersForm diaglog
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    // Products
    const SellOutNewOrderStatisticsCardsQuery = useQuery({
        disable: false,
        queryKey: ["getNewOrderStatisticsCards", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: ({ signal }) => getNewOrderStatisticsCards({ params: { startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }, signal }),
    });

    useEffect(() => {
        if (SellOutNewOrderStatisticsCardsQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SellOutNewOrderStatisticsCardsQuery?.isError:", SellOutNewOrderStatisticsCardsQuery?.error);
            SellOutNewOrderStatisticsCardsQuery?.error?.response?.data?.message
                ? toast.error(SellOutNewOrderStatisticsCardsQuery?.error?.response?.data?.message)
                : !SellOutNewOrderStatisticsCardsQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SellOutNewOrderStatisticsCardsQuery?.isError]);

    console.log("data for NiceTwo SellOutNewOrderStatisticsCardsQuery?.data?.data is:", SellOutNewOrderStatisticsCardsQuery?.data?.data);

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ Cards handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.startDate ? moment(SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.endDate ? moment(SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.endDate).format("YYYY-MM-DD") : "N/S";

    const startDate = SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.startDate ? moment(SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.endDate ? moment(SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams?.endDate).format("MMMM Do, YYYY") : "N/S";

    // 3 cards per row
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Sell Out Orders Overall Statistics Cards from ${startDate} to ${endDate})`,
        pageStyle: `
            @page { size: A4; margin: 1cm; }
            @media print {
                body { -webkit-print-color-adjust: exact; }
                div#print-content { height: 100%; }
                .header, .footer { background-color: transparent !important; }
                img { display: block; margin: 0 auto; }
                .col-12, .lg\\:col-6, .xl\\:col-3 { flex: 0 0 33.33% !important; max-width: 33.33% !important; }
                .print-only { display: block !important; }
            }
        `,
    });

    //=============== handle displaying filters on Chart ======================
    const dataFilters = SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams;

    // Format the dates
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    // Function to ensure array and join names
    const formatArray = (array, key) => {
        return Array.isArray(array) && array.length > 0 ? array.map((item) => item[key]).join(", ") : "N/S";
    };

    //=============== handle displaying filters on Chart end ======================

    // construct chart filters cenetnce
    function formatFilters(dataFilters) {
        function formatFiltersSenteceArrays(arr, key) {
            if (!Array.isArray(arr) || arr.length === 0) return "";
            return arr.map((item) => item[key]).join(", ");
        }
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

        // Extracting and formatting the filters
        const regions = formatFiltersSenteceArrays(dataFilters?.regions, "name");
        const salesAssociates = formatFiltersSenteceArrays(dataFilters?.salesAssociates, "name");
        const agents = formatFiltersSenteceArrays(dataFilters?.agents, "name");
        const routes = formatFiltersSenteceArrays(dataFilters?.routes, "name");
        const statuses = formatFiltersSenteceArrays(dataFilters?.statuses, "label");
        // const orderBy = dataFilters?.orderBy?.label || "N/S";
        // const dataLimit = dataFilters?.dataLimit?.label || "N/S";
        const productCategories = formatFiltersSenteceArrays(dataFilters?.productCategories, "name");
        const productSubCategories = formatFiltersSenteceArrays(dataFilters?.productSubCategories, "name");
        const products = formatFiltersSenteceArrays(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "Filters: ";
        if (regions) sentence += `Regions: ${regions}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (agents) sentence += `Distributors: ${agents}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        // sentence += `Order By: ${orderBy}. `;
        // sentence += `Data Limit: ${dataLimit}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        sentence += `Start Date: ${startDate}. `;
        sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    let chartFiltersSentece = formatFilters(dataFilters);

    return (
        <>
            {SellOutNewOrderStatisticsCardsQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "100%" }}>
                            <Lottie animationData={RobotProcessorLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : SellOutNewOrderStatisticsCardsQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {/* <div>
                        <Button icon="pi pi-filter" onClick={(e) => op.current.toggle(e)} />
                    </div> */}
                    {/* <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    <FiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} /> */}
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div style={{ width: "100%" }}>
                        <Accordion>
                            <AccordionTab header="Selected Filters" closed>
                                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "0.5rem" }}>{chartFiltersSentece}</div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div
                        className="col-12"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            gap: "0.5rem",
                        }}
                    >
                        <div></div>
                        {/* <div>
                            <Tag value={`Sell Out Orders Overall Statistics from ${startDate} to ${endDate}`} severity="info" />
                        </div> */}
                        <div className="text-right">
                            {/* <Button icon="pi pi-filter" onClick={(e) => op.current.toggle(e)} />
                        <OverlayPanel ref={op}>
                            <FiltersForm />
                        </OverlayPanel> */}
                            {/* <Tooltip title="Print">
                                <Button icon="pi pi-print" onClick={handlePrint} style={{ marginRight: "10px" }} />
                               
                            </Tooltip> */}
                            <Tooltip title="Filter">
                                <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                            </Tooltip>
                            <CardsFiltersFormDialog
                                onSubmit={handleSubmitForFilters}
                                filtersFormInitialDataValues={filtersFormInitialDataValues}
                                setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                                showFiltersFormDialog={showFiltersFormDialog}
                                setShowFiltersFormDialog={setShowFiltersFormDialog}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }} ref={componentRef}>
                        <div className="print-only" style={{ width: "100%", textAlign: "center", marginBottom: "20px", display: "none" }}>
                            <img src="assets/layout/images/nice-removebg-preview.png" className="print-only" alt="Nice House of Plastics Logo" style={{ width: "100px", display: "none" }} />
                            {/* <h3 className="print-only" style={{ marginTop: "20px", color: "blue" }}>{`Sell Out Orders Statistics Cards (${startDate} to ${endDate})`}</h3> */}
                            <Tag className="print-only" style={{ marginTop: "10px", color: "white" }} value={`Sell Out Orders Overall Statistics Cards (${startDate} to ${endDate})`} severity="info" />
                        </div>

                        {SellOutNewOrderStatisticsCardsQuery?.data?.data?.data.map((data, index) => (
                            <div key={index} className="col-12 lg:col-6 xl:col-6">
                                <StatsCard data={data} dataFilters={SellOutNewOrderStatisticsCardsQuery?.data?.data?.requestParams} />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
}

export default OrderCardsNewPage;
