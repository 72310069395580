import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

//
import { getRoutePerformanceDeficitSurplus, getProductSalesDeficitSurplus, getAgentSalesDeficitSurplus, getSelloutAgentProductOnlyDrilldown, getSelloutRegionProductOnlyDrilldown, getCustomerRankingSales } from "../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

//lotties
import ComputerInFourCirclesLottie from "../../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import BrownFilesProcessingLottie from "../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialog from "../../widgets/FiltersFormDialog";
import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";

import { Accordion, AccordionTab } from "primereact/accordion";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

// Sample data for Route performance
// const chartData = {
//     routes: ["Route A", "Route B", "Route C"],
//     current: [80000, 60000, 70000],
//     sply: [75000, 65000, 68000],
//     surplusDeficit: [5000, -5000, 2000], // Positive for surplus, negative for deficit
// };

const getChartOptions = (chartData, dataFilters) => {
    if (!chartData || !Array.isArray(chartData?.routes) || !Array.isArray(chartData?.current)) {
        return null;
    }

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    return {
        chart: {
            type: "bar",
        },
        title: {
            text: `Route Performance (Current Vs SPLY) from ${startDate} to ${endDate}`,
        },
        xAxis: {
            categories: chartData?.routes,
            crosshair: true,
        },
        yAxis: {
            title: {
                text: "Sales (UGX)",
            },
        },
        legend: {
            shadow: false,
        },
        tooltip: {
            shared: true,
            valuePrefix: "UGX ",
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
            },
            series: {
                pointPadding: 0.1, // Adjust the space between points within each category
                groupPadding: 0, // Adjust the space between categories
                pointWidth: 10, // adjust bar width
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Current",
                color: "rgba(79,129,189,1)", // Different color for current
                data: chartData.current,
                pointPadding: 0.3,
                pointPlacement: -0.2,
            },
            {
                name: "SPLY",
                color: "rgba(128,0,128,1)", // Purple color in RGBA format // Different color for SPLY
                data: chartData.sply,
                pointPadding: 0.4,
                pointPlacement: -0.2,
            },
            {
                name: "Surplus",
                color: "#32CD32",
                data: chartData.surplusDeficit.map((value) => Math.max(0, value)), // Positive values for surplus
                pointPadding: 0.3,
                pointPlacement: 0.2,
            },
            {
                name: "Deficit",
                color: "#CD5C5C",
                data: chartData.surplusDeficit.map((value) => Math.min(0, value)), // Negative values for deficit
                pointPadding: 0.4,
                pointPlacement: 0.2,
            },
        ],
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
    };
};

const RoutePerformanceComparisonDeficitSurplusChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({ ...filtersFormInitialDataValues, startDate: filters?.startDate, endDate: filters?.endDate });
    }, [filters?.startDate, filters?.endDate]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const RoutePerformanceDeficitSurplusQuery = useQuery({
        disable: false,
        queryKey: ["route-performance-deficit-surplus", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getRoutePerformanceDeficitSurplus({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (RoutePerformanceDeficitSurplusQuery?.isError) {
            console.log("🚀 ~ useEffect ~ RoutePerformanceDeficitSurplusQuery?.isError:", RoutePerformanceDeficitSurplusQuery?.error);
            RoutePerformanceDeficitSurplusQuery?.error?.response?.data?.message
                ? toast.error(RoutePerformanceDeficitSurplusQuery?.error?.response?.data?.message)
                : !RoutePerformanceDeficitSurplusQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [RoutePerformanceDeficitSurplusQuery?.isError]);

    console.log("data for NiceTwo RoutePerformanceDeficitSurplusQuery?.data?.data is:", RoutePerformanceDeficitSurplusQuery?.data?.data);

    const chartOptionsData = getChartOptions(RoutePerformanceDeficitSurplusQuery?.data?.data?.data, RoutePerformanceDeficitSurplusQuery?.data?.data?.requestParams);

    //=============== handle displaying filters on Chart ======================
    const dataFilters = RoutePerformanceDeficitSurplusQuery?.data?.data?.requestParams;

    // Format the dates
    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    // Function to ensure array and join names
    const formatArray = (array, key) => {
        return Array.isArray(array) && array.length > 0 ? array.map((item) => item[key]).join(", ") : "N/S";
    };

    // Extracting and formatting the filters
    const agents = formatArray(dataFilters?.agents, "name");
    const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
    const regions = formatArray(dataFilters?.regions, "name");
    const routes = formatArray(dataFilters?.routes, "name");
    const statuses = formatArray(dataFilters?.statuses, "label");
    const orderBy = dataFilters?.orderBy?.label || "N/S";
    const dataLimit = dataFilters?.dataLimit?.label || "N/S";
    const productCategories = formatArray(dataFilters?.productCategories, "name");
    const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
    const products = formatArray(dataFilters?.products, "name");

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            {RoutePerformanceDeficitSurplusQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={ComputerInFourCirclesLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : RoutePerformanceDeficitSurplusQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>

                    <Accordion>
                        <AccordionTab header="Selected Filters">
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "0.5rem" }}>
                                <p>
                                    <strong>Start Date:</strong> {startDate}
                                </p>
                                <p>
                                    <strong>End Date:</strong> {endDate}
                                </p>
                                <p>
                                    <strong>Agents:</strong> {agents}
                                </p>
                                <p>
                                    <strong>Sales Associates:</strong> {salesAssociates}
                                </p>
                                <p>
                                    <strong>Regions:</strong> {regions}
                                </p>
                                <p>
                                    <strong>Routes:</strong> {routes}
                                </p>
                                <p>
                                    <strong>Statuses:</strong> {statuses}
                                </p>
                                <p>
                                    <strong>Order By:</strong> {orderBy}
                                </p>
                                <p>
                                    <strong>Data Limit:</strong> {dataLimit}
                                </p>
                                <p>
                                    <strong>Product Categories:</strong> {productCategories}
                                </p>
                                <p>
                                    <strong>Product Sub Categories:</strong> {productSubCategories}
                                </p>
                                <p>
                                    <strong>Products:</strong> {products}
                                </p>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    <HighchartsReact highcharts={Highcharts} options={chartOptionsData} />
                </>
            )}
        </>
    );
};

export default RoutePerformanceComparisonDeficitSurplusChart;
