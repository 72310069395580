import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

import React, { useState, useRef, useContext, useEffect } from "react";
import { DashboardFilterContext } from "../../../../context/DashboardFilterContext";
import { getRegionPerformanceDrillDown } from "../../../../services/dashboard/charts";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

//
import { Button } from "primereact/button";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import DashboardLoadingLottie from "../../../../assets/lotties/nice-hop/dashboard-loading-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";
//
import FiltersFormDialog from "../../widgets/FiltersFormDialog";

import noData from "highcharts/modules/no-data-to-display";
// Initialize the required Highcharts modules
noData(Highcharts);
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

const CustomerSalesChart = () => {
    const chartRef = useRef(null);
    const chartDivRef = useRef(null);
    const { filters } = useContext(DashboardFilterContext);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        start_date: filters?.startDate,
        end_date: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        // statuses: [],
        measure: null,
        order: null,
        dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            start_date: filters?.startDate,
            end_date: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            measure: filters?.measure,
        });
    }, [filters]);

    // FiltersForm diaglog
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const RegionPerformanceDrillDownChartQuery = useQuery({
        queryKey: ["region-performance-drill-down", filters?.startDate, filters?.endDate, filters?.measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getRegionPerformanceDrillDown({ start_date: filters?.startDate, end_date: filters?.endDate, measure: filters?.measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (RegionPerformanceDrillDownChartQuery?.isError) {
            console.log("🚀 ~ useEffect ~ RegionPerformanceDrillDownChartQuery?.isError:", RegionPerformanceDrillDownChartQuery?.error);
            RegionPerformanceDrillDownChartQuery?.error?.response?.data?.message
                ? toast.error(RegionPerformanceDrillDownChartQuery?.error?.response?.data?.message)
                : !RegionPerformanceDrillDownChartQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [RegionPerformanceDrillDownChartQuery?.isError]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chartDivRef.current && !chartDivRef.current.contains(event.target) && chartRef.current && chartRef.current.chart) {
                console.log("Clicked outside the component");
                const chart = chartRef.current.chart;
                chart.drillUp();
                chart.drillUp();
                // setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (RegionPerformanceDrillDownChartQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={DashboardLoadingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (RegionPerformanceDrillDownChartQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    const regionDrillDownData = RegionPerformanceDrillDownChartQuery?.data?.data?.data;

    if (!regionDrillDownData) {
        return <div>No data available</div>;
    }

    // Sample hierarchical data
    const sampleData = {
        categories: [
            {
                id: "category1",
                name: "Bottles",
                sales: 50000,
                subCategories: [
                    {
                        id: "subcategory1-1",
                        name: "Water Bottles",
                        sales: 30000,
                        products: [
                            { name: "Bottle A", sales: 15000 },
                            { name: "Bottle B", sales: 10000 },
                            { name: "Bottle C", sales: 5000 },
                        ],
                    },
                    {
                        id: "subcategory1-2",
                        name: "Juice Bottles",
                        sales: 20000,
                        products: [
                            { name: "Bottle D", sales: 12000 },
                            { name: "Bottle E", sales: 8000 },
                        ],
                    },
                ],
            },
            {
                id: "category2",
                name: "Containers",
                sales: 40000,
                subCategories: [
                    {
                        id: "subcategory2-1",
                        name: "Food Containers",
                        sales: 25000,
                        products: [
                            { name: "Container A", sales: 10000 },
                            { name: "Container B", sales: 8000 },
                            { name: "Container C", sales: 7000 },
                        ],
                    },
                    {
                        id: "subcategory2-2",
                        name: "Storage Containers",
                        sales: 15000,
                        products: [
                            { name: "Container D", sales: 9000 },
                            { name: "Container E", sales: 6000 },
                        ],
                    },
                ],
            },
        ],
    };

    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const categoriesData = data.regions.map((category) => {
            drilldownSeries.push({
                id: category.id,
                name: category.name.replace(/\b(\w)(\w*)/g, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()),
                data: category.salesAssociates.map((subCategory) => ({
                    name: subCategory.name.replace(/\b(\w)(\w*)/g, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()),
                    y: subCategory.sales,
                    drilldown: subCategory.id,
                })),
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "11px", // Set the font size of the data labels
                    },
                },
            });
            category.salesAssociates.forEach((subCategory) => {
                drilldownSeries.push({
                    id: subCategory.id,
                    name: subCategory.name.replace(/\b(\w)(\w*)/g, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()),
                    data: subCategory.agents.map((product) => [product.name, product.sales]),
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: "11px", // Set the font size of the data labels
                        },
                    },
                });
            });
            return {
                name: category.name.replace(/\b(\w)(\w*)/g, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()),
                y: category.sales,
                drilldown: category.id,
            };
        });
        return { categoriesData, drilldownSeries };
    };

    const { categoriesData, drilldownSeries } = generateDrilldownData(regionDrillDownData);
    // console.log("zindazed", drilldownSeries);
    let drilldownStack = [];
    let drilldownheight = [];
    let productTitle = "all Products";
    if (filtersFormInitialDataValues.products.length) {
        let products = "";
        filtersFormInitialDataValues.products.forEach((element, index) => {
            if (index === 0) {
                products += `${element.name}`;
            } else if (index === filtersFormInitialDataValues.products.length - 1) {
                products += ` and ${element.name}`;
            } else {
                products += `, ${element.name}`;
            }
        });
        productTitle = products;
        // console.log("ObjectValues products", filtersFormInitialDataValues.products);
    } else if (filtersFormInitialDataValues.productSubCategories.length) {
        let products = "";
        filtersFormInitialDataValues.productSubCategories.forEach((element, index) => {
            if (index === 0) {
                products += `${element.name}`;
            } else if (index === filtersFormInitialDataValues.productSubCategories.length - 1) {
                products += ` and ${element.name}`;
            } else {
                products += `, ${element.name}`;
            }
        });
        productTitle = products;
    } else if (filtersFormInitialDataValues.productCategories.length) {
        let products = "";
        filtersFormInitialDataValues.productCategories.forEach((element, index) => {
            if (index === 0) {
                products += `${element.name}`;
            } else if (index === filtersFormInitialDataValues.productCategories.length - 1) {
                products += ` and ${element.name}`;
            } else {
                products += `, ${element.name}`;
            }
        });
        productTitle = products;
    }

    let filterTitle = `Regional Performance`;
    if (filtersFormInitialDataValues.agents.length) {
        let agents = "";
        let agentsNum = filtersFormInitialDataValues.agents.length;
        filtersFormInitialDataValues.agents.forEach((element, index) => {
            if (index === 0) {
                agents += `${element.name}`;
            } else if (index === agentsNum - 1) {
                agents += ` and ${element.name}`;
            } else {
                agents += `, ${element.name}`;
            }
        });
        let salesAssociates = "";
        const salesAssociateNum = filtersFormInitialDataValues.sales_associates.length;
        filtersFormInitialDataValues.sales_associates.forEach((element, index) => {
            if (index === 0) {
                salesAssociates += `${element.name}`;
            } else if (index === salesAssociateNum - 1) {
                salesAssociates += ` and ${element.name}`;
            } else {
                salesAssociates += `, ${element.name}`;
            }
        });
        let regions = "";
        const regionsNum = filtersFormInitialDataValues.regions.length;
        filtersFormInitialDataValues.regions.forEach((element, index) => {
            if (index === 0) {
                regions += `${element.name}`;
            } else if (index === regionsNum - 1) {
                regions += ` and ${element.name}`;
            } else {
                regions += `, ${element.name}`;
            }
        });
        let channels = "";
        let channelsNum = filtersFormInitialDataValues.channels.length;
        filtersFormInitialDataValues.channels.forEach((element, index) => {
            if (index === 0) {
                channels += `${element.name}`;
            } else if (index === channelsNum - 1) {
                channels += ` and ${element.name}`;
            } else {
                channels += `, ${element.name}`;
            }
        });
        filterTitle = `Performance of ${agentsNum > 1 ? "Agents" : "Agent"}: ${agents} under ${salesAssociateNum > 1 ? "Sales Associates" : "Sales Associate"}: ${salesAssociates} within ${regionsNum > 1 ? "Regions" : "Region"}: ${regions} in ${
            channelsNum > 1 ? "Channels" : "Channel"
        }: ${channels} on ${productTitle}`;
        console.log("ObjectValues agents", filtersFormInitialDataValues.agents);
    } else if (filtersFormInitialDataValues.sales_associates.length) {
        let salesAssociates = "";
        const salesAssociateNum = filtersFormInitialDataValues.sales_associates.length;
        filtersFormInitialDataValues.sales_associates.forEach((element, index) => {
            if (index === 0) {
                salesAssociates += `${element.name}`;
            } else if (index === salesAssociateNum - 1) {
                salesAssociates += ` and ${element.name}`;
            } else {
                salesAssociates += `, ${element.name}`;
            }
        });
        let regions = "";
        const regionsNum = filtersFormInitialDataValues.regions.length;
        filtersFormInitialDataValues.regions.forEach((element, index) => {
            if (index === 0) {
                regions += `${element.name}`;
            } else if (index === regionsNum - 1) {
                regions += ` and ${element.name}`;
            } else {
                regions += `, ${element.name}`;
            }
        });
        let channels = "";
        let channelsNum = filtersFormInitialDataValues.channels.length;
        filtersFormInitialDataValues.channels.forEach((element, index) => {
            if (index === 0) {
                channels += `${element.name}`;
            } else if (index === channelsNum - 1) {
                channels += ` and ${element.name}`;
            } else {
                channels += `, ${element.name}`;
            }
        });
        filterTitle = `Performance of ${salesAssociateNum > 1 ? "Sales Associates" : "Sales Associate"}: ${salesAssociates} within ${regionsNum > 1 ? "Regions" : "Region"}: ${regions} in ${channelsNum > 1 ? "Channels" : "Channel"}: ${channels} on ${productTitle}`;
        console.log("ObjectValues sales_associates", filtersFormInitialDataValues.sales_associates);
    } else if (filtersFormInitialDataValues.regions.length) {
        let regions = "";
        const regionsNum = filtersFormInitialDataValues.regions.length;
        filtersFormInitialDataValues.regions.forEach((element, index) => {
            if (index === 0) {
                regions += `${element.name}`;
            } else if (index === regionsNum - 1) {
                regions += ` and ${element.name}`;
            } else {
                regions += `, ${element.name}`;
            }
        });
        let channels = "";
        let channelsNum = filtersFormInitialDataValues.channels.length;
        filtersFormInitialDataValues.channels.forEach((element, index) => {
            if (index === 0) {
                channels += `${element.name}`;
            } else if (index === channelsNum - 1) {
                channels += ` and ${element.name}`;
            } else {
                channels += `, ${element.name}`;
            }
        });
        filterTitle = `Performance of ${regionsNum > 1 ? "Regions" : "Region"}: ${regions} in ${channelsNum > 1 ? "Channels" : "Channel"}: ${channels} on ${productTitle}`;
        console.log("ObjectValues regions", filtersFormInitialDataValues.regions);
    } else if (filtersFormInitialDataValues.channels.length) {
        let channels = "";
        let channelsNum = filtersFormInitialDataValues.channels.length;
        filtersFormInitialDataValues.channels.forEach((element, index) => {
            if (index === 0) {
                channels += `${element.name}`;
            } else if (index === channelsNum - 1) {
                channels += ` and ${element.name}`;
            } else {
                channels += `, ${element.name}`;
            }
        });
        filterTitle = `Performance of all Regions in ${channelsNum > 1 ? "Channels" : "Channel"}: ${channels} on ${productTitle}`;
        console.log("ObjectValues channels", filtersFormInitialDataValues.channels);
    }
    const options = {
        chart: {
            type: "bar",
            height: Math.max(530, 530 * (categoriesData.length / 6)),
            events: {
                drilldown: function (e) {
                    // Change chart height based on the drilldown level
                    let newHeight;
                    if (e.point.drilldown) {
                        const datalength = drilldownSeries.filter((map) => map.name === e.point.name && map.id === e.point.drilldown)[0].data.length;
                        // const datalength = 6;
                        // console.log("region_datalength", datalengthw);
                        // console.log("zindazed2", e.point.name);
                        if (e.point.series.name === "Region") {
                            newHeight = Math.max(530, 530 * (datalength / 6)); // Example height for the next drilldown level
                        } else {
                            newHeight = Math.max(530, 530 * (datalength / 6)); // Example height for further drilldown levels
                        }
                    } else {
                        newHeight = Math.max(530, 530 * (categoriesData.length / 6)); // Default height
                    }
                    drilldownheight.push(newHeight);
                    drilldownStack.push(e.point.name);
                    this.update({
                        chart: {
                            height: newHeight,
                        },
                    });
                    if (e.point.drilldown.includes("subcategory")) {
                        this.setSubtitle({ text: `${e.point.name} Distributors: Drill up using the navigation on the right` });
                    } else if (e.point.drilldown.includes("category")) {
                        this.setSubtitle({ text: `${e.point.name} Sales Associates: Drill Down to Distributors` });
                    }
                },
                drillup: function () {
                    // Remove the last drilldown level from the stack
                    drilldownStack.pop();
                    drilldownheight.pop();

                    // Check the size of the stack to determine how many levels were drilled up
                    const stackSize = drilldownStack.length;
                    let theight = Math.max(530, 530 * (categoriesData.length / 6));

                    if (stackSize === 0) {
                        console.log("User went back to the top level");
                        this.setSubtitle({ text: `Regions: Drill Down to Sales Associates` });
                    } else if (stackSize === 1) {
                        console.log("User went back one step");
                        this.setSubtitle({ text: `${drilldownStack[stackSize - 1]} Sales Associates: Drill Down to Distributors` });
                        theight = drilldownheight[stackSize - 1];
                    } else if (stackSize === 2) {
                        console.log("User went back two steps");
                    } else {
                        console.log("User went back multiple steps");
                    }

                    // Reset height when drilling up
                    this.update(
                        {
                            chart: {
                                height: theight,
                            },
                        },
                        true,
                        false,
                        false
                    );
                },
            },
        },
        title: {
            text: `${filterTitle} from ${RegionPerformanceDrillDownChartQuery?.data?.data?.heading}`,
        },
        subtitle: {
            text: "Regions: Drill Down to Sales Associates",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: `${RegionPerformanceDrillDownChartQuery?.data?.data?.measureName} ${RegionPerformanceDrillDownChartQuery?.data?.data?.measureName === "Volume" ? "" : "(UGX)"}`,
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.0f}",
                },
            },
        },
        // tooltip: {
        //     headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        //     pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>${RegionPerformanceDrillDownChartQuery?.data?.data?.measureName === "Volume" ? "" : "UGX "} {point.y:,.0f}</b><br/>`,
        // },
        tooltip: {
            enabled: false,
        },
        series: [
            {
                name: "Region",
                // colorByPoint: true,
                data: categoriesData,
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "11px", // Set the font size of the data labels
                    },
                },
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            series: drilldownSeries,
        },
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "printChart", "downloadXLS"],
                },
            },
        },
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    return (
        <>
            <div className="col-12 text-right">
                {/* <Button icon="pi pi-filter" onClick={(e) => op.current.toggle(e)} />
                        <OverlayPanel ref={op}>
                            <FiltersForm />
                        </OverlayPanel> */}

                <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                <FiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} />
            </div>
            <div style={{ height: "530px", overflowY: "auto" }} ref={chartDivRef}>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            </div>
        </>
    );
};

export default CustomerSalesChart;
