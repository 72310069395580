import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import AgentsPerformanceDrillWithLollipopCombinedChart from "./AgentsPerformanceDrillWithLollipopCombinedChart";
import ProductsPerformanceDrillWithLollipopCombinedChart from "./ProductsPerformanceDrillWithLollipopCombinedChart";
import RegionsPerformanceDrillWithLollipopCombinedChart from "./RegionsPerformanceDrillWithLollipopCombinedChart";
import ActualTargetChart from "./ActualPerformanceWithLollipopCombinedChart";
import ChannelDrillTargetChart from "./ChannelsPerformanceDrillWithLollipopCombinedChart";
// import MomTrendChart from "../Mom-trend-chart/MomTrendChart";

function ToggleComparisonActualPerfomanceDrillBarCharts({ measure = "sales_value" }) {
    const [selectedChart, setSelectedChart] = useState("period");

    const chartOptions = [
        { label: "Period Performance", value: "period" },
        { label: "Channels Performance", value: "channels" },
        { label: "Regional Performance", value: "regions" },
        { label: "Business Partners Performance", value: "agents" },
        { label: "Product Category Perfomance", value: "products" },
    ];
    return (
        <div className="col-12">
            <Dropdown value={selectedChart} options={chartOptions} onChange={(e) => setSelectedChart(e.value)} placeholder="Select a chart" className="p-mb-4" />

            {selectedChart === "period" && <ActualTargetChart measure={measure} />}
            {selectedChart === "channels" && <ChannelDrillTargetChart measure={measure} />}
            {selectedChart === "agents" && <AgentsPerformanceDrillWithLollipopCombinedChart measure={measure} />}
            {selectedChart === "products" && <ProductsPerformanceDrillWithLollipopCombinedChart measure={measure} />}
            {selectedChart === "regions" && <RegionsPerformanceDrillWithLollipopCombinedChart measure={measure} />}
        </div>
    );
}

export default ToggleComparisonActualPerfomanceDrillBarCharts;
