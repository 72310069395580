import React, { useState, useContext, useEffect, useRef } from "react";
import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import numeral from "numeral";

//

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OverlayPanel } from "primereact/overlaypanel";

import { getNewCustomerStatistics } from "../../../../services/nice-two-endpoints/nice-two-coverage-cards-service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";

import { Grid, Stack } from "@mui/material";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import RobotProcessorLottie from "../../../../assets/lotties/nice-hop/robot-processor-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

//
import CoverageStatsCard from "./widgets/CoverageStatsCard";
//
import useHandleQueryError from "../../../../hooks/useHandleQueryError";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const NewCustomerStatisticsCard = ({ measure = "sales_value" }) => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        viewInterval: { id: 1, label: "Default", value: "default" },
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
        });
    }, [filters]);

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const NewCustomerStatisticsDataQuery = useQuery({
        disable: false,
        queryKey: ["getNewCustomerStatistics", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getNewCustomerStatistics({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(NewCustomerStatisticsDataQuery?.isError, NewCustomerStatisticsDataQuery?.error);

    console.log("data for NiceTwo NewCustomerStatisticsDataQuery?.data?.data is:", NewCustomerStatisticsDataQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, NewCustomerStatisticsDataQuery?.data?.data);

    const customersStatisticsActualData = NewCustomerStatisticsDataQuery?.data?.data?.data;
    const selctedFilters = NewCustomerStatisticsDataQuery?.data?.data?.requestParams;

    //========================

    return NewCustomerStatisticsDataQuery?.isLoading ? (
        <Grid item xs={12} md={6} lg={3}>
            <Lottie animationData={RobotProcessorLottie} loop={true} autoplay={true} />
            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
        </Grid>
    ) : NewCustomerStatisticsDataQuery?.isError ? (
        <Grid item xs={12} md={6} lg={3}>
            <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
        </Grid>
    ) : (
        <>
            <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1}>
                    <CoverageStatsCard cardData={customersStatisticsActualData} selctedFilters={selctedFilters} handleSubmitForFilters={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} />
                </Stack>
            </Grid>
        </>
    );
};

export default NewCustomerStatisticsCard;
