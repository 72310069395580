import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";

// Initialize the drilldown module
drilldown(Highcharts);

const sampleData = {
    categories: [
        {
            id: "category1",
            name: "Bottles",
            sply_sales: 100,
            actual_sales: 120,
            target_sales: 130,
            subCategories: [
                {
                    id: "subcategory1-1",
                    name: "Water Bottles",
                    sply_sales: 50,
                    actual_sales: 60,
                    target_sales: 70,
                    products: [
                        {
                            id: "1674111328076558",
                            name: "Basin Round(Virgin) 15Ltr MULTPLE-COLORS",
                            sply_sales: 14466600,
                            actual_sales: 53594100,
                            target_sales: 42373999.974999994,
                        },
                        {
                            id: "1674111337710014",
                            name: "Basin Round(Virgin) 15Ltr ORANGE",
                            sply_sales: 8126300,
                            actual_sales: 21215800,
                            target_sales: 42373999.974999994,
                        },
                    ],
                },
            ],
        },
    ],
};

// const sampleData = {
//     categories: [
//         {
//             id: "1673858757354708",
//             name: "WASHING H",
//             sply_sales: 54468930,
//             actual_sales: 126201050,
//             target_sales: 1342575099.1999998,
//             subCategories: [
//                 {
//                     id: "1674026885489908",
//                     name: "BASIN ROUND (VIRGIN)",
//                     sply_sales: 42530300,
//                     actual_sales: 98145400,
//                     target_sales: 677983999.5999999,
//                     products: [
//                         {
//                             id: "1674111328076558",
//                             name: "Basin Round(Virgin) 15Ltr MULTPLE-COLORS",
//                             sply_sales: 14466600,
//                             actual_sales: 53594100,
//                             target_sales: 42373999.974999994,
//                         },
//                         {
//                             id: "1674111337710014",
//                             name: "Basin Round(Virgin) 15Ltr ORANGE",
//                             sply_sales: 8126300,
//                             actual_sales: 21215800,
//                             target_sales: 42373999.974999994,
//                         },
//                     ],
//                 },
//             ],
//         },
//     ],
// };
const LayeredGroupedProductSalesDrilldownWithGroupedLollipopChart = ({ data = sampleData }) => {
    const chartRef = useRef(null);

    // Format data for chart series
    // Example: { name: "Bottles", sply: 100, actual: 120, target: 130, drilldown: "category1" }
    // products represents final level
    const formatData = (items, level) => {
        return items.map((item) => ({
            name: item.name,
            sply: item.sply_sales,
            actual: item.actual_sales,
            target: item.target_sales,
            drilldown: level !== "products" ? item.id : null,
        }));
    };
    // Calculate percentage change
    // Example: calculatePercentageChange(120, 100) returns 16.67 (%)
    // const calculatePercentageChange = (actual, comparison) => {
    //     return ((actual - comparison) / actual) * 100;
    // };

    const calculatePercentageChange = (actual, comparison) => {
        if (actual === 0) {
            // Handle the case where actual is zero
            return comparison === 0 ? 0 : comparison > 0 ? -100 : 100;
        }
        return ((actual - comparison) / actual) * 100;
    };

    // Create drilldown series for each level
    const createDrilldownSeries = (items, level) => {
        return items.map((item) => {
            const nextLevel = level === "categories" ? "subCategories" : "products";
            const hasNextLevel = item[nextLevel] && item[nextLevel].length > 0;
            const drilldownData = hasNextLevel ? formatData(item[nextLevel], nextLevel) : [];

            return {
                id: item.id,
                name: item.name,
                data: drilldownData,
            };
        });
    };

    // Create percentage change lollipop series
    // Example output: { type: 'lollipop', name: 'Percentage Change Actual vs. SPLY', data: [{name: 'Bottles', y: 16.67}] }
    const createPercentageChangeSeries = (items, comparisonType) => {
        return {
            type: "lollipop",
            name: `Percentage Change Actual vs. ${comparisonType}`,
            data: items.map((item) => {
                const percentageChange = calculatePercentageChange(item.actual_sales, item[`${comparisonType.toLowerCase()}_sales`]);
                return {
                    name: item.name,
                    y: percentageChange,
                    color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                };
            }),
            yAxis: 1,
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
            },
            marker: {
                enabled: true,
                lineColor: null,
            },
            dataLabels: {
                enabled: true,
                format: "{point.y:.2f}%",
            },
        };
    };

    // Create drilldown series for all levels
    const drilldownSeries = [...createDrilldownSeries(data.categories, "categories"), ...data.categories.flatMap((category) => createDrilldownSeries(category.subCategories, "subCategories"))];

    const options = {
        chart: {
            type: "bar",
            events: {
                drilldown: function (e) {
                    if (!e.seriesOptions) {
                        const chart = this;
                        const series = chart.userOptions.drilldown.series.find((s) => s.id === e.point.drilldown);
                        if (series && series.data && series.data.length > 0) {
                            const splyData = series.data.map((item) => ({ name: item.name, y: item.sply, drilldown: item.drilldown }));
                            const actualData = series.data.map((item) => ({ name: item.name, y: item.actual, drilldown: item.drilldown }));
                            const targetData = series.data.map((item) => ({ name: item.name, y: item.target, drilldown: item.drilldown }));

                            // Add SPLY, Actual, and Target sales series
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                name: "SPLY Sales",
                                data: splyData,
                                color: Highcharts.getOptions().colors[0],
                            });
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                name: "Actual Sales",
                                data: actualData,
                                color: Highcharts.getOptions().colors[1],
                            });
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                name: "Target Sales",
                                data: targetData,
                                color: Highcharts.getOptions().colors[2],
                            });

                            // Add percentage change lollipop series for SPLY comparison
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                type: "lollipop",
                                name: "Percentage Change Actual vs. SPLY",
                                data: series.data.map((item) => {
                                    const percentageChange = calculatePercentageChange(item.actual, item.sply);
                                    return {
                                        name: item.name,
                                        y: percentageChange,
                                        color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                    };
                                }),
                                yAxis: 1,
                                tooltip: {
                                    pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
                                },
                                marker: {
                                    enabled: true,
                                    lineColor: null,
                                },
                                dataLabels: {
                                    enabled: true,
                                    format: "{point.y:.2f}%",
                                },
                            });

                            // Add percentage change lollipop series for Target comparison
                            chart.addSingleSeriesAsDrilldown(e.point, {
                                type: "lollipop",
                                name: "Percentage Change Actual vs. Target",
                                data: series.data.map((item) => {
                                    const percentageChange = calculatePercentageChange(item.actual, item.target);
                                    return {
                                        name: item.name,
                                        y: percentageChange,
                                        color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                    };
                                }),
                                yAxis: 1,
                                tooltip: {
                                    pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
                                },
                                marker: {
                                    enabled: true,
                                    lineColor: null,
                                },
                                dataLabels: {
                                    enabled: true,
                                    format: "{point.y:.2f}%",
                                },
                            });

                            chart.applyDrilldown();
                        } else {
                            // If there's no data to drill down to, prevent the drilldown
                            e.preventDefault();
                        }
                    }
                },
            },
        },
        title: { text: "Sales Performance" },
        xAxis: { type: "category" },
        yAxis: [
            {
                title: { text: "Sales" },
                width: "60%",
                lineWidth: 2,
                offset: 0,
            },
            {
                title: { text: "Percentage Change" },
                left: "65%",
                width: "30%",
                offset: 0,
                lineWidth: 2,
                max: 100,
                min: -100,
            },
        ],
        legend: { enabled: true },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y}",
                },
                point: {
                    // to disable click events on points without drilldown data
                    events: {
                        click: function () {
                            if (!this.drilldown) {
                                return false;
                            }
                        },
                    },
                },
            },
        },
        credits: {
            enabled: false,
        },
        // Main chart series
        series: [
            {
                name: "SPLY Sales",
                data: data.categories.map((category) => ({
                    name: category.name,
                    y: category.sply_sales,
                    drilldown: category.subCategories && category.subCategories.length > 0 ? category.id : null,
                    color: Highcharts.getOptions().colors[0],
                })),
            },
            {
                name: "Actual Sales",
                data: data.categories.map((category) => ({
                    name: category.name,
                    y: category.actual_sales,
                    drilldown: category.subCategories && category.subCategories.length > 0 ? category.id : null,
                    color: Highcharts.getOptions().colors[1],
                })),
            },
            {
                name: "Target Sales",
                data: data.categories.map((category) => ({
                    name: category.name,
                    y: category.target_sales,
                    drilldown: category.subCategories && category.subCategories.length > 0 ? category.id : null,
                    color: Highcharts.getOptions().colors[2],
                })),
            },
            createPercentageChangeSeries(data.categories, "SPLY"),
            createPercentageChangeSeries(data.categories, "Target"),
        ],
        drilldown: {
            allowPointDrilldown: false,
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            activeDataLabelStyle: {
                color: "#FFFFFF",
                textDecoration: "none",
                textOutline: "1px #000000",
            },

            series: drilldownSeries,
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} immutable={true} />
        </div>
    );
};
export default LayeredGroupedProductSalesDrilldownWithGroupedLollipopChart;
