import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

//
import { getCustomerRankingSales } from "../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

//lotties
import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
import MaterialUiLoaderLottie from "../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import BrownFilesProcessingLottie from "../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";
import { Tooltip } from "@mui/material";

import FiltersFormDialog from "../widgets/FiltersFormDialog";
import { Button } from "primereact/button";
import moment from "moment";

exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const customerSalesData = {
    customers: ["Customer A", "Customer B", "Customer C", "Customer D", "Customer E"],
    sales: [20000, 15000, 22000, 13000, 18000],
};

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const generateColors = (numColors) => {
    let colors = [];
    for (let i = 0; i < numColors; i++) {
        colors.push(getRandomColor());
    }
    return colors;
};

const getCustomerChartOptions = (customerSalesData, dataFilters) => {
    if (!customerSalesData?.customers || !Array.isArray(customerSalesData?.customers)) {
        return null;
    }

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    // const colors = generateColors(customerSalesData.customers.length);
    // const seriesData = customerSalesData.customers.map((customer, index) => ({
    //     name: customer,
    //     data: [customerSalesData.sales[index]],
    //     color: colors[index],
    // }));

    // return {
    //     chart: {
    //         type: "column",
    //     },
    //     title: {
    //         text: `Customer Sales Rankings (Current Vs SPLY) (${startDate} to ${endDate})`,
    //     },
    //     xAxis: {
    //         categories: ["Sales"],
    //         crosshair: true,
    //     },
    //     yAxis: {
    //         title: {
    //             text: "Sales (UGX)",
    //         },
    //     },
    //     tooltip: {
    //         shared: false,
    //         pointFormatter: function () {
    //             return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>UGX ${this.y.toLocaleString()}</b><br/>`;
    //         },
    //     },
    //     plotOptions: {
    //         column: {
    //             dataLabels: {
    //                 enabled: true,
    //                 format: "UGX {point.y:,.0f}",
    //             },
    //         },
    //     },
    //     credits: {
    //         enabled: false,
    //     },
    //     series: seriesData,
    //     legend: {
    //         enabled: true,
    //     },
    //     noData: {
    //         style: {
    //             fontWeight: "bold",
    //             fontSize: "16px",
    //             color: "#303030",
    //         },
    //         position: {
    //             align: "center",
    //             verticalAlign: "middle",
    //         },
    //         text: "No data to display",
    //     },
    // };

    return {
        chart: {
            type: "bar", // Horizontal bar chart
        },
        title: {
            text: `Customer Sales Rankings from ${startDate} to ${endDate}`,
        },
        xAxis: {
            categories: customerSalesData.customers,
            title: {
                text: "Customers",
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Sales",
                align: "high",
            },
            labels: {
                overflow: "justify",
            },
        },
        tooltip: {
            valuePrefix: "UGX",
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                },
            },
        },
        legend: {
            reversed: true,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Sales",
                data: customerSalesData.sales,
            },
        ],
    };
};

const CustomerRankingSalesChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const CustomerRankingSalesQuery = useQuery({
        disable: false,
        queryKey: ["customer-ranking-sales", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getCustomerRankingSales({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (CustomerRankingSalesQuery?.isError) {
            console.log("🚀 ~ useEffect ~ CustomerRankingSalesQuery?.isError:", CustomerRankingSalesQuery?.error);
            CustomerRankingSalesQuery?.error?.response?.data?.message
                ? toast.error(CustomerRankingSalesQuery?.error?.response?.data?.message)
                : !CustomerRankingSalesQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [CustomerRankingSalesQuery?.isError]);

    console.log("data for NiceTwo CustomerRankingSalesQuery?.data?.data is:", CustomerRankingSalesQuery?.data?.data);

    const chartOptionsData = getCustomerChartOptions(CustomerRankingSalesQuery?.data?.data?.data, CustomerRankingSalesQuery?.data?.data?.requestParams);

    return (
        <>
            {CustomerRankingSalesQuery?.isLoading ? (
                <>
                    {/* <div className="col-12">
                        <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                    </div> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={LinesRunningLoaderLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </>
            ) : CustomerRankingSalesQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>

                    <HighchartsReact highcharts={Highcharts} options={chartOptionsData} />
                </>
            )}
        </>
    );
};

export default CustomerRankingSalesChart;
