import React from "react";

import CoverageHeatMapsPage from "./coverage-heatmaps/CoverageHeatMapsPage";

function CoverageCardSupportComponents() {
    return (
        <div>
            <CoverageHeatMapsPage />
        </div>
    );
}

export default CoverageCardSupportComponents;
