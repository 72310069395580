import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

//
const sampleData = {
    periods: [
        {
            id: "period1",
            name: "January",
            sales: 100000,
            associates: [
                {
                    id: "associate1-1",
                    name: "John Doe",
                    sales: 60000,
                    categories: [
                        {
                            id: "category1-1-1",
                            name: "Bottles",
                            sales: 30000,
                            subCategories: [
                                {
                                    id: "subcategory1-1-1-1",
                                    name: "Water Bottles",
                                    sales: 20000,
                                    products: [
                                        { name: "Bottle A", sales: 8000 },
                                        { name: "Bottle B", sales: 7000 },
                                        { name: "Bottle C", sales: 5000 },
                                    ],
                                },
                                {
                                    id: "subcategory1-1-1-2",
                                    name: "Juice Bottles",
                                    sales: 10000,
                                    products: [
                                        { name: "Bottle D", sales: 6000 },
                                        { name: "Bottle E", sales: 4000 },
                                    ],
                                },
                            ],
                        },
                        {
                            id: "category1-1-2",
                            name: "Containers",
                            sales: 30000,
                            subCategories: [
                                {
                                    id: "subcategory1-1-2-1",
                                    name: "Food Containers",
                                    sales: 20000,
                                    products: [
                                        { name: "Container A", sales: 10000 },
                                        { name: "Container B", sales: 8000 },
                                        { name: "Container C", sales: 2000 },
                                    ],
                                },
                                {
                                    id: "subcategory1-1-2-2",
                                    name: "Storage Containers",
                                    sales: 10000,
                                    products: [
                                        { name: "Container D", sales: 6000 },
                                        { name: "Container E", sales: 4000 },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "associate1-2",
                    name: "Jane Smith",
                    sales: 40000,
                    categories: [
                        {
                            id: "category1-2-1",
                            name: "Bags",
                            sales: 20000,
                            subCategories: [
                                {
                                    id: "subcategory1-2-1-1",
                                    name: "Hand Bags",
                                    sales: 15000,
                                    products: [
                                        { name: "Bag A", sales: 8000 },
                                        { name: "Bag B", sales: 7000 },
                                    ],
                                },
                                {
                                    id: "subcategory1-2-1-2",
                                    name: "Travel Bags",
                                    sales: 5000,
                                    products: [
                                        { name: "Bag C", sales: 3000 },
                                        { name: "Bag D", sales: 2000 },
                                    ],
                                },
                            ],
                        },
                        {
                            id: "category1-2-2",
                            name: "Cups",
                            sales: 20000,
                            subCategories: [
                                {
                                    id: "subcategory1-2-2-1",
                                    name: "Tea Cups",
                                    sales: 12000,
                                    products: [
                                        { name: "Cup A", sales: 6000 },
                                        { name: "Cup B", sales: 6000 },
                                    ],
                                },
                                {
                                    id: "subcategory1-2-2-2",
                                    name: "Coffee Cups",
                                    sales: 8000,
                                    products: [
                                        { name: "Cup C", sales: 5000 },
                                        { name: "Cup D", sales: 3000 },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: "period2",
            name: "February",
            sales: 120000,
            associates: [
                {
                    id: "associate2-1",
                    name: "Alice Johnson",
                    sales: 70000,
                    categories: [
                        {
                            id: "category2-1-1",
                            name: "Plates",
                            sales: 40000,
                            subCategories: [
                                {
                                    id: "subcategory2-1-1-1",
                                    name: "Dinner Plates",
                                    sales: 25000,
                                    products: [
                                        { name: "Plate A", sales: 10000 },
                                        { name: "Plate B", sales: 8000 },
                                        { name: "Plate C", sales: 7000 },
                                    ],
                                },
                                {
                                    id: "subcategory2-1-1-2",
                                    name: "Side Plates",
                                    sales: 15000,
                                    products: [
                                        { name: "Plate D", sales: 9000 },
                                        { name: "Plate E", sales: 6000 },
                                    ],
                                },
                            ],
                        },
                        {
                            id: "category2-1-2",
                            name: "Bowls",
                            sales: 30000,
                            subCategories: [
                                {
                                    id: "subcategory2-1-2-1",
                                    name: "Soup Bowls",
                                    sales: 20000,
                                    products: [
                                        { name: "Bowl A", sales: 10000 },
                                        { name: "Bowl B", sales: 8000 },
                                        { name: "Bowl C", sales: 2000 },
                                    ],
                                },
                                {
                                    id: "subcategory2-1-2-2",
                                    name: "Salad Bowls",
                                    sales: 10000,
                                    products: [
                                        { name: "Bowl D", sales: 6000 },
                                        { name: "Bowl E", sales: 4000 },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "associate2-2",
                    name: "Bob Brown",
                    sales: 50000,
                    categories: [
                        {
                            id: "category2-2-1",
                            name: "Glasses",
                            sales: 30000,
                            subCategories: [
                                {
                                    id: "subcategory2-2-1-1",
                                    name: "Wine Glasses",
                                    sales: 15000,
                                    products: [
                                        { name: "Glass A", sales: 6000 },
                                        { name: "Glass B", sales: 5000 },
                                        { name: "Glass C", sales: 4000 },
                                    ],
                                },
                                {
                                    id: "subcategory2-2-1-2",
                                    name: "Water Glasses",
                                    sales: 15000,
                                    products: [
                                        { name: "Glass D", sales: 9000 },
                                        { name: "Glass E", sales: 6000 },
                                    ],
                                },
                            ],
                        },
                        {
                            id: "category2-2-2",
                            name: "Mugs",
                            sales: 20000,
                            subCategories: [
                                {
                                    id: "subcategory2-2-2-1",
                                    name: "Coffee Mugs",
                                    sales: 12000,
                                    products: [
                                        { name: "Mug A", sales: 6000 },
                                        { name: "Mug B", sales: 6000 },
                                    ],
                                },
                                {
                                    id: "subcategory2-2-2-2",
                                    name: "Tea Mugs",
                                    sales: 8000,
                                    products: [
                                        { name: "Mug C", sales: 5000 },
                                        { name: "Mug D", sales: 3000 },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

//
const getChartOptions = (sampleData) => {
    if (!sampleData?.periods || !Array.isArray(sampleData?.periods)) {
        return null;
    }

    //
    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const periodsData = data.periods.map((period) => {
            drilldownSeries.push({
                id: period.id,
                name: `${period.name} Associates`,
                data: period.associates.map((associate) => ({
                    name: associate.name,
                    y: associate.sales,
                    drilldown: associate.id,
                })),
            });

            period.associates.forEach((associate) => {
                drilldownSeries.push({
                    id: associate.id,
                    name: `${associate.name} Categories`,
                    data: associate.categories.map((category) => ({
                        name: category.name,
                        y: category.sales,
                        drilldown: category.id,
                    })),
                });

                associate.categories.forEach((category) => {
                    drilldownSeries.push({
                        id: category.id,
                        name: `${category.name} Sub-categories`,
                        data: category.subCategories.map((subCategory) => ({
                            name: subCategory.name,
                            y: subCategory.sales,
                            drilldown: subCategory.id,
                        })),
                    });

                    category.subCategories.forEach((subCategory) => {
                        drilldownSeries.push({
                            id: subCategory.id,
                            name: `${subCategory.name} Products`,
                            data: subCategory.products.map((product) => [product.name, product.sales]),
                        });
                    });
                });
            });

            return {
                name: period.name,
                y: period.sales,
                drilldown: period.id,
            };
        });
        return { periodsData, drilldownSeries };
    };

    const { periodsData, drilldownSeries } = generateDrilldownData(sampleData);

    //
    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: "Sales by Period and Associate",
        },
        subtitle: {
            text: "Click the columns to view sales associates, categories, sub-categories, and products.",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Total Sales (UGX)",
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.1f}",
                },
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.2f} UGX</b><br/>',
        },
        series: [
            {
                name: "Periods",
                colorByPoint: true,
                data: periodsData,
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            series: drilldownSeries,
        },
        credits: {
            enabled: false,
        },
    };

    return options;
};

const chartOptionsData = getChartOptions(sampleData);

const SelloutPeriodSalesAssocProductDrilldownChart = () => {
    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptionsData} />
        </div>
    );
};

export default SelloutPeriodSalesAssocProductDrilldownChart;
