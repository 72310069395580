import React, { useState, useContext, useEffect, useRef } from "react";
import Highcharts, { format } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";

import { getRevenueGauge } from "../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";
import { IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OverlayPanel } from "primereact/overlaypanel";

//
import CardsFiltersFormDialog from "./widgets/CardsFiltersFormDialog";
import { Button } from "primereact/button";
import { Tooltip } from "@mui/material";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

// Initialize the required modules
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

// const revenueData = {
//     actual: 290000000000, // in millions
//     target: 240000000000, // percentage of annual target
//     title: "Revenue (WTD)", // percentage of annual target
// };

function roundToNearestPlace(number) {
    if (number === 0) {
        return 0;
    }

    // Determine the number of digits in the number
    const digits = number.toString().length;

    // Calculate the factor to round to the nearest place
    const factor = Math.pow(10, digits - 3);

    // Round the number
    const rounded = Math.round(number / factor) * factor;

    return rounded;
}

const formatNumber = (num) => {
    if (isNaN(num)) {
        return num;
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const StatsCardTarget = ({ measure = "sales_value" }) => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    const op = useRef(null);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            // { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const RevenueGaugeQuery = useQuery({
        disable: false,
        queryKey: ["revenue-gauge", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getRevenueGauge({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (RevenueGaugeQuery?.isError) {
            console.log("🚀 ~ useEffect ~ RevenueGaugeQuery?.isError:", RevenueGaugeQuery?.error);
            RevenueGaugeQuery?.error?.response?.data?.message ? toast.error(RevenueGaugeQuery?.error?.response?.data?.message) : !RevenueGaugeQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [RevenueGaugeQuery?.isError]);

    if (RevenueGaugeQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (RevenueGaugeQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo RevenueGaugeQuery?.data?.data is:", RevenueGaugeQuery?.data?.data?.data);

    // console.log("zinasd", sampleData, RevenueGaugeQuery?.data?.data);

    const revenueData = RevenueGaugeQuery?.data?.data?.data;

    const maxScale = roundToNearestPlace(revenueData?.actual < revenueData?.target ? (revenueData?.target < revenueData?.smly ? revenueData?.smly : revenueData?.target) : revenueData?.actual < revenueData?.smly ? revenueData?.smly : revenueData?.actual);
    const maxName = revenueData?.actual < revenueData?.target ? (revenueData?.target < revenueData?.smly ? "sply" : "target") : revenueData?.actual < revenueData?.smly ? "sply" : "actual";
    const options = {
        chart: {
            type: "gauge",
            className: "highcharts-gauge-chart",
            marginBottom: 0,
            height: 250,
        },
        title: {
            text: revenueData?.title,
            style: {
                fontSize: "16px",
                fontWeight: "bold",
                color: "#000",
            },
        },
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ["50%", "64%"],
            size: "110%",
        },
        yAxis: {
            min: 0,
            max: maxScale, // Adjusted based on the image max value
            // title: {
            //     text: "",
            // },
            lineWidth: 0,
            minorTickInterval: null,
            labels: {
                formatter: function () {
                    // Automatically format the label to show 'B' instead of 'G'
                    if (this.value >= 1000000000) {
                        return this.value / 1000000000 + "b"; // Format to billions
                    }
                    if (this.value >= 1000000) {
                        return this.value / 1000000 + "m"; // Format to billions
                    }
                    if (this.value >= 1000) {
                        return this.value / 1000 + "k"; // Format to billions
                    }
                    return this.value; // Return the original value for lower numbers
                },
                distance: 20,
                style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                },
            },
            tickColor: "#FFFFFF",
            tickWidth: 1,
            tickAmount: 4,
            tickPositions: [0, roundToNearestPlace(Math.round((100 / 100) * revenueData?.target)), roundToNearestPlace(Math.round((100 / 100) * revenueData?.actual)), roundToNearestPlace(Math.round((100 / 100) * revenueData?.sply)), roundToNearestPlace(Math.round((100 / 100) * revenueData?.smly))],
            plotBands:
                maxName === "target"
                    ? [
                          //   {
                          //       from: 0,
                          //       to: Math.round((15 / 100) * revenueData?.target),
                          //       color: "#ff0000", // Adjust colors to match the image
                          //   },
                          {
                              from: 0,
                              to: Math.round((90 / 100) * revenueData?.target),
                              color: "red",
                          },
                          {
                              from: Math.round((90 / 100) * revenueData?.target),
                              to: Math.round((98 / 100) * revenueData?.target),
                              color: "orange",
                          },
                          {
                              from: Math.round((98 / 100) * revenueData?.target),
                              to: Math.round((100 / 100) * revenueData?.target),
                              color: "green",
                          },
                          //   {
                          //       from: Math.round((85 / 100) * revenueData?.target),
                          //       to: Math.round((98 / 100) * revenueData?.target),
                          //       color: "#feb204",
                          //   },
                          //   {
                          //       from: Math.round((100 / 100) * revenueData?.actual),
                          //       to: Math.round((100 / 100) * revenueData?.target),
                          //       //   color: "#e6e6e6",
                          //       color: "#c9c9c9",
                          //   },
                      ]
                    : maxName === "sply"
                    ? [
                          {
                              from: 0,
                              to: Math.round((90 / 100) * revenueData?.target),
                              color: "red",
                          },
                          {
                              from: Math.round((90 / 100) * revenueData?.target),
                              to: Math.round((98 / 100) * revenueData?.target),
                              color: "orange",
                          },
                          {
                              from: Math.round((98 / 100) * revenueData?.target),
                              to: Math.round((100 / 100) * revenueData?.target),
                              color: "green",
                          },
                          //   {
                          //       from: 0,
                          //       to: Math.round((15 / 100) * revenueData?.target),
                          //       color: "#ff0000", // Adjust colors to match the image
                          //   },
                          //   {
                          //       from: 0,
                          //       to: Math.round((100 / 100) * revenueData?.target),
                          //       color: "#00ff00",
                          //   },
                          //   {
                          //       from: Math.round((90 / 100) * revenueData?.target),
                          //       to: Math.round((100 / 100) * revenueData?.target),
                          //       color: "#feb204",
                          //   },
                          {
                              from: Math.round((100 / 100) * revenueData?.target),
                              to: Math.round((100 / 100) * revenueData?.smly),
                              color: "green",
                          },
                      ]
                    : [
                          {
                              from: 0,
                              to: Math.round((90 / 100) * revenueData?.target),
                              color: "red",
                          },
                          {
                              from: Math.round((90 / 100) * revenueData?.target),
                              to: Math.round((98 / 100) * revenueData?.target),
                              color: "orange",
                          },
                          {
                              from: Math.round((98 / 100) * revenueData?.target),
                              to: Math.round((100 / 100) * revenueData?.target),
                              color: "green",
                          },
                          //   {
                          //       from: 0,
                          //       to: Math.round((15 / 100) * revenueData?.target),
                          //       color: "#ff0000", // Adjust colors to match the image
                          //   },
                          //   {
                          //       from: 0,
                          //       to: Math.round((100 / 100) * revenueData?.target),
                          //       color: "#00ff00",
                          //   },
                          //   {
                          //       from: 0,
                          //       to: Math.round((15 / 100) * revenueData?.target),
                          //       color: "#ff0000", // Adjust colors to match the image
                          //   },
                          //   {
                          //       from: 0,
                          //       to: Math.round((100 / 100) * revenueData?.target),
                          //       color: "#00ff00",
                          //   },
                          //   {
                          //       from: Math.round((90 / 100) * revenueData?.target),
                          //       to: Math.round((100 / 100) * revenueData?.target),
                          //       color: "#feb204",
                          //   },
                          {
                              from: Math.round((100 / 100) * revenueData?.target),
                              to: Math.round((100 / 100) * revenueData?.actual),
                              color: "green",
                          },
                      ],
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            gauge: {
                dataLabels: {
                    enabled: true,
                    allowOverlap: true, // Allow overlapping data labels
                    borderWidth: 0,
                },
            },
            series: {
                dial: {
                    baseWidth: 12,
                    baseLength: 0,
                    rearLength: 0,
                },
                pivot: {
                    radius: 5,
                },
                dataLabels: {
                    useHTML: true,
                    format: "${y}",
                },
            },
        },
        series: [
            {
                name: "SMLY",
                data: [Math.round(revenueData?.smly)],

                dataLabels: {
                    format: `<div style="text-align: center; font-size: 11px; color: #808080">Full Month LY: ${measure === "quantity" ? "" : "UGX"} ${formatNumber(revenueData?.smly)}</div>`,
                    borderWidth: 0,
                    color: (Highcharts.defaultOptions.title && Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || "#333333",
                    style: {
                        fontSize: "16px",
                    },
                    y: 97,
                },
                dial: {
                    radius: "80%",
                    backgroundColor: "#808080",
                    baseWidth: 12,
                    baseLength: "0%",
                    rearLength: "0%",
                },
                pivot: {
                    backgroundColor: "#808080",
                    radius: 6,
                },
            },
            {
                name: "SPLY",
                data: [Math.round(revenueData?.sply)],
                tooltip: {
                    valueSuffix: "",
                },
                dataLabels: {
                    format: `<div style="text-align: center; font-size: 10px; margin-top: 5px; color: black"><span style="text-align: center; font-size: 10px; color: #C0C0C0">SPLY: ${measure === "quantity" ? "" : "UGX"} ${formatNumber(
                        revenueData?.sply
                    )}</span> Revenue is <span style="text-align: center; font-size: 10px; color: ${
                        (revenueData?.target === 0 ? 0 : (revenueData?.actual / revenueData?.sply) * 100).toFixed(1) < 90 ? "red" : ((revenueData?.actual / revenueData?.sply) * 100).toFixed(1) > 98 ? "green" : "orange"
                    }">${(revenueData?.sply === 0 ? 0 : (revenueData?.actual / revenueData?.sply) * 100).toFixed(1)}%</span> of SPLY</div>`,
                    borderWidth: 0,
                    color: (Highcharts.defaultOptions.title && Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || "#333333",
                    style: {
                        fontSize: "16px",
                    },
                    y: 37,
                },
                dial: {
                    radius: "80%",
                    backgroundColor: "#C0C0C0",
                    baseWidth: 12,
                    baseLength: "0%",
                    rearLength: "0%",
                },
                pivot: {
                    backgroundColor: "#C0C0C0",
                    radius: 6,
                },
            },
            {
                name: "Target",
                data: [Math.round(revenueData?.target)],
                tooltip: {
                    valueSuffix: "",
                },
                dataLabels: {
                    format: `<div style="text-align: center; font-size: 10px; margin-top: 5px; color: black"><span style="text-align: center; font-size: 10px; color: #306EFF ">Target: ${measure === "quantity" ? "" : "UGX"} ${formatNumber(
                        revenueData?.target
                    )}</span> Revenue is <span style="text-align: center; font-size: 10px; color: ${
                        (revenueData?.target === 0 ? 0 : (revenueData?.actual / revenueData?.target) * 100).toFixed(1) < 90 ? "red" : ((revenueData?.actual / revenueData?.target) * 100).toFixed(1) > 98 ? "green" : "orange"
                    }">${(revenueData?.target === 0 ? 0 : (revenueData?.actual / revenueData?.target) * 100).toFixed(1)}%</span> of the target </div>`,
                    borderWidth: 0,
                    color: (Highcharts.defaultOptions.title && Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || "#333333",
                    style: {
                        fontSize: "16px",
                    },
                    y: 21,
                },
                dial: {
                    radius: "80%",
                    backgroundColor: "#306EFF",
                    baseWidth: 12,
                    baseLength: "0%",
                    rearLength: "0%",
                },
                pivot: {
                    backgroundColor: "#306EFF",
                    radius: 6,
                },
            },
            {
                name: "Speed",
                data: [Math.round(revenueData?.actual)],
                tooltip: {
                    valueSuffix: "",
                },

                dataLabels: {
                    format: `<div>Revenue: <span style="text-align: center; height: fit-content; font-size: 17px; margin-bottom: -5px; color: ${revenueData?.actual > revenueData?.sply ? "green" : revenueData?.actual < revenueData?.sply ? "red" : "orange"}">${
                        measure === "quantity" ? "" : "UGX"
                    }  {y:,.0f}</span></div>
                    `,
                    borderWidth: 0,
                    color: (Highcharts.defaultOptions.title && Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || "#333333",
                    style: {
                        fontSize: "16px",
                    },
                    y: 7,
                },
                dial: {
                    radius: "80%",
                    backgroundColor: `${revenueData?.actual > revenueData?.sply ? "green" : revenueData?.actual < revenueData?.sply ? "red" : "orange"}`,
                    baseWidth: 12,
                    baseLength: "0%",
                    rearLength: "0%",
                },
                pivot: {
                    backgroundColor: `${revenueData?.actual > revenueData?.sply ? "green" : revenueData?.actual < revenueData?.sply ? "red" : "orange"}`,
                    radius: 6,
                },
            },
        ],
        exporting: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
    };

    // const options = Highcharts.merge(commonGaugeOptions, {
    //     title: {
    //         text: "Revenue (YTD)",
    //     },
    //     series: [
    //         {
    //             name: "Revenue",
    //             data: [revenueData], // Use the passed revenue data
    //         },
    //     ],
    // });

    return (
        <div className="card mb-0" style={{ height: "300px" }}>
            <div style={{ position: "relative", top: "30px", marginTop: "-30px", zIndex: "20", width: "fit-content" }}>
                {/* filter chart content */}
                <Tooltip title="Filter">
                    <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                </Tooltip>

                <CardsFiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} />

                {/* How to read Chart tooltip */}
                <Tooltip title="How to interpret this chart">
                    <IconButton onClick={(e) => op.current.toggle(e)}>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <OverlayPanel ref={op} style={{ maxWidth: "80vw", padding: "20px" }}>
                <div>
                    <h4>Interpreting the Revenue Gauge</h4>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Overview</h5>
                    <p>The revenue gauge visualizes the current monthly revenue in comparison to the same period last year (SPLY) and assesses performance against the set target. It provides a quick and intuitive way to gauge revenue performance.</p>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Key Components</h5>
                    <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Black Hand:</strong> Points to the current month's revenue.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Grey Hand:</strong> Points to the revenue for the same period last year (SPLY).
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Current Month Revenue:</strong> Displayed in bold black text just below the gauge,corresponding to the black hand on the gauge.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>SPLY Revenue:</strong> Displayed in grey text at the bottom, corresponding to the grey hand on the gauge.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong>Percentage Accomplishments:</strong> Below the current revenue figure, showing:
                            <ul>
                                <li>The percentage of the target met by the current month's revenue.</li>
                                <li>The percentage that the current month's revenue represents relative to the SPLY revenue.</li>
                            </ul>
                        </li>
                    </ul>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Color Coding for Percentages</h5>
                    <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ marginBottom: "5px" }}>
                            <strong style={{ color: "red" }}>Red:</strong> Indicates less than 90% of the target/SPLY.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong style={{ color: "orange" }}>Amber:</strong> Indicates between 90% and 98% of the target/SPLY.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong style={{ color: "green" }}>Green:</strong> Indicates more than 98% of the target/SPLY.
                        </li>
                    </ul>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Gauge Scale Colors</h5>
                    <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ marginBottom: "5px" }}>
                            <strong style={{ color: "red" }}>Red Area:</strong> Represents less than 90% of the target.
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                            <strong style={{ color: "orange" }}>Amber Area:</strong> Represents between 90% and less than 98% of the target.
                        </li>
                        {/* <li style={{ marginBottom: "5px" }}>
                            <strong style={{ color: "green" }}>Light Green Area:</strong> Represents between 98% and 100% of the target.
                        </li> */}
                        <li style={{ marginBottom: "5px" }}>
                            <strong style={{ color: "green" }}>Green Area:</strong> Represents 98% and beyond of the target.
                        </li>
                    </ul>

                    <h5 style={{ marginTop: "15px", marginBottom: "10px" }}>Target Indicator</h5>
                    <p>The point where the amber area ends and the green area begins on the gauge scale represents the target. If the target is zero, the entire scale is green, as there is no specific target to compare against.</p>
                </div>
            </OverlayPanel>
            {/* <div className="card mb-0" style={{ flex: "1 1 22%", maxWidth: "22%", padding: "1rem", background: "#fff", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}> */}
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default StatsCardTarget;
