import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

//
import { getAllNiceTxCompleteDrill } from "../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import BrownFilesProcessingLottie from "../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import ServerProcessingLottie from "../../../../assets/lotties/nice-hop/server-processing-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialog from "../../widgets/FiltersFormDialog";
import { Button } from "primereact/button";

import moment from "moment";
import { Tooltip } from "@mui/material";

import { Accordion, AccordionTab } from "primereact/accordion";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

const sampleData = {
    regions: [
        {
            id: "region1",
            name: "North",
            sales: 5000000,
            associates: [
                {
                    id: "associate1-1",
                    name: "Allan Niwabiine",
                    sales: 2000000,
                    agents: [
                        {
                            id: "agent1-1-1",
                            name: "Ronam international Ltd CBD",
                            sales: 1000000,
                            routes: [
                                {
                                    id: "route1-1-1-1",
                                    name: "Bunyaruguru Bushenyi",
                                    sales: 500000,
                                    customers: [
                                        {
                                            id: "customer1-1-1-1-1",
                                            name: "Customer 1",
                                            sales: 250000,
                                            product_categories: [
                                                {
                                                    id: "category1",
                                                    categoryName: "WRITING INSTRUMENTS",
                                                    sales: 125000,
                                                    product_sub_categories: [
                                                        {
                                                            id: "subcategory1-1",
                                                            subCategoryName: "Pens",
                                                            sales: 75000,
                                                            products: [
                                                                { name: "Pen A", sales: 40000 },
                                                                { name: "Pen B", sales: 25000 },
                                                                { name: "Pen C", sales: 10000 },
                                                            ],
                                                        },
                                                        {
                                                            id: "subcategory1-2",
                                                            subCategoryName: "Pencils",
                                                            sales: 50000,
                                                            products: [
                                                                { name: "Pencil A", sales: 30000 },
                                                                { name: "Pencil B", sales: 20000 },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "associate1-2",
                    name: "SHARON DEBORAH NAMUKWAYA",
                    sales: 1500000,
                    agents: [
                        {
                            id: "agent1-2-1",
                            name: "Mutegyeki",
                            sales: 750000,
                            routes: [
                                {
                                    id: "route1-2-1-1",
                                    name: "Wanyange Bugembe Kakira",
                                    sales: 375000,
                                    customers: [
                                        {
                                            id: "customer1-2-1-1-1",
                                            name: "Customer 2",
                                            sales: 187500,
                                            product_categories: [
                                                {
                                                    id: "category2",
                                                    categoryName: "SATO",
                                                    sales: 93750,
                                                    product_sub_categories: [
                                                        {
                                                            id: "subcategory2-1",
                                                            subCategoryName: "Toilet Seats",
                                                            sales: 56250,
                                                            products: [
                                                                { name: "Seat A", sales: 30000 },
                                                                { name: "Seat B", sales: 26250 },
                                                            ],
                                                        },
                                                        {
                                                            id: "subcategory2-2",
                                                            subCategoryName: "Tissue Holders",
                                                            sales: 37500,
                                                            products: [
                                                                { name: "Holder A", sales: 20000 },
                                                                { name: "Holder B", sales: 17500 },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: "region2",
            name: "Region Strikers",
            sales: 3000000,
            associates: [
                {
                    id: "associate2-1",
                    name: "Associate 2",
                    sales: 1800000,
                    agents: [
                        {
                            id: "agent2-1-1",
                            name: "Agent 2",
                            sales: 900000,
                            routes: [
                                {
                                    id: "route2-1-1-1",
                                    name: "Route 2",
                                    sales: 450000,
                                    customers: [
                                        {
                                            id: "customer2-1-1-1-1",
                                            name: "Customer 3",
                                            sales: 225000,
                                            product_categories: [
                                                {
                                                    id: "category3",
                                                    categoryName: "Bottles",
                                                    sales: 112500,
                                                    product_sub_categories: [
                                                        {
                                                            id: "subcategory3-1",
                                                            subCategoryName: "Water Bottles",
                                                            sales: 67500,
                                                            products: [
                                                                { name: "Bottle A", sales: 37500 },
                                                                { name: "Bottle B", sales: 30000 },
                                                            ],
                                                        },
                                                        {
                                                            id: "subcategory3-2",
                                                            subCategoryName: "Juice Bottles",
                                                            sales: 45000,
                                                            products: [
                                                                { name: "Bottle C", sales: 25000 },
                                                                { name: "Bottle D", sales: 20000 },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

// Sample hierarchical data for agents and products

const getChartOptions = (sampleData, dataFilters) => {
    if (!sampleData?.regions || !Array.isArray(sampleData?.regions)) {
        return null;
    }

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const regionsData = data.regions.map((region) => {
            const regionId = region.id;

            // Add associates drilldown
            drilldownSeries.push({
                id: regionId,
                name: `${region.name} Associates`,
                data: region.associates.map((associate) => ({
                    name: associate.name,
                    y: associate.sales,
                    drilldown: `${regionId}-${associate.name}`, // Ensure unique drilldown ID
                })),
            });

            region.associates.forEach((associate) => {
                const associateId = `${regionId}-${associate.name}`;

                // Add agents drilldown
                drilldownSeries.push({
                    id: associateId,
                    name: `${associate.name} Agents`,
                    data: associate.agents.map((agent) => ({
                        name: agent.name,
                        y: agent.sales,
                        drilldown: `${associateId}-${agent.name}`, // Ensure unique drilldown ID
                    })),
                });

                associate.agents.forEach((agent) => {
                    const agentId = `${associateId}-${agent.name}`;

                    // Add routes drilldown
                    drilldownSeries.push({
                        id: agentId,
                        name: `${agent.name} Routes`,
                        data: agent.routes.map((route) => ({
                            name: route.name,
                            y: route.sales,
                            drilldown: `${agentId}-${route.name}`, // Ensure unique drilldown ID
                        })),
                    });

                    agent.routes.forEach((route) => {
                        const routeId = `${agentId}-${route.name}`;

                        // Add customers drilldown
                        drilldownSeries.push({
                            id: routeId,
                            name: `${route.name} Customers`,
                            data: route.customers.map((customer) => ({
                                name: customer.name,
                                y: customer.sales,
                                drilldown: `${routeId}-${customer.name}`, // Ensure unique drilldown ID
                            })),
                        });

                        route.customers.forEach((customer) => {
                            const customerId = `${routeId}-${customer.name}`;

                            // Add product categories drilldown
                            drilldownSeries.push({
                                id: customerId,
                                name: `${customer.name} Product Categories`,
                                data: customer.product_categories.map((category) => ({
                                    name: category.categoryName,
                                    y: category.sales,
                                    drilldown: `${customerId}-${category.categoryName}`, // Ensure unique drilldown ID
                                })),
                            });

                            customer.product_categories.forEach((category) => {
                                const categoryId = `${customerId}-${category.categoryName}`;

                                // Add product sub-categories drilldown
                                drilldownSeries.push({
                                    id: categoryId,
                                    name: `${category.categoryName} Sub-categories`,
                                    data: category.product_sub_categories.map((subCategory) => ({
                                        name: subCategory.subCategoryName,
                                        y: subCategory.sales,
                                        drilldown: `${categoryId}-${subCategory.subCategoryName}`, // Ensure unique drilldown ID
                                    })),
                                });

                                category.product_sub_categories.forEach((subCategory) => {
                                    const subCategoryId = `${categoryId}-${subCategory.subCategoryName}`;

                                    // Add products drilldown
                                    drilldownSeries.push({
                                        id: subCategoryId,
                                        name: `${subCategory.subCategoryName} Products`,
                                        data: subCategory.products.map((product) => [product.name, product.sales]),
                                    });
                                });
                            });
                        });
                    });
                });
            });

            return {
                name: region.name,
                y: region.sales,
                drilldown: regionId,
            };
        });

        return { regionsData, drilldownSeries };
    };

    const { regionsData, drilldownSeries } = generateDrilldownData(sampleData);

    return {
        chart: {
            type: "bar", // Change this to "bar"
        },
        title: {
            text: `Sales Drilldown by Region, Associate, Agent, Route, Customer, and Product from ${startDate} to ${endDate}`,
        },
        subtitle: {
            text: "Click the columns to view deeper levels.",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Total Sales (UGX)",
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.1f}",
                },
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.2f} UGX</b><br/>',
        },
        series: [
            {
                name: "Regions",
                colorByPoint: true,
                data: regionsData,
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            series: drilldownSeries,
        },
        credits: {
            enabled: false,
        },
    };
};

const SelloutTransactionsDrilldownChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "5", value: 5 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const SelloutNiceTxCompleteDrillQuery = useQuery({
        disable: false,
        queryKey: ["nice-two-tx-complete-drill", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getAllNiceTxCompleteDrill({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SelloutNiceTxCompleteDrillQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SelloutNiceTxCompleteDrillQuery?.isError:", SelloutNiceTxCompleteDrillQuery?.error);
            SelloutNiceTxCompleteDrillQuery?.error?.response?.data?.message
                ? toast.error(SelloutNiceTxCompleteDrillQuery?.error?.response?.data?.message)
                : !SelloutNiceTxCompleteDrillQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SelloutNiceTxCompleteDrillQuery?.isError]);

    console.log("data for NiceTwo SelloutNiceTxCompleteDrillQuery?.data?.data is:", SelloutNiceTxCompleteDrillQuery?.data?.data);

    const chartOptionsData = getChartOptions(SelloutNiceTxCompleteDrillQuery?.data?.data?.data, SelloutNiceTxCompleteDrillQuery?.data?.data?.requestParams);

    //=============== handle displaying filters on Chart ======================
    const dataFilters = SelloutNiceTxCompleteDrillQuery?.data?.data?.requestParams;

    // Format the dates
    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    // Function to ensure array and join names
    const formatArray = (array, key) => {
        return Array.isArray(array) && array.length > 0 ? array.map((item) => item[key]).join(", ") : "N/S";
    };

    // Extracting and formatting the filters
    const agents = formatArray(dataFilters?.agents, "name");
    const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
    const regions = formatArray(dataFilters?.regions, "name");
    const routes = formatArray(dataFilters?.routes, "name");
    const statuses = formatArray(dataFilters?.statuses, "label");
    const orderBy = dataFilters?.orderBy?.label || "N/S";
    const dataLimit = dataFilters?.dataLimit?.label || "N/S";
    const productCategories = formatArray(dataFilters?.productCategories, "name");
    const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
    const products = formatArray(dataFilters?.products, "name");

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            {SelloutNiceTxCompleteDrillQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={ServerProcessingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : SelloutNiceTxCompleteDrillQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialog
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>

                    <Accordion>
                        <AccordionTab header="Selected Filters" closed>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "0.5rem" }}>
                                <p>
                                    <strong>Start Date:</strong> {startDate}
                                </p>
                                <p>
                                    <strong>End Date:</strong> {endDate}
                                </p>
                                <p>
                                    <strong>Agents:</strong> {agents}
                                </p>
                                <p>
                                    <strong>Sales Associates:</strong> {salesAssociates}
                                </p>
                                <p>
                                    <strong>Regions:</strong> {regions}
                                </p>
                                <p>
                                    <strong>Routes:</strong> {routes}
                                </p>
                                <p>
                                    <strong>Statuses:</strong> {statuses}
                                </p>
                                <p>
                                    <strong>Order By:</strong> {orderBy}
                                </p>
                                <p>
                                    <strong>Data Limit:</strong> {dataLimit}
                                </p>
                                <p>
                                    <strong>Product Categories:</strong> {productCategories}
                                </p>
                                <p>
                                    <strong>Product Sub Categories:</strong> {productSubCategories}
                                </p>
                                <p>
                                    <strong>Products:</strong> {products}
                                </p>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    <HighchartsReact highcharts={Highcharts} options={chartOptionsData} />
                </>
            )}
        </>
    );
};

export default SelloutTransactionsDrilldownChart;
