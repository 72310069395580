import React, { useState, useContext, useMemo, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";
import drilldown from "highcharts/modules/drilldown";
import HC_accessibility from "highcharts/modules/accessibility";

//
import { getRegionPfmceActualVsSPLYVsTargetSplineChart } from "../../../../../services/nice-two-endpoints/nice-two-db-comparison-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import { Accordion, AccordionTab } from "primereact/accordion";

//lotties
import MaterialUiLoaderLottie from "../../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
import BrownFilesProcessingLottie from "../../../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialogForLineGraphs from "../../../widgets/FiltersFormDialogForLineGraphs";
import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);
HC_accessibility(Highcharts);

// Initialize the drilldown module
drilldown(Highcharts);

const sampleData = {
    regions: [
        {
            id: "region1",
            name: "North Region",
            sply_sales: 190,
            actual_sales: 230,
            target_sales: 250,
            categories: [
                {
                    id: "category1",
                    name: "Bottles",
                    sply_sales: 100,
                    actual_sales: 120,
                    target_sales: 130,
                    subCategories: [
                        {
                            id: "subcategory1-1",
                            name: "Water Bottles",
                            sply_sales: 50,
                            actual_sales: 60,
                            target_sales: 70,
                            products: [
                                { id: "product1-1-1", name: "Bottle A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                                { id: "product1-1-2", name: "Bottle B", sply_sales: 15, actual_sales: 18, target_sales: 20 },
                                { id: "product1-1-3", name: "Bottle C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                            ],
                        },
                        {
                            id: "subcategory1-2",
                            name: "Juice Bottles",
                            sply_sales: 50,
                            actual_sales: 60,
                            target_sales: 70,
                            products: [
                                { id: "product1-2-1", name: "Bottle D", sply_sales: 25, actual_sales: 28, target_sales: 30 },
                                { id: "product1-2-2", name: "Bottle E", sply_sales: 20, actual_sales: 22, target_sales: 25 },
                            ],
                        },
                    ],
                },
                {
                    id: "category2",
                    name: "Containers",
                    sply_sales: 90,
                    actual_sales: 110,
                    target_sales: 120,
                    subCategories: [
                        {
                            id: "subcategory2-1",
                            name: "Food Containers",
                            sply_sales: 45,
                            actual_sales: 55,
                            target_sales: 60,
                            products: [
                                { id: "product2-1-1", name: "Container A", sply_sales: 15, actual_sales: 20, target_sales: 25 },
                                { id: "product2-1-2", name: "Container B", sply_sales: 12, actual_sales: 15, target_sales: 18 },
                                { id: "product2-1-3", name: "Container C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                            ],
                        },
                        {
                            id: "subcategory2-2",
                            name: "Storage Containers",
                            sply_sales: 45,
                            actual_sales: 55,
                            target_sales: 60,
                            products: [
                                { id: "product2-2-1", name: "Container D", sply_sales: 25, actual_sales: 30, target_sales: 35 },
                                { id: "product2-2-2", name: "Container E", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: "region2",
            name: "South Region",
            sply_sales: 170,
            actual_sales: 200,
            target_sales: 220,
            categories: [
                {
                    id: "category3",
                    name: "Cutlery",
                    sply_sales: 80,
                    actual_sales: 95,
                    target_sales: 105,
                    subCategories: [
                        {
                            id: "subcategory3-1",
                            name: "Forks",
                            sply_sales: 40,
                            actual_sales: 48,
                            target_sales: 52,
                            products: [
                                { id: "product3-1-1", name: "Fork A", sply_sales: 20, actual_sales: 24, target_sales: 26 },
                                { id: "product3-1-2", name: "Fork B", sply_sales: 20, actual_sales: 24, target_sales: 26 },
                            ],
                        },
                        {
                            id: "subcategory3-2",
                            name: "Spoons",
                            sply_sales: 40,
                            actual_sales: 47,
                            target_sales: 53,
                            products: [
                                { id: "product3-2-1", name: "Spoon A", sply_sales: 20, actual_sales: 23, target_sales: 26 },
                                { id: "product3-2-2", name: "Spoon B", sply_sales: 20, actual_sales: 24, target_sales: 27 },
                            ],
                        },
                    ],
                },
                {
                    id: "category4",
                    name: "Plates",
                    sply_sales: 90,
                    actual_sales: 105,
                    target_sales: 115,
                    subCategories: [
                        {
                            id: "subcategory4-1",
                            name: "Dinner Plates",
                            sply_sales: 50,
                            actual_sales: 58,
                            target_sales: 63,
                            products: [
                                { id: "product4-1-1", name: "Dinner Plate A", sply_sales: 25, actual_sales: 29, target_sales: 31 },
                                { id: "product4-1-2", name: "Dinner Plate B", sply_sales: 25, actual_sales: 29, target_sales: 32 },
                            ],
                        },
                        {
                            id: "subcategory4-2",
                            name: "Side Plates",
                            sply_sales: 40,
                            actual_sales: 47,
                            target_sales: 52,
                            products: [
                                { id: "product4-2-1", name: "Side Plate A", sply_sales: 20, actual_sales: 23, target_sales: 26 },
                                { id: "product4-2-2", name: "Side Plate B", sply_sales: 20, actual_sales: 24, target_sales: 26 },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
const getChartOptions = (data, dataFilters, visibleSeries) => {
    console.log("🚀 ~ gggggg getChartOptions ~ data:", data?.data);
    if (!data?.data || !Array.isArray(data?.data)) {
        return null;
    }
    function formatArray(arr, key) {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        return arr.map((item) => item[key]).join(", ");
    }

    function formatFilters(dataFilters) {
        // Format the dates
        const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : null;
        const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : null;

        // Extracting and formatting the filters
        const agents = formatArray(dataFilters?.agents, "name");
        const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
        const regions = formatArray(dataFilters?.regions, "name");
        const routes = formatArray(dataFilters?.routes, "name");
        const statuses = formatArray(dataFilters?.statuses, "label");
        const orderBy = dataFilters?.orderBy?.label;
        const dataLimit = dataFilters?.dataLimit?.label;
        const dataLimitNumber = dataFilters?.dataLimitNumber;
        const viewInterval = dataFilters?.viewInterval?.label;
        const productCategories = formatArray(dataFilters?.productCategories, "name");
        const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
        const products = formatArray(dataFilters?.products, "name");

        // Constructing the sentence
        let sentence = "Filters: ";
        if (agents) sentence += `Distributors: ${agents}. `;
        if (salesAssociates) sentence += `Sales Associates: ${salesAssociates}. `;
        if (regions) sentence += `Regions: ${regions}. `;
        if (routes) sentence += `Routes: ${routes}. `;
        if (statuses) sentence += `Statuses: ${statuses}. `;
        if (orderBy) sentence += `Order By: ${orderBy}. `;
        if (dataLimit) sentence += `Data Limit: ${dataLimit}. `;
        if (dataLimitNumber) sentence += `Data Limit No: ${dataLimitNumber}. `;
        if (viewInterval) sentence += `View Interval: ${viewInterval}. `;
        if (productCategories) sentence += `Product Categories: ${productCategories}. `;
        if (productSubCategories) sentence += `Product Sub Categories: ${productSubCategories}. `;
        if (products) sentence += `Products: ${products}. `;
        if (startDate) sentence += `Start Date: ${startDate}. `;
        if (endDate) sentence += `End Date: ${endDate}.`;

        return sentence.trim();
    }

    let chartTitle = formatFilters(dataFilters);

    const defaultColors = Highcharts.getOptions().colors;
    const options = {
        chart: {
            type: "spline",
        },
        title: { text: `Regions Sales Performance <br/> ${chartTitle}` },
        xAxis: {
            categories: data.xAxisCategories,
            title: {
                text: "categories",
            },
        },
        yAxis: {
            title: {
                text: "Sales",
            },
            labels: {
                formatter: function () {
                    // Automatically format the label to show 'B' instead of 'G'
                    if (this.value >= 1000000000) {
                        return this.value / 1000000000 + "b"; // Format to billions
                    }
                    if (this.value >= 1000000) {
                        return this.value / 1000000 + "m"; // Format to billions
                    }
                    if (this.value >= 1000) {
                        return this.value / 1000 + "k"; // Format to billions
                    }
                    return this.value; // Return the original value for lower numbers
                },
            },
        },
        // \
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: data?.data?.flatMap((item, index) => [
            {
                name: `${item.name} - SPLY Sales`,
                data: item.sply_sales,
                visible: visibleSeries.SPLY,
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
                dashStyle: "Dot", // Use dotted line
                lineWidth: 2,
                // visible: data.data.length <= 1 ? true : false,
                color: defaultColors[index % defaultColors.length],
            },
            {
                name: `${item.name} - Actual Sales`,
                data: item.actual_sales,
                visible: visibleSeries.Actual,
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
                dashStyle: "Solid", // Use dotted line
                lineWidth: 2,
                color: defaultColors[index % defaultColors.length],
            },
            {
                name: `${item.name} - Target Sales`,
                data: item.target_sales,
                visible: visibleSeries.Target,
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
                },
                dashStyle: "Dash", // Use dotted line
                lineWidth: 2,
                // visible: data.data.length <= 1 ? true : false,
                color: defaultColors[index % defaultColors.length],
            },
        ]),
        legend: {
            align: "right", // Aligns the legend to the right
            verticalAlign: "middle", // Vertically centers the legend
            layout: "vertical", // Displays the legend items in a vertical layout
            maxHeight: 250, // Set a maximum height for the legend
            scrollable: true, // Make the legend scrollable
            navigation: {
                enabled: true, // Enables navigation arrows for the legend
            },
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom",
                        },
                    },
                },
            ],
        },
    };
    return options;
};

const RegionSalesPerformanceSplineChart = ({ measure = "sales_value" }) => {
    const [visibleSeries, setVisibleSeries] = useState({
        SPLY: true,
        Actual: true,
        Target: true,
    });

    //
    const toggleSeriesVisibility = (seriesType) => {
        setVisibleSeries((prev) => ({
            ...prev,
            [seriesType]: !prev[seriesType],
        }));
    };

    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            // { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        viewInterval: { id: 1, label: "Default", value: "default" },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    console.log("testing RegionSalesPerformanceSplineChart such that it doesnt go into infinite due to useEffect dependancy array : ", filtersFormInitialDataValues);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery = useQuery({
        disable: false,
        queryKey: ["selloutTxRegionPfmceActualVsSPLYVsTargetSplineChart", filters?.startDate, filters?.endDate, measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getRegionPfmceActualVsSPLYVsTargetSplineChart({ startDate: filters?.startDate, endDate: filters?.endDate, measure: measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.isError) {
            console.log("🚀 ~ useEffect ~ selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.isError:", selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.error);
            selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.error?.response?.data?.message
                ? toast.error(selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.error?.response?.data?.message)
                : !selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.isError]);

    console.log("data for NiceTwo selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.data?.data is:", selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.data?.data);

    const seriesData = selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.data?.data?.data;

    //=============== handle displaying filters on Chart ======================
    const dataFilters = selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.data?.data?.requestParams;

    // Format the dates
    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    return (
        <>
            {selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.isLoading ? (
                <>
                    <div className="col-12">
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={BrownFilesProcessingLottie} style={{ height: "300px" }} loop={true} autoplay={true} />
                                <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                            </div>
                        </div>
                        {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    </div>
                </>
            ) : selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div className="series-toggle-buttons" style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                            <Button
                                label="SPLY"
                                onClick={() => toggleSeriesVisibility("SPLY")}
                                style={{
                                    backgroundColor: visibleSeries.SPLY ? "#2caffe" : "grey",
                                    borderColor: visibleSeries.SPLY ? "#2caffe" : "grey",
                                }}
                            />
                            <Button
                                label="Actual"
                                onClick={() => toggleSeriesVisibility("Actual")}
                                style={{
                                    backgroundColor: visibleSeries.Actual ? "#544fc5" : "grey",
                                    borderColor: visibleSeries.Actual ? "#544fc5" : "grey",
                                }}
                            />
                            <Button
                                label="Target"
                                onClick={() => toggleSeriesVisibility("Target")}
                                style={{
                                    backgroundColor: visibleSeries.Target ? "#00e272" : "grey",
                                    borderColor: visibleSeries.Target ? "#00e272" : "grey",
                                }}
                            />
                        </div>

                        <div className="text-right">
                            <Tooltip title="Filter">
                                <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                            </Tooltip>

                            <FiltersFormDialogForLineGraphs
                                onSubmit={handleSubmitForFilters}
                                filtersFormInitialDataValues={filtersFormInitialDataValues}
                                setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                                showFiltersFormDialog={showFiltersFormDialog}
                                setShowFiltersFormDialog={setShowFiltersFormDialog}
                            />
                        </div>
                    </div>

                    <div style={{ height: "400px", overflowY: "auto" }}>
                        <HighchartsReact highcharts={Highcharts} options={getChartOptions(seriesData, selloutTxRegionPfmceActualVsSPLYVsTargetSplineChartQuery?.data?.data?.requestParams, visibleSeries)} immutable={true} />
                    </div>
                </>
            )}
        </>
    );
};

export default RegionSalesPerformanceSplineChart;
