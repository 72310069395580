import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

import React, { useState, useContext, useEffect } from "react";
import { DashboardFilterContext } from "../../../../context/DashboardFilterContext";
import { getActualPerformanceChart } from "../../../../services/dashboard/charts";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

//lotties
import MaterialUiLoaderLottie from "../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";
//
import FiltersFormDialog from "../../widgets/FiltersFormDialog";
import { Button } from "primereact/button";

import { Tooltip } from "@mui/material";

import noData from "highcharts/modules/no-data-to-display";
// Initialize the required Highcharts modules
noData(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

const API_URL = "http://localhost:8000/api/performance-data";

const ActualPerformanceChart = () => {
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        start_date: filters?.startDate,
        end_date: filters?.endDate,
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        statuses: [],
        order: null,
        dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            start_date: filters?.startDate,
            end_date: filters?.endDate,
            regions: filters?.regions,
            channels: filters?.channels,
            agents: filters?.agents,
            sales_associates: filters?.sales_associates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            measure: filters?.measure,
        });
    }, [filters]);

    // FiltersForm diaglog
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const performanceChartQuery = useQuery({
        disable: false,
        queryKey: ["performance-chart", filters?.startDate, filters?.endDate, filters?.measure, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getActualPerformanceChart({ start_date: filters?.startDate, end_date: filters?.endDate, measure: filters?.measure, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (performanceChartQuery?.isError) {
            console.log("🚀 ~ useEffect ~ performanceChartQuery?.isError:", performanceChartQuery?.error);
            performanceChartQuery?.error?.response?.data?.message ? toast.error(performanceChartQuery?.error?.response?.data?.message) : !performanceChartQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [performanceChartQuery?.isError]);

    console.log("data for performanceChartQuery?.data?.data is:", performanceChartQuery?.data?.data);

    if (performanceChartQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (performanceChartQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    const getChartOptions = () => ({
        chart: {
            type: "line",
            // height: 700,
        },
        title: {
            text: `Actual Performance from ${performanceChartQuery?.data?.data?.heading} vs SPLY`,
        },
        xAxis: {
            categories: performanceChartQuery?.data?.data?.data?.current.map((data) => data.month) || [],
        },
        yAxis: {
            title: {
                text: performanceChartQuery?.data?.data?.measureName,
            },
        },
        tooltip: {
            shared: true,
            valuePrefix: performanceChartQuery?.data?.data?.measureName === "Volume" ? "" : "UGX ",
            valueSuffix: "",
            headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
        },
        series: [
            {
                name: "Selected Period",
                data: performanceChartQuery?.data?.data?.data?.current.map((data) => data.salesValue) || [],
                color: "#28a745", // Green
            },
            {
                name: "SPLY",
                data: performanceChartQuery?.data?.data?.data?.spl.map((data) => data.salesValue) || [],
                color: "#ff7300", // Amber
            },
        ],
        exporting: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "16px",
                color: "#303030",
            },
            position: {
                align: "center",
                verticalAlign: "middle",
            },
            text: "No data to display",
        },
    });

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    // if (error) {
    //     return <div>Error: {error}</div>;
    // }

    return (
        <>
            <div className="col-12 text-right">
                {/* <Button icon="pi pi-filter" onClick={(e) => op.current.toggle(e)} />
                        <OverlayPanel ref={op}>
                            <FiltersForm />
                        </OverlayPanel> */}

                <Tooltip title="Filter">
                    <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                </Tooltip>
                <FiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} />
            </div>
            <HighchartsReact highcharts={Highcharts} options={getChartOptions()} />
        </>
    );
};

export default ActualPerformanceChart;
