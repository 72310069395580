import React, { createContext, useState, useContext } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }
        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    };

    const onToggleMenuWithPrimeSideBarMenuClick = () => {
        setMenuVisible((prevState) => !prevState);
    };

    return (
        <SidebarContext.Provider
            value={{
                mobileTopbarMenuActive,
                menuVisible,
                overlayMenuActive,
                mobileMenuActive,
                onWrapperClick,
                onMobileTopbarMenuClick,
                onMobileSubTopbarMenuClick,
                onToggleMenuWithPrimeSideBarMenuClick,
                setMenuVisible,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error("useSidebar must be used within a SidebarProvider");
    }
    return context;
};
