import React, { useState } from "react";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";

import Cards from "./old-charts/cards/Cards";
import SellOutTopProductsPage from "./old-charts/sell-out-top-products/SellOutTopProductsPage";
import SellInTopProductsPage from "./old-charts/sell-in-top-products/SellInTopProductsPage";

import ActualPerformanceChart from "./old-charts/actual-performance-chart/ActualPerformanceChart";
import ChannelPerformanceChart from "./old-charts/channel-performance-chart/ChannelPerformanceChart";
import ProductCategoryPerformanceChart from "./old-charts/product-category-performance-chart/ProductCategoryPerformanceChart";
import RegionPerformanceChart from "./old-charts/region-performance-chart/RegionPerformanceChart";

//
import MomTrendChart from "./old-charts/Mom-trend-chart/MomTrendChart";
import MomTrendActualVsBudgetChart from "./old-charts/Mom-trend-actual-vs-budget-chart/MomTrendActualVsBudgetChart";
import MomRegionChart from "./old-charts/Mom-region-chart/MomRegionChart";
import MomProductCategoryChart from "./old-charts/Mom-product-catergory-chart/MomProductCategoryChart";

//
import ProductQuantitySellInVsSellOutTrendChart from "./old-charts/product-quantity-sell-in-vs-sell-out-trend-chart/ProductQuantitySellInVsSellOutTrendChart";
import ProductSalesSellInVsSellOutTrendChart from "./old-charts/product-sales-sell-in-vs-sell-out-trend-chart/ProductSalesSellInVsSellOutTrendChart";

//
import ProductSalesDrilldownLollipopChart from "./old-charts/rankings-bar-graphs/ProductSalesDrilldownLollipopChart";
import EmployeeSalesChart from "./old-charts/rankings-bar-graphs/EmployeeSalesChart";
import CustomerSalesChart from "./old-charts/rankings-bar-graphs/CustomerSalesChart";
import TopRegionsPage from "./old-charts/sell-out-top-products/TopRegionsPage";
import TopAgentsPage from "./old-charts/sell-out-top-products/TopAgentsPage";
import SalesCardChart from "./old-charts/cards/SalesCardChart";
import ProductSalesPerfomanceLineGraph from "./old-charts/product-category-performance-chart/ProductSalesPerfomanceLineGraph";
import ProductDrillTargetChart from "./drilldowns/ProductsPerformanceDrillWithLollipopCombinedChart";
import RegionDrillTargetChart from "./drilldowns/RegionsPerformanceDrillWithLollipopCombinedChart";
import AgentDrillTargetChart from "./drilldowns/AgentsPerformanceDrillWithLollipopCombinedChart";
//
import ToggleComparisonActualPerfomanceSplineCharts from "./new-charts/comparison-actual-perfomance-spline-charts/ToggleComparisonActualPerfomanceSplineCharts";
import ToggleNewCards from "./new-cards/ToggleNewCards";

// Testing Dummy charts
import DummyAgentsPerformanceDrillWithLollipopCombinedChart from "./drilldowns/dummy/DummyAgentsPerformanceDrillWithLollipopCombinedChart";

function DashbaordPage() {
    const [selectedChart, setSelectedChart] = useState("products");
    const [selectedRank, setSelectedRank] = useState("products");
    const [selectedTrend, setSelectedTrend] = useState("dummy");
    const [selectedbar, setSelectedbar] = useState("regions");

    const chartOptions = [
        { label: "Products Performance", value: "products" },
        { label: "Product Performance", value: "employee" },
        { label: "Region Performance", value: "customer" },
    ];

    const rankOptions = [
        { label: "Top Regions", value: "regions" },
        { label: "Top Sales Associates", value: "salesAssociates" },
        { label: "Top Distributors", value: "agents" },
        { label: "Top Products", value: "products" },
    ];

    const trendOptions = [
        { label: "Products Performance", value: "dummy" },
        { label: "Actual Performance", value: "actual" },
        { label: "Product Category Performance", value: "productCat" },
        { label: "Channel Performance", value: "channel" },
        { label: "Regional Performance", value: "region" },
    ];

    const barOptions = [
        { label: "Regional Performance", value: "regions" },
        { label: "Distributors Performance", value: "agents" },
        { label: "Products Performance", value: "products" },

        // { label: "Channel Performance", value: "channel" },
        // { label: "Regional Performance", value: "region" },
    ];

    const handleChartChange = (e) => {
        setSelectedChart(e.value);
    };

    const handleRankChange = (e) => {
        setSelectedRank(e.value);
    };
    const handleTrendChange = (e) => {
        setSelectedTrend(e.value);
    };
    const handleBarChange = (e) => {
        setSelectedbar(e.value);
    };
    return (
        <div style={{ display: "flex", flexWrap: "wrap", padding: "0.5rem" }}>
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}></div>
            <ToggleNewCards />
            {/* <DummyAgentsPerformanceDrillWithLollipopCombinedChart /> */}

            {/* ================================= old charts are below ===================================== */}
            {/* <Panel header="Statistics Cards" toggleable style={{ minWidth: "100%" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    //<Cards />
                    //<SalesCardChart />
                    <ToggleNewCards />
                </div>
            </Panel> */}
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Performance Trend vs Same Period Last Year (SPLY)" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <div style={{ position: "relative", top: "60px", left: "10px", zIndex: "1000", marginTop: "-60px", width: "fit-content" }}>
                                <Dropdown value={selectedbar} options={barOptions} onChange={handleBarChange} placeholder="Choose..." style={{ marginBottom: "20px" }} />
                            </div>
                            {selectedbar === "agents" && <AgentDrillTargetChart />}
                            {selectedbar === "products" && <ProductDrillTargetChart />}
                            {selectedbar === "regions" && <RegionDrillTargetChart />}
                           // {selectedbar === "actual" && <MomTrendChart />}
                           // {selectedbar === "productCat" && <MomProductCategoryChart />}
                           // {selectedbar === "channel" && <MomTrendActualVsBudgetChart />}
                          //  {selectedbar === "region" && <MomRegionChart />}
                        </div>
                        <div className="col-12 xl:col-6">
                            <ToggleComparisonActualPerfomanceSplineCharts />
                        </div>
                    </div>
                </Panel>
            </div> */}
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Product Category Perfomance" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <ProductCategoryPerformanceChart />
                        </div>
                        <div className="col-12 xl:col-6">
                            <MomProductCategoryChart />
                        </div>
                    </div>
                </Panel>
            </div>
            <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Channel Perfomance" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <ChannelPerformanceChart />
                        </div>
                        <div className="col-12 xl:col-6">
                            <MomTrendActualVsBudgetChart />
                        </div>
                    </div>
                </Panel>
            </div> */}
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Regional Perfomance" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <RegionPerformanceChart />
                        </div>
                        <div className="col-12 xl:col-6">
                            <MomRegionChart />
                        </div>
                    </div>
                </Panel>
            </div> */}
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Product Quantity Vs Product Sales Bar Chart" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <ProductQuantitySellInVsSellOutTrendChart />
                        </div>
                        <div className="col-12 xl:col-6">
                            <ProductSalesSellInVsSellOutTrendChart />
                        </div>
                    </div>
                </Panel>
            </div> */}
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Performance Drill-down and Top Rankings" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-6">
                            <div style={{ position: "relative", top: "60px", left: "10px", zIndex: "1000", marginTop: "-60px", width: "fit-content" }}>
                                <Dropdown value={selectedChart} options={chartOptions} onChange={handleChartChange} placeholder="Choose" style={{ marginBottom: "20px" }} />
                            </div>
                            {selectedChart === "products" && <ProductSalesDrilldownLollipopChart />}
                            {selectedChart === "employee" && <EmployeeSalesChart />}
                            {selectedChart === "customer" && <CustomerSalesChart />}
                        </div>
                        <div className="col-12 xl:col-6">
                            <div style={{ position: "relative", top: "60px", left: "10px", zIndex: "1000", marginTop: "-60px", width: "fit-content" }}>
                                <Dropdown value={selectedRank} options={rankOptions} onChange={handleRankChange} placeholder="Select what to rank" style={{ marginBottom: "20px" }} />
                            </div>
                            {selectedRank === "products" && <SellInTopProductsPage />}
                            {selectedRank === "regions" && <TopRegionsPage />}
                            {selectedRank === "salesAssociates" && <SellOutTopProductsPage />}
                            {selectedRank === "agents" && <TopAgentsPage />}
                        </div>
                    </div>
                </Panel>
            </div> */}
            {/* <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                <Panel header="Top Rankings" toggleable style={{ minWidth: "100%" }}>
                    <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-12 xl:col-12">
                            <SellOutTopProductsPage />
                        </div>
                        <div className="col-12 xl:col-12">
                            <SellInTopProductsPage />
                        </div>
                    </div>
                </Panel>
            </div> */}
        </div>
    );
}

export default DashbaordPage;
