import React, { useEffect, useContext, useState, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField, Autocomplete, Grid, Stack, InputLabel, CircularProgress, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { getAllNiceTwoAgents, getAllNiceTwoSalesAssociates, getAllNiceTwoRegions, getAllNiceTwoRoutes, getAllNiceTwoProductCategories, getAllNiceTwoProductSubCategories, getAllNiceTwoProducts } from "../../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { NiceTwoDBFilterContext } from "../../../../../context/NiceTwoDBFilterContext";

const CardsFiltersTotalCustomersFormDialog = ({ onSubmit, filtersFormInitialDataValues, setFiltersFormInitialDataValues, showFiltersFormDialog, setShowFiltersFormDialog }) => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showResetConfirmDialog, setShowResetConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    // State for selected regions and routes
    const [selectedRegions, setSelectedRegions] = useState(filtersFormInitialDataValues?.regions);
    const [selectedRoutes, setSelectedRoutes] = useState(filtersFormInitialDataValues?.routes);

    const [selectedSalesAssociates, setSelectedSalesAssociates] = useState(filtersFormInitialDataValues?.salesAssociates);

    const memoizedFiltersFormInitialDataValues = useMemo(() => filtersFormInitialDataValues, [filtersFormInitialDataValues]);
    useEffect(() => {
        setSelectedRegions(filtersFormInitialDataValues?.regions);
        setSelectedRoutes(filtersFormInitialDataValues?.routes);
        // setSelectedSalesAssociates(filtersFormInitialDataValues?.salesAssociates);
    }, [memoizedFiltersFormInitialDataValues]);

    // const initialValues = {
    //     startDate: null,
    //     endDate: null,
    //     agent: [],
    //     salesAssociates: [],
    //     regions: [],
    //     routes: [],
    //     statuses: [],
    //     orderBy: null,
    //     productCategories: [],
    //     productSubCategories: [],
    //     products: [],
    // };

    // toast handler
    const toastError = (error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
        error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
    };

    // Queries and useEffects
    // Agents Query
    const agentsQuery = useQuery({
        queryKey: ["agents", selectedRegions, selectedSalesAssociates],
        queryFn: () => getAllNiceTwoAgents({ regions: selectedRegions, salesAssociates: selectedSalesAssociates }),
    });

    useEffect(() => {
        if (agentsQuery?.isError) {
            toastError(agentsQuery?.error);
        }
    }, [agentsQuery?.isError]);

    // // Sales Associates Query
    // const salesAssociatesQuery = useQuery({
    //     queryKey: ["salesAssociates", selectedRegions],
    //     queryFn: () => getAllNiceTwoSalesAssociates({ regions: selectedRegions }),
    // });

    // useEffect(() => {
    //     if (salesAssociatesQuery?.isError) {
    //         toastError(salesAssociatesQuery?.error);
    //     }
    // }, [salesAssociatesQuery?.isError]);

    // Regions Query
    const regionsQuery = useQuery({
        queryKey: ["regions"],
        queryFn: () => getAllNiceTwoRegions({}),
    });

    useEffect(() => {
        if (regionsQuery?.isError) {
            toastError(regionsQuery?.error);
        }
    }, [regionsQuery?.isError]);

    // Routes Query (depends on selected region)
    const routesQuery = useQuery({
        queryKey: ["routes", selectedRegions],
        queryFn: () => getAllNiceTwoRoutes({ regions: selectedRegions }),
        enabled: selectedRegions.length > 0,
    });

    useEffect(() => {
        if (routesQuery?.isError) {
            toastError(routesQuery?.error);
        }
    }, [routesQuery?.isError]);

    //hndle form submit
    const handleFormSubmit = (values) => {
        // setPendingData(values);
        // setShowConfirmDialog(true);

        onSubmit(values);
        setShowFiltersFormDialog(false);
    };

    const handleConfirm = () => {
        if (pendingData) {
            onSubmit(pendingData);
            setPendingData(null);
            setShowFiltersFormDialog(false);
        }
        setShowConfirmDialog(false);
    };

    const handleCancel = () => {
        setShowConfirmDialog(false);
    };

    //

    const statusOptions = [
        { id: 1, label: "Pending", value: "PENDING" },
        { id: 2, label: "Closed", value: "CLOSED" },
        { id: 3, label: "Canceled", value: "CANCELED" },
    ];

    //
    const viewIntervalOptions = [
        { id: 1, label: "Default", value: "default" },
        { id: 2, label: "Days", value: "days" },
        { id: 3, label: "Weeks", value: "weeks" },
        { id: 4, label: "Months", value: "months" },
        { id: 5, label: "Years", value: "years" },
    ];

    //-=========== handle reset ===============
    const handleReset = (resetForm) => {
        // setShowResetConfirmDialog(true);
        setSelectedRegions([]);
        setSelectedRoutes([]);
        setSelectedSalesAssociates([]);
        resetForm();
        setFiltersFormInitialDataValues({
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            startDate: null,
            endDate: null,
            agents: [],
            // salesAssociates: [],
            regions: [],
            routes: [],
        });
        // setShowResetConfirmDialog(false);
    };

    const handleConfirmReset = (resetForm) => {
        setSelectedRegions([]);
        setSelectedRoutes([]);
        setSelectedSalesAssociates([]);
        resetForm();
        setFiltersFormInitialDataValues({
            // startDate: filters?.startDate,
            // endDate: filters?.endDate,
            startDate: null,
            endDate: null,
            agents: [],
            // salesAssociates: [],
            regions: [],
            routes: [],
        });
        setShowResetConfirmDialog(false);
    };

    const handleCancelReset = () => {
        setShowResetConfirmDialog(false);
    };

    // Validation schema
    // const validationSchema = Yup.object()
    //     .shape({
    //         startDate: Yup.date().required(),
    //         endDate: Yup.date()
    //             .nullable()
    //             .when("startDate", (startDate, schema) => (startDate ? schema.required("End date is required when start date is chosen") : schema))
    //             .test("is-after-start-date", "End date cannot be before start date", function (value) {
    //                 const { startDate } = this.parent;
    //                 return !startDate || !value || new Date(startDate) <= new Date(value);
    //             }),
    //         agents: Yup.array().required("Agents is a required field"),
    //         salesAssociates: Yup.array(),
    //         regions: Yup.array(),
    //         routes: Yup.array(),
    //         statuses: Yup.array(),
    //         order: Yup.string().nullable(),
    //         productCategories: Yup.array(),
    //         productSubCategories: Yup.array(),
    //         products: Yup.array(),
    //     })
    //     .test("at-least-one-field", "At least one field must be filled", function (value) {
    //         const { agents, salesAssociates, regions, routes, statuses, order, productCategories, productSubCategories, products, startDate, endDate } = value;
    //         return (
    //             (agents && agents.length > 0) ||
    //             (salesAssociates && salesAssociates.length > 0) ||
    //             (regions && regions.length > 0) ||
    //             (routes && routes.length > 0) ||
    //             (statuses && statuses.length > 0) ||
    //             order ||
    //             (productCategories && productCategories.length > 0) ||
    //             (productSubCategories && productSubCategories.length > 0) ||
    //             (products && products.length > 0) ||
    //             startDate ||
    //             endDate
    //         );
    //     });
    // Custom validation function
    const validate = (values) => {
        const errors = {};

        // Date validation
        if (values.startDate && !values.endDate) {
            errors.endDate = "End date is required when start date is chosen";
        }
        if (values.startDate && values.endDate && new Date(values.startDate) > new Date(values.endDate)) {
            errors.endDate = "End date cannot be before start date";
        }

        // if (values.startDate) errors.agents = "start date  is required";

        // if (!Array.isArray(values.agents) || values.agents.length === 0) errors.agents = "Agent is required";
        // Ensure at least one field is filled and check if fields are arrays
        if (
            (!Array.isArray(values.agents) || values.agents.length === 0) &&
            // (!Array.isArray(values.salesAssociates) || values.salesAssociates.length === 0) &&
            (!Array.isArray(values.regions) || values.regions.length === 0) &&
            (!Array.isArray(values.routes) || values.routes.length === 0) &&
            (!Array.isArray(values.statuses) || values.statuses.length === 0) &&
            !values.startDate &&
            !values.endDate
        ) {
            errors.form = "At least one field must be filled";
        }

        return errors;
    };

    return (
        <Dialog
            header="Filters"
            visible={showFiltersFormDialog}
            maximizable
            footer={() => (
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={() => setShowFiltersFormDialog(false)} className="p-button-text" />
                    {/* <Button label="Submit" icon="pi pi-check" onClick={handleSubmit} /> */}
                </div>
            )}
            onHide={() => setShowFiltersFormDialog(false)}
            style={{ width: "60vw" }}
        >
            <Formik
                initialValues={filtersFormInitialDataValues}
                onSubmit={handleFormSubmit}
                // validationSchema={validationSchema}
                validate={validate}
                // validateOnMount
                // initialTouched={{
                //     startDate: true,
                //     endDate: true,
                //     agent: true,
                //     salesAssociates: true,
                //     regions: true,
                //     routes: true,
                //     statuses: true,
                //     orderBy: true,
                //     dataLimit: true,
                //     productCategories: true,
                //     productSubCategories: true,
                //     products: true,
                // }}
            >
                {({ values, setFieldValue, resetForm, errors, touched, isSubmitting }) => (
                    <Form>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="startDate">Start Date</InputLabel>
                                        <Field name="startDate">
                                            {({ field }) => (
                                                <DatePicker
                                                    label="Start Date"
                                                    format="DD/MM/YYYY"
                                                    views={["year", "month", "day"]}
                                                    value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                    onChange={(value) => setFieldValue("startDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            error={Boolean(touched.startDate && errors.startDate)}
                                                            // helperText={touched.startDate && errors.startDate ? errors.startDate : ""}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="startDate" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="endDate">End Date</InputLabel>
                                        <Field name="endDate">
                                            {({ field }) => (
                                                <DatePicker
                                                    label="End Date"
                                                    format="DD/MM/YYYY"
                                                    views={["year", "month", "day"]}
                                                    value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                    onChange={(value) => setFieldValue("endDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            error={Boolean(touched.endDate && errors.endDate)}
                                                            // helperText={touched.endDate && errors.endDate ? errors.endDate : ""}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="endDate" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </LocalizationProvider>

                            {/* Multi-select fields */}

                            <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="regions">Regions</InputLabel>
                                    <Field name="regions">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={regionsQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedRegions}
                                                onChange={(event, newValue) => {
                                                    setSelectedRegions(newValue);
                                                    setSelectedRoutes([]);
                                                    setFieldValue("regions", newValue);
                                                    setFieldValue("routes", []);
                                                    // setSelectedSalesAssociates([]);
                                                    // setFieldValue("salesAssociates", []);
                                                    setFieldValue("agents", []);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select regions"
                                                        error={Boolean(touched.regions && errors.regions)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="regions" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="routes">Routes</InputLabel>
                                    <Field name="routes">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={routesQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedRoutes}
                                                onChange={(event, newValue) => {
                                                    setSelectedRoutes(newValue);
                                                    setFieldValue("routes", newValue);
                                                }}
                                                disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || routesQuery?.isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select routes"
                                                        error={Boolean(touched.routes && errors.routes)}
                                                        disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || routesQuery?.isLoading}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {routesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="routes" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid>

                            {/* <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="salesAssociates">Sales Associates</InputLabel>
                                    <Field name="salesAssociates">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.user_id === value?.user_id}
                                                multiple
                                                options={salesAssociatesQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                // value={field.value}
                                                value={selectedSalesAssociates}
                                                // onChange={(event, newValue) => setFieldValue("salesAssociates", newValue)}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("salesAssociates", newValue);
                                                    setSelectedSalesAssociates(newValue);
                                                    setFieldValue("agents", []);
                                                }}
                                                disabled={regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select sales associates"
                                                        error={Boolean(touched.salesAssociates && errors.salesAssociates)}
                                                        disabled={regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {salesAssociatesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="salesAssociates" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid> */}

                            <Grid item xs={12} md={6} lg={3}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="agents">Distributors</InputLabel>
                                    <Field name="agents">
                                        {({ field }) => (
                                            <Autocomplete
                                                //isOptionEqualToValue helps to define how comparison is gonna be made
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                multiple
                                                options={agentsQuery?.data?.data?.data || []}
                                                getOptionLabel={(option) => option.name}
                                                value={field.value}
                                                onChange={(event, newValue) => setFieldValue("agents", newValue)}
                                                disabled={regionsQuery?.isLoading || agentsQuery?.isLoading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select Distributors"
                                                        error={Boolean(touched.agents && errors.agents)}
                                                        // helperText={touched.agents && errors.agents ? errors.agents : ""}
                                                        disabled={regionsQuery?.isLoading || agentsQuery?.isLoading}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {agentsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="agents" component="div" style={{ color: "red" }} />
                                </Stack>
                            </Grid>

                            {/* Multi-select fields */}

                            <Grid item xs={12} md={12} lg={12}>
                                <center>
                                    {" "}
                                    <Button type="submit" label="Filter" className="p-button-primary m-2" />
                                    <Button type="button" onClick={() => handleReset(resetForm)} label="Reset" className="p-button-secondary m-2" />
                                </center>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <center>{errors.form && <div style={{ color: "red" }}>{errors.form}</div>}</center>
                            </Grid>
                        </Grid>
                        <Dialog
                            visible={showConfirmDialog}
                            header="Confirmation"
                            modal
                            onHide={handleCancel}
                            footer={
                                <>
                                    <Button label="Yes" onClick={handleConfirm} />
                                    <Button label="No" onClick={handleCancel} />
                                </>
                            }
                        >
                            Are you sure you want to submit the form?
                        </Dialog>

                        <Dialog
                            header="Confirm Reset"
                            visible={showResetConfirmDialog}
                            onHide={handleCancelReset}
                            modal
                            footer={
                                <>
                                    <Button label="Yes" onClick={() => handleConfirmReset(resetForm)} color="primary" autoFocus />
                                    <Button label="No" onClick={handleCancelReset} color="primary" />
                                </>
                            }
                        >
                            Are you sure you want to reset the form? All changes will be lost.
                        </Dialog>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CardsFiltersTotalCustomersFormDialog;
