import axiosAPI from "../axiosApi";

export async function getDailyRevenueData(params = {}) {
    const response = await axiosAPI.get("getDailyRevenueData", { params: params });
    return response;
}

export async function getMonthlyRevenueData(params = {}) {
    const response = await axiosAPI.get("getMonthlyRevenueData", { params: params });
    return response;
}

export async function getWeeklyRevenueData(params = {}) {
    const response = await axiosAPI.get("getWeeklyRevenueData", { params: params });
    return response;
}
