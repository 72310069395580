import React, { useState, useContext, useMemo, useEffect, useRef } from "react";

//
import { getSelloutHeatMap } from "../../services/nice-two-endpoints/nice-two-db-comparison-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

import { Accordion, AccordionTab } from "primereact/accordion";

//lotties
import MaterialUiLoaderLottie from "../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
import BrownFilesProcessingLottie from "../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import NoData from "../../assets/lotties/nice-hop/nodata.json";
import SnailErrorLottie from "../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";
import { Panel } from "primereact/panel";
import FiltersFormDialogNoSortAndOrderBy from "./widgets/FiltersFormDialogNoSortAndOrderBy";
import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";
//
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsHeatmap from "highcharts/modules/heatmap";

import DoubleScrollbar from "react-double-scrollbar";

const formatNumber = (num) => {
    if (isNaN(num)) {
        return num;
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getChartOptions = (sampleData, dataFilters, windowWidth, windowHeight, filtersFormInitialDataValues) => {
    if (!sampleData || !Array.isArray(sampleData) || !Array.isArray(sampleData)) {
        return null;
    }

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";
    // Format data for chart series
    // Extract days from the first item's sales data
    const days = sampleData[0].sales.map((sale) => sale.date);
    const dates = sampleData[0].sales.map((sale) => sale.fullDate);

    const combinedList = days.map((day, index) => {
        console.log("looped day", day);
        return day === "1st" ? dates[index] : day;
    });
    console.log("datessdasdL", sampleData);

    const heatmapData = sampleData.flatMap((item, index) => item.sales.map((sale, dayIndex) => [dayIndex, index, sale.value]));

    const options = {
        chart: {
            type: "heatmap",
            plotBorderWidth: 1,
            height: windowHeight - 50 > 50 * sampleData.length ? windowHeight - 50 : 50 * sampleData.length,
            width: windowWidth > 100 * days.length ? windowWidth : 100 * days.length,
            scrollablePlotArea: {
                minWidth: 600, // Set the minimum width before scrolling is enabled
            },
        },
        title: {
            text: `Regions Sales Vs Targets Heatmap From ${moment(filtersFormInitialDataValues.startDate).format("Do MMM YYYY")} to ${moment(filtersFormInitialDataValues.endDate).format("Do MMM YYYY")} <br/>`,
        },
        xAxis: [
            {
                categories: [dates[0], ...combinedList.slice(1)],
                labels: {
                    style: {
                        fontSize: "12px",
                        // fontWeight: "bold",
                    },
                },
            },
            {
                categories: [dates[0], ...combinedList.slice(1)],
                opposite: true, // Display x-axis at the top
                linkedTo: 0, // Link this xAxis to the first one
                labels: {
                    style: {
                        fontSize: "12px",
                        // fontWeight: "bold",
                    },
                },
            },
        ],
        yAxis: {
            categories: sampleData.map((item) => `${item.label}`),
            categories: sampleData.map((item) => `${item.label}`),
            title: null,
            reversed: true,
        },
        colorAxis: {
            stops: [
                [0, "#D22B2B"], // Even Lighter Red
                [0.89999999999, "#D22B2B"], // Even Lighter Red
                [0.9, "orange"], // Amber
                [0.97, "orange"], // Amber
                [1, "#2ECC40"], // Green
            ],
            min: 0,
            max: 100,
            max: 100,
        },
        legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            verticalAlign: "top",
            y: 25,
            symbolHeight: 280,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter: function () {
                const item = sampleData[this.point.y];
                const day = days[this.point.x];
                const saleForDay = item.sales.find((sale) => sale.date === day);
                const formattedValue = saleForDay ? saleForDay.value.toLocaleString(undefined, { maximumFractionDigits: 0, useGrouping: true }) : "0";
                return `<b>Date:</b> ${saleForDay.day}, ${saleForDay.fullDate}<br><b>Region:</b> ${item.label}<br><b>Sales:</b> UGX ${formatNumber(Math.round(saleForDay.totalSales))}<br><b>Target:</b> UGX ${formatNumber(Math.round(saleForDay.totalTargetOverall))}`;
            },
        },
        plotOptions: {
            heatmap: {
                dataLabels: {
                    enabled: true,
                    color: "#000000",
                    format: "{point.value:,.0f}", // Format for the data labels
                },
            },
        },

        series: [
            {
                name: "Sales per day",
                borderWidth: 1,
                data: heatmapData,
                dataLabels: {
                    enabled: true,
                    color: "#FFFFFF",
                    format: "{point.value:.0f}%", // Remove decimal places and add percentage sign
                    style: {
                        fontSize: "14px",
                        textOutline: "1px contrast", // Add a text outline for better visibility
                        // textShadow: "0px 0px 3px black", // Increase the font size of the entries
                    },
                },
                // rowsize: 10, // Set the size of each row
                // colsize: 10, // Set the size of each column
            },
        ],
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        "printChart", // Include print option
                        // "separator", // Add a separator if needed
                        // "downloadPNG", // Include download PNG option
                        // "downloadJPEG", // Include download JPEG option
                        // "downloadPDF", // Include download PDF option
                        // "downloadSVG", // Include download SVG option
                    ],
                },
            },
        },
    };

    return options;
};

const SellOutHeatMapChart = () => {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);
    const chartRef = useRef();

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth - 100);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 350);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    console.log("testing SellOutHeatMapChart such that it doesnt go into infinite due to useEffect dependancy array : ", filtersFormInitialDataValues);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const SelloutHeatMapQuery = useQuery({
        disable: false,
        queryKey: ["sellout-heat-map", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSelloutHeatMap({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SelloutHeatMapQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SelloutHeatMapQuery?.isError:", SelloutHeatMapQuery?.error);
            SelloutHeatMapQuery?.error?.response?.data?.message ? toast.error(SelloutHeatMapQuery?.error?.response?.data?.message) : !SelloutHeatMapQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SelloutHeatMapQuery?.isError]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth - 100);
            setWindowHeight(window.innerHeight - 350);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    console.log("data for NiceTwo SelloutHeatMapQuery?.data?.data is:", SelloutHeatMapQuery?.data?.data);

    const seriesData = SelloutHeatMapQuery?.data?.data?.data;

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            chart.series[0].setData(
                seriesData.flatMap((item, index) => item.sales.map((sale, dayIndex) => [dayIndex, index, sale.value])),
                false
            ); // Update data
            chart.redraw(); // Redraw chart
        }
    }, [seriesData]);

    var divWidth = 500;
    if (!SelloutHeatMapQuery?.isLoading && !SelloutHeatMapQuery?.isError) {
        divWidth = 100 * seriesData[0].sales.map((sale) => sale.date).length;
    }

    //=============== handle displaying filters on Chart ======================
    const dataFilters = SelloutHeatMapQuery?.data?.data?.requestParams;

    // Format the dates
    const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";

    // Function to ensure array and join names
    const formatArray = (array, key) => {
        return Array.isArray(array) && array.length > 0 ? array.map((item) => item[key]).join(", ") : "N/S";
    };

    // Extracting and formatting the filters
    const agents = formatArray(dataFilters?.agents, "name");
    const salesAssociates = formatArray(dataFilters?.salesAssociates, "name");
    const regions = formatArray(dataFilters?.regions, "name");
    const routes = formatArray(dataFilters?.routes, "name");
    const statuses = formatArray(dataFilters?.statuses, "label");
    const orderBy = dataFilters?.orderBy?.label || "N/S";
    const dataLimit = dataFilters?.dataLimit?.label || "N/S";
    const productCategories = formatArray(dataFilters?.productCategories, "name");
    const productSubCategories = formatArray(dataFilters?.productSubCategories, "name");
    const products = formatArray(dataFilters?.products, "name");

    //=============== handle displaying filters on Chart end ======================

    return (
        <>
            {SelloutHeatMapQuery?.isLoading ? (
                <>
                    <div className="col-12">
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={BrownFilesProcessingLottie} style={{ height: "300px" }} loop={true} autoplay={true} />
                                <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                            </div>
                        </div>
                        {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    </div>
                </>
            ) : SelloutHeatMapQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : seriesData.length === 0 ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>
                        <FiltersFormDialogNoSortAndOrderBy
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                        <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                            <Lottie animationData={NoData} loop={false} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="col-12 text-right">
                        <Tooltip title="Filter">
                            <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                        </Tooltip>

                        <FiltersFormDialogNoSortAndOrderBy
                            onSubmit={handleSubmitForFilters}
                            filtersFormInitialDataValues={filtersFormInitialDataValues}
                            setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                            showFiltersFormDialog={showFiltersFormDialog}
                            setShowFiltersFormDialog={setShowFiltersFormDialog}
                        />
                    </div>
                    {/* <br />

                    <Accordion>
                        <AccordionTab header="Selected Filters" closed>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "0.5rem" }}>
                                <p>
                                    <strong>Start Date:</strong> {startDate}
                                </p>
                                <p>
                                    <strong>End Date:</strong> {endDate}
                                </p>
                                <p>
                                    <strong>Agents:</strong> {agents}
                                </p>
                                <p>
                                    <strong>Sales Associates:</strong> {salesAssociates}
                                </p>
                                <p>
                                    <strong>Regions:</strong> {regions}
                                </p>
                                <p>
                                    <strong>Routes:</strong> {routes}
                                </p>
                                <p>
                                    <strong>Statuses:</strong> {statuses}
                                </p>
                                <p>
                                    <strong>Order By:</strong> {orderBy}
                                </p>
                                <p>
                                    <strong>Data Limit:</strong> {dataLimit}
                                </p>
                                <p>
                                    <strong>Product Categories:</strong> {productCategories}
                                </p>
                                <p>
                                    <strong>Product Sub Categories:</strong> {productSubCategories}
                                </p>
                                <p>
                                    <strong>Products:</strong> {products}
                                </p>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <br /> */}

                    <div className="pb-5">
                        {/* <div style={{ display: "flex", height: "600px", overflow: "auto" }}> */}
                        {/* <div style={{ flex: 1, minWidth: "50%" }}>
                    <DataTable value={sampleData} scrollable scrollHeight="100%">
                        <Column field="region" header="Region" />
                        <Column field="agent" header="Agent" />
                        <Column field="territory" header="Territory" />
                        <Column field="nhopSalesLead" header="NHOP Sales Lead" />
                    </DataTable>
                </div> */}
                        {/* <div style={{ width: "100%", overflowX: "auto", height: `${windowHeight}px`, overflowY: "auto" }}> */}
                        <div style={{ width: "100%", overflowX: "auto", height: "500px", paddingTop: "2rem", paddingBottom: "2rem", overflowY: "auto" }}>
                            <DoubleScrollbar>
                                <div style={{ width: `${windowWidth > divWidth ? windowWidth : divWidth}px` }}>
                                    <HighchartsReact highcharts={Highcharts} options={getChartOptions(seriesData, dataFilters, windowWidth, windowHeight, filtersFormInitialDataValues)} ref={chartRef} />
                                </div>
                            </DoubleScrollbar>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SellOutHeatMapChart;
