import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

import { Button } from "primereact/button";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

// Sample hierarchical data
// const sampleData = {
//     regions: [
//         {
//             id: "region1",
//             name: "North America",
//             sales: 150000,
//             associates: [
//                 {
//                     id: "associate1-1",
//                     name: "Associate 1",
//                     sales: 80000,
//                     agents: [
//                         {
//                             id: "agent1-1-1",
//                             name: "Agent 1",
//                             sales: 40000,
//                             routes: [
//                                 {
//                                     id: "route1-1-1-1",
//                                     name: "Route 1",
//                                     sales: 20000,
//                                     customers: [
//                                         {
//                                             id: "customer1-1-1-1-1",
//                                             name: "Customer 1",
//                                             sales: 10000,
//                                             categories: [
//                                                 {
//                                                     id: "category1",
//                                                     name: "Bottles",
//                                                     sales: 5000,
//                                                     subCategories: [
//                                                         {
//                                                             id: "subcategory1-1",
//                                                             name: "Water Bottles",
//                                                             sales: 3000,
//                                                             products: [
//                                                                 { name: "Bottle A", sales: 1500 },
//                                                                 { name: "Bottle B", sales: 1000 },
//                                                                 { name: "Bottle C", sales: 500 },
//                                                             ],
//                                                         },
//                                                         {
//                                                             id: "subcategory1-2",
//                                                             name: "Juice Bottles",
//                                                             sales: 2000,
//                                                             products: [
//                                                                 { name: "Bottle D", sales: 1200 },
//                                                                 { name: "Bottle E", sales: 800 },
//                                                             ],
//                                                         },
//                                                     ],
//                                                 },
//                                             ],
//                                         },
//                                     ],
//                                 },
//                             ],
//                         },
//                     ],
//                 },
//             ],
//         },
//     ],
// };
const sampleData = {
    regions: [
        {
            id: "region1",
            name: "North",
            sales: 5000000,
            associates: [
                {
                    id: "associate1-1",
                    name: "Allan Niwabiine",
                    sales: 2000000,
                    agents: [
                        {
                            id: "agent1-1-1",
                            name: "Ronam international Ltd CBD",
                            sales: 1000000,
                            routes: [
                                {
                                    id: "route1-1-1-1",
                                    name: "Bunyaruguru Bushenyi",
                                    sales: 500000,
                                    customers: [
                                        {
                                            id: "customer1-1-1-1-1",
                                            name: "Customer 1",
                                            sales: 250000,
                                            product_categories: [
                                                {
                                                    id: "category1",
                                                    categoryName: "WRITING INSTRUMENTS",
                                                    sales: 125000,
                                                    product_sub_categories: [
                                                        {
                                                            id: "subcategory1-1",
                                                            subCategoryName: "Pens",
                                                            sales: 75000,
                                                            products: [
                                                                { name: "Pen A", sales: 40000 },
                                                                { name: "Pen B", sales: 25000 },
                                                                { name: "Pen C", sales: 10000 },
                                                            ],
                                                        },
                                                        {
                                                            id: "subcategory1-2",
                                                            subCategoryName: "Pencils",
                                                            sales: 50000,
                                                            products: [
                                                                { name: "Pencil A", sales: 30000 },
                                                                { name: "Pencil B", sales: 20000 },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "associate1-2",
                    name: "SHARON DEBORAH NAMUKWAYA",
                    sales: 1500000,
                    agents: [
                        {
                            id: "agent1-2-1",
                            name: "Mutegyeki",
                            sales: 750000,
                            routes: [
                                {
                                    id: "route1-2-1-1",
                                    name: "Wanyange Bugembe Kakira",
                                    sales: 375000,
                                    customers: [
                                        {
                                            id: "customer1-2-1-1-1",
                                            name: "Customer 2",
                                            sales: 187500,
                                            product_categories: [
                                                {
                                                    id: "category2",
                                                    categoryName: "SATO",
                                                    sales: 93750,
                                                    product_sub_categories: [
                                                        {
                                                            id: "subcategory2-1",
                                                            subCategoryName: "Toilet Seats",
                                                            sales: 56250,
                                                            products: [
                                                                { name: "Seat A", sales: 30000 },
                                                                { name: "Seat B", sales: 26250 },
                                                            ],
                                                        },
                                                        {
                                                            id: "subcategory2-2",
                                                            subCategoryName: "Tissue Holders",
                                                            sales: 37500,
                                                            products: [
                                                                { name: "Holder A", sales: 20000 },
                                                                { name: "Holder B", sales: 17500 },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: "region2",
            name: "Region Strikers",
            sales: 3000000,
            associates: [
                {
                    id: "associate2-1",
                    name: "Associate 2",
                    sales: 1800000,
                    agents: [
                        {
                            id: "agent2-1-1",
                            name: "Agent 2",
                            sales: 900000,
                            routes: [
                                {
                                    id: "route2-1-1-1",
                                    name: "Route 2",
                                    sales: 450000,
                                    customers: [
                                        {
                                            id: "customer2-1-1-1-1",
                                            name: "Customer 3",
                                            sales: 225000,
                                            product_categories: [
                                                {
                                                    id: "category3",
                                                    categoryName: "Bottles",
                                                    sales: 112500,
                                                    product_sub_categories: [
                                                        {
                                                            id: "subcategory3-1",
                                                            subCategoryName: "Water Bottles",
                                                            sales: 67500,
                                                            products: [
                                                                { name: "Bottle A", sales: 37500 },
                                                                { name: "Bottle B", sales: 30000 },
                                                            ],
                                                        },
                                                        {
                                                            id: "subcategory3-2",
                                                            subCategoryName: "Juice Bottles",
                                                            sales: 45000,
                                                            products: [
                                                                { name: "Bottle C", sales: 25000 },
                                                                { name: "Bottle D", sales: 20000 },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

const generateDrilldownData = (data) => {
    const drilldownSeries = [];
    const regionsData = data.regions.map((region) => {
        const regionId = region.id;

        // Add associates drilldown
        drilldownSeries.push({
            id: regionId,
            name: `${region.name} Associates`,
            data: region.associates.map((associate) => ({
                name: associate.name,
                y: associate.sales,
                drilldown: `${regionId}-${associate.name}`, // Ensure unique drilldown ID
            })),
        });

        region.associates.forEach((associate) => {
            const associateId = `${regionId}-${associate.name}`;

            // Add agents drilldown
            drilldownSeries.push({
                id: associateId,
                name: `${associate.name} Agents`,
                data: associate.agents.map((agent) => ({
                    name: agent.name,
                    y: agent.sales,
                    drilldown: `${associateId}-${agent.name}`, // Ensure unique drilldown ID
                })),
            });

            associate.agents.forEach((agent) => {
                const agentId = `${associateId}-${agent.name}`;

                // Add routes drilldown
                drilldownSeries.push({
                    id: agentId,
                    name: `${agent.name} Routes`,
                    data: agent.routes.map((route) => ({
                        name: route.name,
                        y: route.sales,
                        drilldown: `${agentId}-${route.name}`, // Ensure unique drilldown ID
                    })),
                });

                agent.routes.forEach((route) => {
                    const routeId = `${agentId}-${route.name}`;

                    // Add customers drilldown
                    drilldownSeries.push({
                        id: routeId,
                        name: `${route.name} Customers`,
                        data: route.customers.map((customer) => ({
                            name: customer.name,
                            y: customer.sales,
                            drilldown: `${routeId}-${customer.name}`, // Ensure unique drilldown ID
                        })),
                    });

                    route.customers.forEach((customer) => {
                        const customerId = `${routeId}-${customer.name}`;

                        // Add product categories drilldown
                        drilldownSeries.push({
                            id: customerId,
                            name: `${customer.name} Product Categories`,
                            data: customer.product_categories.map((category) => ({
                                name: category.categoryName,
                                y: category.sales,
                                drilldown: `${customerId}-${category.categoryName}`, // Ensure unique drilldown ID
                            })),
                        });

                        customer.product_categories.forEach((category) => {
                            const categoryId = `${customerId}-${category.categoryName}`;

                            // Add product sub-categories drilldown
                            drilldownSeries.push({
                                id: categoryId,
                                name: `${category.categoryName} Sub-categories`,
                                data: category.product_sub_categories.map((subCategory) => ({
                                    name: subCategory.subCategoryName,
                                    y: subCategory.sales,
                                    drilldown: `${categoryId}-${subCategory.subCategoryName}`, // Ensure unique drilldown ID
                                })),
                            });

                            category.product_sub_categories.forEach((subCategory) => {
                                const subCategoryId = `${categoryId}-${subCategory.subCategoryName}`;

                                // Add products drilldown
                                drilldownSeries.push({
                                    id: subCategoryId,
                                    name: `${subCategory.subCategoryName} Products`,
                                    data: subCategory.products.map((product) => [product.name, product.sales]),
                                });
                            });
                        });
                    });
                });
            });
        });

        return {
            name: region.name,
            y: region.sales,
            drilldown: regionId,
        };
    });

    const options = {
        chart: {
            type: "bar", // Change this to "bar"
        },
        title: {
            text: "Sales Drilldown by Region, Associate, Agent, Route, Customer, and Product",
        },
        subtitle: {
            text: "Click the columns to view deeper levels.",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Total Sales (UGX)",
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y:,.1f}",
                },
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.2f} UGX</b><br/>',
        },
        series: [
            {
                name: "Regions",
                colorByPoint: true,
                data: regionsData,
            },
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            series: drilldownSeries,
        },
        credits: {
            enabled: false,
        },
    };

    return { regionsData, drilldownSeries, options };
};

const { regionsData, drilldownSeries, options } = generateDrilldownData(sampleData);

console.log("🚀 ~ generateDrilldownData ~  drilldownSeries:", drilldownSeries);
console.log("🚀 ~ generateDrilldownData ~ regionsData:", regionsData);

const SelloutTransactionsDrilldownChart = () => {
    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default SelloutTransactionsDrilldownChart;
