import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DashboardFilterContext } from "./context/DashboardFilterContext";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { TextField, Grid, Stack, CircularProgress, InputLabel, FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getAllChannels, getRegions, getSalesAssociates, getAgents, getProductCategories, getProductSubcategories, getProducts } from "./services/dashboard/filters";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Fieldset } from "primereact/fieldset";
import { toast } from "react-toastify";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";

import { Avatar } from "primereact/avatar";

// Service Imports
// import { getAllProductCategories } from "./services/product-categories/product-categories-service";

// import { getAllProductSubCategories } from "./services/product-subcategories/product-subcategories-service";

// import { getAllProducts } from "./services/products/products-service";

// import { getAllAgents } from "./services/agents/agents-service";

// import { getAllChannels } from "./services/channels/channels-service";

// import { getAllCustomers } from "./services/customers/customers-service";

// import { getAllRegions } from "./services/regions/regions-service";

// import { getAllRoutes } from "./services/routes/routes-service";

// const measures = [{"Revenue":"sales_value"}, {"Volume":"quantity"}, {"Gross Profit":"gross_profit"}];

const validationSchema = Yup.object().shape({
    dateRange: Yup.date().nullable(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    month: Yup.string().nullable(),
    week: Yup.string().nullable(),
    financialYear: Yup.object().nullable(),
    measure: Yup.string().nullable(),
    region: Yup.object().nullable(),
    channel: Yup.object().nullable(),
    productCategory: Yup.object().nullable(),
    itemSubcategory: Yup.object().nullable(),
    product: Yup.object().nullable(),
    salesEmployee: Yup.object().nullable(),
    agent: Yup.object().nullable(),
    customer: Yup.object().nullable(),
    paymentTerms: Yup.object().nullable(),
});

// const FinancialYearSelector = ({ years, selectedYears, setSelectedYears }) => {
//     const toggleYear = (year) => {
//         if (selectedYears.includes(year)) {
//             setSelectedYears(selectedYears.filter((y) => y !== year));
//         } else {
//             setSelectedYears([...selectedYears, year]);
//         }
//     };

//     return (
//         <div>
//             {years.map((year) => (
//                 <div key={year}>
//                     <input
//                         type="checkbox"
//                         onChange={() => toggleYear(year)}
//                         checked={selectedYears.includes(year)} // Hardcoded to true
//                     />
//                     {/* <Checkbox onChange={() => toggleYear(year)} checked={selectedYears.includes(year)} /> */}
//                     <label>{year}</label>
//                 </div>
//             ))}
//         </div>
//     );
// };

// const MonthWeekSelector = ({ months, selectedMonths, setSelectedMonths }) => {
//     const toggleMonth = (month) => {
//         if (selectedMonths.includes(month)) {
//             setSelectedMonths(selectedMonths.filter((m) => m !== month));
//         } else {
//             setSelectedMonths([...selectedMonths, month]);
//         }
//     };

//     return (
//         <div>
//             {months.map((month) => (
//                 <div key={month}>
//                     <input
//                         type="checkbox"
//                         onChange={() => toggleMonth(month)}
//                         checked={selectedMonths.includes(month)} // Hardcoded to true
//                     />
//                     {/* <Checkbox checked={selectedMonths.includes(month)} onChange={() => toggleMonth(month)} /> */}
//                     <label>{month}</label>
//                 </div>
//             ))}
//         </div>
//     );
// };

// const DaySelector = ({ days, selectedDays, setSelectedDays }) => {
//     const toggleDay = (day) => {
//         if (selectedDays.includes(day)) {
//             setSelectedDays(selectedDays.filter((d) => d !== day));
//         } else {
//             setSelectedDays([...selectedDays, day]);
//         }
//     };

//     return (
//         <div>
//             {days.map((day) => (
//                 <div key={day}>
//                     <input
//                         type="checkbox"
//                         checked={selectedDays.includes(day)}
//                         onChange={() => toggleDay(day)} // Hardcoded to true
//                     />
//                     {/* <Checkbox checked={selectedDays.includes(day)} onChange={() => toggleDay(day)} /> */}
//                     <label>{day}</label>
//                 </div>
//             ))}
//         </div>
//     );
// };

function findDateInRange(startDate, endDate, day) {
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");

    let current = start.clone();
    // console.log("datess 1", current.date());
    while (current.isSameOrBefore(end)) {
        console.log("datess 2", `${current.format("YYYY-MM-DD")}`, day, current.date());
        if (String(current.date()) === String(day)) {
            return `${current.format("YYYY-MM-DD")}`;
        }
        current.add(1, "days");
    }

    return null; // If no matching date is found
}

const DashboardFilterSidebar = ({ visible, onHide }) => {
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    console.log("🚀 ~ DashboardFilterSidebar ~ filters:", filters);
    //===================initioalise queries =========================

    //financial year filters
    const [selectedYears, setSelectedYears] = useState(null);
    const [selectedMonths, setSelectedMonths] = useState(null);
    const [selectedWeeks, setSelectedWeeks] = useState(null);
    const [selectedDays, setSelectedDays] = useState(null);
    const [selectWeek, setSelectWeek] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [startDateLast, setStartDateLast] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateLast, setEndDateLast] = useState(null);
    const [days, setDays] = useState([]);

    const financialYears = [];
    for (let year = 2020; year <= 2100; year++) {
        financialYears.push({ id: year, name: `${year}-${year + 1}` });
    } // Replace with your financial years
    const months = [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "Jun" },
        { id: 7, name: "Jul" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sep" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" },
    ];
    const calendarWeeks = [
        { id: 1, name: "Week 1" },
        { id: 2, name: "Week 2" },
        { id: 3, name: "Week 3" },
        { id: 4, name: "Week 4" },
        { id: 5, name: "Week 5" },
        { id: 6, name: "Week 6" },
        { id: 7, name: "Week 7" },
        { id: 8, name: "Week 8" },
        { id: 9, name: "Week 9" },
        { id: 10, name: "Week 10" },
        { id: 11, name: "Week 11" },
        { id: 12, name: "Week 12" },
        { id: 13, name: "Week 13" },
        { id: 14, name: "Week 14" },
        { id: 15, name: "Week 15" },
        { id: 16, name: "Week 16" },
        { id: 17, name: "Week 17" },
        { id: 18, name: "Week 18" },
        { id: 19, name: "Week 19" },
        { id: 20, name: "Week 20" },
        { id: 21, name: "Week 21" },
        { id: 22, name: "Week 22" },
        { id: 23, name: "Week 23" },
        { id: 24, name: "Week 24" },
        { id: 25, name: "Week 25" },
        { id: 26, name: "Week 26" },
        { id: 27, name: "Week 27" },
        { id: 28, name: "Week 28" },
        { id: 29, name: "Week 29" },
        { id: 30, name: "Week 30" },
        { id: 31, name: "Week 31" },
        { id: 32, name: "Week 32" },
        { id: 33, name: "Week 33" },
        { id: 34, name: "Week 34" },
        { id: 35, name: "Week 35" },
        { id: 36, name: "Week 36" },
        { id: 37, name: "Week 37" },
        { id: 38, name: "Week 38" },
        { id: 39, name: "Week 39" },
        { id: 40, name: "Week 40" },
        { id: 41, name: "Week 41" },
        { id: 42, name: "Week 42" },
        { id: 43, name: "Week 43" },
        { id: 44, name: "Week 44" },
        { id: 45, name: "Week 45" },
        { id: 46, name: "Week 46" },
        { id: 47, name: "Week 47" },
        { id: 48, name: "Week 48" },
        { id: 49, name: "Week 49" },
        { id: 50, name: "Week 50" },
        { id: 51, name: "Week 51" },
        { id: 52, name: "Week 52" },
    ]; // Replace with actual months or weeks
    // Replace with actual days

    //product
    const [selectedProductCategories, setSelectedProductCategories] = useState(filters?.productCategories);
    const [selectedProductSubCategories, setSelectedProductSubCategories] = useState(filters?.productSubCategories);

    // State for selected regions and channels
    const [selectedSalesAssociates, setSelectedSalesAssociates] = useState(filters?.sales_associates);
    const [selectedRegions, setSelectedRegions] = useState(filters?.regions);
    const [selectedChannels, setSelectedChannels] = useState(filters?.channels);

    // toast handler
    const toastError = (error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
        error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
    };

    // Queries and useEffects
    // Agents Query
    const agentsQuery = useQuery({
        queryKey: ["sellin-agents", selectedSalesAssociates],
        queryFn: () => getAgents({ sales_associates: selectedSalesAssociates }),
        enabled: selectedSalesAssociates.length > 0,
    });

    useEffect(() => {
        if (agentsQuery?.isError) {
            toastError(agentsQuery?.error);
        }
    }, [agentsQuery?.isError]);

    // Sales Associates Query
    const salesAssociatesQuery = useQuery({
        queryKey: ["sellin-sales_associates", selectedRegions],
        queryFn: () => getSalesAssociates({ regions: selectedRegions }),
        enabled: selectedRegions.length > 0,
    });

    useEffect(() => {
        if (salesAssociatesQuery?.isError) {
            toastError(salesAssociatesQuery?.error);
        }
    }, [salesAssociatesQuery?.isError]);

    // Regions Query (depends on selected channel)
    const regionsQuery = useQuery({
        queryKey: ["sellin-regions", selectedChannels],
        queryFn: () => getRegions({ channels: selectedChannels }),
        enabled: selectedChannels.length > 0,
    });

    useEffect(() => {
        if (regionsQuery?.isError) {
            toastError(regionsQuery?.error);
        }
    }, [regionsQuery?.isError]);

    // Channels Query
    const channelsQuery = useQuery({
        queryKey: ["sellin-channels"],
        queryFn: () => getAllChannels({}),
    });

    useEffect(() => {
        if (channelsQuery?.isError) {
            toastError(channelsQuery?.error);
        }
    }, [channelsQuery?.isError]);

    // Product Categories Query
    const productCategoriesQuery = useQuery({
        queryKey: ["sellin-productCategories"],
        queryFn: () => getProductCategories({}),
    });

    useEffect(() => {
        if (productCategoriesQuery?.isError) {
            toastError(productCategoriesQuery?.error);
        }
    }, [productCategoriesQuery?.isError]);

    // Product Subcategories Query (depends on selected product category)
    const productSubCategoriesQuery = useQuery({
        queryKey: ["sellin-productSubCategories", selectedProductCategories],
        queryFn: () => getProductSubcategories({ productCategories: selectedProductCategories }),
        enabled: selectedProductCategories.length > 0,
    });

    console.log("🚀 ~ FiltersFormDialog ~ productSubCategoriesQuery:", productSubCategoriesQuery);

    useEffect(() => {
        if (productSubCategoriesQuery?.isError) {
            toastError(productSubCategoriesQuery?.error);
        }
    }, [productSubCategoriesQuery?.isError]);

    // Products Query (depends on selected product subcategory)
    const productsQuery = useQuery({
        queryKey: ["sellin-products", selectedProductSubCategories],
        queryFn: () => getProducts({ productSubcategories: selectedProductSubCategories }),
        enabled: selectedProductSubCategories.length > 0,
    });

    useEffect(() => {
        if (productsQuery?.isError) {
            toastError(productsQuery?.error);
        }
    }, [productsQuery?.isError]);

    const measures = [
        { measure: "sales_value", name: "Revenue" },
        { measure: "quantity", name: "Volume" },
        { measure: "gross_profit", name: "Gross Profit" },
    ];

    // console.log("data for salesAssociatesQuery?.data?.data is:", salesAssociatesQuery?.data?.data);

    //===================== initialise quaeries =====================

    const handleSubmit = (values) => {
        console.log("sdasdfegr", values);
        setFilters(values);
        onHide();
    };

    const handleResetFilters = (e, resetForm, initialValues, setValues, values) => {
        e.preventDefault();
        // setValues(initialValues);
        setSelectedProductSubCategories([]);
        setSelectedProductCategories([]);
        setSelectedSalesAssociates([]);
        setSelectedRegions([]);
        setSelectedChannels([]);
        resetForm();
        setValues({
            startDate: moment().startOf("year").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            // endDate: moment().endOf("year").format("YYYY-MM-DD"),
            agents: [],
            sales_associates: [],
            regions: [],
            channels: [],
            measure: "sales_value",
            productCategories: [],
            productSubCategories: [],
            products: [],
        });

        resetFilters();

        // reset form has a problem it automatically triggers initil values
        // so its better we use set values and we reset formik values our seleves
        // resetForm();
        // onHide();
    };

    const customHeader = (
        <div className="flex align-items-center gap-2">
            <img src={"assets/layout/images/nice-removebg-preview.png"} alt="logo" style={{ height: "50px" }} />
            {/* <Avatar image="assets/layout/images/nice-removebg-preview.png" shape="circle" /> */}
            {/* <span className="font-bold">NHOP</span> */}
        </div>
    );
    return (
        <Sidebar
            visible={visible}
            onHide={onHide}
            header={customHeader}
            //  className="w-full md:w-30rem lg:w-30rem"
            style={{ zIndex: "1000" }}
        >
            <center>
                <Button
                    label="Filters"
                    icon="pi pi-filter"
                    className="p-button-text"
                    style={{ color: "#0000FF" }} // Blue color
                />
            </center>
            <Formik initialValues={filters} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ values, setFieldValue, resetForm, initialValues, setValues, errors, touched, isSubmitting }) => (
                    <Form>
                        {/* <Fieldset legend="Date Range" className="mt-2 mb-2" toggleable>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <div className="p-field m-4">
                                    <Field name="startDate">{({ field }) => <DatePicker label="Start Date" value={field.value} onChange={(value) => setFieldValue("startDate", value ? moment(value).format("YYYY-MM-DD") : null)} renderInput={(params) => <TextField {...params} fullWidth />} />}</Field>
                                </div>
                                <div className="p-field m-4">
                                    <Field name="endDate">{({ field }) => <DatePicker label="End Date" value={field.value} onChange={(value) => setFieldValue("endDate", value ? moment(value).format("YYYY-MM-DD") : null)} renderInput={(params) => <TextField {...params} fullWidth />} />}</Field>
                                </div>
                            </LocalizationProvider>
                        </Fieldset> */}

                        {/* <Fieldset legend="Date Range" className="mt-2 mb-2" toggleable> */}
                        <Accordion
                        // activeIndex={0}
                        >
                            <AccordionTab header="Date Range">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <div className="p-field m-4">
                                        <Field name="startDate">
                                            {({ field }) => (
                                                <DatePicker
                                                    label="Start Date"
                                                    format="DD/MM/YYYY"
                                                    views={["year", "month", "day"]}
                                                    value={startDate ? moment(startDate, "YYYY-MM-DD") : field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                    onChange={(value) => {
                                                        setFieldValue("startDate", value ? moment(value).format("YYYY-MM-DD") : null);
                                                        setSelectedYears(null);
                                                        setSelectedMonths(null);
                                                        setSelectedWeeks(null);
                                                        setSelectedDays(null);
                                                        setSelectWeek(false);
                                                        setStartDate(null);
                                                        setStartDateLast(null);
                                                        setEndDate(null);
                                                        setEndDateLast(null);
                                                        setDays([]);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="p-field m-4">
                                        <Field name="endDate">
                                            {({ field }) => (
                                                <DatePicker
                                                    label="End Date"
                                                    format="DD/MM/YYYY"
                                                    views={["year", "month", "day"]}
                                                    value={endDate ? moment(endDate, "YYYY-MM-DD") : field.value ? moment(field.value, "YYYY-MM-DD") : null}
                                                    onChange={(value) => {
                                                        setFieldValue("endDate", value ? moment(value).format("YYYY-MM-DD") : null);
                                                        setSelectedYears(null);
                                                        setSelectedMonths(null);
                                                        setSelectedWeeks(null);
                                                        setSelectedDays(null);
                                                        setSelectWeek(false);
                                                        setStartDate(null);
                                                        setStartDateLast(null);
                                                        setEndDate(null);
                                                        setEndDateLast(null);
                                                        setDays([]);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </LocalizationProvider>
                            </AccordionTab>

                            <AccordionTab header="Date Selector">
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="financial_years">Financial Year</InputLabel>
                                        <Field name="financial_years">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    // multiple
                                                    options={financialYears}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedYears}
                                                    onChange={(event, newValue) => {
                                                        setSelectedYears(newValue);
                                                        setSelectedMonths(null);
                                                        setSelectedWeeks(null);
                                                        setSelectedDays(null);
                                                        if (newValue !== null) {
                                                            setStartDate(`${newValue.id}-07-01`);
                                                            setStartDateLast(`${newValue.id}-07-01`);
                                                            setEndDate(`${newValue.id + 1}-06-30`);
                                                            setEndDateLast(`${newValue.id + 1}-06-30`);

                                                            setFieldValue("startDate", moment(`${newValue.id}-07-01`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${newValue.id + 1}-06-30`).format("YYYY-MM-DD"));
                                                        } else {
                                                            setStartDate(null);
                                                            setEndDate(null);
                                                            setFieldValue("startDate", moment().startOf("year").format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment().format("YYYY-MM-DD"));
                                                        }

                                                        // setFieldValue("channels", newValue);
                                                        // setFieldValue("regions", []);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    // disabled={channelsQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Financial Year"
                                                            // error={Boolean(touched.channels && errors.channels)}
                                                            // disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {channelsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="financial_years" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="months">Months</InputLabel>
                                        <Field name="months">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    // multiple
                                                    options={months}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedMonths}
                                                    onChange={(event, newValue) => {
                                                        setSelectedMonths(newValue);
                                                        setSelectedWeeks(null);
                                                        setSelectedDays(null);
                                                        setSelectWeek(false);
                                                        if (newValue !== null) {
                                                            setStartDate(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-01`);
                                                            setStartDateLast(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-01`);
                                                            setEndDate(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-${moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth()}`);
                                                            setEndDateLast(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-${moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth()}`);

                                                            setFieldValue("startDate", moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-01`).format("YYYY-MM-DD"));
                                                            setFieldValue(
                                                                "endDate",
                                                                moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}-${moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth()}`).format("YYYY-MM-DD")
                                                            );

                                                            const newDays = [];
                                                            console.log("daasss", moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM"));
                                                            for (let day = 1; day <= moment(`${newValue.id > 6 ? selectedYears.id : selectedYears.id + 1}-${newValue.id}`, "YYYY-MM").daysInMonth(); day++) {
                                                                newDays.push(`${day}`);
                                                            }
                                                            setDays(newDays);
                                                        } else {
                                                            setStartDate(`${selectedYears.id}-07-01`);
                                                            setEndDate(`${selectedYears.id + 1}-06-30`);
                                                            setFieldValue("startDate", moment(`${selectedYears.id}-07-01`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${selectedYears.id + 1}-06-30`).format("YYYY-MM-DD"));
                                                        }

                                                        // setFieldValue("regions", newValue);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    disabled={selectedYears === null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Months"
                                                            // error={Boolean(touched.regions && errors.regions)}
                                                            disabled={selectedYears === null}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="months" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="weeks">Weeks</InputLabel>
                                        <Field name="weeks">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    // multiple
                                                    options={calendarWeeks}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedWeeks}
                                                    onChange={(event, newValue) => {
                                                        setSelectedWeeks(newValue);
                                                        setSelectedMonths(null);
                                                        setSelectedDays(null);
                                                        setSelectWeek(true);
                                                        if (newValue !== null) {
                                                            let weekYear = selectedYears.id;
                                                            // console.log(moment().year(selectedYears.id).week(newValue.id).startOf("week").format("YYYY-MM-DD"));
                                                            if (moment().year(selectedYears.id).week(newValue.id).startOf("week").format("YYYY-MM-DD") > moment(`${selectedYears.id}-06-30`, "YYYY-MM-DD").format("YYYY-MM-DD")) {
                                                                weekYear = selectedYears.id + 1;
                                                            }
                                                            setStartDate(`${moment().year(weekYear).week(newValue.id).startOf("week").format("YYYY-MM-DD")}`);
                                                            setStartDateLast(`${moment().year(weekYear).week(newValue.id).startOf("week").format("YYYY-MM-DD")}`);
                                                            setEndDate(`${moment().year(weekYear).week(newValue.id).endOf("week").format("YYYY-MM-DD")}`);
                                                            setEndDateLast(`${moment().year(weekYear).week(newValue.id).endOf("week").format("YYYY-MM-DD")}`);

                                                            setFieldValue("startDate", moment(`${moment().year(weekYear).week(newValue.id).startOf("week").format("YYYY-MM-DD")}`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${moment().year(weekYear).week(newValue.id).endOf("week").format("YYYY-MM-DD")}`).format("YYYY-MM-DD"));
                                                            const newDays = [];
                                                            for (let i = 0; i < 7; i++) {
                                                                newDays.push(moment().year(weekYear).week(newValue.id).startOf("week").add(i, "days").format("D"));
                                                            }
                                                            setDays(newDays);
                                                        } else {
                                                            setStartDate(`${selectedYears.id}-07-01`);
                                                            setEndDate(`${selectedYears.id + 1}-06-30`);
                                                            setFieldValue("startDate", moment(`${selectedYears.id}-07-01`).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(`${selectedYears.id + 1}-06-30`).format("YYYY-MM-DD"));
                                                        }
                                                        // setFieldValue("regions", newValue);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    disabled={selectedYears === null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Week"
                                                            // error={Boolean(touched.regions && errors.regions)}
                                                            disabled={selectedYears === null}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="weeks" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="days">Days</InputLabel>
                                        <Field name="days">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    // multiple
                                                    options={days}
                                                    getOptionLabel={(option) => option}
                                                    value={selectedDays}
                                                    onChange={(event, newValue) => {
                                                        setSelectedDays(newValue);
                                                        if (newValue !== null) {
                                                            // console.log("datess 3", findDateInRange(startDateLast, endDateLast, newValue));
                                                            if (selectWeek) {
                                                                setStartDate(findDateInRange(startDateLast, endDateLast, newValue));
                                                                setEndDate(findDateInRange(startDateLast, endDateLast, newValue));
                                                                setFieldValue("startDate", moment(findDateInRange(startDateLast, endDateLast, newValue)).format("YYYY-MM-DD"));
                                                                setFieldValue("endDate", moment(findDateInRange(startDateLast, endDateLast, newValue)).format("YYYY-MM-DD"));
                                                            } else {
                                                                setStartDate(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}}`);
                                                                setEndDate(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}}`);

                                                                console.log("sdadfegwgq", moment(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}`).format("YYYY-MM-DD"));
                                                                setFieldValue("startDate", moment(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}`).format("YYYY-MM-DD"));
                                                                setFieldValue("endDate", moment(`${moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}-${newValue}`).format("YYYY-MM-DD"));
                                                            }
                                                        } else {
                                                            setStartDate(startDateLast);
                                                            setEndDate(endDateLast);
                                                            setFieldValue("startDate", moment(startDateLast).format("YYYY-MM-DD"));
                                                            setFieldValue("endDate", moment(endDateLast).format("YYYY-MM-DD"));
                                                        }
                                                        // setFieldValue("regions", newValue);
                                                        // setFieldValue("sales_associates", []);
                                                        // setFieldValue("agents", []);
                                                    }}
                                                    disabled={selectedYears === null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Day"
                                                            // error={Boolean(touched.regions && errors.regions)}
                                                            disabled={selectedYears === null}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // endAdornment: (
                                                                //     <>
                                                                //         {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                //         {params.InputProps.endAdornment}
                                                                //     </>
                                                                // ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="days" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </AccordionTab>

                            {/* </Fieldset> */}

                            {/* <AccordionTab header="Date"> */}

                            {/* </AccordionTab> */}

                            <AccordionTab header="Geo">
                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="channels">Channel</InputLabel>
                                        <Field name="channels">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={channelsQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedChannels}
                                                    onChange={(event, newValue) => {
                                                        setSelectedChannels(newValue);
                                                        setSelectedRegions([]);
                                                        setSelectedSalesAssociates([]);
                                                        setFieldValue("channels", newValue);
                                                        setFieldValue("regions", []);
                                                        setFieldValue("sales_associates", []);
                                                        setFieldValue("agents", []);
                                                    }}
                                                    // disabled={channelsQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select channels"
                                                            error={Boolean(touched.channels && errors.channels)}
                                                            // disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {channelsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="channels" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="regions">Region</InputLabel>
                                        <Field name="regions">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={regionsQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedRegions}
                                                    onChange={(event, newValue) => {
                                                        setSelectedRegions(newValue);
                                                        setSelectedSalesAssociates([]);
                                                        setFieldValue("regions", newValue);
                                                        setFieldValue("sales_associates", []);
                                                        setFieldValue("agents", []);
                                                    }}
                                                    disabled={!Array.isArray(selectedChannels) || (Array.isArray(selectedChannels) && selectedChannels.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select regions"
                                                            error={Boolean(touched.regions && errors.regions)}
                                                            disabled={!Array.isArray(selectedChannels) || (Array.isArray(selectedChannels) && selectedChannels.length === 0) || regionsQuery?.isLoading || channelsQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="regions" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="sales_associates">Sales Associates</InputLabel>
                                        <Field name="sales_associates">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={salesAssociatesQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={field.value}
                                                    onChange={(event, newValue) => {
                                                        setSelectedSalesAssociates(newValue);
                                                        setFieldValue("sales_associates", newValue);
                                                        setFieldValue("agents", []);
                                                    }}
                                                    disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select sales associates"
                                                            error={Boolean(touched.sales_associates && errors.sales_associates)}
                                                            disabled={!Array.isArray(selectedRegions) || (Array.isArray(selectedRegions) && selectedRegions.length === 0) || regionsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {salesAssociatesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="sales_associates" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="agents">Business Partners</InputLabel>
                                        <Field name="agents">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={agentsQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={field.value}
                                                    onChange={(event, newValue) => {
                                                        setFieldValue("agents", newValue);
                                                    }}
                                                    disabled={!Array.isArray(selectedSalesAssociates) || (Array.isArray(selectedSalesAssociates) && selectedSalesAssociates.length === 0) || agentsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select Business Partners"
                                                            error={Boolean(touched.agents && errors.agents)}
                                                            // helperText={touched.agents && errors.agents ? errors.agents : ""}
                                                            disabled={!Array.isArray(selectedSalesAssociates) || (Array.isArray(selectedSalesAssociates) && selectedSalesAssociates.length === 0) || agentsQuery?.isLoading || salesAssociatesQuery?.isLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {agentsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="agents" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </AccordionTab>

                            <AccordionTab header="Products">
                                <Grid item xs={12} md={6} lg={4}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="productCategories">Product Category</InputLabel>
                                        <Field name="productCategories">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={productCategoriesQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedProductCategories}
                                                    onChange={(event, newValue) => {
                                                        setSelectedProductCategories(newValue);
                                                        setSelectedProductSubCategories([]);
                                                        setFieldValue("productCategories", newValue);
                                                        setFieldValue("productSubCategories", []);
                                                        setFieldValue("products", []);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select product categories"
                                                            error={Boolean(touched.productCategories && errors.productCategories)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {productCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="productCategories" component="div" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="productSubCategories">Product Sub-Category</InputLabel>
                                        <Field name="productSubCategories">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={productSubCategoriesQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={selectedProductSubCategories}
                                                    onChange={(event, newValue) => {
                                                        setSelectedProductSubCategories(newValue);
                                                        setFieldValue("productSubCategories", newValue);
                                                        setFieldValue("products", []);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select product sub-categories"
                                                            error={Boolean(touched.productSubCategories && errors.productSubCategories)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {productSubCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="productSubCategories" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="products">Product</InputLabel>
                                        <Field name="products">
                                            {({ field }) => (
                                                <Autocomplete
                                                    //isOptionEqualToValue helps to define how comparison is gonna be made
                                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                    multiple
                                                    options={productsQuery?.data?.data?.data || []}
                                                    getOptionLabel={(option) => option.name}
                                                    value={field.value}
                                                    onChange={(event, newValue) => setFieldValue("products", newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Select products"
                                                            error={Boolean(touched.products && errors.products)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {productsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage name="products" component="div" style={{ color: "red" }} />
                                    </Stack>
                                </Grid>
                            </AccordionTab>

                            <AccordionTab header="Others">
                                {/* <div className="p-field m-4">
                                {" "}
                                <Field name="month">
                                    {({ field }) => <Autocomplete options={moment.months()} getOptionLabel={(option) => option} value={field.value} onChange={(event, newValue) => setFieldValue("month", newValue)} renderInput={(params) => <TextField {...params} label="Month" fullWidth />} />}
                                </Field>
                            </div> */}
                                {/* <div className="p-field m-4">
                                {" "}
                                <Field name="week">
                                    {({ field }) => (
                                        <Autocomplete
                                            options={Array.from({ length: 52 }, (_, i) => `Week ${i + 1}`)}
                                            getOptionLabel={(option) => option}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("week", newValue)}
                                            renderInput={(params) => <TextField {...params} label="Week" fullWidth />}
                                        />
                                    )}
                                </Field>
                            </div> */}
                                {/* <div className="p-field m-4">
                                <Field name="financialYear">
                                    {({ field }) => (
                                        <Autocomplete options={financialYears} getOptionLabel={(option) => option.name} value={field.value} onChange={(event, newValue) => setFieldValue("financialYear", newValue)} renderInput={(params) => <TextField {...params} label="Financial Year" fullWidth />} />
                                    )}
                                </Field>
                            </div> */}
                                <div className="p-field m-4">
                                    <Field name="measure">
                                        {({ field, meta, form }) => (
                                            <>
                                                <Autocomplete
                                                    options={measures}
                                                    getOptionLabel={(option) => option.name}
                                                    value={measures.find((option) => option.measure === field.value) || null}
                                                    onChange={(event, newValue) => form.setFieldValue("measure", newValue ? newValue.measure : null)}
                                                    renderInput={(params) => <TextField {...params} label="Measure" fullWidth error={meta.touched && !!meta.error} helperText={meta.touched && meta.error} />}
                                                />
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </AccordionTab>
                        </Accordion>

                        {/* <Fieldset legend="Geo" className="mt-2 mb-2" toggleable collapsed>
                            <div className="p-field m-4">
                                <Field name="region">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            key={field.value ? field.value.id : "region"}
                                            options={regionsQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("region", newValue)}
                                            disabled={regionsQuery?.isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Region"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {regionsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="p-field m-4">
                                <Field name="channel">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={channelsQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("channel", newValue)}
                                            disabled={channelsQuery?.isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Channel"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {channelsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>

                            <div className="p-field m-4">
                                <Field name="route">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={routesQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("route", newValue)}
                                            disabled={routesQuery?.isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Route"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {routesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                        </Fieldset>

                        <Fieldset legend="Product" className="mt-2 mb-2" toggleable collapsed>
                            <div className="p-field m-4">
                                <Field name="productCategory">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={productCategoriesQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("productCategory", newValue)}
                                            disabled={productCategoriesQuery?.isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Product Category"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {productCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="p-field m-4">
                                <Field name="productSubcategory">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={productSubCategoriesQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("productSubcategory", newValue)}
                                            disabled={productSubCategoriesQuery?.isLoading || !values.productCategory}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Product Subcategory"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {productSubCategoriesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="p-field m-4">
                                <Field name="product">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={productsQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("product", newValue)}
                                            disabled={productsQuery?.isLoading || !values.productSubcategory}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Product"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {productsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                        </Fieldset>

                        <Fieldset legend="Others" className="mt-2 mb-2" toggleable collapsed>
                            <div className="p-field m-4">
                                <Field name="salesEmployee">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={salesAssociatesQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("salesEmployee", newValue)}
                                            disabled={salesAssociatesQuery?.isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Sales Employee"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {salesAssociatesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>

                            <div className="p-field m-4">
                                <Field name="agent">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={agentsQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("agent", newValue)}
                                            disabled={agentsQuery?.isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Agent"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {agentsQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="p-field m-4">
                                <Field name="customer">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={customersQuery?.data?.data?.data ?? []}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("customer", newValue)}
                                            disabled={customersQuery?.isLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Customer"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {customersQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div> */}
                        {/* <div className="p-field m-4">
                                <Field name="paymentTerms">
                                    {({ field }) => (
                                        <Autocomplete
                                            //isOptionEqualToValue helps to define how comparison is gonna be made
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            options={paymentTerms}
                                            getOptionLabel={(option) => option.name}
                                            value={field.value}
                                            onChange={(event, newValue) => setFieldValue("paymentTerms", newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Payment Terms"
                                                    fullWidth
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {routesQuery?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                            </div> */}
                        {/* </Fieldset> */}

                        <Button type="submit" label="Filter" className="p-button-primary m-2" />

                        <Button label="Reset" type="button" onClick={(e) => handleResetFilters(e, resetForm, initialValues, setValues, values)} className="p-button-secondary m-2" />
                    </Form>
                )}
            </Formik>
        </Sidebar>
    );
};

export default DashboardFilterSidebar;
