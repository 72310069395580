import axiosAPI from "../axiosApi";

export async function getAllChannels(params = {}) {
    const response = await axiosAPI.get("channels", { params: params });
    return response;
}

export async function getChannelById(id) {
    const response = await axiosAPI.get(`channels/` + id);
    return response;
}

export async function postChannel(data) {
    const response = await axiosAPI.post(`channels`, data);
    return response;
}

export async function updateChannel(id, data) {
    const response = await axiosAPI.post(`channels/${id}`, data);
    return response;
}

export async function deleteChannelById(id) {
    const response = await axiosAPI.delete(`channels/${id}`);
    return response;
}
