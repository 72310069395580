import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

import { Button } from "primereact/button";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

// Sample hierarchical data
const sampleData = {
    agents: [
        {
            id: "agent1",
            name: "Agent A",
            sales: 90000,
            categories: [
                {
                    id: "category1",
                    name: "Bottles",
                    sales: 50000,
                    subCategories: [
                        {
                            id: "subcategory1-1",
                            name: "Water Bottles",
                            sales: 30000,
                            products: [
                                { name: "Bottle A", sales: 15000 },
                                { name: "Bottle B", sales: 10000 },
                                { name: "Bottle C", sales: 5000 },
                            ],
                        },
                        {
                            id: "subcategory1-2",
                            name: "Juice Bottles",
                            sales: 20000,
                            products: [
                                { name: "Bottle D", sales: 12000 },
                                { name: "Bottle E", sales: 8000 },
                            ],
                        },
                    ],
                },
                {
                    id: "category2",
                    name: "Containers",
                    sales: 40000,
                    subCategories: [
                        {
                            id: "subcategory2-1",
                            name: "Food Containers",
                            sales: 25000,
                            products: [
                                { name: "Container A", sales: 10000 },
                                { name: "Container B", sales: 8000 },
                                { name: "Container C", sales: 7000 },
                            ],
                        },
                        {
                            id: "subcategory2-2",
                            name: "Storage Containers",
                            sales: 15000,
                            products: [
                                { name: "Container D", sales: 9000 },
                                { name: "Container E", sales: 6000 },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: "agent2",
            name: "Agent B",
            sales: 60000,
            categories: [
                {
                    id: "category3",
                    name: "Plastic Bags",
                    sales: 30000,
                    subCategories: [
                        {
                            id: "subcategory3-1",
                            name: "Shopping Bags",
                            sales: 15000,
                            products: [
                                { name: "Bag A", sales: 8000 },
                                { name: "Bag B", sales: 7000 },
                            ],
                        },
                        {
                            id: "subcategory3-2",
                            name: "Garbage Bags",
                            sales: 15000,
                            products: [
                                { name: "Bag C", sales: 9000 },
                                { name: "Bag D", sales: 6000 },
                            ],
                        },
                    ],
                },
                {
                    id: "category4",
                    name: "Plastic Cups",
                    sales: 30000,
                    subCategories: [
                        {
                            id: "subcategory4-1",
                            name: "Disposable Cups",
                            sales: 20000,
                            products: [
                                { name: "Cup A", sales: 12000 },
                                { name: "Cup B", sales: 8000 },
                            ],
                        },
                        {
                            id: "subcategory4-2",
                            name: "Reusable Cups",
                            sales: 10000,
                            products: [
                                { name: "Cup C", sales: 6000 },
                                { name: "Cup D", sales: 4000 },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

const generateDrilldownData = (data) => {
    const drilldownSeries = [];
    const agentsData = data.agents.map((agent) => {
        drilldownSeries.push({
            id: agent.id,
            name: `${agent.name} Categories`,
            data: agent.categories.map((category) => ({
                name: category.name,
                y: category.sales,
                drilldown: category.id,
            })),
        });
        agent.categories.forEach((category) => {
            drilldownSeries.push({
                id: category.id,
                name: `${category.name} Sub-categories`,
                data: category.subCategories.map((subCategory) => ({
                    name: subCategory.name,
                    y: subCategory.sales,
                    drilldown: subCategory.id,
                })),
            });
            category.subCategories.forEach((subCategory) => {
                drilldownSeries.push({
                    id: subCategory.id,
                    name: `${subCategory.name} Products`,
                    data: subCategory.products.map((product) => [product.name, product.sales]),
                });
            });
        });
        return {
            name: agent.name,
            y: agent.sales,
            drilldown: agent.id,
        };
    });
    return { agentsData, drilldownSeries };
};

const { agentsData, drilldownSeries } = generateDrilldownData(sampleData);

const options = {
    chart: {
        type: "column",
    },
    title: {
        text: "Agents Performance by Sales",
    },
    subtitle: {
        text: "Click the columns to view categories, sub-categories, and products.",
    },
    xAxis: {
        type: "category",
    },
    yAxis: {
        title: {
            text: "Total Sales (UGX)",
        },
    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: "{point.y:.1f}",
            },
        },
    },
    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f} UGX</b><br/>',
    },
    series: [
        {
            name: "Agents",
            colorByPoint: true,
            data: agentsData,
        },
    ],
    drilldown: {
        breadcrumbs: {
            position: {
                align: "right",
            },
        },
        series: drilldownSeries,
    },
    credits: {
        enabled: false,
    },
};

const SelloutAgentProductDrilldownChart = () => {
    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default SelloutAgentProductDrilldownChart;
