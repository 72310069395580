import axiosAPI from "../axiosApi";

//new
export async function getProductsDrillTargetChart(params = {}) {
    const response = await axiosAPI.get("products-drilldown-target", { params: params });
    return response;
}
export async function getRegionsDrillTargetChart(params = {}) {
    const response = await axiosAPI.get("region-drilldown-target", { params: params });
    return response;
}
export async function getActualPerformanceTargetChart(params = {}) {
    const response = await axiosAPI.get("actual-performance-target", { params: params });
    return response;
}

export async function getAgentsDrillTargetChart(params = {}) {
    const response = await axiosAPI.get("agent-drilldown-target", { params: params });
    return response;
}

export async function getChannelsDrillTargetChart(params = {}) {
    const response = await axiosAPI.get("channel-drilldown-target", { params: params });
    return response;
}
//

export async function getActualPerformanceChart(params = {}) {
    const response = await axiosAPI.get("performance-data", { params: params });
    return response;
}

export async function getSellinRegionsHeatMap(params = {}) {
    const response = await axiosAPI.get("sellin-region-heat-map", { params: params });
    return response;
}

export async function getSellinCategoriesHeatMap(params = {}) {
    const response = await axiosAPI.get("sellin-category-heat-map", { params: params });
    return response;
}
export async function getSellinChannelsHeatMap(params = {}) {
    const response = await axiosAPI.get("sellin-channel-heat-map", { params: params });
    return response;
}

export async function getSellinSubcategoriesHeatMap(params = {}) {
    const response = await axiosAPI.get("sellin-subcategory-heat-map", { params: params });
    return response;
}

export async function getSellinAgentsHeatMap(params = {}) {
    const response = await axiosAPI.get("sellin-agent-heat-map", { params: params });
    return response;
}

export async function getChannelPerformanceChart(params = {}) {
    const response = await axiosAPI.get("channel-performance", { params: params });
    return response;
}

export async function getRegionPerformanceChart(params = {}) {
    const response = await axiosAPI.get("region-performance", { params: params });
    return response;
}

export async function getProductCategoryPerformanceChart(params = {}) {
    const response = await axiosAPI.get("productCategory-performance", { params: params });
    return response;
}

export async function getProductPerformanceDrillDown(params = {}) {
    const response = await axiosAPI.get("productsDrilldown-performance", { params: params });
    return response;
}

export async function getRegionPerformanceDrillDown(params = {}) {
    const response = await axiosAPI.get("regionsDrilldown-performance", { params: params });
    return response;
}

export async function getSuplusDeficitPerformanceChart(params = {}) {
    const response = await axiosAPI.get("actual-suplus-deficit", { params: params });
    return response;
}

export async function getSuplusDeficitChannelPerformanceChart(params = {}) {
    const response = await axiosAPI.get("channel-suplus-deficit", { params: params });
    return response;
}

export async function getSuplusDeficitRegionPerformanceChart(params = {}) {
    const response = await axiosAPI.get("region-suplus-deficit", { params: params });
    return response;
}

export async function getSuplusDeficitProductCategoryPerformanceChart(params = {}) {
    const response = await axiosAPI.get("productsCategory-suplus-deficit", { params: params });
    return response;
}
