import axiosAPI from "../axiosApi";

export async function getAllTransactions(params = {}) {
    const response = await axiosAPI.get("transactions", { params: params });
    return response;
}

export async function getTransactionById(id) {
    const response = await axiosAPI.get(`transactions/` + id);
    return response;
}

export async function postTransaction(data) {
    const response = await axiosAPI.post(`transactions`, data);
    return response;
}

export async function updateTransaction(id, data) {
    const response = await axiosAPI.post(`transactions/${id}`, data);
    return response;
}

export async function deleteTransactionById(id) {
    const response = await axiosAPI.delete(`transactions/${id}`);
    return response;
}
