import React, { useState, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { deleteBcData, flushDataCache, dispatchBcDataJob, jobDone, postToImportExcel } from "../../../services/file-imports/file-imports-service";

import BlueFileTransferLottie from "./lotties/blue-file-transfer-lottie.json";
import ReadingFileLottie from "./lotties/reading-file-lotttie.json";
import ParsingFileLottie from "./lotties/parsing-file-lottie.json";
import SnailErrorLottie from "./lotties/snail-error-lottie.json";
import UploadSuccessfullLottie from "./lotties/upload-succesfull-lottie.json";
import clearLottie from "./lotties/clear-lottie.json";
import jobLottie from "./lotties/job-lottie.json";
import doneLottie from "./lotties/done-lottie.json";
import updatingLottie from "./lotties/updating-lottie.json";

import Lottie from "lottie-react";

const ExcelmportPage = (props) => {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [jobRunning, setJobRunning] = useState(false);
    const [flushed, setFlushed] = useState(false);
    const [flushedMsg, setFlushedMsg] = useState("");
    const [dispatchedMsg, setDispatchedMsg] = useState("");
    const [error, setError] = useState(null);
    const toast = useRef(null);
    const queryClient = useQueryClient();

    const [isReading, setIsReading] = useState(false);
    const [isSussfullyUploaded, setIsSussfullyUploaded] = useState(false);

    const [isLoadingExcelImportMutation, setIsLoadingExcelImportMutation] = useState(false);

    const excelImportMutation = useMutation({
        mutationFn: postToImportExcel,
        // onSuccess: () => {
        //     setIsLoadingExcelImportMutation(false);
        //     queryClient.invalidateQueries(["users"]);
        //     toast.current.show({ severity: "success", summary: "Success", detail: "Uploaded Successfully", life: 3000 });
        //     setIsUploading(false);
        //     props.onClose();
        // },
        onError: (error) => {
            setIsLoadingExcelImportMutation(false);
            setIsUploading(false);
            const errorMessage = error?.response?.data?.message ? error.response.data.message : !error?.response ? "Check Your Internet Connection Please" : "An Error Occurred Please Contact Admin";
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.error("Upload error:", error);
        },
    });

    const deleteData = useMutation({
        mutationFn: deleteBcData,
        // onSuccess: () => {
        //     setIsLoadingExcelImportMutation(false);
        //     queryClient.invalidateQueries(["users"]);
        //     toast.current.show({ severity: "success", summary: "Success", detail: "Uploaded Successfully", life: 3000 });
        //     setIsUploading(false);
        //     props.onClose();
        // },
        onError: (error) => {
            // setIsLoadingExcelImportMutation(false);
            setIsUploading(false);
            const errorMessage = error?.response?.data?.message ? error.response.data.message : !error?.response ? "Check Your Internet Connection Please" : "An Error Occurred Please Contact Admin";
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.error("Upload error:", error);
        },
    });

    const flushCache = useMutation({
        mutationFn: flushDataCache,
        // onSuccess: () => {
        //     setIsLoadingExcelImportMutation(false);
        //     queryClient.invalidateQueries(["users"]);
        //     toast.current.show({ severity: "success", summary: "Success", detail: "Uploaded Successfully", life: 3000 });
        //     setIsUploading(false);
        //     props.onClose();
        // },
        onError: (error) => {
            // setIsLoadingExcelImportMutation(false);
            setIsUploading(false);
            setFlushed(false);
            const errorMessage = error?.response?.data?.message ? error.response.data.message : !error?.response ? "Check Your Internet Connection Please" : "An Error Occurred Please Contact Admin";
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.error("Upload error:", error);
        },
    });

    const dispatchJob = useMutation({
        mutationFn: dispatchBcDataJob,
        // onSuccess: () => {
        //     setIsLoadingExcelImportMutation(false);
        //     queryClient.invalidateQueries(["users"]);
        //     toast.current.show({ severity: "success", summary: "Success", detail: "Uploaded Successfully", life: 3000 });
        //     setIsUploading(false);
        //     props.onClose();
        // },
        onError: (error) => {
            // setIsLoadingExcelImportMutation(false);
            setIsUploading(false);
            setFlushed(false);
            const errorMessage = error?.response?.data?.message ? error.response.data.message : !error?.response ? "Check Your Internet Connection Please" : "An Error Occurred Please Contact Admin";
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.error("Job error:", error);
        },
    });

    const isJobDone = useMutation({
        mutationFn: jobDone,
        // onSuccess: () => {
        //     setIsLoadingExcelImportMutation(false);
        //     queryClient.invalidateQueries(["users"]);
        //     toast.current.show({ severity: "success", summary: "Success", detail: "Uploaded Successfully", life: 3000 });
        //     setIsUploading(false);
        //     props.onClose();
        // },
        onError: (error) => {
            // setIsLoadingExcelImportMutation(false);
            setIsUploading(false);
            setFlushed(false);
            const errorMessage = error?.response?.data?.message ? error.response.data.message : !error?.response ? "Check Your Internet Connection Please" : "An Error Occurred Please Contact Admin";
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.error("Job Check error:", error);
        },
    });

    const handleFileChange = (e) => {
        setFile(e.files[0]);
        setIsSussfullyUploaded(false);
        setError(null);
    };

    const handleReload = async () => {
        setFlushed(true);
        var flush = await flushCache.mutateAsync();
        setFlushedMsg(flush?.data?.message);
        // useEffect(() => {
        const timer = setTimeout(() => {
            window.location.reload();
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup the timer if the component unmounts before the timer completes
        return () => clearTimeout(timer);
        // }, []);
        // console.log("flush", flush?.data?.message);
        // if (flush?.data?.message === "All cache has been flushed") {
        //     setFlushed(true);
        // }
    };

    function waitOneSecond() {
        return new Promise((resolve) => setTimeout(resolve, 1000));
    }

    const handleJob = async () => {
        setJobRunning(true);
        var dispatchedJob = await dispatchJob.mutateAsync();

        setDispatchedMsg(dispatchedJob?.data?.message);

        const checkJobStatus = async () => {
            try {
                var jobStatusResponse = await isJobDone.mutateAsync();
                var jobStatus = jobStatusResponse?.data?.has_jobs;

                console.log("job status:", jobStatus);
                if (jobStatus) {
                    await waitOneSecond();
                    await checkJobStatus();
                } else {
                    setDispatchedMsg("Update Complete");
                    toast.current.show({ severity: "success", summary: "Success", detail: "Database Updated with latest BC data", life: 3000 });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            } catch (error) {
                console.error("Error checking Job Status:", error);
            }
        };

        await checkJobStatus();
        setJobRunning(false);
    };

    const handleDownload = () => {
        // Sample data for the Excel file
        const data = [
            {
                "Transaction Number": null,
                "Origin No.": null,
                "Posting Date": null,
                "Due Date": null,
                "Month-YY": null,
                "BP Code": null,
                "BP Name": null,
                "Sales Employee Name": null,
                "Item No.": null,
                "Item Description": null,
                "Item Subcategory": null,
                "Product Category": null,
                "Selling Price": null,
                "Actual Quantity Sold": null,
                "Sales Value": null,
                "Doc. Type": null,
                Region: null,
                Channel: null,
                "Payment Terms": null,
                "Gross Profit": null,
                Week: null,
            },
        ];

        // Convert JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(data);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sell in");

        // Generate Excel file and trigger download
        XLSX.writeFile(wb, "sell in Template.xlsx");
    };

    // const readExcel = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             try {
    //                 const workbook = XLSX.read(e.target.result, { type: "binary" });
    //                 const sheetName = workbook.SheetNames[0];
    //                 const sheet = workbook.Sheets[sheetName];
    //                 const data = XLSX.utils.sheet_to_json(sheet);
    //                 resolve(data);
    //             } catch (error) {
    //                 reject(error);
    //             }
    //         };
    //         reader.onerror = reject;
    //         reader.readAsBinaryString(file);
    //     });
    // };

    // const readExcel = async (file) => {
    //     try {
    //         const data = await file.arrayBuffer();
    //         return new Promise((resolve, reject) => {
    //             console.log("Reading input file:");
    //             const workbook = XLSX.read(data);
    //             const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet, {
    //                 header: 1,
    //                 defval: "",
    //             });

    //             console.log(jsonData);
    //             resolve(jsonData);
    //         });
    //     } catch (error) {
    //         return Promise.reject(error);
    //     }
    // };

    // const createWorker = () => {
    //     const blob = new Blob(
    //         [
    //             `
    //             importScripts('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.17.0/xlsx.full.min.js');
    //             self.onmessage = async function(e) {
    //                 try {
    //                     const file = e.data;
    //                     const data = await file.arrayBuffer();
    //                     const workbook = XLSX.read(data, { type: 'array' });
    //                     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //                     const jsonData = XLSX.utils.sheet_to_json(worksheet, {
    //                         header: 1,
    //                         defval: "",
    //                     });
    //                     self.postMessage({ status: 'success', data: jsonData });
    //                 } catch (error) {
    //                     self.postMessage({ status: 'error', error: error.message });
    //                 }
    //             };
    //         `,
    //         ],
    //         { type: "application/javascript" }
    //     );

    //     return new Worker(URL.createObjectURL(blob));
    // };

    // // with type array
    // const createWorker = () => {
    //     const blob = new Blob(
    //         [
    //             `
    //             importScripts('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.17.0/xlsx.full.min.js');
    //             self.onmessage = async function(e) {
    //                 try {
    //                     const file = e.data;
    //                     const data = await file.arrayBuffer();
    //                     const workbook = XLSX.read(data, { type: 'array' });

    //                     if (workbook.SheetNames.length === 0) {
    //                         throw new Error('The workbook is empty.');
    //                     }

    //                     const sheetName = 'sell in';
    //                     if (!workbook.SheetNames.includes(sheetName)) {
    //                         throw new Error('The sheet "sell in" is missing.');
    //                     }

    //                     const worksheet = workbook.Sheets[sheetName];
    //                     const jsonData = XLSX.utils.sheet_to_json(worksheet, {
    //                         header: 1,
    //                         defval: "",
    //                     });

    //                     if (jsonData.length === 0) {
    //                         throw new Error('The "sell in" sheet is empty.');
    //                     }

    //                     const requiredColumns = ['BP Code', 'Item Subcategory', 'Selling Price','Actual Quantity Sold', 'Region'];
    //                     const headers = jsonData[0].map(header => header.toLowerCase());
    //                     console.log("The excel headers are : ",headers);

    //                     const missingColumns = requiredColumns.filter(column => !headers.includes(column.toLowerCase()));
    //                     if (missingColumns.length > 0) {
    //                         throw new Error('Missing required columns: ' + missingColumns.join(', '));
    //                     }

    //                     self.postMessage({ status: 'success', data: jsonData });
    //                 } catch (error) {
    //                     self.postMessage({ status: 'error', error: error.message });
    //                 }
    //             };
    //         `,
    //         ],
    //         { type: "application/javascript" }
    //     );

    //     return new Worker(URL.createObjectURL(blob));
    // };

    // with type binary
    const createWorker = () => {
        const blob = new Blob(
            [
                `
                importScripts('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.17.0/xlsx.full.min.js');
                self.onmessage = async function(e) {
                    try {
                        const file = e.data;
                        const fileName = file.name;
                        const fileSize = file.size;
                        const fileType = file.type;
                        
                        const data = await file.arrayBuffer();
                        const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
    
                        if (workbook.SheetNames.length === 0) {
                            throw new Error('The workbook is empty.');
                        }
    
                        const sheetName = 'sell in';
                        if (!workbook.SheetNames.includes(sheetName)) {
                            throw new Error('The sheet "sell in" is missing.');
                        }
    
                        const worksheet = workbook.Sheets[sheetName];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet);
    
                        if (jsonData.length === 0) {
                            throw new Error('The "sell in" sheet is empty.');
                        }
    
                        const requiredColumns = ['BP Code', 'Item Subcategory', 'Selling Price','Actual Quantity Sold', 'Region'];
                        const headers = Object.keys(jsonData[0]).map(header => header.trim().toLowerCase());
                        console.log("The excel headers are : ", headers);
    
                        const missingColumns = requiredColumns.filter(column => !headers.includes(column.trim().toLowerCase()));
                        if (missingColumns.length > 0) {
                            throw new Error('Missing required columns: ' + missingColumns.join(', '));
                        }
    
                        self.postMessage({ status: 'success', data: jsonData, fileInfo: { fileName, fileSize, fileType } });
                    } catch (error) {
                        self.postMessage({ status: 'error', error: error.message });
                    }
                };
            `,
            ],
            { type: "application/javascript" }
        );

        return new Worker(URL.createObjectURL(blob));
    };

    // // with file reader
    // const createWorker = () => {
    //     const blob = new Blob(
    //         [
    //             `
    //             importScripts('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.17.0/xlsx.full.min.js');

    //             self.onmessage = function(e) {
    //                 const file = e.data;
    //                 const reader = new FileReader();

    //                 reader.onload = async function(event) {
    //                     try {
    //                         const data = event.target.result;
    //                         const workbook = XLSX.read(data, { type: 'binary' });

    //                         if (workbook.SheetNames.length === 0) {
    //                             throw new Error('The workbook is empty.');
    //                         }

    //                         const sheetName = 'sell in';
    //                         if (!workbook.SheetNames.includes(sheetName)) {
    //                             throw new Error('The sheet "sell in" is missing.');
    //                         }

    //                         const worksheet = workbook.Sheets[sheetName];
    //                         const jsonData = XLSX.utils.sheet_to_json(worksheet);

    //                         if (jsonData.length === 0) {
    //                             throw new Error('The "sell in" sheet is empty.');
    //                         }

    //                         const requiredColumns = ['BP Code', 'Item Subcategory', 'Selling Price', 'Actual Quantity Sold', 'Region'];
    //                         const headers = Object.keys(jsonData[0]);
    //                          console.log("The excel headers are : ",headers);

    //                         const missingColumns = requiredColumns.filter(column => !headers.includes(column));
    //                         if (missingColumns.length > 0) {
    //                             throw new Error('Missing required columns: ' + missingColumns.join(', '));
    //                         }

    //                         self.postMessage({ status: 'success', data: jsonData });
    //                     } catch (error) {
    //                         self.postMessage({ status: 'error', error: error.message });
    //                     }
    //                 };

    //                 reader.onerror = function(event) {
    //                     self.postMessage({ status: 'error', error: 'Error reading file.' });
    //                 };

    //                 reader.readAsBinaryString(file);
    //             };
    //             `,
    //         ],
    //         { type: "application/javascript" }
    //     );

    //     return new Worker(URL.createObjectURL(blob));
    // };

    const readExcel = (file) => {
        return new Promise((resolve, reject) => {
            const worker = createWorker();

            worker.onmessage = function (e) {
                if (e.data.status === "success") {
                    resolve(e.data);
                } else {
                    reject(new Error(e.data.error));
                }
                worker.terminate();
            };

            worker.onerror = function (e) {
                reject(new Error(e.message));
                worker.terminate();
            };

            worker.postMessage(file);
        });
    };

    //uploading file
    const handleUpload = async () => {
        if (!file) {
            setError("Please select a file first.");
            setIsSussfullyUploaded(false);
            return;
        }

        // setIsUploading(true);
        setIsSussfullyUploaded(false);
        setError(null);
        setIsReading(true);

        try {
            const { data, fileInfo } = await readExcel(file);
            console.log("File info:", fileInfo);
            setIsReading(false);
            setIsUploading(true);
            await deleteData.mutateAsync();
            await sendDataInChunks(data);
        } catch (error) {
            console.error("Error processing file:", error);
            setError("Error processing file. Please try again. " + error);
            setIsSussfullyUploaded(false);
            setIsUploading(false);
            setIsReading(false);
        }
    };

    //

    const sendDataInChunks = async (data, fileInfo) => {
        const chunkSize = 1500;
        const totalChunks = Math.ceil(data.length / chunkSize);
        let currentChunk = 0;

        const sendChunk = async (chunk) => {
            console.log("🚀 ~ sendChunk ~ chunk:", chunk);
            try {
                const formattedChunk = chunk.map(formatRow);
                console.log("🚀 ~ sendChunk ~ formattedChunk:", formattedChunk);

                await excelImportMutation.mutateAsync({ data: formattedChunk, file_name: fileInfo?.fileName });
                currentChunk++;
                setProgress(Math.round((currentChunk / totalChunks) * 100));

                if (currentChunk < totalChunks) {
                    const nextChunk = data.slice(currentChunk * chunkSize, (currentChunk + 1) * chunkSize);
                    await sendChunk(nextChunk);
                } else {
                    setIsSussfullyUploaded(true);
                    setIsUploading(false);
                    setProgress(0);
                    setFile(null);
                    toast.current.show({ severity: "success", summary: "Success", detail: "File Data Uploaded Successfully", life: 3000 });
                }
            } catch (error) {
                console.error("Error sending chunk:", error);
                setError("Error uploading data. Please try again.");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error uploading data. Please try again.", life: 3000 });
                setIsUploading(false);
                setIsSussfullyUploaded(false);
            }
        };

        const firstChunk = data.slice(0, chunkSize);
        await sendChunk(firstChunk);
    };

    const formatRow = (row) => ({
        "Transaction Number": row["Transaction Number"] || row[" Transaction Number "],
        "Origin No.": row["Origin No."] || row[" Origin No. "],
        "Posting Date": row["Posting Date"] || row[" Posting Date "],
        "Due Date": row["Due Date"] || row[" Due Date "],
        "Month-YY": row["Month-YY"] || row[" Month-YY "],
        "BP Code": row["BP Code"] || row[" BP Code "],
        "BP Name": row["BP Name"] || row[" BP Name "],
        "Sales Employee Name": row["Sales Employee Name"] || row[" Sales Employee Name "],
        PRODUCT_ID: row["Item No."] || row[" Item No. "] || row["PRODUCT_ID"],
        PRODUCT_NAME: row["Item Description"] || row[" Item Description "] || row["PRODUCT_NAME"],
        "Item Subcategory": row["Item Subcategory"] || row[" Item Subcategory "],
        "Product Category": row["Product Category"] || row[" Product Category "],
        "Selling Price": row["Selling Price"] || row[" Selling Price "],
        "Actual Quantity Sold": row["Actual Quantity Sold"] || row[" Actual Quantity Sold "],
        "Sales Value": row["Sales Value"] || row[" Sales Value "],
        "Doc. Type": row["Doc. Type"] || row[" Doc. Type "],
        Region: row["Region"] || row[" Region "],
        Channel: row["Channel"] || row[" Channel "],
        "Payment Terms": row["Payment Terms"] || row[" Payment Terms "],
        "Gross Profit": row["Gross Profit"] || row[" Gross Profit "],
        Week: row["Week"] || row[" Week "],
    });

    return (
        <div>
            <div title="" className="card">
                <center>
                    <h4>Excel Import</h4>
                </center>
                <Toast ref={toast} />
                <FileUpload name="demo[]" customUpload uploadHandler={handleFileChange} auto accept=".xlsx, .xls" maxFileSize={100000000} disabled={isUploading} />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button label="Upload" icon="pi pi-upload" onClick={handleUpload} disabled={!file || isUploading || isReading} className="mt-2" />
                    <Button label="Download Template" icon="pi pi-download" onClick={handleDownload} disabled={file || isUploading || isReading} className="mt-2" />
                </div>
                {/* <div>
                <button onClick={handleDownload}>Download Excel</button>
            </div> */}
                {isReading && (
                    <center>
                        <h3 style={{ color: "blue" }}>Parsing file...</h3>
                    </center>
                )}
                {isUploading && (
                    <center>
                        <div className="progress-container mt-2">
                            <ProgressBar value={progress} />
                            <p>{progress}% completed</p>
                        </div>
                    </center>
                )}

                {error && (
                    <center>
                        <h3 style={{ color: "red" }}>{error}</h3>
                    </center>
                )}

                {!isUploading && !isReading && !error && isSussfullyUploaded && (
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <h3 style={{ color: "green" }}>Congratulations file has been imported succesfully</h3>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={UploadSuccessfullLottie} loop={true} autoplay={true} />
                        </div>
                    </div>
                )}

                {isUploading && (
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={BlueFileTransferLottie} loop={true} autoplay={true} />
                        </div>
                    </div>
                )}

                {isReading && (
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={ParsingFileLottie} loop={true} autoplay={true} />
                        </div>
                    </div>
                )}

                {error && (
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                        </div>
                    </div>
                )}
            </div>
            {/* <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}> */}
            <div title="" className="card mt-5">
                <center>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                        <h4 style={{ margin: 0, display: "flex", alignItems: "center" }}>Clear Cache to Revert to Latest</h4>
                        <Button label="Clear Cache" icon="pi pi-refresh" onClick={handleReload} disabled={flushed || file || isUploading || isReading} className="mt-2" />
                    </div>
                    {flushed && (
                        <div>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={clearLottie} loop={false} autoplay={true} />
                            </div>
                            <h6>{flushedMsg}</h6>
                        </div>
                    )}
                </center>
            </div>
            <div title="" className="card mt-5">
                <center>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                        <h4 style={{ margin: 0, display: "flex", alignItems: "center" }}>Fetch BC data and update database</h4>
                        <Button label="Fetch Data" icon="pi pi-sync" onClick={handleJob} disabled={true || jobRunning || dispatchedMsg || file || isUploading || isReading} className="mt-2" />
                    </div>
                    {jobRunning && dispatchedMsg !== "Update Complete" && (
                        <div>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={updatingLottie} loop={true} autoplay={true} />
                            </div>
                            <h6>{dispatchedMsg}</h6>
                        </div>
                    )}
                    {dispatchedMsg && dispatchedMsg === "Update Complete" && (
                        <div>
                            <div style={{ maxWidth: "400px" }}>
                                <Lottie animationData={doneLottie} loop={false} autoplay={true} />
                            </div>
                            <h6>{dispatchedMsg}</h6>
                        </div>
                    )}
                </center>
            </div>
            {/* </div> */}
        </div>
    );
};

export default ExcelmportPage;
