import React from "react";
import { Card, CardContent, Typography, Box, Tooltip } from "@mui/material";
//icon imports
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TimelineIcon from "@mui/icons-material/Timeline";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import PublicIcon from "@mui/icons-material/Public";
import CategoryIcon from "@mui/icons-material/Category";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EuroIcon from "@mui/icons-material/Euro";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import StoreIcon from "@mui/icons-material/Store";

const iconMap = {
    ShoppingCartIcon: ShoppingCartIcon,
    MonetizationOnIcon: MonetizationOnIcon,
    TrendingUpIcon: TrendingUpIcon,
    TimelineIcon: TimelineIcon,
    MultilineChartIcon: MultilineChartIcon,
    PublicIcon: PublicIcon,
    CategoryIcon: CategoryIcon,
    ShowChartIcon: ShowChartIcon,
    PieChartIcon: PieChartIcon,
    ListAltIcon: ListAltIcon,
    BarChartIcon: BarChartIcon,
    StarIcon: StarIcon,
    PersonIcon: PersonIcon,
    EmojiEventsIcon: EmojiEventsIcon,
    AssessmentIcon: AssessmentIcon,
    TrendingDownIcon: TrendingDownIcon,
    EqualizerIcon: EqualizerIcon,
    EuroIcon: EuroIcon,
    AttachMoneyIcon: AttachMoneyIcon,
    DonutSmallIcon: DonutSmallIcon,
    BusinessCenterIcon: BusinessCenterIcon,
    StoreIcon: StoreIcon,
};

const StatsCard = ({ data }) => {
    let IconComponent = iconMap[data?.icon] ?? TrendingUpIcon;

    const getChangeColor = (change) => {
        if (change.startsWith("0")) {
            return "text-blue-500";
        } else if (change.startsWith("-")) {
            return "text-red-500";
        } else {
            return "text-green-500";
        }
    };

    const formatNumber = (num) => {
        if (isNaN(num)) {
            return num;
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <div className="card mb-0" style={{ height: "200px" }}>
            <div className="mb-3">
                <h1 className="text-center text-900 mb-3 text-xl" style={{ fontWeight: "bold" }}>
                    {data?.title}
                </h1>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "150px" }}>
                    <Tooltip title={data?.tooltip} arrow>
                        <div style={{ fontSize: "20px", fontWeight: "1000", textAlign: "center" }}>
                            <span style={{ color: "#2F4F4F" }}>{data?.unit} </span>
                            {formatNumber(data?.value)}
                        </div>
                    </Tooltip>
                </div>
                {/* <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                    <IconComponent style={{ color: data?.color }} />
                </div> */}
            </div>
            {/* {data?.change2 && (
                <div className="flex justify-content-between">
                    <div>
                        {data?.change && <span className={`${getChangeColor(data?.change)} font-medium`}>{data?.change}</span>}
                        {data?.description && <span className="text-500"> {data?.description} vs SPLY</span>}
                    </div>
                    <div>
                        {data?.change2 && <span className={`${getChangeColor(data?.change2)} font-medium`}>{data?.change2}</span>}
                        {data?.description && <span className="text-500"> {data?.description} vs Target</span>}
                    </div>
                </div>
            )} */}
            {/* {data?.change && !data.change2 && <span className={`${getChangeColor(data?.change)} font-medium`}>{data?.change}</span>} */}
            {/* {data?.description && !data.change2 && <span className="text-500"> {data?.description}</span>} */}
        </div>
    );
};

export default StatsCard;
