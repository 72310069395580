import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import HighchartsLollipop from "highcharts/modules/lollipop";

HighchartsDrilldown(Highcharts);
HighchartsLollipop(Highcharts);

const sampleData = {
    categories: [
        {
            id: "category1",
            name: "Bottles",
            sply_sales: 100,
            actual_sales: 120,
            target_sales: 130,
            subCategories: [
                {
                    id: "subcategory1-1",
                    name: "Water Bottles",
                    sply_sales: 50,
                    actual_sales: 60,
                    target_sales: 70,
                    products: [
                        { name: "Bottle A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
                        { name: "Bottle B", sply_sales: 15, actual_sales: 18, target_sales: 20 },
                        { name: "Bottle C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
                    ],
                },
                // Add more subcategories and products as needed
            ],
        },
        // Add more categories as needed
    ],
};

const LayeredGroupedProductSalesDrilldownWithGroupedLollipopSeparateCharts = ({ data = sampleData }) => {
    // Compute percentage changes
    const computePercentageChanges = (data) => {
        data.categories.forEach((category) => {
            category.percentageChangeActualSPLY = ((category.actual_sales - category.sply_sales) / category.sply_sales) * 100;
            category.percentageChangeActualTarget = ((category.actual_sales - category.target_sales) / category.target_sales) * 100;

            category.subCategories.forEach((subCategory) => {
                subCategory.percentageChangeActualSPLY = ((subCategory.actual_sales - subCategory.sply_sales) / subCategory.sply_sales) * 100;
                subCategory.percentageChangeActualTarget = ((subCategory.actual_sales - subCategory.target_sales) / subCategory.target_sales) * 100;

                subCategory.products.forEach((product) => {
                    product.percentageChangeActualSPLY = ((product.actual_sales - product.sply_sales) / product.sply_sales) * 100;
                    product.percentageChangeActualTarget = ((product.actual_sales - product.target_sales) / product.target_sales) * 100;
                });
            });
        });
    };

    // Generate drilldown data
    const generateDrilldownData = (data) => {
        const drilldownSeries = [];
        const categoriesData = [];

        // Handle product categories
        data.categories.forEach((category) => {
            categoriesData.push([
                {
                    name: category.name,
                    y: category.sply_sales,
                    drilldown: `${category.id}_sply`,
                },
                {
                    name: category.name,
                    y: category.actual_sales,
                    drilldown: `${category.id}_actual`,
                },
                {
                    name: category.name,
                    y: category.target_sales,
                    drilldown: `${category.id}_target`,
                },
            ]);

            // Handle product subcategories
            ["sply", "actual", "target"].forEach((saleType) => {
                const categoryDrilldown = {
                    name: `${category.name} ${saleType.toUpperCase()} Sales`,
                    id: `${category.id}_${saleType}`,
                    data: category.subCategories.map((subCategory) => ({
                        name: subCategory.name,
                        y: subCategory[`${saleType}_sales`],
                        drilldown: `${subCategory.id}_${saleType}`,
                    })),
                };
                drilldownSeries.push(categoryDrilldown);

                // Handle products
                category.subCategories.forEach((subCategory) => {
                    const subCategoryDrilldown = {
                        name: `${subCategory.name} ${saleType.toUpperCase()} Sales`,
                        id: `${subCategory.id}_${saleType}`,
                        data: subCategory.products.map((product) => ({
                            name: product.name,
                            y: product[`${saleType}_sales`],
                        })),
                    };
                    drilldownSeries.push(subCategoryDrilldown);
                });
            });
        });

        return { categoriesData, drilldownSeries };
    };

    const { categoriesData, drilldownSeries } = generateDrilldownData(data);
    computePercentageChanges(data);

    const syncCharts = (e, chart) => {
        if (e.type === "drilldown") {
            Highcharts.charts.forEach((c) => {
                if (c && c !== chart) {
                    var series = c.series[0],
                        point = series.points[e.point.index];
                    point.doDrilldown();
                }
            });
        } else {
            Highcharts.charts.forEach((c) => {
                if (c) c.drillUp();
            });
        }
    };

    const columnOptions = {
        chart: {
            type: "column",
            events: {
                drilldown(e) {
                    syncCharts(e, this);
                },
                drillup(e) {
                    syncCharts(e, this);
                },
            },
        },
        title: {
            text: "Product Sales Drilldown",
        },
        xAxis: {
            type: "category",
        },
        yAxis: {
            title: {
                text: "Sales",
            },
        },
        legend: {
            enabled: true,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                },
            },
        },
        series: [
            {
                name: "SPLY Sales",
                data: categoriesData.map((category) => category[0]),
                color: Highcharts.getOptions().colors[0],
            },
            {
                name: "Actual Sales",
                data: categoriesData.map((category) => category[1]),
                color: Highcharts.getOptions().colors[1],
            },
            {
                name: "Target Sales",
                data: categoriesData.map((category) => category[2]),
                color: Highcharts.getOptions().colors[2],
            },
        ],
        drilldown: {
            allowPointDrilldown: true,
            series: drilldownSeries,
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
        },
    };

    const lollipopOptions = {
        chart: {
            type: "column",
            events: {
                drilldown(e) {
                    syncCharts(e, this);
                },
                drillup(e) {
                    syncCharts(e, this);
                },
            },
        },
        title: {
            text: "Percentage Change in Sales",
        },
        xAxis: {
            type: "category",
        },
        yAxis: [
            {
                title: {
                    text: "Sales",
                },
            },
            {
                title: {
                    text: "Percentage Change",
                },
                opposite: true,
            },
        ],
        legend: {
            enabled: true,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                },
            },
        },
        series: [
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. SPLY",
                data: data.categories.map((category) => ({
                    y: category.percentageChangeActualSPLY,
                    color: category.percentageChangeActualSPLY > 0 ? "#90ed7d" : "#f45b5b",
                })),
                yAxis: 1,
                marker: {
                    enabled: true,
                    lineColor: null, // Remove black border
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
                showInLegend: true,
            },
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. Target",
                data: data.categories.map((category) => ({
                    y: category.percentageChangeActualTarget,
                    color: category.percentageChangeActualTarget > 0 ? "#90ed7d" : "#f45b5b",
                })),
                yAxis: 1,
                marker: {
                    enabled: true,
                    lineColor: null, // Remove black border
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
                showInLegend: true,
            },
        ],
        drilldown: {
            allowPointDrilldown: true,
            series: drilldownSeries,
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={columnOptions} />
            <HighchartsReact highcharts={Highcharts} options={lollipopOptions} />
        </div>
    );
};

export default LayeredGroupedProductSalesDrilldownWithGroupedLollipopSeparateCharts;
