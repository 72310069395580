import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

import { getProductSalesVsSplyVsTarget } from "../../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { NiceTwoDBFilterContext } from "../../../../../context/NiceTwoDBFilterContext";
import { toast } from "react-toastify";
import { ProgressBar } from "primereact/progressbar";

//lotties
import MaterialUiLoaderLottie from "../../../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../../../../assets/lotties/nice-hop/snail-error-lottie.json";
import Lottie from "lottie-react";

import FiltersFormDialogForLineGraphs from "../../../widgets/FiltersFormDialogForLineGraphs";
import { Button } from "primereact/button";
import moment from "moment";

import { Tooltip } from "@mui/material";

import { Accordion, AccordionTab } from "primereact/accordion";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

// Sample data
const sampleData = {
    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    products: [
        {
            name: "Product A",
            sply_sales: [100, 120, 110, 130, 140, 150, 160, 170, 180, 190, 200, 210],
            actual_sales: [110, 130, 120, 140, 150, 160, 170, 180, 190, 200, 210, 220],
            target_sales: [120, 140, 130, 150, 160, 170, 180, 190, 200, 210, 220, 230],
        },
        // {
        //     name: "Product B",
        //     sply_sales: [90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200],
        //     actual_sales: [95, 105, 115, 125, 135, 145, 155, 165, 175, 185, 195, 205],
        //     target_sales: [100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210],
        // },
        // Add more products as needed
    ],
};

// const generateSeriesData = (data) => {
//     if (!Array.isArray(data)) {
//         return [];
//     }
//     return data
//         .map((product) => {
//             return [
//                 {
//                     name: `${product.name} (Target)`,
//                     data: product.salesTarget,
//                     // color: getRandomColor(),
//                 },
//                 {
//                     name: `${product.name} (This Year)`,
//                     data: product.salesThisYear,
//                     // color: getRandomColor(),
//                 },
//                 {
//                     name: `${product.name} (Last Year)`,
//                     data: product.salesLastYear,
//                     dashStyle: "ShortDash",
//                     // color: getRandomColor(),
//                 },
//             ];
//         })
//         .flat();
// };

function ProductSalesPerfomanceLineGraph() {
    const { filters, setFilters, resetFilters } = useContext(NiceTwoDBFilterContext);
    console.log("🚀 ~ Cards ~ filters:", filters);

    //==================== chart filters ===================
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        agents: [],
        salesAssociates: [],
        regions: [],
        routes: [],
        statuses: [
            { id: 1, label: "Pending", value: "PENDING" },
            { id: 2, label: "Closed", value: "CLOSED" },
        ],
        orderBy: { id: 3, label: "Descending", value: "desc" },
        dataLimit: { id: 2, label: "1", value: 1 },
        // dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    useEffect(() => {
        setFiltersFormInitialDataValues({
            ...filtersFormInitialDataValues,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            regions: filters?.regions,
            routes: filters?.routes,
            agents: filters?.agents,
            salesAssociates: filters?.salesAssociates,
            productCategories: filters?.productCategories,
            productSubCategories: filters?.productSubCategories,
            products: filters?.products,
            statuses: filters?.statuses,
        });
    }, [filters]);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    //==================== end chart filters ===================

    // Products
    const ProductSalesVsSplyVsTargetQuery = useQuery({
        disable: false,
        queryKey: ["product-sales-vs-sply-vs-target", filters?.startDate, filters?.endDate, ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getProductSalesVsSplyVsTarget({ startDate: filters?.startDate, endDate: filters?.endDate, ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (ProductSalesVsSplyVsTargetQuery?.isError) {
            console.log("🚀 ~ useEffect ~ ProductSalesVsSplyVsTargetQuery?.isError:", ProductSalesVsSplyVsTargetQuery?.error);
            ProductSalesVsSplyVsTargetQuery?.error?.response?.data?.message
                ? toast.error(ProductSalesVsSplyVsTargetQuery?.error?.response?.data?.message)
                : !ProductSalesVsSplyVsTargetQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [ProductSalesVsSplyVsTargetQuery?.isError]);

    if (ProductSalesVsSplyVsTargetQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (ProductSalesVsSplyVsTargetQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    console.log("data for NiceTwo ProductSalesVsSplyVsTargetQuery?.data?.data is:", ProductSalesVsSplyVsTargetQuery?.data?.data);

    console.log("zinasd", sampleData, ProductSalesVsSplyVsTargetQuery?.data?.data);

    const data = ProductSalesVsSplyVsTargetQuery?.data?.data?.data;

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = ProductSalesVsSplyVsTargetQuery?.data?.data?.requestParams?.startDate ? moment(ProductSalesVsSplyVsTargetQuery?.data?.data?.requestParams.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = ProductSalesVsSplyVsTargetQuery?.data?.data?.requestParams?.endDate ? moment(ProductSalesVsSplyVsTargetQuery?.data?.data?.requestParams.endDate).format("YYYY-MM-DD") : "N/S";

    const options = {
        chart: {
            type: "spline",
        },
        title: {
            text: "Product Sales Performance",
        },
        xAxis: {
            categories: data.xAxisCategories,
            title: {
                text: "Months",
            },
        },
        yAxis: {
            title: {
                text: "Sales",
            },
        },
        // \
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: data.data.flatMap((product) => [
            {
                name: `${product.name} - SPLY Sales`,
                data: product.salesLastYear,
            },
            {
                name: `${product.name} - Actual Sales`,
                data: product.salesThisYear,
            },
            {
                name: `${product.name} - Target Sales`,
                data: product.salesTarget,
            },
        ]),
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom",
                        },
                    },
                },
            ],
        },
    };

    return (
        <div>
            <div className="col-12 text-right">
                <Tooltip title="Filter">
                    <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                </Tooltip>
                <FiltersFormDialogForLineGraphs
                    onSubmit={handleSubmitForFilters}
                    filtersFormInitialDataValues={filtersFormInitialDataValues}
                    setFiltersFormInitialDataValues={setFiltersFormInitialDataValues}
                    showFiltersFormDialog={showFiltersFormDialog}
                    setShowFiltersFormDialog={setShowFiltersFormDialog}
                />
            </div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
}

export default ProductSalesPerfomanceLineGraph;
