import React, { useState, useContext, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsHeatmap from "highcharts/modules/heatmap";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import { Panel } from "primereact/panel";

import { DashboardFilterContext } from "../../context/DashboardFilterContext";
import { getSellinSubcategoriesHeatMap } from "../../services/dashboard/charts";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

//lotties
import MaterialUiLoaderLottie from "../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
import GirlGirlPlottingGraphsLottie from "../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
import SnailErrorLottie from "../../assets/lotties/nice-hop/snail-error-lottie.json";
import NoData from "../../assets/lotties/nice-hop/nodata.json";
import Lottie from "lottie-react";
//
import FiltersFormDialog from "./FiltersFormDialog";
import { Button } from "primereact/button";

import { Tooltip } from "@mui/material";

import noData from "highcharts/modules/no-data-to-display";

import DoubleScrollbar from "react-double-scrollbar";

import moment from "moment";
// Initialize the required Highcharts modules
noData(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

// Initialize the heatmap module
highchartsHeatmap(Highcharts);

const formatNumber = (num) => {
    if (isNaN(num)) {
        return num;
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Sample data
// const sampleData = [
//     {
//         region: "Central",
//         agent: "Direct Routes - Entebbe",
//         territory: "Entebbe",
//         nhopSalesLead: "William Nsubuga",
//         sales: [
//             { category: "Mon", value: 10 },
//             { category: "Tue", value: 20 },
//             { category: "Wed", value: 30 },
//             { category: "Thu", value: 40 },
//             { category: "Fri", value: 50 },
//             { category: "Sat", value: 60 },
//             { category: "Sun", value: 70 },
//         ],
//     },
//     {
//         region: "East",
//         agent: "Direct Routes - Masaka",
//         territory: "Makindye",
//         nhopSalesLead: "Susan Twinamasiko",
//         sales: [
//             { category: "Mon", value: 15 },
//             { category: "Tue", value: 25 },
//             { category: "Wed", value: 35 },
//             { category: "Thu", value: 45 },
//             { category: "Fri", value: 55 },
//             { category: "Sat", value: 65 },
//             { category: "Sun", value: 75 },
//         ],
//     },
//     {
//         region: "North",
//         agent: "Equip Care",
//         territory: "Nansana",
//         nhopSalesLead: "Mariam Ndugga",
//         sales: [
//             { category: "Mon", value: 12 },
//             { category: "Tue", value: 22 },
//             { category: "Wed", value: 32 },
//             { category: "Thu", value: 42 },
//             { category: "Fri", value: 52 },
//             { category: "Sat", value: 62 },
//             { category: "Sun", value: 72 },
//         ],
//     },
//     {
//         region: "South",
//         agent: "Equip Care",
//         territory: "Natete",
//         nhopSalesLead: "Allan Magala",
//         sales: [
//             { category: "Mon", value: 14 },
//             { category: "Tue", value: 24 },
//             { category: "Wed", value: 34 },
//             { category: "Thu", value: 44 },
//             { category: "Fri", value: 54 },
//             { category: "Sat", value: 64 },
//             { category: "Sun", value: 74 },
//         ],
//     },
// ];

const getChartOptions = (sampleData, windowWidth, windowHeight, filtersFormInitialDataValues) => {
    if (!sampleData || !Array.isArray(sampleData) || !Array.isArray(sampleData)) {
        return null;
    }

    // Access and format startDate and endDate from dataFilters
    // N/S means Not Specified
    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("YYYY-MM-DD") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("YYYY-MM-DD") : "N/S";

    // const startDate = dataFilters?.startDate ? moment(dataFilters.startDate).format("MMMM Do, YYYY") : "N/S";
    // const endDate = dataFilters?.endDate ? moment(dataFilters.endDate).format("MMMM Do, YYYY") : "N/S";
    // Format data for chart series
    // Extract days from the first item's sales data
    const days = sampleData[0].sales.map((sale) => sale.date);
    const dates = sampleData[0].sales.map((sale) => sale.fullDate);

    const combinedList = days.map((day, index) => {
        console.log("looped day", day);
        return day === "1st" ? dates[index] : day;
    });
    console.log("datessdasdL", sampleData);

    const heatmapData = sampleData.flatMap((item, index) => item.sales.map((sale, dayIndex) => [dayIndex, index, sale.value]));

    console.log("heatmapData", heatmapData);

    const options = {
        chart: {
            type: "heatmap",
            plotBorderWidth: 1,
            height: windowHeight - 50 > 50 * sampleData.length ? windowHeight - 50 : 50 * sampleData.length,
            width: windowWidth > 100 * days.length ? windowWidth : 100 * days.length,
            scrollablePlotArea: {
                minWidth: 600, // Set the minimum width before scrolling is enabled
            },
        },
        title: {
            text: `Product Sub-Categories Sales Vs Targets Heatmap From ${moment(filtersFormInitialDataValues.startDate).format("Do MMM YYYY")} to ${moment(filtersFormInitialDataValues.endDate).format("Do MMM YYYY")} <br/>`,
        },
        xAxis: [
            {
                categories: [dates[0], ...combinedList.slice(1)],
                labels: {
                    style: {
                        fontSize: "12px",
                        // fontWeight: "bold",
                    },
                },
            },
            {
                categories: [dates[0], ...combinedList.slice(1)],
                opposite: true, // Display x-axis at the top
                linkedTo: 0, // Link this xAxis to the first one
                labels: {
                    style: {
                        fontSize: "12px",
                        // fontWeight: "bold",
                    },
                },
            },
        ],
        yAxis: {
            categories: sampleData.map((item) => `${item.label}`),
            categories: sampleData.map((item) => `${item.label}`),
            title: null,
            reversed: true,
        },
        colorAxis: {
            stops: [
                [0, "#D22B2B"], // Even Lighter Red
                [0.89999999999, "#D22B2B"], // Even Lighter Red
                [0.9, "orange"], // Amber
                [0.97, "orange"], // Amber
                [1, "#2ECC40"], // Green
            ],
            min: 0,
            max: 100,
            max: 100,
        },
        legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            verticalAlign: "top",
            y: 25,
            symbolHeight: 280,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter: function () {
                const item = sampleData[this.point.y];
                const day = days[this.point.x];
                const saleForDay = item.sales.find((sale) => sale.date === day);
                const formattedValue = saleForDay ? saleForDay.value.toLocaleString(undefined, { maximumFractionDigits: 0, useGrouping: true }) : "0";
                return `<b>Date:</b> ${saleForDay.day}, ${saleForDay.fullDate}<br><b>Region:</b> ${item.label}<br><b>Sales:</b> UGX ${formatNumber(Math.round(saleForDay.totalSales))}<br><b>Target:</b> UGX ${formatNumber(Math.round(saleForDay.totalTargetOverall))}`;
            },
        },
        plotOptions: {
            heatmap: {
                dataLabels: {
                    enabled: true,
                    color: "#000000",
                    format: "{point.value:,.0f}", // Format for the data labels
                },
            },
        },

        series: [
            {
                name: "Sales per day",
                borderWidth: 1,
                data: heatmapData,
                dataLabels: {
                    enabled: true,
                    color: "#FFFFFF",
                    format: "{point.value:.0f}%", // Remove decimal places and add percentage sign
                    style: {
                        fontSize: "14px",
                        textOutline: "1px contrast", // Add a text outline for better visibility
                        // textShadow: "0px 0px 3px black", // Increase the font size of the entries
                    },
                },
                // rowsize: 10, // Set the size of each row
                // colsize: 10, // Set the size of each column
            },
        ],
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        "printChart", // Include print option
                        // "separator", // Add a separator if needed
                        // "downloadPNG", // Include download PNG option
                        // "downloadJPEG", // Include download JPEG option
                        // "downloadPDF", // Include download PDF option
                        // "downloadSVG", // Include download SVG option
                    ],
                },
            },
        },
    };

    return options;
};

const SubcategoryHeatmapTable = () => {
    const { filters, setFilters, resetFilters } = useContext(DashboardFilterContext);
    const chartRef = useRef();

    //chart filters initial data state
    const [filtersFormInitialDataValues, setFiltersFormInitialDataValues] = useState({
        startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        agents: [],
        sales_associates: [],
        regions: [],
        channels: [],
        statuses: [],
        order: null,
        dataLimitNumber: null,
        productCategories: [],
        productSubCategories: [],
        products: [],
    });

    // useEffect(() => {
    //     setFiltersFormInitialDataValues({
    //         ...filtersFormInitialDataValues,
    //         startDate: filters?.startDate,
    //         endDate: filters?.endDate,
    //         regions: filters?.regions,
    //         channels: filters?.channels,
    //         agents: filters?.agents,
    //         sales_associates: filters?.sales_associates,
    //         productCategories: filters?.productCategories,
    //         productSubCategories: filters?.productSubCategories,
    //         products: filters?.products,
    //         measure: filters?.measure,
    //     });
    // }, [filters]);

    // FiltersForm diaglog
    const [showFiltersFormDialog, setShowFiltersFormDialog] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth - 100);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 350);

    const toggleFiltersFormDialog = () => {
        setShowFiltersFormDialog(!showFiltersFormDialog);
    };

    const SellinCategoriesHeatmapQuery = useQuery({
        disable: false,
        queryKey: ["sellin-subcategories-heatmap", ...Object.values(filtersFormInitialDataValues)],
        queryFn: () => getSellinSubcategoriesHeatMap({ ...filtersFormInitialDataValues }),
    });

    useEffect(() => {
        if (SellinCategoriesHeatmapQuery?.isError) {
            console.log("🚀 ~ useEffect ~ SellinCategoriesHeatmapQuery?.isError:", SellinCategoriesHeatmapQuery?.error);
            SellinCategoriesHeatmapQuery?.error?.response?.data?.message
                ? toast.error(SellinCategoriesHeatmapQuery?.error?.response?.data?.message)
                : !SellinCategoriesHeatmapQuery?.error?.response
                ? toast.warning("Check Your Internet Connection Please")
                : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [SellinCategoriesHeatmapQuery?.isError]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth - 100);
            setWindowHeight(window.innerHeight - 350);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    console.log("data for SellinCategoriesHeatmapQuery?.data?.data is:", SellinCategoriesHeatmapQuery?.data?.data);

    const seriesData = SellinCategoriesHeatmapQuery?.data?.data?.data;

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;
            chart.series[0].setData(
                seriesData.flatMap((item, index) => item.sales.map((sale, dayIndex) => [dayIndex, index, sale.value])),
                false
            ); // Update data
            chart.redraw(); // Redraw chart
        }
    }, [seriesData]);

    if (SellinCategoriesHeatmapQuery?.isLoading) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={GirlGirlPlottingGraphsLottie} loop={true} autoplay={true} />
                    <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    if (SellinCategoriesHeatmapQuery?.isError) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ maxWidth: "400px" }}>
                    <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                </div>
            </div>
        );
    }

    const divWidth = 100 * seriesData[0]?.sales.map((sale) => sale.date).length ?? 0;

    const handleSubmitForFilters = (data) => {
        console.log("🚀 ~ handleSubmitForFilters ~ data:", data);
        setFiltersFormInitialDataValues(data);
    };

    console.log("dateafnkfiaei", seriesData, seriesData.length);
    if (seriesData.length === 0) {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="col-12 text-right">
                    <Tooltip title="Filter">
                        <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                    </Tooltip>
                    <FiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} />
                    <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                        <Lottie animationData={NoData} loop={false} autoplay={true} />
                    </div>
                </div>
            </div>
        );
    }

    // Extract days from the first item's sales data
    // const days = sampleData[0].sales.map((sale) => sale.category);

    // const heatmapData = sampleData.flatMap((item, index) => item.sales.map((sale, dayIndex) => [dayIndex, index, sale.value]));

    // const heatmapOptions = {
    //     chart: {
    //         type: "heatmap",
    //         marginTop: 40,
    //         marginBottom: 80,
    //         plotBorderWidth: 1,
    //     },
    //     title: {
    //         text: "NHOP Sales Heatmap",
    //     },
    //     xAxis: {
    //         categories: days,
    //     },
    //     yAxis: {
    //         categories: sampleData.map((item) => `${item.agent} (${item.region})`),
    //         title: null,
    //         reversed: true,
    //     },
    //     colorAxis: {
    //         stops: [
    //             [0, "#FF4136"], // Red
    //             [0.5, "#FFDC00"], // Amber
    //             [1, "#2ECC40"], // Green
    //         ],
    //         min: 0,
    //         max: 80,
    //     },
    //     legend: {
    //         align: "right",
    //         layout: "vertical",
    //         margin: 0,
    //         verticalAlign: "top",
    //         y: 25,
    //         symbolHeight: 280,
    //     },
    //     credits: {
    //         enabled: false,
    //     },
    //     tooltip: {
    //         formatter: function () {
    //             const item = sampleData[this.point.y];
    //             const day = days[this.point.x];
    //             const saleForDay = item.sales.find((sale) => sale.category === day);
    //             return `
    //                  Region: ${item.region} <br>
    //                  Agent: ${item.agent} <br>
    //                  Territory: ${item.territory} <br>
    //                  NHOP Sales Lead: ${item.nhopSalesLead} <br>
    //                  Sold <b>${saleForDay.value}</b> items on <b>${day}</b>`;
    //         },
    //     },
    //     series: [
    //         {
    //             name: "Sales per day",
    //             borderWidth: 1,
    //             data: heatmapData,
    //             dataLabels: {
    //                 enabled: true,
    //                 color: "#000000",
    //             },
    //         },
    //     ],
    // };

    return (
        <div className="pb-5">
            {/* <div style={{ display: "flex", height: "600px", overflow: "auto" }}> */}
            {/* <div style={{ flex: 1, minWidth: "50%" }}>
                    <DataTable value={sampleData} scrollable scrollHeight="100%">
                        <Column field="region" header="Region" />
                        <Column field="agent" header="Agent" />
                        <Column field="territory" header="Territory" />
                        <Column field="nhopSalesLead" header="NHOP Sales Lead" />
                    </DataTable>
                </div> */}
            <div className="col-12 text-right">
                <Tooltip title="Filter">
                    <Button icon="pi pi-filter" onClick={toggleFiltersFormDialog} />
                </Tooltip>
                <FiltersFormDialog onSubmit={handleSubmitForFilters} filtersFormInitialDataValues={filtersFormInitialDataValues} setFiltersFormInitialDataValues={setFiltersFormInitialDataValues} showFiltersFormDialog={showFiltersFormDialog} setShowFiltersFormDialog={setShowFiltersFormDialog} />
            </div>
            <div>
                {/* <div style={{ width: "100%", overflowX: "auto", height: `${windowHeight}px`, overflowY: "auto" }}> */}
                <div style={{ width: "100%", overflowX: "auto", height: "500px", paddingTop: "2rem", paddingBottom: "2rem", overflowY: "auto" }}>
                    <DoubleScrollbar>
                        <div style={{ width: `${windowWidth > divWidth ? windowWidth : divWidth}px` }}>
                            <HighchartsReact highcharts={Highcharts} options={getChartOptions(seriesData, windowWidth, windowHeight, filtersFormInitialDataValues)} ref={chartRef} />
                        </div>
                    </DoubleScrollbar>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default SubcategoryHeatmapTable;
