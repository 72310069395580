import React, { useState } from "react";
import HeatMapTable from "./RegionsHeatMapTable";
import { Dropdown } from "primereact/dropdown";
import AgentsHeatmapTable from "./AgentsHeatMapTable";
import { Panel } from "primereact/panel";
import SubcategoryHeatmapTable from "./SubcategoriesHeatMapTable";
import CategoriesHeatmapTable from "./CategoriesHeatMapTable";
import ChannelsHeatmapTable from "./ChannelsHeatMapTable";

function SellInHeatMapPage() {
    const [selectedbar, setSelectedbar] = useState("region");

    const barOptions = [
        { label: "Channels Heat Map", value: "channel" },
        { label: "Regions Heat Map", value: "region" },
        { label: "Distributors Heat Map", value: "agent" },
        { label: "Categories Heat Map", value: "category" },
        { label: "Sub-Categories Heat Map", value: "subcategory" },
    ];

    const handleBarChange = (e) => {
        setSelectedbar(e.value);
    };
    return (
        <div className="col-12 xl:col-12">
            <Panel header="Sell in Heat Maps" style={{ padding: "2px" }}>
                <div className="col-12">
                    <Dropdown value={selectedbar} options={barOptions} onChange={handleBarChange} placeholder="Choose..." className="p-mb-4 mx-3 my-2" style={{ marginBottom: "20px" }} />
                    {selectedbar === "channel" && <ChannelsHeatmapTable />}
                    {selectedbar === "region" && <HeatMapTable />}
                    {selectedbar === "agent" && <AgentsHeatmapTable />}
                    {selectedbar === "subcategory" && <SubcategoryHeatmapTable />}
                    {selectedbar === "category" && <CategoriesHeatmapTable />}
                </div>
            </Panel>
        </div>
    );
}

export default SellInHeatMapPage;
