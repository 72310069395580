import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

// Sample data
const productSalesData = {
    products: ["Cups", "Basins", "Toothbrushes", "Buckets", "Plates", "Containers", "Spoons", "Bowls", "Trays", "Bottles"],
    sellIn: [1600, 2100, 1700, 2300, 1800, 2200, 1500, 2000, 2400, 1900],
    sellOut: [1400, 1900, 1900, 2100, 1600, 2000, 1700, 1800, 2200, 1700],
};

// Helper function to generate colors
const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const productColors = productSalesData.products.map(() => getRandomColor());

// Chart options
// Chart options
const getSalesSellInVsSellOutChartOptions = () => ({
    chart: {
        type: "bar",
    },
    title: {
        text: `Product Sales Sell In vs Sell Out - ${new Date().getFullYear()}`,
        align: "left",
    },
    // subtitle: {
    //     text: "Source: Internal Data",
    //     align: "left",
    // },
    xAxis: {
        categories: productSalesData.products,
        title: {
            text: "Products",
        },
        gridLineWidth: 1,
        lineWidth: 0,
    },
    yAxis: {
        min: 0,
        title: {
            text: "Sales (Ugx)",
            align: "high",
        },
        labels: {
            overflow: "justify",
        },
        gridLineWidth: 0,
    },
    tooltip: {
        valueSuffix: " Ugx",
    },
    plotOptions: {
        bar: {
            borderRadius: "50%",
            dataLabels: {
                enabled: true,
            },
            groupPadding: 0.1,
        },
    },
    legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true,
    },
    credits: {
        enabled: false,
    },
    series: [
        {
            name: "Sell In",
            data: productSalesData.sellIn,
            color: "#F9C80E", // Set color for Sell In
        },
        {
            name: "Sell Out",
            data: productSalesData.sellOut,
            color: "#F86624", // Set color for Sell Out
        },
    ],
});

// Nested pie chart options
const getNestedPieChartOptions = () => {
    const totalSellIn = productSalesData.sellIn.reduce((a, b) => a + b, 0);
    const totalSellOut = productSalesData.sellOut.reduce((a, b) => a + b, 0);

    const sellInData = productSalesData.products.map((product, index) => ({
        name: `${product} (Sell In)`,
        y: productSalesData.sellIn[index],
        color: productColors[index],
    }));

    const sellOutData = productSalesData.products.map((product, index) => ({
        name: `${product} (Sell Out)`,
        y: productSalesData.sellOut[index],
        color: productColors[index],
    }));

    return {
        chart: {
            type: "pie",
        },
        title: {
            text: "Product Sell In vs Sell Out",
        },
        tooltip: {
            valueSuffix: " units",
        },
        plotOptions: {
            pie: {
                shadow: false,
                center: ["50%", "50%"],
            },
        },
        series: [
            {
                name: "Total Price",
                data: [
                    {
                        name: "Sell In",
                        y: totalSellIn,
                        color: "#F9C80E",
                    },
                    {
                        name: "Sell Out",
                        y: totalSellOut,
                        color: "#F86624",
                    },
                ],
                size: "60%",
                dataLabels: {
                    formatter: function () {
                        return `<b>${this.point.name}:</b> ${this.y} Ugx`;
                    },
                    color: "#ffffff",
                    distance: -30,
                },
            },
            {
                name: "Price",
                data: [...sellInData, ...sellOutData],
                size: "80%",
                innerSize: "60%",
                dataLabels: {
                    formatter: function () {
                        return `<b>${this.point.name}:</b> ${this.y} Ugx`;
                    },
                },
                id: "versions",
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        series: [
                            {},
                            {
                                id: "versions",
                                dataLabels: {
                                    distance: 15,
                                    format: "{point.name}",
                                    filter: {
                                        property: "percentage",
                                        operator: ">",
                                        value: 2,
                                    },
                                },
                            },
                        ],
                    },
                },
            ],
        },
    };
};

const ProductSalesSellInVsSellOutTrendChart = () => {
    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={getSalesSellInVsSellOutChartOptions()} />
            <HighchartsReact highcharts={Highcharts} options={getNestedPieChartOptions()} />
        </>
    );
};

export default ProductSalesSellInVsSellOutTrendChart;
