import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);
// distributors  is the new naming of agents
// const sampleData = {
//     distributors: [
//         {
//             id: "Distributor1",
//             name: "Distributor A",
//             sply_sales: 100,
//             actual_sales: 120,
//             target_sales: 130,
//             categories: [
//                 {
//                     id: "category1",
//                     name: "Bottles",
//                     sply_sales: 100,
//                     actual_sales: 120,
//                     target_sales: 130,
//                     subCategories: [
//                         {
//                             id: "subcategory1-1",
//                             name: "Water Bottles",
//                             sply_sales: 50,
//                             actual_sales: 60,
//                             target_sales: 70,
//                             products: [
//                                 { name: "Bottle A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
//                                 { name: "Bottle B", sply_sales: 15, actual_sales: 18, target_sales: 20 },
//                                 { name: "Bottle C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
//                             ],
//                         },
//                         {
//                             id: "subcategory1-2",
//                             name: "Juice Bottles",
//                             sply_sales: 50,
//                             actual_sales: 60,
//                             target_sales: 70,
//                             products: [
//                                 { name: "Bottle D", sply_sales: 25, actual_sales: 28, target_sales: 30 },
//                                 { name: "Bottle E", sply_sales: 20, actual_sales: 22, target_sales: 25 },
//                             ],
//                         },
//                     ],
//                 },
//                 // Add more categories if needed
//             ],
//         },
//         {
//             id: "Distributor2",
//             name: "Distributor B",
//             sply_sales: 90,
//             actual_sales: 110,
//             target_sales: 120,
//             categories: [
//                 {
//                     id: "category2",
//                     name: "Containers",
//                     sply_sales: 90,
//                     actual_sales: 110,
//                     target_sales: 120,
//                     subCategories: [
//                         {
//                             id: "subcategory2-1",
//                             name: "Food Containers",
//                             sply_sales: 45,
//                             actual_sales: 55,
//                             target_sales: 60,
//                             products: [
//                                 { name: "Container A", sply_sales: 15, actual_sales: 20, target_sales: 25 },
//                                 { name: "Container B", sply_sales: 12, actual_sales: 15, target_sales: 18 },
//                                 { name: "Container C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
//                             ],
//                         },
//                         {
//                             id: "subcategory2-2",
//                             name: "Storage Containers",
//                             sply_sales: 45,
//                             actual_sales: 55,
//                             target_sales: 60,
//                             products: [
//                                 { name: "Container D", sply_sales: 25, actual_sales: 30, target_sales: 35 },
//                                 { name: "Container E", sply_sales: 20, actual_sales: 25, target_sales: 30 },
//                             ],
//                         },
//                     ],
//                 },
//                 // Add more categories if needed
//             ],
//         },
//         {
//             id: "Distributor3",
//             name: "Distributor C",
//             sply_sales: 80,
//             actual_sales: 100,
//             target_sales: 110,
//             categories: [
//                 {
//                     id: "category3",
//                     name: "Packaging",
//                     sply_sales: 80,
//                     actual_sales: 100,
//                     target_sales: 110,
//                     subCategories: [
//                         {
//                             id: "subcategory3-1",
//                             name: "Plastic Packaging",
//                             sply_sales: 40,
//                             actual_sales: 50,
//                             target_sales: 55,
//                             products: [
//                                 { name: "Package A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
//                                 { name: "Package B", sply_sales: 10, actual_sales: 12, target_sales: 15 },
//                                 { name: "Package C", sply_sales: 10, actual_sales: 13, target_sales: 15 },
//                             ],
//                         },
//                         {
//                             id: "subcategory3-2",
//                             name: "Glass Packaging",
//                             sply_sales: 40,
//                             actual_sales: 50,
//                             target_sales: 55,
//                             products: [
//                                 { name: "Package D", sply_sales: 20, actual_sales: 25, target_sales: 30 },
//                                 { name: "Package E", sply_sales: 20, actual_sales: 25, target_sales: 30 },
//                             ],
//                         },
//                     ],
//                 },
//                 // Add more categories if needed
//             ],
//         },
//         {
//             id: "Distributor4",
//             name: "Distributor D",
//             sply_sales: 70,
//             actual_sales: 90,
//             target_sales: 100,
//             categories: [
//                 {
//                     id: "category4",
//                     name: "Utensils",
//                     sply_sales: 70,
//                     actual_sales: 90,
//                     target_sales: 100,
//                     subCategories: [
//                         {
//                             id: "subcategory4-1",
//                             name: "Plastic Utensils",
//                             sply_sales: 35,
//                             actual_sales: 45,
//                             target_sales: 50,
//                             products: [
//                                 { name: "Utensil A", sply_sales: 15, actual_sales: 20, target_sales: 25 },
//                                 { name: "Utensil B", sply_sales: 10, actual_sales: 12, target_sales: 15 },
//                                 { name: "Utensil C", sply_sales: 10, actual_sales: 13, target_sales: 15 },
//                             ],
//                         },
//                         {
//                             id: "subcategory4-2",
//                             name: "Metal Utensils",
//                             sply_sales: 35,
//                             actual_sales: 45,
//                             target_sales: 50,
//                             products: [
//                                 { name: "Utensil D", sply_sales: 20, actual_sales: 25, target_sales: 30 },
//                                 { name: "Utensil E", sply_sales: 15, actual_sales: 20, target_sales: 25 },
//                             ],
//                         },
//                     ],
//                 },
//                 // Add more categories if needed
//             ],
//         },
//         {
//             id: "Distributor5",
//             name: "Distributor E",
//             sply_sales: 60,
//             actual_sales: 80,
//             target_sales: 90,
//             categories: [
//                 {
//                     id: "category5",
//                     name: "Accessories",
//                     sply_sales: 60,
//                     actual_sales: 80,
//                     target_sales: 90,
//                     subCategories: [
//                         {
//                             id: "subcategory5-1",
//                             name: "Phone Accessories",
//                             sply_sales: 30,
//                             actual_sales: 40,
//                             target_sales: 45,
//                             products: [
//                                 { name: "Accessory A", sply_sales: 15, actual_sales: 20, target_sales: 25 },
//                                 { name: "Accessory B", sply_sales: 10, actual_sales: 12, target_sales: 15 },
//                                 { name: "Accessory C", sply_sales: 5, actual_sales: 8, target_sales: 10 },
//                             ],
//                         },
//                         {
//                             id: "subcategory5-2",
//                             name: "Computer Accessories",
//                             sply_sales: 30,
//                             actual_sales: 40,
//                             target_sales: 45,
//                             products: [
//                                 { name: "Accessory D", sply_sales: 15, actual_sales: 20, target_sales: 25 },
//                                 { name: "Accessory E", sply_sales: 10, actual_sales: 12, target_sales: 15 },
//                                 { name: "Accessory F", sply_sales: 5, actual_sales: 8, target_sales: 10 },
//                             ],
//                         },
//                     ],
//                 },
//                 // Add more categories if needed
//             ],
//         },
//     ],
// };

// const sampleData = {
//     distributors: [
//         {
//             id: "Distributor1",
//             name: "Distributor A",
//             sply_sales: 100,
//             actual_sales: 120,
//             target_sales: 130,
//             categories: [
//                 {
//                     id: "category1",
//                     name: "Bottles",
//                     sply_sales: 100,
//                     actual_sales: 120,
//                     target_sales: 130,
//                     subCategories: [
//                         {
//                             id: "subcategory1-1",
//                             name: "Water Bottles",
//                             sply_sales: 50,
//                             actual_sales: 60,
//                             target_sales: 70,
//                             products: [
//                                 { id: "16741113280765", name: "Bottle A", sply_sales: 20, actual_sales: 25, target_sales: 30 },
//                                 { id: "16741113280", name: "Bottle B", sply_sales: 15, actual_sales: 18, target_sales: 20 },
//                                 { id: "1674111328076558987", name: "Bottle C", sply_sales: 10, actual_sales: 12, target_sales: 15 },
//                             ],
//                         },
//                         {
//                             id: "subcategory1-2",
//                             name: "Juice Bottles",
//                             sply_sales: 50,
//                             actual_sales: 60,
//                             target_sales: 70,
//                             products: [
//                                 { id: "1674111328076559", name: "Bottle D", sply_sales: 25, actual_sales: 28, target_sales: 30 },
//                                 { id: "1674111291505230", name: "Bottle E", sply_sales: 20, actual_sales: 22, target_sales: 25 },
//                             ],
//                         },
//                     ],
//                 },
//                 // Add more categories if needed
//             ],
//         },
//     ],
// };
const sampleData = {
    distributors: [
        {
            id: "1673858757354709087",
            name: "South West",
            sply_sales: 96885936.72000003,
            actual_sales: 169519096.82000005,
            target_sales: 293983074.17741925,
            categories: [
                {
                    id: "1673858757354708",
                    name: "WASHING H",
                    sply_sales: 28353690,
                    actual_sales: 67543920,
                    target_sales: 86040944.4677419,

                    subCategories: [
                        {
                            id: "1674026885489908",
                            name: "BASIN ROUND (VIRGIN)",
                            sply_sales: 24364800,
                            actual_sales: 65132600,
                            target_sales: 57687279.72580643,

                            products: [
                                {
                                    id: "1674111328076558",
                                    name: "Basin Round(Virgin) 15Ltr MULTPLE-COLORS",
                                    sply_sales: 14128200,
                                    actual_sales: 53453100,
                                    target_sales: 3605454.982862902,
                                },
                                {
                                    id: "1674111291505230",
                                    name: "Basin Round(Virgin) 15Ltr BLOOD-RED",
                                    sply_sales: 1367700,
                                    actual_sales: 0,
                                    target_sales: 3605454.982862902,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

function DistributorPerformanceDrillWithLollipopCombinedChart({ data = sampleData }) {
    const chartRef = useRef(null);

    // Format data for chart series
    const formatData = (items) => {
        return items.map((item) => ({
            name: item.name,
            sply: item.sply_sales,
            actual: item.actual_sales,
            target: item.target_sales,
            drilldown: item.id,
        }));
    };

    // Calculate percentage change
    const calculatePercentageChange = (actual, comparison) => {
        return ((actual - comparison) / comparison) * 100;
    };

    // Create drilldown series for each level
    const createDrilldownSeries = (items, level) => {
        return items.map((item) => {
            const nextLevel = level === "distributors" ? "categories" : level === "categories" ? "subCategories" : "products";
            const hasNextLevel = item[nextLevel] && item[nextLevel].length > 0;
            const drilldownData = hasNextLevel ? formatData(item[nextLevel]) : [];

            return {
                id: item.id,
                data: drilldownData,
                drilldown: hasNextLevel,
            };
        });
    };

    // Create percentage change lollipop series
    const createPercentageChangeSeries = (items, comparisonType) => {
        return {
            type: "lollipop",
            name: `Percentage Change Actual vs. ${comparisonType}`,
            data: items.map((item) => {
                const percentageChange = calculatePercentageChange(item.actual_sales, item[`${comparisonType.toLowerCase()}_sales`]);
                return {
                    name: item.name,
                    y: percentageChange,
                    color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                };
            }),
            yAxis: 1,
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
            },
            marker: {
                enabled: true,
                lineColor: null,
            },
            dataLabels: {
                enabled: true,
                format: "{point.y:.2f}%",
            },
        };
    };

    // Create drilldown series for all levels
    const drilldownSeries = [
        ...createDrilldownSeries(data.distributors, "distributors"),
        ...data.distributors.flatMap((item) => createDrilldownSeries(item.categories, "categories")),
        ...data.distributors.flatMap((item) => item.categories.flatMap((category) => createDrilldownSeries(category.subCategories, "subCategories"))),
    ];

    const options = {
        chart: {
            type: "bar",
            events: {
                drilldown: function (e) {
                    if (!e.seriesOptions) {
                        const chart = this;
                        const series = chart.userOptions.drilldown.series.find((s) => s.id === e.point.drilldown);

                        const splyData = series.data.map((item) => ({ name: item.name, y: item.sply, drilldown: item.drilldown }));
                        const actualData = series.data.map((item) => ({ name: item.name, y: item.actual, drilldown: item.drilldown }));
                        const targetData = series.data.map((item) => ({ name: item.name, y: item.target, drilldown: item.drilldown }));

                        // Add SPLY, Actual, and Target sales series
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            name: "SPLY Sales",
                            data: splyData,
                            color: Highcharts.getOptions().colors[0],
                        });
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            name: "Actual Sales",
                            data: actualData,
                            color: Highcharts.getOptions().colors[1],
                        });
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            name: "Target Sales",
                            data: targetData,
                            color: Highcharts.getOptions().colors[2],
                        });

                        // Add percentage change lollipop series for SPLY comparison
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            type: "lollipop",
                            name: "Percentage Change Actual vs. SPLY",
                            data: series.data.map((item) => {
                                const percentageChange = calculatePercentageChange(item.actual, item.sply);
                                return {
                                    name: item.name,
                                    y: percentageChange,
                                    color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                };
                            }),
                            yAxis: 1,
                            tooltip: {
                                pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
                            },
                            marker: {
                                enabled: true,
                                lineColor: null,
                            },
                            dataLabels: {
                                enabled: true,
                                format: "{point.y:.2f}%",
                            },
                        });

                        // Add percentage change lollipop series for Target comparison
                        chart.addSingleSeriesAsDrilldown(e.point, {
                            type: "lollipop",
                            name: "Percentage Change Actual vs. Target",
                            data: series.data.map((item) => {
                                const percentageChange = calculatePercentageChange(item.actual, item.target);
                                return {
                                    name: item.name,
                                    y: percentageChange,
                                    color: percentageChange < 0 ? "red" : percentageChange > 0 ? "green" : "orange",
                                };
                            }),
                            yAxis: 1,
                            tooltip: {
                                pointFormat: "{series.name}: <b>{point.y:.2f}%</b>",
                            },
                            marker: {
                                enabled: true,
                                lineColor: null,
                            },
                            dataLabels: {
                                enabled: true,
                                format: "{point.y:.2f}%",
                            },
                        });

                        chart.applyDrilldown();
                    }
                },
            },
        },
        title: { text: "Distributors Sales Performance" },
        xAxis: { type: "category" },
        yAxis: [
            {
                title: { text: "Sales" },
                width: "60%",
                lineWidth: 2,
                offset: 0,
            },
            {
                title: { text: "Percentage Change" },
                left: "65%",
                width: "30%",
                offset: 0,
                lineWidth: 2,
                max: 100,
                min: -100,
            },
        ],
        legend: { enabled: true },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y}",
                },
            },
        },
        credits: {
            enabled: false,
        },
        // Main chart series
        series: [
            {
                name: "SPLY Sales",
                data: data.distributors.map((item) => ({
                    name: item.name,
                    y: item.sply_sales,
                    drilldown: item.id,
                    color: Highcharts.getOptions().colors[0],
                })),
            },
            {
                name: "Actual Sales",
                data: data.distributors.map((item) => ({
                    name: item.name,
                    y: item.actual_sales,
                    drilldown: item.id,
                    color: Highcharts.getOptions().colors[1],
                })),
            },
            {
                name: "Target Sales",
                data: data.distributors.map((item) => ({
                    name: item.name,
                    y: item.target_sales,
                    drilldown: item.id,
                    color: Highcharts.getOptions().colors[2],
                })),
            },
            createPercentageChangeSeries(data.distributors, "SPLY"),
            createPercentageChangeSeries(data.distributors, "Target"),
        ],
        drilldown: {
            allowPointDrilldown: false,
            breadcrumbs: {
                position: {
                    align: "right",
                },
            },
            activeDataLabelStyle: {
                color: "#FFFFFF",
                textDecoration: "none",
                textOutline: "1px #000000",
            },
            series: drilldownSeries,
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} immutable={true} />
        </div>
    );
}

export default DistributorPerformanceDrillWithLollipopCombinedChart;
