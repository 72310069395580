import axiosAPI from "../axiosApi";

export async function getAllRegions(params = {}) {
    const response = await axiosAPI.get("regions", { params: params });
    return response;
}

export async function getRegionById(id) {
    const response = await axiosAPI.get(`regions/` + id);
    return response;
}

export async function postRegion(data) {
    const response = await axiosAPI.post(`regions`, data);
    return response;
}

export async function updateRegion(id, data) {
    const response = await axiosAPI.post(`regions/${id}`, data);
    return response;
}

export async function deleteRegionById(id) {
    const response = await axiosAPI.delete(`regions/${id}`);
    return response;
}
