import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";

//
import { Form, Field } from "react-final-form";
import { InputNumber } from "primereact/inputnumber";

import { OverlayPanel } from "primereact/overlaypanel";
import setFieldTouched from "final-form-set-field-touched";
import { classNames } from "primereact/utils";

//
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DashboardFilterContext } from "../../../../context/DashboardFilterContext";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { AutoComplete } from "primereact/autocomplete";
//
import { getAllNiceTwoRegions, getAllNiceTwoRoutes } from "../../../../services/nice-two-endpoints/nice-two-dashboard-endpoints";
import { ProgressBar } from "primereact/progressbar";

import { Button } from "primereact/button";

// import LinesRunningLoaderLottie from "../../../assets/lotties/nice-hop/lines-running-loader-lottie.json";
// import MaterialUiLoaderLottie from "../../../assets/lotties/nice-hop/material-ui-loading-lottie.json";
// import RobotProcessorLottie from "../../../assets/lotties/nice-hop/robot-processor-lottie.json";
// import BrownFilesProcessingLottie from "../../../assets/lotties/nice-hop/brown-files-processing-lottie.json";
// import ComputerInFourCirclesLottie from "../../../assets/lotties/nice-hop/computer-in-four-circles-lottie.json";
// import ServerProcessingLottie from "../../../assets/lotties/nice-hop/server-processing-lottie.json";
// import DarkBluePeopleOnServerLottie from "../../../assets/lotties/nice-hop/dark-blue-people-on-server-lottie.json";
// import BoyGirlPlottingGraphLottie from "../../../assets/lotties/nice-hop/boy-girl-plotting-graph-lottie.json";
// import BoyBoyScanningFilesLottie from "../../../assets/lotties/nice-hop/boy-boy-scanning-files-lottie.json";
// import GirlGirlPlottingGraphsLottie from "../../../assets/lotties/nice-hop/girl-girl-plotting-graphs-lottie.json";
// import SnailErrorLottie from "../../../assets/lotties/nice-hop/snail-error-lottie.json";
// import Lottie from "lottie-react";

// Initialize the modules
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

// Sample hierarchical data
const sampleData = {
    categories: [
        {
            id: "category1",
            name: "Bottles",
            sales: 50000,
            subCategories: [
                {
                    id: "subcategory1-1",
                    name: "Water Bottles",
                    sales: 30000,
                    products: [
                        { name: "Bottle A", sales: 15000 },
                        { name: "Bottle B", sales: 10000 },
                        { name: "Bottle C", sales: 5000 },
                    ],
                },
                {
                    id: "subcategory1-2",
                    name: "Juice Bottles",
                    sales: 20000,
                    products: [
                        { name: "Bottle D", sales: 12000 },
                        { name: "Bottle E", sales: 8000 },
                    ],
                },
            ],
        },
        {
            id: "category2",
            name: "Containers",
            sales: 40000,
            subCategories: [
                {
                    id: "subcategory2-1",
                    name: "Food Containers",
                    sales: 25000,
                    products: [
                        { name: "Container A", sales: 10000 },
                        { name: "Container B", sales: 8000 },
                        { name: "Container C", sales: 7000 },
                    ],
                },
                {
                    id: "subcategory2-2",
                    name: "Storage Containers",
                    sales: 15000,
                    products: [
                        { name: "Container D", sales: 9000 },
                        { name: "Container E", sales: 6000 },
                    ],
                },
            ],
        },
    ],
};

const generateDrilldownData = (data) => {
    const drilldownSeries = [];
    const categoriesData = data.categories.map((category) => {
        drilldownSeries.push({
            id: category.id,
            name: `${category.name} Sub-categories`,
            data: category.subCategories.map((subCategory) => ({
                name: subCategory.name,
                y: subCategory.sales,
                drilldown: subCategory.id,
            })),
        });
        category.subCategories.forEach((subCategory) => {
            drilldownSeries.push({
                id: subCategory.id,
                name: `${subCategory.name} Products`,
                data: subCategory.products.map((product) => [product.name, product.sales]),
            });
        });
        return {
            name: category.name,
            y: category.sales,
            drilldown: category.id,
        };
    });
    return { categoriesData, drilldownSeries };
};

const { categoriesData, drilldownSeries } = generateDrilldownData(sampleData);

const options = {
    chart: {
        type: "bar",
    },
    title: {
        text: "Product Categories Ranked by Sales",
    },
    subtitle: {
        text: "Click the columns to view sub-categories and products.",
    },
    xAxis: {
        type: "category",
    },
    yAxis: {
        title: {
            text: "Total Sales (UGX)",
        },
    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: "{point.y:.1f}",
            },
        },
    },
    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f} UGX</b><br/>',
    },
    series: [
        {
            name: "Categories",
            colorByPoint: true,
            data: categoriesData,
        },
    ],
    drilldown: {
        breadcrumbs: {
            position: {
                align: "right",
            },
        },
        series: drilldownSeries,
    },
    credits: {
        enabled: false,
    },
};

const SelloutProductDrilldownChart = () => {
    const op = useRef(null);
    const [initialData, setInitialData] = useState({ firstRecords: 5, lastRecords: null });

    const validate = (values) => {
        console.log("🚀 ~ validate ~ values:", values);
        const errors = {};
        if ((values.firstRecords === null || values.firstRecords === "") && (values.lastRecords === null || values.lastRecords === "")) {
            errors.firstRecords = "One of firstRecords or lastRecords is required";
            errors.lastRecords = "One of firstRecords or lastRecords is required";
        }
        if (values.firstRecords !== null && values.firstRecords < 0) {
            errors.firstRecords = "Must be 0 or greater";
        }
        if (values.lastRecords !== null && values.lastRecords < 0) {
            errors.lastRecords = "Must be 0 or greater";
        }
        return errors;
    };

    const onSubmitForm = (values) => {
        console.log("Form submitted successfully", values);
        setInitialData(values);
        // Handle form submission here
    };

    //===================================================================
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [filteredRegions, setFilteredRegions] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [filteredRoutes, setFilteredRoutes] = useState([]);
    // Nice Two Regions
    const niceTwoRegionsQuery = useQuery({
        disable: false,
        queryKey: ["nice-two-regions"],
        queryFn: () => getAllNiceTwoRegions({}),
    });

    useEffect(() => {
        if (niceTwoRegionsQuery?.isError) {
            console.log("🚀 ~ useEffect ~ niceTwoRegionsQuery?.isError:", niceTwoRegionsQuery?.error);
            niceTwoRegionsQuery?.error?.response?.data?.message ? toast.error(niceTwoRegionsQuery?.error?.response?.data?.message) : !niceTwoRegionsQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [niceTwoRegionsQuery?.isError]);

    console.log("data for NiceTwo niceTwoRegionsQuery?.data?.data is:", niceTwoRegionsQuery?.data?.data);

    // Nice Two Routes
    const niceTwoRoutesQuery = useQuery({
        queryKey: ["nice-two-routes", selectedRegion?.name],
        queryFn: () => getAllNiceTwoRoutes({ region_name: selectedRegion?.name }),
        enabled: !!selectedRegion, // Only run this query if a region is selected
    });

    useEffect(() => {
        if (niceTwoRoutesQuery?.isError) {
            console.log("🚀 ~ useEffect ~ niceTwoRoutesQuery?.isError:", niceTwoRoutesQuery?.error);
            niceTwoRoutesQuery?.error?.response?.data?.message ? toast.error(niceTwoRoutesQuery?.error?.response?.data?.message) : !niceTwoRoutesQuery?.error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occurred Please Contact Admin");
        }
    }, [niceTwoRoutesQuery?.isError]);

    console.log("data for NiceTwo niceTwoRoutesQuery?.data?.data is:", niceTwoRoutesQuery?.data?.data);

    return (
        <div>
            <Button icon="pi pi-filter" onClick={(e) => op.current.toggle(e)} />

            <OverlayPanel ref={op}>
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    initialValuesEqual={() => true}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, values }) => {
                        console.log("🚀 ~ SelloutProductDrilldownChart ~ values:", values);

                        return (
                            <form onSubmit={handleSubmit}>
                                {/* First Records Field */}
                                <div className="p-field m-4">
                                    <Field name="firstRecords">
                                        {({ input, meta }) => (
                                            <div>
                                                <label htmlFor="firstRecords">First Records</label>
                                                <InputNumber
                                                    id="firstRecords"
                                                    {...input}
                                                    value={values.firstRecords}
                                                    onChange={(event) => input.onChange(event.value)}
                                                    onValueChange={(event) => input.onChange(event.value)}
                                                    disabled={values.lastRecords > 0}
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {/* Last Records Field */}
                                <div className="p-field m-4">
                                    <Field name="lastRecords">
                                        {({ input, meta }) => (
                                            <div>
                                                <label htmlFor="lastRecords">Last Records</label>
                                                <InputNumber
                                                    id="lastRecords"
                                                    {...input}
                                                    value={values.lastRecords}
                                                    onChange={(event) => input.onChange(event.value)}
                                                    onValueChange={(event) => input.onChange(event.value)}
                                                    disabled={values.firstRecords > 0}
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {/* Region Field */}
                                <div className="p-field m-4">
                                    <Field name="region_id">
                                        {({ input, meta }) => (
                                            <div>
                                                <label htmlFor="region_id">Region</label>
                                                <AutoComplete
                                                    value={selectedRegion?.name || ""}
                                                    suggestions={filteredRegions}
                                                    completeMethod={(e) => {
                                                        const results = niceTwoRegionsQuery.data?.data?.data.filter((region) => {
                                                            return region.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                        });
                                                        setFilteredRegions(results);
                                                    }}
                                                    field="name"
                                                    dropdown={true}
                                                    onChange={(e) => {
                                                        if (typeof e.value === "string") {
                                                            setSelectedRegion({ name: e.value });
                                                            input.onChange("");
                                                        } else if (typeof e.value === "object" && e.value !== null) {
                                                            setSelectedRegion(e.value);
                                                            input.onChange(e.value.id);

                                                            // Reset the selected route
                                                            setSelectedRoute(null);
                                                            form.change("route_id", null); // Reset the form state for route_id
                                                        }
                                                    }}
                                                    id="region"
                                                    selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Region"}</div>}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {niceTwoRegionsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {/* Route Field */}
                                <div className="p-field m-4">
                                    <Field name="route_id">
                                        {({ input, meta }) => (
                                            <div>
                                                <label htmlFor="route_id">Route</label>
                                                <AutoComplete
                                                    value={selectedRoute?.name || ""}
                                                    suggestions={filteredRoutes}
                                                    completeMethod={(e) => {
                                                        const results = niceTwoRoutesQuery.data?.data?.data.filter((route) => {
                                                            return route.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                        });
                                                        setFilteredRoutes(results);
                                                    }}
                                                    field="name"
                                                    dropdown={true}
                                                    onChange={(e) => {
                                                        if (typeof e.value === "string") {
                                                            setSelectedRoute({ name: e.value });
                                                            input.onChange("");
                                                        } else if (typeof e.value === "object" && e.value !== null) {
                                                            setSelectedRoute(e.value);
                                                            input.onChange(e.value.id);
                                                        }
                                                    }}
                                                    id="route"
                                                    selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Route"}</div>}
                                                    disabled={!selectedRegion}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {niceTwoRoutesQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {/* Submit Button */}
                                <Button type="submit" label="Filter" className="p-button-primary" />
                            </form>
                        );
                    }}
                />
            </OverlayPanel>

            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default SelloutProductDrilldownChart;
