import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import LollipopModule from "highcharts/modules/lollipop";

// Initialize the modules
HighchartsMore(Highcharts);
highchartsDumbbell(Highcharts);
LollipopModule(Highcharts);

const sampleData = [
    {
        name: "Chairs",
        sply: 100,
        actual: 120,
        target: 130,
        percentageChangeActualTarget: 7.7,
        percentageChangeActualSPLY: 20,
    },
    {
        name: "Basins",
        sply: 90,
        actual: 85,
        target: 95,
        percentageChangeActualTarget: -10.5,
        percentageChangeActualSPLY: -5.6,
    },
    {
        name: "Cups",
        sply: 110,
        actual: 115,
        target: 120,
        percentageChangeActualTarget: 4.3,
        percentageChangeActualSPLY: 4.5,
    },
];

const LayeredProductSalesChart = () => {
    const options = {
        chart: {
            type: "bar",
        },
        title: {
            text: "Plastic Product Sales",
        },
        xAxis: {
            categories: sampleData.map((data) => data.name),
        },
        yAxis: [
            {
                title: {
                    text: "Sales",
                },
                width: "30%",
                lineWidth: 2,
                offset: 0,
            },
            {
                title: {
                    text: "Percentage Change Actual vs. Target",
                },
                left: "35%", // Adjust left to prevent chart axis intersect
                width: "30%",
                offset: 0, // Adjust the offset to create spacing
                lineWidth: 2,
            },
            {
                title: {
                    text: "Percentage Change Actual vs. SPLY",
                },
                left: "70%", // Adjust left to prevent chart axis intersect
                width: "30%",
                offset: 0, // Adjust the offset to create spacing
                lineWidth: 2,
            },
        ],
        series: [
            {
                type: "bar",
                name: "SPLY",
                data: sampleData.map((data) => data.sply),
                yAxis: 0,
                color: "#FBCEB1",
                showInLegend: true,
            },
            {
                type: "bar",
                name: "Actual",
                data: sampleData.map((data) => data.actual),
                yAxis: 0,
                color: "#1e90ff",
                showInLegend: true,
            },
            {
                type: "bar",
                name: "Target",
                data: sampleData.map((data) => data.target),
                yAxis: 0,
                color: "#90ed7d",
                showInLegend: true,
            },
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. Target",
                data: sampleData.map((data) => ({
                    y: data.percentageChangeActualTarget,
                    color: data.percentageChangeActualTarget > 0 ? "#90ed7d" : "#f45b5b",
                })),
                yAxis: 1,
                marker: {
                    enabled: true,
                    lineColor: null, // Remove black border
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
            },
            {
                type: "lollipop",
                name: "Percentage Change Actual vs. SPLY",
                data: sampleData.map((data) => ({
                    y: data.percentageChangeActualSPLY,
                    color: data.percentageChangeActualSPLY > 0 ? "#90ed7d" : "#f45b5b",
                })),
                yAxis: 2,
                marker: {
                    enabled: true,
                    lineColor: null, // Remove black border
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.y}%",
                },
            },
        ],
        plotOptions: {
            lollipop: {
                colorByPoint: false,
                marker: {
                    lineColor: null, // Remove black border
                    lineWidth: 1,
                },
            },
            bar: {
                // grouping: false, // Prevent bars from overlapping
                // pointPadding: 0.1, // Adjust padding between bars
                // groupPadding: 0.1, // Adjust padding between groups of bars
            },
        },
    };

    return (
        <div className="col-12">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default LayeredProductSalesChart;
