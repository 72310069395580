import React from "react";
import CoverageCards from "./CoverageCards";
import { Grid, Stack } from "@mui/material";
import TotalCustomersCard from "./TotalCustomersCard";
import TotalVisitsCard from "./TotalVisitsCard";
import TotalUniqueVisitsCard from "./TotalUniqueVisitsCard";
import TotalProductiveVisitsCard from "./TotalProductiveVisitsCard";
import TotalUniqueProductiveVisitsCard from "./TotalUniqueProductiveVisitsCard";
import NewCustomerStatisticsCard from "./NewCustomerStatisticsCard";
import TotalVisitsTimeCard from "./TotalVisitsTimeCard";
import AverageVisitsTimeCard from "./AverageVisitsTimeCard";
import MedianVisitsTimeCard from "./MedianVisitsTimeCard";
import InactiveCustomerStatisticsCards from "./InactiveCustomerStatisticsCards";
import AgentVisitsPieChartCard from "./AgentVisitsPieChartCard";
import SalesAssociateVisitsPieChartCard from "./SalesAssociateVisitsPieChartCard";

function CoverageCardsPage() {
    return (
        <div>
            <Grid style={{ padding: "10px" }} container spacing={2} justifyContent="center" alignItems="center">
                <TotalCustomersCard />
                <TotalVisitsCard />
                <TotalUniqueVisitsCard />
                <TotalProductiveVisitsCard />
                <TotalUniqueProductiveVisitsCard />
                <NewCustomerStatisticsCard />
                <TotalVisitsTimeCard />
                <AverageVisitsTimeCard />
                <MedianVisitsTimeCard />
                <InactiveCustomerStatisticsCards />
                <Grid item xs={12} md={12} lg={12} container spacing={2} justifyContent="center" alignItems="center">
                    <AgentVisitsPieChartCard />

                    <SalesAssociateVisitsPieChartCard />
                </Grid>

                {/* <RevenueWeekToDate measure={measure} />

                <RevenueMonthToDate measure={measure} />

                <RevenueYearToDate measure={measure} /> */}

                {/* <Grid item xs={12} md={6} lg={4}>
                    <StatsCardTarget
                        measure={measure}
                       
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <SpotLandingCard {...data.spotLanding} measure={measure} />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <RunRateCard {...data.dailyRunRate} measure={measure} />
                </Grid> */}
            </Grid>
        </div>
    );
}

export default CoverageCardsPage;
